export class APPError extends Error {
  constructor(message: string, cause: Error) {
    super(message, { cause })
    this.name = 'APPError'
  }
}
export class APPErrorWithData extends Error {
  public data: unknown
  constructor(message: string, data: unknown, cause?: Error) {
    super(message, { cause })
    this.name = 'APPErrorWithData'
    this.data = data
  }
}
