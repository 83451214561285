import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { FEUIConfig } from 'types/types'

import { useThemeContext } from './ThemeProvider'

type SidebarContextType = {
  canFadeIn: boolean
  handleSidebarToggle: () => void
  isSidebarExpanded: boolean
  openIndices: number[]
  setCanFadeIn: Dispatch<SetStateAction<boolean>>
  setIsSidebarExpanded: Dispatch<SetStateAction<boolean>>
  setOpenIndices: Dispatch<SetStateAction<number[]>>
}

export const SidebarContext = createContext<SidebarContextType>({} as SidebarContextType)

export const SidebarProvider = ({ children, config }: { children: ReactNode; config: FEUIConfig[] | undefined }) => {
  const { isLaptop, isTablet } = useThemeContext()
  const location = useLocation()

  const [isSidebarExpanded, setIsSidebarExpanded] = useState<boolean>(isLaptop) // We use this value so that we can add nice transition when going from an un-expanded to an expanded state
  const [openIndices, setOpenIndices] = useState<number[]>([]) // Track open indices of the accordion
  const [canFadeIn, setCanFadeIn] = useState<boolean>(false) // Used to know which content we want to fade in

  // Filter the bot array to see if we are currently on a chat bot
  const isBotPage = config ? config.some((bot) => location.pathname.includes(bot.route)) : false

  const handleSidebarToggle = () => {
    // The value of isSidebarExpanded represents the state the sidebar was in before toggling it
    if (isSidebarExpanded && isTablet) {
      setCanFadeIn(false) // Reset fade state when collapsing, only on a large viewpoint
      setOpenIndices([]) // Only close indices when on desktop view
    }

    setIsSidebarExpanded(!isSidebarExpanded)
  }

  useEffect(() => {
    if (!isTablet && isSidebarExpanded) {
      // If we go from isTablet -> !isTablet where the sidebar is expanded, close it automatically
      setIsSidebarExpanded(false)
    } else if (isTablet && !isSidebarExpanded) {
      // If we go from !isTablet -> isTablet where the sidebar is not expanded, don't by default let items fade in within the sidebar
      setCanFadeIn(false)
      // Also reset open indices that might have been opened on a mobile view
      setOpenIndices([])
    } else {
      setCanFadeIn(true)
    }
    // We only want this useEffect to run when we go in between breakpoints, specifically isTablet
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTablet])

  useEffect(() => {
    if (!isBotPage) {
      // If we move from a Bot page to a non-Bot page, reset these indices
      setOpenIndices([])
    }
  }, [isBotPage])

  return (
    <SidebarContext.Provider
      value={{
        canFadeIn,
        handleSidebarToggle,
        isSidebarExpanded,
        openIndices,
        setCanFadeIn,
        setIsSidebarExpanded,
        setOpenIndices,
      }}
    >
      {children}
    </SidebarContext.Provider>
  )
}

export const useSidebarContext = (): SidebarContextType => useContext(SidebarContext)
