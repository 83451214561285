import { useMemo } from 'react'
import { BoxProps } from '@chakra-ui/layout'
import { AnimatePresence, AnimatePresenceProps, motion } from 'framer-motion'

type AnimatedBoxProps = {
  type?: 'fade' | 'slide'
  slideDirection?: 'left' | 'right' | 'up' | 'down'
  delay?: number // seconds
  duration?: number // seconds
} & BoxProps &
  Pick<AnimatePresenceProps, 'mode'>

export const AnimatedBox = (props: AnimatedBoxProps) => {
  const {
    children,
    className,
    delay = 0.1,
    duration = 0.3,
    mode = 'wait',
    slideDirection = 'up',
    type = 'fade',
  } = props

  const fadeVariants = useMemo(
    () => ({
      initial: { opacity: 0 },
      animate: { opacity: 1 },
      exit: { opacity: 0 },
    }),
    []
  )

  const slideVariants = useMemo(
    () => ({
      initial: {
        opacity: 0,
        x: slideDirection === 'left' ? 20 : slideDirection === 'right' ? -20 : 0,
        y: slideDirection === 'up' ? 20 : slideDirection === 'down' ? -20 : 0,
      },
      animate: { opacity: 1, x: 0, y: 0 },
      exit: { opacity: 0 },
    }),
    [slideDirection]
  )

  const motionVariants = useMemo(() => {
    switch (type) {
      case 'slide':
        return slideVariants
      case 'fade':
      default:
        return fadeVariants
    }
  }, [fadeVariants, slideVariants, type])

  return (
    <AnimatePresence mode={mode}>
      <motion.div
        {...motionVariants}
        transition={{ duration: duration, delay: delay }}
        tabIndex={0}
        className={className}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  )
}
