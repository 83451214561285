import React, { useContext, useEffect, useState } from 'react'
import { initReactI18next, useTranslation } from 'react-i18next'
import i18n, { TFunction } from 'i18next'
import httpBackend from 'i18next-http-backend'

import { SuspenseFullPage } from '../router/Fallback'
import { ChildrenProps } from '../types/types'

import { useConfigContext } from './ConfigurationProvider'

i18n
  .use(httpBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-US',
    defaultNS: 'generic',
    debug: false,
    supportedLngs: ['en-US', 'fr-CA'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      allowMultiLoading: false,
      loadPath: (lngs: string[], namespaces: string[]) => {
        return namespaces.map((ns) => {
          if (ns.endsWith('Modal')) {
            // namespaces in /locales/modals/
            return `/locales/${lngs[0]}/modals/${ns}.json`
          } else if (ns.startsWith('bot/')) {
            // bot specific namespaces
            return `/${ns.split('/')[1]}/assets/locales/${lngs[0]}/translation.json`
          } else if (ns.includes('appconfiguration')) {
            return ''
          } else {
            // all namespaces in /locales
            return `/locales/${lngs[0]}/${ns}.json`
          }
        })
      },
    },
  })

export type LanguageAbbreviationType = 'en' | 'fr'

export type I18ContextType = {
  changeLanguage: (lang: string) => void
  doesTranslationExist: (value: string, ns: string) => boolean
  doesBotTranslationExist: (value: string, ns: string) => boolean
  getTForNS: (ns: string) => TFunction
  language: string
  languageAbbreviation: LanguageAbbreviationType
  LANGUAGES: string[]
  loadBotTranslations: (botName: string) => Promise<void>
  loadUISpecificTranslations: (configurationName: 'standard' | 'as') => Promise<void>
  t: TFunction<'generic'>
  tc: TFunction<'termsAndConditions'>
}

const isLanguage = (lang: string): lang is LanguageAbbreviationType => {
  return lang === 'en' || lang === 'fr'
}

export const I18Context = React.createContext<I18ContextType>({} as I18ContextType)

export function I18Provider({ children }: ChildrenProps) {
  const langFromQuery = new URL(window.location.href).searchParams.get('lang') || 'en-US'
  const langSlice = langFromQuery.slice(0, 2)
  const [language, setLanguage] = useState<string>(langFromQuery)
  const [languageAbbreviation, setLanguageAbbreviation] = useState<LanguageAbbreviationType>(
    isLanguage(langSlice) ? langSlice : 'en'
  )
  const { LANGUAGES } = useConfigContext()
  const { t, i18n } = useTranslation()
  const { t: tc } = useTranslation('termsAndConditions')
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    // preload only en-US
    i18n.loadLanguages(['en-US']).then(() => {
      // preload namespaces
      i18n
        .loadNamespaces([
          'about',
          'assessment',
          'chart',
          'controls',
          'filters',
          'generic',
          'home',
          'kBots',
          'landing',
          'clearFilesModal',
          'clearMessagesModal',
          'deleteInstanceModal',
          'feedbackModal',
          'generalModal',
          'sessionModal',
          'signInModal',
          'uploadModal',
          'navigation/footer',
          'navigation/navbar',
          'promptLibrary',
          'settings',
          'termsAndConditions',
          'translation',
          'userProfile',
        ])
        .then(() => {
          setIsReady(true) // Mark as ready when translations are loaded
        })
    })
  }, [i18n])

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [i18n, language])

  if (!isReady) {
    // Display fallback until translations are loaded
    return <SuspenseFullPage />
  }

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang, function () {
      setLanguage(lang)
      const langSlice = lang.slice(0, 2)
      setLanguageAbbreviation(isLanguage(langSlice) ? langSlice : 'en')
    })
  }
  const getTForNS = (ns: string) => {
    return i18n.getFixedT(language, ns, '')
  }
  const loadBotTranslations = async (botName: string) => {
    await i18n.loadNamespaces(`bot/${botName.toLocaleLowerCase()}`)
  }

  const loadUISpecificTranslations = async (configurationName: 'standard' | 'as') => {
    await i18n.loadNamespaces(`appconfiguration/${configurationName}`)
  }

  const doesTranslationExist = (value: string, ns: string) => {
    return i18n.exists(value, {
      ns,
      fallbackLng: [], // Disable fallback languages for this check
    })
  }

  const doesBotTranslationExist = (value: string, ns: string) => {
    return doesTranslationExist(value, `bot/${ns}`)
  }

  return (
    <>
      <I18Context.Provider
        value={{
          changeLanguage,
          doesBotTranslationExist,
          doesTranslationExist,
          getTForNS,
          language,
          languageAbbreviation,
          LANGUAGES,
          loadBotTranslations,
          loadUISpecificTranslations,
          t,
          tc,
        }}
      >
        {children}
      </I18Context.Provider>
    </>
  )
}

export const useI18Context = (): I18ContextType => useContext(I18Context)
