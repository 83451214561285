import { ClientEngagementConfig, ReportHierarchy, Translate } from '@kleo/types'
import { TFunction } from 'i18next'

import { TableRow, TableRowStatus } from 'types/types'

import { formattedDate } from './formattedDate'

// use to normalize report data to TableRow format
export const normalizeReportData = (data: ReportHierarchy, language: string): TableRow[] => {
  const result: TableRow[] = []

  for (const tier1 in data) {
    result.push({
      id: tier1,
      name: tier1,
      parentId: undefined,
      type: 'folder',
    })

    for (const tier2 in data[tier1]) {
      const tier2Id = `${tier1}/${tier2}`
      result.push({
        id: tier2Id,
        name: tier2,
        parentId: tier1,
        type: 'folder',
      })

      for (const categoryId in data[tier1][tier2]) {
        const categoryIdFull = `${tier2Id}/${categoryId}`
        result.push({
          id: categoryIdFull,
          name: categoryId,
          parentId: tier2Id,
          type: 'folder',
        })

        data[tier1][tier2][categoryId].forEach((file) => {
          result.push({
            id: `${categoryIdFull}/${file.reportId}`,
            name: file.reportId,
            parentId: categoryIdFull,
            type: 'file',
            created: formattedDate(parseInt(file.created), language),
            epoch: file.created,
          })
        })
      }
    }
  }

  return result
}

const summarizeFileStatus = (rawStatus: Translate.TranslationJobStatus): TableRowStatus => {
  if (
    rawStatus === Translate.TranslationJobStatus.Failed ||
    rawStatus === Translate.TranslationJobStatus.Canceled ||
    rawStatus === Translate.TranslationJobStatus.ValidationFailed
  ) {
    return 'failed'
  }

  if (
    rawStatus === Translate.TranslationJobStatus.Cancelling ||
    rawStatus === Translate.TranslationJobStatus.NotStarted ||
    rawStatus === Translate.TranslationJobStatus.Running
  ) {
    return 'inProgress'
  }

  return 'success'
}

const summarizeFolderStatus = (folder: Translate.FolderData) => {
  let hasFailed = false
  let hasSucceeded = false

  for (const file of folder.files) {
    if (
      file.status === Translate.TranslationJobStatus.Cancelling ||
      file.status === Translate.TranslationJobStatus.NotStarted ||
      file.status === Translate.TranslationJobStatus.Running
    ) {
      return 'inProgress'
    }
    if (
      file.status === Translate.TranslationJobStatus.Failed ||
      file.status === Translate.TranslationJobStatus.Canceled ||
      file.status === Translate.TranslationJobStatus.ValidationFailed
    ) {
      hasFailed = true
    }
    if (file.status === Translate.TranslationJobStatus.Succeeded) {
      hasSucceeded = true
    }
  }

  if (hasSucceeded && hasFailed) {
    return 'success'
  }
  if (hasFailed) {
    return 'failed'
  }
  if (hasSucceeded) {
    return 'success'
  }

  return 'inProgress'
}

// use to normalize translation data to TableRow format
export const normalizeTranslationData = (data: Translate.FolderData[], language: string, t: TFunction): TableRow[] => {
  const result: TableRow[] = []

  data.forEach((folder) => {
    result.push({
      id: folder.folderId,
      name: `Folder ${folder.folderId}`,
      parentId: undefined,
      type: 'folder',
      createdAt: formattedDate(folder.createdAt, language),
      status: summarizeFolderStatus(folder),
    })

    folder.files.forEach((file) => {
      result.push({
        id: file.id,
        name: file.file,
        parentId: folder.folderId,
        type: 'file',
        status: summarizeFileStatus(file.status),
        createdAt: formattedDate(folder.createdAt, language),
      })
    })
  })

  return result
}

export const normalizeAssessmentClients = (data: ClientEngagementConfig[], language: 'en' | 'fr'): TableRow[] => {
  return data.map((item) => ({
    id: item.engagementId, // use engagementId as unique ID
    name: item.label[language] || item.label.en, // fallback to English if missing
    businessAssociation: item.businessAssociation,
    type: 'file', // mark as "file" since no hierarchy
  }))
}
