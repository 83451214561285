import { forwardRef } from 'react'
import { Button as ChakraButton, ButtonProps } from '@chakra-ui/button'

import { useThemeContext } from 'providers/ThemeProvider'

import { CustomButtonProps } from 'types/types'

type TextButtonProps = CustomButtonProps & Omit<ButtonProps, 'leftIcon' | 'rightIcon'>

export const TextButton = forwardRef<HTMLButtonElement, TextButtonProps>((props, ref) => {
  const {
    children,
    className: classNameToAdd,
    padding = 'py-2',
    rounded = 'lg',
    variant,
    whiteSpace = 'normal',
    ...rest
  } = props
  const { isLightMode } = useThemeContext()

  const isLightButton =
    variant === 'kpmgWhite' ||
    variant === 'blackAlpha' ||
    (isLightMode && (variant === 'outline' || variant === 'ghost'))

  return (
    <ChakraButton
      {...rest}
      className={`h-auto shadow-none ${isLightButton ? 'focus-visible:ring-light' : 'focus-visible:ring-dark'} ${padding} ${classNameToAdd}`}
      ref={ref}
      rounded={rounded}
      tabIndex={0}
      variant={variant}
      whiteSpace={whiteSpace}
    >
      {children}
    </ChakraButton>
  )
})
