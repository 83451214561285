import { API } from '@kleo/types'
import * as Yup from 'yup'

export const StreamMessageSchema: Yup.ObjectSchema<API.StreamMessage> = Yup.object({
  type: Yup.string().required(),
  id: Yup.string().required(),
  created: Yup.number().required(),
})

export const ContentStreamMessageSchema: Yup.ObjectSchema<API.ContentStreamMessage> = StreamMessageSchema.shape({
  type: Yup.string().oneOf(['message']).required(),
  delta: Yup.string().required(),
})

export const DelimiterStreamMessageSchema: Yup.ObjectSchema<API.DelimiterStreamMessage> = StreamMessageSchema.shape({
  type: Yup.string().oneOf(['delimiter']).required(),
  delta: Yup.string().required(),
})

export const ContextStreamMessageSchema: Yup.ObjectSchema<API.ContextStreamMessage> = StreamMessageSchema.shape({
  type: Yup.string().oneOf(['context']).required(),
  context: Yup.object({
    context: Yup.string().required(),
    docList: Yup.array().of(Yup.string().required()).required(),
    docCount: Yup.number().required(),
    tokens: Yup.number().required(),
    size: Yup.number().required(),
    gptTotalTokens: Yup.number().required(),
    gptPromptTokens: Yup.number().required(),
    gptResponseTokens: Yup.number().required(),
  }),
})

export const ErrorStreamMessageSchema: Yup.ObjectSchema<API.ErrorStreamMessage> = StreamMessageSchema.shape({
  type: Yup.string().oneOf(['error']).required(),
  delta: Yup.string().required(),
  code: Yup.string().required(),
  errType: Yup.string().required(),
})
