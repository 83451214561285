import { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SingleValue } from 'react-select'
import { useDisclosure } from '@chakra-ui/hooks'
import {
  API,
  DeleteKBotResponse,
  DualLanguageOptionalValues,
  DualLanguageRequiredValues,
  GetKBotResponse,
  KBotBaseConfig,
  KBotConfig,
  ListKBotsResponse,
  SaveKBotResponse,
  SourceType,
} from '@kleo/types'
import * as Yup from 'yup'

import { useEventLogger } from 'hooks/useEventLogger'
import { useFetcher } from 'hooks/useFetcher'
import { useValidation } from 'hooks/useValidation'

import { NetworkError } from 'utils/appError'
import { generateRequestId } from 'utils/generateRequestId'
import { fetchData } from 'utils/http/methods'
import {
  collectAllPromptsFromSubPrompts,
  fillMissingLanguageField,
  findIconInPrompts,
  nullForEmptyStrings,
} from 'utils/KBotsUtils'
import {
  createKBotFormValuesSchema,
  createKBotUploadJSONValuesSchema,
  KBotConfigResponseSchema,
} from 'utils/kBotValidationSchemas'

import type {
  FEUIConfig,
  GetKBotFormValue,
  KBotFormState,
  KBotFormValues,
  SearchFilterItem,
  SetKBotFormValue,
  ValidationErrors,
} from 'types/types'

import { useAuthContext } from './AuthProvider'
import { useConfigContext } from './ConfigurationProvider'
import { useI18Context } from './i18Provider'
import { useUploadContext } from './UploadProvider'

const defaultFormState = {
  description: [
    {
      en: null,
      fr: null,
    },
  ],
  fileContent: undefined,
  fixedName: undefined,
  icon: undefined,
  instructions: '',
  name: {
    en: '',
    fr: '',
  },
  starterPrompts: {
    category: {
      en: '',
      fr: '',
    },
    icon: undefined,
    prompts: [],
  },
  temperature: '0.0' as API.OpenAITemperature,
  templateId: undefined,
}

const maxDescriptionCharacters = 3000

type KBotContextType = {
  clearFormState: (state: KBotFormState) => void
  createFormErrors: ValidationErrors<KBotFormValues>
  createValidate: (values: KBotFormValues) => Promise<void>
  createValidateField: (field: string) => Promise<void>
  deleteKBot: (kBotConfig: KBotConfig, onCloseDel: () => void, onClose: () => void) => void
  deleteKBotError: string
  downloadKBotError: string
  downloadKBot: (kBotConfig: KBotConfig) => void
  editFormErrors: ValidationErrors<KBotFormValues>
  editSelectedBot: KBotBaseConfig | null
  editValidate: (values: KBotFormValues) => Promise<void>
  editValidateField: (field: string) => Promise<void>
  failedToPreSelectKBot: boolean
  fetchIndividualKBotDataError: 'unableToFetchKBotDetails' | null
  fetchKBotsError: string | null
  generalConfig: {
    allowedUploadTypes: string[]
    descriptionMaxLength: number
    instructionsMaxLength: number
    mapUploadTypesToText: string[]
    nameMaxLength: number
    templateMaxFileSize: number
    uploadFileMaxCount: number
    uploadTokenMaxSize: number
  }
  getIndividualKBotData: (source: SourceType, name: string, handleError?: boolean) => Promise<KBotConfig | null>
  getKBotDataWithValidation: (botName: string) => Promise<{ success: boolean }>
  getKBotFormValue: GetKBotFormValue
  getKBotFormValues: (state: KBotFormState) => KBotFormValues
  getKBotTemplate: (templateId: string) => DualLanguageRequiredValues | undefined
  getSelectedBot: (state: KBotFormState) => KBotBaseConfig | null
  getTranslatedValue: (langValues: DualLanguageRequiredValues | DualLanguageOptionalValues) => string
  handleBotSelect: (e: SingleValue<SearchFilterItem>, state: KBotFormState) => void
  isDeletingKBot: boolean
  isDownloadingKBot: boolean
  isFetchingIndividualKBotData: boolean
  isFetchingKBots: boolean
  isJSONUploading: boolean
  isJSONUploadOpen: boolean
  kBotData: ListKBotsResponse | null
  kBotForm: KBotFormValues | null
  kBots: KBotBaseConfig[] | undefined
  kBotDetailsForChatUtilization: Record<string, KBotConfig | null>
  kBotDetailsForKBotUtilization: KBotConfig | null
  kBotDataValidationError: boolean
  maxDescriptionCharacters: number
  onJSONUpload: (files: File[], state: KBotFormState, uploadTokenMaxSize: number) => void
  onJSONUploadClose: () => void
  onJSONUploadOpen: () => void
  saveKBot: ({ state, payload }: { state: KBotFormState; payload: KBotFormValues }) => Promise<SaveKBotResponse>
  setEditSelectedBot: Dispatch<SetStateAction<KBotBaseConfig | null>>
  setFailedToPreSelectKBot: Dispatch<SetStateAction<boolean>>
  setFetchingIndividualKBotDataError: Dispatch<SetStateAction<'unableToFetchKBotDetails' | null>>
  setIsJSONUploading: Dispatch<SetStateAction<boolean>>
  setKBotDataValidationError: Dispatch<SetStateAction<boolean>>
  setKBotDetailsForChatUtilization: Dispatch<SetStateAction<Record<string, KBotConfig | null>>>
  setKBotDetailsForKBotUtilization: Dispatch<SetStateAction<KBotConfig | null>>
  setKBotForm: Dispatch<SetStateAction<KBotFormValues | null>>
  setKBotFormValue: SetKBotFormValue
  setShouldFetchKBots: Dispatch<SetStateAction<boolean>>
  setUploadJSONError: Dispatch<SetStateAction<string>>
  systemKBotOptions: SearchFilterItem[] | undefined
  systemKBots: KBotBaseConfig[]
  uploadJSONError: string
  userKBotOptions: SearchFilterItem[] | undefined
}

export const KBotContext = createContext<KBotContextType>({} as KBotContextType)

type KBotsProviderProps = {
  children?: React.ReactNode
  kBotConfig: API.KBotsConfig | undefined
  botConfigs: FEUIConfig[] | undefined
}

export const KBotsProvider = ({ children, kBotConfig, botConfigs }: KBotsProviderProps) => {
  const { languageAbbreviation } = useI18Context()
  const { t } = useTranslation('kBots')
  const { getToken } = useAuthContext()
  const { API_ENDPOINT } = useConfigContext()
  const { setDocContents, deleteDocumentContent } = useUploadContext()
  const { logUIErrorEvent } = useEventLogger()

  const { isOpen: isJSONUploadOpen, onOpen: onJSONUploadOpen, onClose: onJSONUploadClose } = useDisclosure()
  const {
    formErrors: createFormErrors,
    validate: createValidate,
    validateField: createValidateField,
    clearErrors: createClearErrors,
  } = useValidation<KBotFormValues>(
    createKBotFormValuesSchema(maxDescriptionCharacters, kBotConfig?.uploadTokenMaxSize ?? 0)
  )
  const {
    formErrors: editFormErrors,
    validate: editValidate,
    validateField: editValidateField,
    clearErrors: editClearErrors,
  } = useValidation<KBotFormValues>(
    createKBotFormValuesSchema(maxDescriptionCharacters, kBotConfig?.uploadTokenMaxSize ?? 0)
  )
  const [kBotForm, setKBotForm] = useState<KBotFormValues | null>(null)
  const [shouldFetchKBots, setShouldFetchKBots] = useState(false)
  const [fetchKBotsError, setFetchKBotsError] = useState<string | null>(null)
  // select for create and edit form
  const [createSelectedBot, setCreateSelectedBot] = useState<KBotBaseConfig | null>(null)
  const [editSelectedBot, setEditSelectedBot] = useState<KBotBaseConfig | null>(null)

  const [isJSONUploading, setIsJSONUploading] = useState(false)
  const [uploadJSONError, setUploadJSONError] = useState('')

  const [isDeletingKBot, setIsDeletingKBot] = useState(false)
  const [deleteKBotError, setDeleteKBotError] = useState('')

  const [isDownloadingKBot, setIsDownloadingKBot] = useState(false)
  const [downloadKBotError, setDownloadKBotError] = useState('')

  const [kBotCreateFormState, setKBotCreateFormState] = useState<KBotFormValues>(defaultFormState)
  const [kBotEditFormState, setKBotEditFormState] = useState<KBotFormValues>(defaultFormState)

  // This value is used to determine whether or not we have attempted to pre-select a K-Bot for the General Bot
  const [failedToPreSelectKBot, setFailedToPreSelectKBot] = useState<boolean>(false)

  const [kBotDataValidationError, setKBotDataValidationError] = useState(false)

  const [kBotDetailsForChatUtilization, setKBotDetailsForChatUtilization] = useState<Record<string, KBotConfig | null>>(
    {}
  )
  const [kBotDetailsForKBotUtilization, setKBotDetailsForKBotUtilization] = useState<KBotConfig | null>(null)

  const [isFetchingIndividualKBotData, setIsFetchingIndividualKBotData] = useState<boolean>(false)
  const [fetchIndividualKBotDataError, setFetchingIndividualKBotDataError] = useState<
    'unableToFetchKBotDetails' | null
  >(null)

  const { data: kBotData, isLoading: isFetchingKBots } = useFetcher<ListKBotsResponse>({
    allowRefetch: true,
    url: '/chatapi/listKBotsApi',
    shouldFetch: shouldFetchKBots,
    noCache: true,
    payload: {
      language: 'EN',
    },
    onError: (e) => {
      e instanceof NetworkError && setFetchKBotsError('fetchKBotsError')
    },
    onSuccess: () => {
      setFetchKBotsError(null)
    },
    final: () => {
      setShouldFetchKBots(false)
    },
  })

  const getIndividualKBotData = useCallback(
    async (source: SourceType, name: string, handleError = true) => {
      // Don't call api if name is empty string
      if (name.trim().length === 0) {
        return null
      }
      setFetchingIndividualKBotDataError(null)
      setIsFetchingIndividualKBotData(true)
      try {
        const token = await getToken()
        const response = await fetchData<GetKBotResponse>({
          url: `${API_ENDPOINT}/chatapi/getKBotApi`,
          token,
          payload: {
            name,
            source,
            language: 'EN',
          },
          retries: 1,
          requestId: generateRequestId(),
        })
        return response.botConfig
      } catch (e) {
        if (handleError && e instanceof NetworkError) {
          setFetchingIndividualKBotDataError('unableToFetchKBotDetails')
        }
        return null
      } finally {
        setIsFetchingIndividualKBotData(false)
      }
    },
    [API_ENDPOINT, getToken]
  )

  const fetchKBotAndSetForm = useCallback(
    async (source: SourceType, name: string, mode: string) => {
      let setter: Dispatch<SetStateAction<KBotFormValues>>
      if (mode === 'create') {
        setter = setKBotCreateFormState
      } else {
        setter = setKBotEditFormState
      }
      const kBotConfig = await getIndividualKBotData(source, name)

      if (kBotConfig) {
        const {
          name,
          description,
          fileContent,
          kbotTemperature,
          starterPrompts,
          template,
          templateId,
          userInstructions,
        } = kBotConfig

        const icon = findIconInPrompts(starterPrompts)
        const prompts = collectAllPromptsFromSubPrompts(starterPrompts)

        setter({
          icon: template.icon,
          templateId,
          name: template,
          fixedName: mode === 'create' ? undefined : name,
          instructions: userInstructions,
          description,
          starterPrompts: {
            category: template,
            icon,
            // Limit the prompts to the first 10 entries
            prompts: prompts.slice(0, 10),
          },
          temperature: kbotTemperature,
        })

        if (mode === 'edit') {
          editClearErrors()
        } else {
          createClearErrors()
        }

        if (fileContent && fileContent.length > 0) {
          setDocContents((prevDocContents) => ({
            ...prevDocContents,
            KBOTS: {
              ...prevDocContents['KBOTS'],
              [`${mode}KBotTemp`]: {
                text: fileContent,
                documentId: '',
                characterCount: fileContent.length,
                size: 0,
                type: '',
                usedOCR: false,
              },
            },
          }))
        } else {
          deleteDocumentContent(['KBOTS'], [`${mode}KBotTemp`])
        }
      }
    },
    // Can't add editClearErrors and createClearErrors to the dependency array, otherwise we run into infinite useEffect call loops
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getIndividualKBotData, setDocContents]
  )

  // bot dropdown select for create form
  useEffect(() => {
    if (createSelectedBot) {
      const { source: selectedSource, name: selectedName } = createSelectedBot
      fetchKBotAndSetForm(selectedSource, selectedName, 'create')
    }
  }, [createSelectedBot, fetchKBotAndSetForm, setDocContents])

  // bot dropdown select for edit form
  useEffect(() => {
    if (editSelectedBot) {
      const { source: selectedSource, name: selectedName } = editSelectedBot
      fetchKBotAndSetForm(selectedSource, selectedName, 'edit')
    }
  }, [editSelectedBot, fetchKBotAndSetForm, setDocContents])

  const getKBotFormValues = useCallback(
    (state: KBotFormState) => (state === 'edit' ? kBotEditFormState : kBotCreateFormState),
    [kBotCreateFormState, kBotEditFormState]
  )

  const getSelectedBot = useCallback(
    (state: KBotFormState) => (state === 'edit' ? editSelectedBot : createSelectedBot),
    [createSelectedBot, editSelectedBot]
  )

  const getKBotFormValue = useCallback(
    <K extends keyof KBotFormValues>(field: K, state: KBotFormState): KBotFormValues[K] =>
      state === 'edit' ? kBotEditFormState[field] : kBotCreateFormState[field],
    [kBotCreateFormState, kBotEditFormState]
  )

  const setKBotFormValue = useCallback(
    <K extends keyof KBotFormValues>(
      field: K,
      valueOrUpdater: KBotFormValues[K] | ((prevValue: KBotFormValues[K]) => KBotFormValues[K]),
      state: KBotFormState,
      shouldValidate = false,
      onlyValidateField = false
    ) => {
      const updateFormState = (prevState: KBotFormValues) => ({
        ...prevState,
        [field]:
          typeof valueOrUpdater === 'function'
            ? (valueOrUpdater as (prevValue: KBotFormValues[K]) => KBotFormValues[K])(prevState[field])
            : valueOrUpdater,
      })

      if (state === 'edit') {
        if (shouldValidate) {
          const updatedFormValues = updateFormState(kBotEditFormState)
          if (onlyValidateField) {
            editValidateField(field)
          } else {
            editValidate(updatedFormValues)
          }
        }
        setKBotEditFormState((formState) => updateFormState(formState))
      } else {
        if (shouldValidate) {
          const updatedFormValues = updateFormState(kBotCreateFormState)
          if (onlyValidateField) {
            createValidateField(field)
          } else {
            createValidate(updatedFormValues)
          }
        }
        setKBotCreateFormState((formState) => updateFormState(formState))
      }
    },
    [createValidate, createValidateField, editValidate, editValidateField, kBotCreateFormState, kBotEditFormState]
  )

  const getKBotTemplate = useCallback(
    (templateId: string) => {
      const configTemplate = kBotData?.botConfigs?.find((botConfig) => botConfig.templateId === templateId)?.template
      return configTemplate
    },
    [kBotData?.botConfigs]
  )

  const systemKBots = useMemo(() => {
    if (kBotData) {
      const kBotOptionsToSet = kBotData.botConfigs.filter((config) => config.source === 'kleo')

      return kBotOptionsToSet
    }
    return []
  }, [kBotData])

  const systemKBotOptions = useMemo(() => {
    return systemKBots
      .map((config) => ({
        label: config.template[languageAbbreviation],
        value: config.templateId,
      }))
      .sort((a, b) => Number(b.label === 'general') - Number(a.label === 'general'))
  }, [languageAbbreviation, systemKBots])

  const userKBotOptions = useMemo(() => {
    if (kBotData) {
      const kBotOptionsToSet: SearchFilterItem[] = kBotData.botConfigs
        .filter((config) => config.source === 'user')
        .map((config) => ({
          label: config.template[languageAbbreviation],
          value: config.templateId,
        }))
      return kBotOptionsToSet
    }
  }, [kBotData, languageAbbreviation])

  const {
    allowedUploadTypes,
    descriptionMaxLength,
    instructionsMaxLength,
    mapUploadTypesToText,
    nameMaxLength,
    templateMaxFileSize,
    uploadFileMaxCount,
    uploadTokenMaxSize,
  } = useMemo(() => {
    if (!kBotConfig) {
      return {
        allowedUploadTypes: [],
        descriptionMaxLength: 1500,
        instructionsMaxLength: 8000,
        mapUploadTypesToText: [],
        maxInput: 100000,
        nameMaxLength: 50,
        templateMaxFileSize: 0,
        uploadFileMaxCount: 0,
        uploadTokenMaxSize: 0,
      }
    }

    const {
      allowedUploadTypes,
      descriptionMaxLength,
      instructionsMaxLength,
      nameMaxLength,
      templateMaxFileSize,
      mapUploadTypesToText,
      uploadFileMaxCount,
      uploadTokenMaxSize,
    } = kBotConfig

    return {
      allowedUploadTypes: allowedUploadTypes ?? [],
      descriptionMaxLength: descriptionMaxLength ?? 1500,
      instructionsMaxLength: instructionsMaxLength ?? 8000,
      mapUploadTypesToText: mapUploadTypesToText ?? [],
      nameMaxLength: nameMaxLength ?? 50,
      templateMaxFileSize: templateMaxFileSize ?? 0,
      uploadFileMaxCount: uploadFileMaxCount ?? 0,
      uploadTokenMaxSize: uploadTokenMaxSize ?? 0,
    }
  }, [kBotConfig])

  // update KBot template value if language switches

  // Auto-select the General config for the K-Bot selection modal on Page load
  useEffect(() => {
    const preSelectGeneralKBot = async (kBotData: ListKBotsResponse, botName: string) => {
      setFailedToPreSelectKBot(false)
      const matchedConfig = kBotData.botConfigs.find((config) => config.name.toLowerCase() === 'general')
      if (matchedConfig) {
        const kBotDetails = await getIndividualKBotData(matchedConfig.source, matchedConfig.name)
        setKBotDetailsForChatUtilization((current) => {
          return { ...current, [botName]: kBotDetails }
        })
        setKBotDetailsForKBotUtilization(kBotDetails)
      } else {
        setFailedToPreSelectKBot(true)
      }
    }
    // Set the default selected kbot for the general bots
    botConfigs?.forEach((config) => {
      if (
        kBotData &&
        config.type === 'general' &&
        (!kBotDetailsForChatUtilization[config.botName] || kBotDetailsForChatUtilization[config.botName] === null)
      ) {
        preSelectGeneralKBot(kBotData, config.botName)
      }
    })
  }, [
    kBotData,
    systemKBotOptions,
    kBotDetailsForChatUtilization,
    getIndividualKBotData,
    languageAbbreviation,
    botConfigs,
  ])

  const getTranslatedValue = useCallback(
    (langValues: DualLanguageRequiredValues | DualLanguageOptionalValues) => {
      const languageValue = langValues[languageAbbreviation]

      if (!languageValue) {
        return ''
      }

      return languageValue
    },
    [languageAbbreviation]
  )

  const handleBotSelect = useCallback(
    (e: SingleValue<SearchFilterItem>, state: KBotFormState) => {
      if (kBotData) {
        const matchedConfig = kBotData.botConfigs.find((config) => config.templateId === e?.value)
        if (matchedConfig) {
          if (state === 'edit') {
            setEditSelectedBot(matchedConfig)
          } else {
            setCreateSelectedBot(matchedConfig)
          }

          // Bot selection has changed, so we need to clear the contents of the upload section.
          setDocContents((prevDocContents) => {
            const { [state === 'edit' ? 'editKBotTemp' : 'createKBotTemp']: _, ...rest } = prevDocContents

            return rest
          })
        }
      }
    },
    [kBotData, setDocContents]
  )

  const onJSONUpload = useCallback(
    async (files: File[], state: KBotFormState, uploadTokenMaxSize: number) => {
      const file = files[0] // Access the first file from the array

      // Check if the file size exceeds 1.5MB (1.5MB = 1.5 * 1024 * 1024 bytes)
      const maxFileSize = 1.5 * 1024 * 1024 // 1.5MB in bytes
      if (file.size > maxFileSize) {
        setUploadJSONError('uploadUrlEndpointFailed')
        return // Exit the function early to prevent further processing
      }

      if (file && file.type === 'application/json') {
        const reader = new FileReader()

        reader.onload = async (e) => {
          try {
            setIsJSONUploading(true)

            // Delay the execution of the rest of the code by 1 second, that way the user can visually see the Uploading animations
            await new Promise((resolve) => setTimeout(resolve, 1000))

            const fileText = e.target?.result as string
            const parsedJson: KBotConfig = JSON.parse(fileText)

            // Validate the parsed JSON against the Yup schema
            createKBotUploadJSONValuesSchema(t)
              .validate(parsedJson, { strict: true, abortEarly: true }) // Use Yup to validate, abortEarly: true to catch one error at a time
              .then((result) => {
                const icon = findIconInPrompts(result.starterPrompts || [])
                const fileContentToUse =
                  result.fileContent && result.fileContent.length < uploadTokenMaxSize * 4
                    ? result.fileContent
                    : undefined

                // If validation passes, update the state with the valid config
                const kBotConfig = {
                  description: result.description || [{ en: '', fr: '' }],
                  fileContent: fileContentToUse || undefined,
                  icon: result.template.icon,
                  instructions: result.userInstructions || '',
                  name: result.template,
                  templateId: undefined,
                  starterPrompts: {
                    category: result.template,
                    icon,
                    prompts: collectAllPromptsFromSubPrompts(result.starterPrompts || []).slice(0, 10),
                  },
                  temperature: result.kbotTemperature || ('0.0' as API.OpenAITemperature),
                }
                if (state === 'edit') {
                  setKBotEditFormState((current) => {
                    return { ...current, ...kBotConfig }
                  })
                } else {
                  setKBotCreateFormState((current) => {
                    return { ...current, ...kBotConfig }
                  })
                }

                // Set the fileContent values as part of the useState kept in UploadProvider
                if (fileContentToUse && fileContentToUse.length > 0) {
                  setDocContents((prevDocContents) => ({
                    ...prevDocContents,
                    KBOTS: {
                      ...prevDocContents['KBOTS'],
                      [`${state}KBotTemp`]: {
                        text: fileContentToUse,
                        documentId: '',
                        characterCount: fileContentToUse.length,
                        size: 0,
                        type: '',
                        usedOCR: false,
                      },
                    },
                  }))
                } else {
                  deleteDocumentContent(['KBOTS'], [`${state}KBotTemp`])
                }

                onJSONUploadClose()
              })
              .catch((err: Yup.ValidationError) => {
                // Extract the error message(s)
                const errorMessage = err.errors[0] // Access the first error message
                setUploadJSONError(errorMessage)
              })
          } catch (error) {
            setUploadJSONError('corruptUploadError')
          } finally {
            setIsJSONUploading(false)
          }
        }

        reader.onerror = () => {
          setUploadJSONError('kBots.error.fileCouldNotBeRead')
        }

        reader.readAsText(file) // Read the file as text
      } else {
        setUploadJSONError('kBots.error.uploadValidJSON')
      }
    },
    [deleteDocumentContent, onJSONUploadClose, setDocContents, t]
  )

  const clearFormState = useCallback(
    (state: KBotFormState) => {
      if (state === 'edit') {
        setEditSelectedBot(null)
        setKBotEditFormState(defaultFormState)
      } else {
        setCreateSelectedBot(null)
        setKBotCreateFormState(defaultFormState)
      }
      setDocContents((prevDocContents) => {
        const { [state === 'edit' ? 'editKBotTemp' : 'createKBotTemp']: _, ...rest } = prevDocContents

        return rest
      })
    },
    [setDocContents]
  )

  const saveKBot = useCallback(
    async ({ state, payload }: { state: KBotFormState; payload: KBotFormValues }) => {
      const token = await getToken()

      const filledNames = fillMissingLanguageField(payload.name)
      const filledStarterPrompt = {
        ...payload.starterPrompts,
        category: filledNames,
        prompts: nullForEmptyStrings(payload.starterPrompts.prompts),
      }

      const response = await fetchData<SaveKBotResponse>({
        url: `${API_ENDPOINT}/chatapi/saveKBotApi`,
        token,
        payload: {
          botConfig: {
            template: { ...filledNames, icon: payload.icon },
            description: nullForEmptyStrings(payload.description),
            userInstructions: payload.instructions,
            fileContent: payload.fileContent ?? '',
            starterPrompts: [filledStarterPrompt],
            kbotTemperature: payload.temperature,
            source: SourceType.user,
            ...(state === 'edit' ? { templateId: payload.templateId, name: payload.fixedName } : {}),
          },
          language: 'EN',
        },
        retries: 0,
        requestId: generateRequestId(),
      })

      return response
    },
    [API_ENDPOINT, getToken]
  )

  const downloadKBot = useCallback(
    (kBotConfig: KBotConfig) => {
      try {
        setIsDownloadingKBot(true)
        setDownloadKBotError('')
        const { templateId, author, date, source, ...pIIOmittedKBotConfig } = kBotConfig
        const dataStr =
          'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(pIIOmittedKBotConfig, null, 2))
        const downloadAnchorNode = document.createElement('a')
        downloadAnchorNode.setAttribute('href', dataStr)
        downloadAnchorNode.setAttribute('download', `${kBotConfig.template[languageAbbreviation]}.json`)
        document.body.appendChild(downloadAnchorNode) // Required for FireFox
        downloadAnchorNode.click()
        downloadAnchorNode.remove()
      } catch (e) {
        setDownloadKBotError(t('kBots.error.downloadError'))
      } finally {
        setIsDownloadingKBot(false)
      }
    },
    [languageAbbreviation, t]
  )

  const deleteKBot = useCallback(
    async (kBotConfig: KBotConfig, onCloseDel: () => void, onClose: () => void) => {
      try {
        setIsDeletingKBot(true)
        setDeleteKBotError('')
        const token = await getToken()
        const response = await fetchData<DeleteKBotResponse>({
          url: `${API_ENDPOINT}/chatapi/deleteKBotApi`,
          token,
          payload: {
            templateId: kBotConfig.templateId,
            language: 'EN',
          },
          retries: 0,
          requestId: generateRequestId(),
        })
        if (response) {
          if (kBotConfig.templateId === kBotDetailsForKBotUtilization?.templateId && kBotData) {
            // If the user has selected in the K-Bot selection modal the K-Bot that we are deleting, unselect it and default to General if available
            const matchedConfig = kBotData.botConfigs.find((config) => config.name.toLowerCase() === 'general')
            if (matchedConfig) {
              const kBotDetails = await getIndividualKBotData(matchedConfig.source, matchedConfig.name)
              setKBotDetailsForKBotUtilization(kBotDetails)
            } else {
              setKBotDetailsForKBotUtilization(null)
            }
          }

          if (kBotConfig.templateId === editSelectedBot?.templateId) {
            clearFormState('edit')
          }

          if (kBotConfig.templateId === createSelectedBot?.templateId) {
            clearFormState('create')
          }

          setShouldFetchKBots(true)
          onCloseDel()
          onClose()
        }
      } catch (e) {
        setDeleteKBotError(t('kBots.error.deleteError'))
      } finally {
        setIsDeletingKBot(false)
      }
    },
    [
      API_ENDPOINT,
      clearFormState,
      createSelectedBot?.templateId,
      editSelectedBot?.templateId,
      getIndividualKBotData,
      getToken,
      kBotData,
      kBotDetailsForKBotUtilization?.templateId,
      t,
    ]
  )

  const getKBotDataWithValidation = useCallback(
    async (botName: string) => {
      try {
        const kBotDetails = kBotDetailsForChatUtilization[botName]

        if (!kBotDetails) {
          throw new Error('unknown kBot details')
        }

        const kBotData = await getIndividualKBotData(kBotDetails.source, kBotDetails.name, false)

        if (!kBotData) {
          throw new Error('unable to get KBot data')
        }

        // If the tempalteId's don't match, it means a new instance of the K-Bot was created.
        // E.g. A user creates their own K-Bot, deletes it, and then re-creates it with the same name. Then a conversation that was created with the deleted K-Bot is trying to reference this newly created K-Bot
        if (kBotData.templateId !== kBotDetails.templateId) {
          throw new Error('K-Bot was previously deleted, and re-created with same name')
        }

        // Validate the response
        await KBotConfigResponseSchema.validate(kBotData, { strict: true, abortEarly: true })

        return { success: true }
      } catch (error) {
        logUIErrorEvent({
          bot: botName,
          error: error as Error,
          errorMessage: 'bad-kbot-data-presented',
        })
        return { success: false }
      }
    },
    [getIndividualKBotData, kBotDetailsForChatUtilization, logUIErrorEvent]
  )

  return (
    <>
      <KBotContext.Provider
        value={{
          clearFormState,
          createFormErrors,
          createValidate,
          createValidateField,
          deleteKBot,
          deleteKBotError,
          downloadKBotError,
          downloadKBot,
          editFormErrors,
          editSelectedBot,
          editValidate,
          editValidateField,
          failedToPreSelectKBot,
          fetchIndividualKBotDataError,
          fetchKBotsError,
          generalConfig: {
            descriptionMaxLength,
            instructionsMaxLength,
            nameMaxLength,
            mapUploadTypesToText,
            allowedUploadTypes: (allowedUploadTypes && Object.keys(allowedUploadTypes)) || [],
            templateMaxFileSize,
            uploadTokenMaxSize,
            uploadFileMaxCount,
          },
          getIndividualKBotData,
          getKBotDataWithValidation,
          getKBotFormValue,
          getKBotFormValues,
          getKBotTemplate,
          getSelectedBot,
          getTranslatedValue,
          handleBotSelect,
          isDeletingKBot,
          isDownloadingKBot,
          isFetchingIndividualKBotData,
          isFetchingKBots,
          isJSONUploading,
          isJSONUploadOpen,
          kBotData,
          kBotDataValidationError,
          kBotDetailsForChatUtilization,
          kBotDetailsForKBotUtilization,
          kBotForm,
          kBots: kBotData?.botConfigs,
          maxDescriptionCharacters,
          onJSONUpload,
          onJSONUploadClose,
          onJSONUploadOpen,
          saveKBot,
          setEditSelectedBot,
          setFailedToPreSelectKBot,
          setFetchingIndividualKBotDataError,
          setIsJSONUploading,
          setKBotDataValidationError,
          setKBotDetailsForChatUtilization,
          setKBotDetailsForKBotUtilization,
          setKBotForm,
          setKBotFormValue,
          setShouldFetchKBots,
          setUploadJSONError,
          systemKBotOptions,
          systemKBots,
          uploadJSONError,
          userKBotOptions,
        }}
      >
        {children}
      </KBotContext.Provider>
    </>
  )
}

export const useKBotContext = (): KBotContextType => useContext(KBotContext)
