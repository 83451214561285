import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { API } from '@kleo/types'

import { generateRequestId } from 'utils/generateRequestId'

import { useAuthContext } from '../providers/AuthProvider'
import { useConfigContext } from '../providers/ConfigurationProvider'
import { fetchData } from '../utils/http/methods'

export const useEventLogger = () => {
  const location = useLocation()
  const { getToken } = useAuthContext()
  const { API_ENDPOINT } = useConfigContext()

  const logEvent = useCallback(
    async (
      payload:
        | API.LogUIEventFeedbackGeneralUSAPayload
        | (Omit<API.LogUIEventErrorPayload, 'error'> & { error?: string })
        | API.LogUIEventFeedbackPayload
        | API.LogUIEventInfoPayload
        | API.LogUIEventNoKBLinkPayload
        | API.LogUIKBLinksPayload
    ) => {
      try {
        const authToken = await getToken()
        // This querySelector gets our build version for the app so we can relate it to GitHub deployments
        const metaTag = document.querySelector('meta[build-version]')
        await fetchData({
          url: `${API_ENDPOINT}/chatapi/logUIEvent`,
          token: authToken,
          setRetrying: () => false,
          payload: {
            ...payload,
            route: location.pathname,
            buildVersion: metaTag && metaTag.getAttribute('build-version'),
            language: 'EN',
          },
          requestId: generateRequestId(),
        })
      } catch (e) {
        console.error('Error: Failed to send information to logUIEvent', e)
      }
    },
    [API_ENDPOINT, getToken, location.pathname]
  )

  const logUIErrorEvent = useCallback(
    async (payload: API.LogUIEventErrorPayload) => {
      const { error } = payload
      logEvent({
        ...payload,
        error: JSON.stringify(formatError(error?.cause, error)),
      })
    },
    [logEvent]
  )
  const logUIInfoEvent = useCallback(
    async (payload: API.LogUIEventInfoPayload) => {
      logEvent(payload)
    },
    [logEvent]
  )

  const logUINoKBLinkPayloadEvent = useCallback(
    async (payload: API.LogUIEventNoKBLinkPayload) => {
      logEvent(payload)
    },
    [logEvent]
  )

  const logUIKBLinksPayloadEvent = useCallback(
    async (payload: API.LogUIKBLinksPayload) => {
      logEvent(payload)
    },
    [logEvent]
  )

  const logUIFeedbackEvent = useCallback(
    async (payload: API.LogUIEventFeedbackPayload) => {
      logEvent(payload)
    },
    [logEvent]
  )

  const logUIGeneralUSAEvent = useCallback(
    async (payload: API.LogUIEventFeedbackGeneralUSAPayload) => {
      logEvent(payload)
    },
    [logEvent]
  )
  return {
    logUIErrorEvent,
    logUIInfoEvent,
    logUIFeedbackEvent,
    logUIGeneralUSAEvent,
    logUINoKBLinkPayloadEvent,
    logUIKBLinksPayloadEvent,
  }
}

export const formatError = <E, V>(_: E, value: V): E | V | API.FormatErrorResultsType => {
  if (value instanceof Error) {
    const err: Error = value
    const props = Object.keys(err).filter((i) => i !== 'message' && i !== 'stack')
    const results: API.FormatErrorResultsType = {
      message: err.message,
      stack: err.stack,
      cause: formatError(_, err.cause as Error),
    }
    props.forEach((i) => {
      results[i as keyof API.FormatErrorResultsType] = (err as never)[i]
    })
    return results
  } else {
    return value
  }
}
