import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { API } from '@kleo/types'
import { saveAs } from 'file-saver'
import { TFunction } from 'i18next'
import isEqual from 'lodash.isequal'

import { useEventLogger } from 'hooks/useEventLogger'

import { AssessmentError, AssessmentSchemaError, AzureFileUploadError, NetworkError } from 'utils/appError'
import { formatMaxFileSize } from 'utils/formatter'
import { generateRequestId } from 'utils/generateRequestId'
import { getFileName } from 'utils/http/file'
import { fetchData, fetchDataRaw, putFileIntoStorage } from 'utils/http/methods'

import type { FEUIConfig } from 'types/types'

import { useAuthContext } from './AuthProvider'
import { useConfigContext } from './ConfigurationProvider'
import { useI18Context } from './i18Provider'

export type AdHocValueType =
  | 'configuration'
  | 'interviews'
  | 'documents'
  | 'summary'
  | 'recommendations'
  | 'observations'

type StepItemStatus = 'completed' | 'incomplete' | 'in-progress' | 'failed'

type ReportProcessType = 'saveReport' | 'previewReport' | 'downloadReport'

export type AdHocStepsType = {
  value: AdHocValueType
  label: string
  progressAmount: number
  status: StepItemStatus
}

type AssessmentContextType = {
  adhocElapsedTime: number
  adHocGenerationStatistics: {
    timeStarted: Date | null
    status: 'completed' | 'in-progress' | 'failed'
    duration: number
  }
  adHocReportData: Record<string, { [key: string]: string | object }>
  adHocSteps: {
    label: string
    value: AdHocValueType
    progressAmount: number
    status: StepItemStatus
  }[]
  adHocStepsState: AdHocStepsType[]
  configProcessError: {
    error: API.AssessmentAPI.RunReportAPIError
    errorData?: API.AssessmentAPI.ErrorData
    requestId?: string
  } | null
  currentReportID: string | undefined
  errorRequestID: string | null
  exceededExpectedConfigurationTime: boolean
  exportReportData: ({
    engagementId,
    tierIds,
    fileLocation,
    latest,
  }: {
    engagementId: string
    tierIds: string[]
    fileLocation: API.AssessmentAPI.GetReportDataAPIRequest['fileLocation']
    latest?: boolean
  }) => Promise<void>
  exportReportError: string | null
  generatedReportID: string | undefined
  generatedReportTier1: string | null
  generatedReportTier2: string | null
  generatedReportTier3: string | null
  getCompletedSteps: () => AdHocValueType[]
  getFirstFailedStepValue: () => AdHocValueType | undefined
  getReportData: ({
    reportId,
    engagementId,
    fileLocation,
  }: {
    reportId: string
    engagementId: string
    fileLocation: API.AssessmentAPI.GetReportDataAPIRequest['fileLocation']
  }) => void
  getReportStatus: ({
    reportId,
    engagementId,
  }: {
    reportId: string
    engagementId: string
  }) => Promise<API.AssessmentAPI.GetReportStatusAPIResponse | undefined> | undefined
  getReportProcessErrors: () => ReportProcessType[]
  hierarchy: API.AssessmentAPI.ReportHierarchy
  isDownloadButtonDisabled: boolean
  isDownloadingAdHocReport: boolean
  isExportReportData: boolean
  isFetchingReportData: boolean
  isFileUploading: boolean
  isGettingAdHocPreview: boolean
  isInitialLoad: boolean
  isProcessCompleted: boolean
  isProcessing: boolean
  isSaveButtonDisabled: boolean
  isSavingAdHocReport: boolean
  isWarningAcknowledged: boolean
  maxFileNameLength: number
  maxFileSize: string
  openQuestionPreviewId: string | null
  reportJsonData: Record<string, string>
  reportsContainerPath: string | null
  resetAdHocProcessInfo: () => void
  resetAdHocStepsStateStatus: () => void
  resetAllClientSpecificInfo: () => void
  saveUserReport: () => void
  selectedClient: API.AssessmentAPI.ClientEngagementConfig | null
  setAdhocElapsedTime: Dispatch<SetStateAction<number>>
  setAdHocGenerationStatistics: Dispatch<
    SetStateAction<{ timeStarted: Date | null; status: 'completed' | 'in-progress' | 'failed'; duration: number }>
  >
  setAdHocStepsState: Dispatch<SetStateAction<AdHocStepsType[]>>
  setConfigProcessError: Dispatch<
    SetStateAction<{
      error: API.AssessmentAPI.RunReportAPIError
      errorData?: API.AssessmentAPI.ErrorData
    } | null>
  >
  setExportReportError: Dispatch<SetStateAction<string | null>>
  setHierarchy: Dispatch<SetStateAction<API.AssessmentAPI.ReportHierarchy>>
  setIsDownloadButtonDisabled: Dispatch<SetStateAction<boolean>>
  setIsDownloadingAdHocReport: Dispatch<SetStateAction<boolean>>
  setIsExportReportData: Dispatch<SetStateAction<boolean>>
  setIsGettingAdHocPreview: Dispatch<SetStateAction<boolean>>
  setIsInitialLoad: Dispatch<SetStateAction<boolean>>
  setIsProcessCompleted: Dispatch<SetStateAction<boolean>>
  setIsProcessing: Dispatch<SetStateAction<boolean>>
  setIsWarningAcknowledged: Dispatch<SetStateAction<boolean>>
  setOpenQuestionPreviewId: Dispatch<SetStateAction<string | null>>
  setReportJsonData: Dispatch<SetStateAction<Record<string, string>>>
  setReportsContainerPath: Dispatch<SetStateAction<string | null>>
  setSelectedClient: Dispatch<SetStateAction<API.AssessmentAPI.ClientEngagementConfig | null>>
  setUploadError: Dispatch<SetStateAction<string>>
  updateAdHocSteps: (reportStatus: { [key: string]: boolean | string | API.AssessmentAPI.StatusErrorEvent[] }) => void
  updateAdHocStepStatus: (stepValue: AdHocValueType, newStatus: StepItemStatus) => void
  updateInProgressStepToFailed: () => void
  updateReportProcessError: (errorName: ReportProcessType, errorValue: boolean | null) => void
  uploadConfigRequest: ({ file, engagementId }: { file: File; engagementId: string }) => void
  uploadError: string
  uploadFileMaxCount: number
}

export const AssessmentContext = createContext<AssessmentContextType>({} as AssessmentContextType)

type AssessmentProviderProps = {
  children?: React.ReactNode
  config: FEUIConfig[] | undefined
}

export const AssessmentProvider = ({ children, config }: AssessmentProviderProps) => {
  const CONFIG_TIME_LIMIT = 30000
  const { getToken } = useAuthContext()
  const { API_ENDPOINT } = useConfigContext()
  const { logUIErrorEvent } = useEventLogger()
  const { language } = useI18Context()
  const { t } = useTranslation('assessment')

  const [selectedClient, setSelectedClient] = useState<API.AssessmentAPI.ClientEngagementConfig | null>(null)

  // Ad-Hoc
  const [adHocStepsState, setAdHocStepsState] = useState<AdHocStepsType[]>([
    {
      value: 'configuration',
      label: t('assessment.adhoc.steps.config'),
      progressAmount: 5,
      status: 'in-progress',
    },
    {
      value: 'documents',
      label: t('assessment.adhoc.steps.documents'),
      progressAmount: 60,
      status: 'incomplete',
    },
    {
      value: 'summary',
      label: t('assessment.adhoc.steps.summary'),
      progressAmount: 15,
      status: 'incomplete',
    },
    {
      value: 'recommendations',
      label: t('assessment.adhoc.steps.recommendations'),
      progressAmount: 15,
      status: 'incomplete',
    },
    {
      value: 'observations',
      label: t('assessment.adhoc.steps.observations'),
      progressAmount: 5,
      status: 'incomplete',
    },
  ])
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [isProcessCompleted, setIsProcessCompleted] = useState<boolean>(false)
  const [isFileUploading, setIsFileUploading] = useState(false)
  const [isExportReportData, setIsExportReportData] = useState(false)
  const [currentReportID, setCurrentReportID] = useState<string | undefined>(undefined)
  const [generatedReportTier1, setGeneratedReportTier1] = useState<string | null>(null)
  const [generatedReportTier2, setGeneratedReportTier2] = useState<string | null>(null)
  const [generatedReportTier3, setGeneratedReportTier3] = useState<string | null>(null)
  const [generatedReportID, setGeneratedReportID] = useState<string | undefined>(undefined)
  const [errorRequestID, setErrorRequestID] = useState<string | null>(null)
  const [configTimer, setConfigTimer] = useState<NodeJS.Timeout>({} as NodeJS.Timeout)
  const [exceededExpectedConfigurationTime, setExceededExpectedConfigurationTime] = useState<boolean>(false)
  const [uploadError, setUploadError] = useState('')
  const [isWarningAcknowledged, setIsWarningAcknowledged] = useState<boolean>(false)
  const [reportsContainerPath, setReportsContainerPath] = useState<string | null>(null)
  const [configProcessError, setConfigProcessError] = useState<{
    error: API.AssessmentAPI.RunReportAPIError
    errorData?: API.AssessmentAPI.ErrorData
  } | null>(null)
  const [exportReportError, setExportReportError] = useState<string | null>(null)
  const [reportProcessError, setReportProcessError] = useState<Record<ReportProcessType, boolean | null>>({
    saveReport: null,
    previewReport: null,
    downloadReport: null,
  })
  const [reportStatusData, setReportStatusData] = useState<{
    data: { [key: string]: boolean }
    error?: API.AssessmentAPI.StatusErrorEvent
    lastUpdated?: string
  }>({ data: {} })
  const [reportJsonData, setReportJsonData] = useState<Record<string, string>>({})
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(false)
  const [isDownloadButtonDisabled, setIsDownloadButtonDisabled] = useState<boolean>(false)
  const [isGettingAdHocPreview, setIsGettingAdHocPreview] = useState<boolean>(false)
  const [adHocGenerationStatistics, setAdHocGenerationStatistics] = useState<{
    timeStarted: Date | null
    status: 'completed' | 'in-progress' | 'failed'
    duration: number
  }>({ timeStarted: null, status: 'in-progress', duration: 0 })
  const [isSavingAdHocReport, setIsSavingAdHocReport] = useState<boolean>(false)
  const [isDownloadingAdHocReport, setIsDownloadingAdHocReport] = useState<boolean>(false)
  const [adHocReportData, setAdHocReportData] = useState<Record<string, { [key: string]: string | object }>>({})

  const fetchStatusIntervalRef = useRef<NodeJS.Timeout | null>(null)
  const progressIntervalRef = useRef<NodeJS.Timeout | null>(null)
  const [adhocElapsedTime, setAdhocElapsedTime] = useState(0)

  const currentReportTierMap: Record<number, Dispatch<SetStateAction<string | null>>> = {
    0: setGeneratedReportTier1,
    1: setGeneratedReportTier2,
    2: setGeneratedReportTier3,
  }

  const [hierarchy, setHierarchy] = useState<API.AssessmentAPI.ReportHierarchy>({})
  const [isFetchingReportData, setIsFetchingReportData] = useState(false)
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [openQuestionPreviewId, setOpenQuestionPreviewId] = useState<string | null>(null)

  const adHocSteps = useMemo((): AdHocStepsType[] => {
    return adHocStepsState.map((step) => ({
      ...step,
      label: t(`assessment.adhoc.steps.${step.value}`),
    }))
  }, [t, adHocStepsState])

  const getFirstFailedStepValue = useCallback((): AdHocValueType | undefined => {
    const failedStep = adHocStepsState.find((step) => step.status === 'failed')
    return failedStep ? failedStep.value : undefined
  }, [adHocStepsState])

  const getCompletedSteps = useCallback((): AdHocValueType[] => {
    return adHocStepsState.filter((step) => step.status === 'completed').map((step) => step.value)
  }, [adHocStepsState])

  const clearConfigurationTimeout = () => {
    clearTimeout(configTimer)
    setExceededExpectedConfigurationTime(false)
  }

  useEffect(() => {
    const isFailedState = getFirstFailedStepValue()
    // If we are currently processing a configuration file, and we haven't finished processing or come across a failed state, call getReportStatus every 10s and increase total duration by 1s
    if (isProcessing && !isProcessCompleted && !isFailedState && currentReportID && selectedClient) {
      fetchStatusIntervalRef.current = setInterval(async () => {
        await getReportStatus({ reportId: currentReportID, engagementId: selectedClient.engagementId })
      }, 10000)
      progressIntervalRef.current = setInterval(() => {
        setAdhocElapsedTime((prev) => prev + 1)
      }, 1000)
    } else if (fetchStatusIntervalRef.current && progressIntervalRef.current) {
      clearInterval(fetchStatusIntervalRef.current)
      clearInterval(progressIntervalRef.current)
      fetchStatusIntervalRef.current = null
      progressIntervalRef.current = null
    }

    return () => {
      if (fetchStatusIntervalRef.current) {
        clearInterval(fetchStatusIntervalRef.current)
      }
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReportID, getFirstFailedStepValue, isProcessCompleted, isProcessing, selectedClient])

  const handleReportStatus = (response: API.AssessmentAPI.GetReportStatusAPIResponse): void => {
    if (response) {
      if (response?.lastUpdated) {
        const currentEpochTime = Date.now()
        // If at least 35 seconds (35000 milliseconds) has passed since the last update, stop processing and fail the report generation
        if (currentEpochTime - parseInt(response.lastUpdated, 10) >= 35000) {
          updateInProgressStepToFailed(response.error?.requestId)
        }
      }
      if (response?.error) {
        const validValues: AdHocValueType[] = [
          'configuration',
          'interviews',
          'documents',
          'summary',
          'recommendations',
          'observations',
        ]
        if (validValues.includes(response.error.step as AdHocValueType)) {
          updateAdHocStepStatus(response.error.step as AdHocValueType, 'failed')
          setErrorRequestID(response.error.requestId)
        } else {
          updateInProgressStepToFailed(response.error.requestId)
        }
        clearConfigurationTimeout()
      }
      if (response?.data && typeof response?.data === 'object') {
        // Only when we get back a response object containing new data do we want to handle updating adHocStepsState
        if (!isEqual(reportStatusData.data, response.data)) {
          setReportStatusData(response)
          if (currentReportID && selectedClient) {
            // Whenever we get new reportStatusData (e.g., a step has completed or a step has errored out), refetch the report data
            getReportData({
              reportId: currentReportID,
              engagementId: selectedClient.engagementId,
              fileLocation: 'adhoc',
            })
          }
          // Got back an empty data object. Now need to check if we have previously completed configuration step or not
          if (Object.keys(response?.data).length === 0) {
            // adHocSteps[0] is always equal to the configuration step, ordering matters in this array
            // If the configuration step was completed, we know a step later failed on us
            if (adHocStepsState[0].status === 'completed') {
              // Find the step that is currently tagged as 'in-progress' and mark it as failed
              updateInProgressStepToFailed(response.error?.requestId)
              clearConfigurationTimeout()
            } else {
              // Since we are still fetching configuration, wait for the timeout to finish
              // We allow up to 30s for the configuration step to be returned as true before cancelling the processing
            }
          } else {
            // Below function takes care of updating our state when changes have occurred
            // E.g. in-progress -> completed, incomplete -> in-progress
            updateAdHocSteps(response.data)
          }
        }
      } else {
        // Unexpected data type
        // Find the step that is currently tagged as 'in-progress' and mark it as failed
        updateInProgressStepToFailed(response.error?.requestId)
        clearConfigurationTimeout()
      }
    }
  }

  const updateAdHocStepStatus = useCallback((stepValue: AdHocValueType, newStatus: StepItemStatus) => {
    // If we get an error for the interviews step, need to make sure we map the error to the "documents" Ad-Hoc step
    const stepValueToMatch = stepValue === 'interviews' ? 'documents' : stepValue
    setAdHocStepsState((prevSteps) =>
      prevSteps.map((step) => (step.value === stepValueToMatch ? { ...step, status: newStatus } : step))
    )
  }, [])

  useEffect(() => {
    // if every step is completed successfully, update the overall status to completed
    if (adHocStepsState.every((step) => step.status === 'completed')) {
      setAdHocGenerationStatistics((prev) => ({
        ...prev,
        status: 'completed',
      }))
      setTimeout(() => {
        setIsProcessCompleted(true)
        setIsProcessing(false)
      }, 2000)
    }
    // if even one step has failed, update the overall status to failed
    if (adHocStepsState.some((step) => step.status === 'failed')) {
      setAdHocGenerationStatistics((prev) => ({
        ...prev,
        status: 'failed',
      }))
    }
  }, [adHocStepsState, setAdHocGenerationStatistics, setIsProcessCompleted, setIsProcessing])

  const updateInProgressStepToFailed = useCallback((requestId?: string) => {
    setAdHocStepsState((prevState) => {
      let foundInProgress = false
      return prevState.map((step) => {
        if (!foundInProgress && step.status === 'in-progress') {
          foundInProgress = true
          return { ...step, status: 'failed' }
        }
        return step
      })
    })
    if (requestId) {
      setErrorRequestID(requestId)
    }
  }, [])

  // Whenever a new client is selected, reset all values that are client-specific
  const resetAllClientSpecificInfo = useCallback(() => {
    resetAdHocStepsStateStatus()
    setAdHocGenerationStatistics({ timeStarted: null, status: 'in-progress', duration: 0 })
    setAdhocElapsedTime(0)
    setConfigProcessError(null)
    setCurrentReportID(undefined)
    setGeneratedReportID(undefined)
    setGeneratedReportTier1(null)
    setGeneratedReportTier2(null)
    setGeneratedReportTier3(null)
    setErrorRequestID(null)
    clearConfigurationTimeout()
    setExportReportError(null)
    setHierarchy({})
    setIsDownloadButtonDisabled(false)
    setIsDownloadingAdHocReport(false)
    setIsFileUploading(false)
    setIsProcessCompleted(false)
    setIsProcessing(false)
    setIsSaveButtonDisabled(false)
    setIsSavingAdHocReport(false)
    setReportJsonData({})
    setReportProcessError({
      saveReport: null,
      previewReport: null,
      downloadReport: null,
    })
    setReportsContainerPath(null)
    setReportStatusData({ data: {} })
    setErrorRequestID(null)
    setUploadError('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resetAdHocProcessInfo = useCallback(() => {
    resetAdHocStepsStateStatus()
    setIsSaveButtonDisabled(false)
    setAdHocGenerationStatistics({ timeStarted: null, status: 'in-progress', duration: 0 })
    setAdhocElapsedTime(0)
    setConfigProcessError(null)
    setCurrentReportID(undefined)
    setErrorRequestID(null)
    setGeneratedReportID(undefined)
    setGeneratedReportTier1(null)
    setGeneratedReportTier2(null)
    setGeneratedReportTier3(null)
    clearConfigurationTimeout()
    setExportReportError(null)
    setIsDownloadButtonDisabled(false)
    setIsDownloadingAdHocReport(false)
    setIsFileUploading(false)
    setIsProcessCompleted(false)
    setIsProcessing(false)
    setIsSavingAdHocReport(false)
    setReportJsonData({})
    setReportProcessError({
      saveReport: null,
      previewReport: null,
      downloadReport: null,
    })
    setReportsContainerPath(null)
    setReportStatusData({ data: {} })
    setErrorRequestID(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateAdHocSteps = (reportStatus: {
    [key: string]: boolean | string | API.AssessmentAPI.StatusErrorEvent[]
  }) => {
    let foundACompleteItem = false

    const areInterviewsAndDocumentsBothCompleted = () => {
      // if both interviews and documents exists in the status response
      if ('interviews' in reportStatus && 'documents' in reportStatus) {
        // if both interviews and documents are true
        if (reportStatus.interviews && reportStatus.documents) {
          return true
        }

        // if one or the other or both are false
        return false
      }

      // if either interviews and documents or both does NOT exists in the status response
      return false
    }

    setAdHocStepsState((prevState) => {
      return prevState.map((step) => {
        // if the step is either documents or interviews

        const isCompleted = reportStatus[step.value] || false
        if (isCompleted) {
          if (step.value === 'documents' || step.value === 'interviews') {
            foundACompleteItem = false
            if (areInterviewsAndDocumentsBothCompleted()) {
              foundACompleteItem = true
              return {
                ...step,
                status: 'completed',
              }
            }
            // Need to check if we were previously in a 'incomplete' state to make sure we aren't overriding a 'failed' state
            // This is for the circumstance where getReportStatus returns a new step that is "true" and an error object
            return {
              ...step,
              status: step.status === 'incomplete' ? 'in-progress' : step.status,
            }
          }

          if (step.value === 'configuration') {
            clearConfigurationTimeout()
          }

          foundACompleteItem = true
          // if its not documents or interviews
          return {
            ...step,
            status: 'completed',
          }
        } else if (foundACompleteItem) {
          foundACompleteItem = false
          // Need to check if we were previously in a 'incomplete' state to make sure we aren't overriding a 'failed' state
          // This is for the circumstance where getReportStatus returns a new step that is "true" and an error object
          return {
            ...step,
            status: step.status === 'incomplete' ? 'in-progress' : step.status,
          }
        } else {
          foundACompleteItem = false
          return {
            ...step,
            status: 'incomplete',
          }
        }
      })
    })
  }

  const resetAdHocStepsStateStatus = () => {
    setAdHocStepsState((prevState) => {
      return prevState.map((step, stepIndex) => {
        return {
          ...step,
          status: stepIndex === 0 ? 'in-progress' : 'incomplete',
        }
      })
    })
  }

  const updateReportProcessError = (errorName: ReportProcessType, errorValue: boolean | null): void => {
    setReportProcessError((prevError) => {
      return {
        ...prevError,
        [errorName]: errorValue,
      }
    })
  }

  const getReportProcessErrors = (): ReportProcessType[] => {
    // return all the errors = true
    return Object.keys(reportProcessError)
      .filter((key) => reportProcessError[key as ReportProcessType])
      .map((key) => key as ReportProcessType)
  }

  const getTransformedDocList = useCallback((docList: Record<number, API.DocListDocument>) => {
    const currentCategories: string[] = []
    const arrayToReturn: { category: string; docList: string[]; include: boolean }[] = []

    Object.values(docList).forEach((listItem) => {
      if (listItem.category) {
        if (!currentCategories.includes(listItem.category)) {
          arrayToReturn.push({ category: listItem.category, docList: [listItem.title], include: true })

          // Acknowledge that this category has been dealt with in the past
          currentCategories.push(listItem.category)
        } else {
          const indexOfCategory = arrayToReturn.findIndex((item) => item.category === listItem.category)
          arrayToReturn[indexOfCategory].docList.push(listItem.title)
        }
      }
    })

    // Ensure docList is always empty
    const transformedArrayToReturn = arrayToReturn.map((item) => ({
      ...item,
      docList: [],
    }))

    return transformedArrayToReturn
  }, [])

  const numberFormat = useMemo(() => {
    return new Intl.NumberFormat(language)
  }, [language])

  const { maxFileNameLength, uploadFileMaxCount, uploadFileMaxSize } = useMemo(() => {
    const assessmentBotConfig = config?.find((botConfig) => botConfig.type === 'assessment')

    if (!assessmentBotConfig) {
      return {
        maxFileNameLength: 0,
        uploadFileMaxCount: 0,
        uploadFileMaxSize: 0,
        uploadTokenMaxSize: 0,
      }
    }

    return {
      maxFileNameLength: assessmentBotConfig.fileNameLength ?? 0,
      uploadFileMaxCount: assessmentBotConfig.uploadFileMaxCount ?? 0,
      uploadFileMaxSize: assessmentBotConfig.uploadFileMaxSize ?? 0,
      uploadTokenMaxSize: assessmentBotConfig.uploadTokenMaxSize ?? 0,
    }
  }, [config])

  const maxFileSize = useMemo(() => {
    return formatMaxFileSize(uploadFileMaxSize, numberFormat, t as TFunction)
  }, [uploadFileMaxSize, numberFormat, t])

  const getReportStatus = async ({ reportId, engagementId }: { reportId: string; engagementId: string }) => {
    try {
      const token = await getToken()
      const reportStatus = await fetchData<API.AssessmentAPI.GetReportStatusAPIResponse>({
        url: `${API_ENDPOINT}/rcassessmentapi/getReportStatus`,
        token,
        payload: {
          engagementId,
          language: 'EN',
          reportId,
        },
        requestId: generateRequestId(),
      })
      if (reportStatus) {
        handleReportStatus(reportStatus)
      }
      return reportStatus
    } catch (e) {
      if (e instanceof AssessmentSchemaError) {
        throw new AssessmentSchemaError(e.message)
      }

      throw new AssessmentError('assessment-request-error-getReportStatus', e as Error)
    }
  }

  const putFileIntoStorageContainer = async ({ url, file }: { url: string; file: File }) => {
    try {
      await putFileIntoStorage({
        url,
        file,
        headers: {
          'Content-Type': file.type,
          'x-ms-blob-type': 'BlockBlob',
        },
        feRequestId: generateRequestId(),
      })
    } catch (e) {
      if (e instanceof AzureFileUploadError) {
        if (e.status === '403') {
          throw new AssessmentError(e.statusText)
        }
        throw e
      }
      throw new AssessmentError('upload-request-error-upload', e as Error)
    }
  }

  const getDocList = async ({ engagementId }: { engagementId: string }) => {
    try {
      const token = await getToken()
      const docList = await fetchData<Record<number, API.DocListDocument>>({
        url: `${API_ENDPOINT}/rcassessmentapi/docChatDocuments`,
        token,
        payload: {
          language: 'EN',
          engagementId,
        },
        requestId: generateRequestId(),
      })
      return docList
    } catch (e) {
      if (e instanceof AssessmentSchemaError) {
        throw new AssessmentSchemaError(e.message)
      }

      throw new AssessmentError(`${API.AssessmentAPI.RunReportAPIError.emptyDocList}`)
    }
  }

  const runReport = async ({
    configurationId,
    engagementId,
    docList,
  }: {
    configurationId: string
    engagementId: string
    docList: { category: string; docList: string[]; include: boolean }[]
  }) => {
    try {
      const token = await getToken()
      const requestId = generateRequestId()
      const response = await fetchData<
        API.AssessmentAPI.RunReportAPIResponse | API.AssessmentAPI.RunReportAPIErrorResponse
      >({
        url: `${API_ENDPOINT}/rcassessmentapi/runReport`,
        token,
        payload: {
          configurationId,
          docList,
          engagementId,
          language: 'EN',
          uiRequestId: requestId,
        },
        requestId: requestId,
      })
      setAdHocGenerationStatistics((prev) => ({
        ...prev,
        timeStarted: new Date(),
      }))
      return response
    } catch (e) {
      if (e instanceof AssessmentSchemaError) {
        throw new AssessmentSchemaError(e.message)
      }

      throw new AssessmentError('assessment-request-error-runReport', e as Error)
    }
  }

  const getConfigUrl = async ({ file, engagementId }: { file: File; engagementId: string }) => {
    try {
      const token = await getToken()
      const response = await fetchData<API.AssessmentAPI.GetUrlUploadResponse>({
        url: `${API_ENDPOINT}/rcassessmentapi/urlUpload`,
        token,
        payload: {
          engagementId,
          filename: file.name,
          fileSize: file.size,
          contentType: file.type,
          language: 'EN',
        },
        requestId: generateRequestId(),
      })

      if ('url' in response) {
        return { url: response.url, configurationId: response.configurationId }
      }
      throw new AssessmentSchemaError(response.errorList?.[0].code)
    } catch (e) {
      if (e instanceof AssessmentSchemaError) {
        throw new AssessmentSchemaError(e.message)
      }
      throw new AssessmentError('assessment-request-error-urlUpload', e as Error)
    }
  }

  const uploadConfigRequest = async ({ file, engagementId }: { file: File; engagementId: string }) => {
    // These two lines are here to make sure that if the timeout is somehow still running from a previous attempt, it gets cleared.
    clearConfigurationTimeout()
    try {
      setIsFileUploading(true)

      const getConfigURLOutput = await getConfigUrl({ file, engagementId })

      if (getConfigURLOutput && getConfigURLOutput.url && getConfigURLOutput.configurationId) {
        const { url, configurationId } = getConfigURLOutput
        try {
          await putFileIntoStorageContainer({ url, file })

          setIsProcessing(true)

          // After 30s, set this flag to true and let the dependencies update whether it should show the timeout message
          // This way, we only calculate if we should show the message twice: when the timeout occurs, and one more time if any errors occur
          setConfigTimer(
            setTimeout(() => {
              setExceededExpectedConfigurationTime(true)
            }, CONFIG_TIME_LIMIT)
          )

          try {
            const docListResponse = await getDocList({ engagementId })

            if ('error' in docListResponse) {
              throw new AssessmentError(`${docListResponse.error}`)
            } else if (docListResponse) {
              const transformedDocList = getTransformedDocList(docListResponse)

              if (selectedClient) {
                const runReportResponse = await runReport({
                  configurationId,
                  docList: transformedDocList,
                  engagementId,
                })
                if (runReportResponse && 'reportId' in runReportResponse) {
                  setCurrentReportID(runReportResponse.reportId)
                } else if ('error' in runReportResponse) {
                  throw new AssessmentError(`${runReportResponse.error}`, undefined, runReportResponse.errorData)
                }
              }
            }
          } catch (error: unknown) {
            clearConfigurationTimeout()
            if (error instanceof AssessmentError && error.message) {
              setConfigProcessError({
                error: error.message as API.AssessmentAPI.RunReportAPIError,
                errorData: error.errorData as API.AssessmentAPI.ErrorData,
              })
              updateAdHocStepStatus('configuration', 'failed')
            } else {
              setConfigProcessError({ error: API.AssessmentAPI.RunReportAPIError.configFailed })
              updateAdHocStepStatus('configuration', 'failed')
            }
          }
        } catch (e) {
          if (e instanceof AzureFileUploadError) {
            logUIErrorEvent({
              bot: 'ASSESSMENT',
              errorMessage: `Did NOT get back status === 201 && statusText === 'Created' when calling putFileIntoStorageContainer. status: ${e.status}, statusText: ${e.statusText}`,
              requestId: e.requestId,
            })
          }
          const err = (e as Error).message.split('-')
          setUploadError(err[err.length - 1])
        }
      }
    } catch (e) {
      if (e instanceof NetworkError) {
        logUIErrorEvent({
          bot: 'ASSESSMENT',
          error: e as Error,
          errorMessage: 'assessment-report-request-error',
          requestId: e.requestId,
        })
      }
      const err = (e as Error).message.split('-')
      setUploadError(err[err.length - 1])
    } finally {
      setIsFileUploading(false)
    }
  }

  const exportReportData = async ({
    engagementId,
    tierIds,
    fileLocation,
    latest = false,
  }: {
    engagementId: string
    tierIds: string[]
    fileLocation: API.AssessmentAPI.GetReportDataAPIRequest['fileLocation']
    latest?: boolean
  }) => {
    try {
      const token = await getToken()
      setIsExportReportData(true)
      const response = await fetchDataRaw<Response>({
        url: `${API_ENDPOINT}/rcassessmentapi/exportReportData`,
        token,
        payload: {
          engagementId,
          language: 'EN',
          tierIds,
          latest,
          fileLocation,
        },
        requestId: generateRequestId(),
      })
      if (response) {
        try {
          const blob = await response.blob()

          saveAs(blob, getFileName(response.headers))
        } catch (e) {
          if (e instanceof AssessmentSchemaError) {
            throw new AssessmentSchemaError(e.message)
          }
          throw new AssessmentError('assessment-request-error-getReportJSONBlob', e as Error)
        }
      }
    } catch (e) {
      if (e instanceof AssessmentSchemaError) {
        throw new AssessmentSchemaError(e.message)
      }
      throw new AssessmentError('assessment-request-error-getReportJSONBlob', e as Error)
    } finally {
      setIsExportReportData(false)
    }
  }

  const saveUserReport = async () => {
    try {
      if (selectedClient && currentReportID) {
        updateReportProcessError('saveReport', false)
        setIsSavingAdHocReport(true)
        const authToken = await getToken()
        const response = await fetchData<API.AssessmentAPI.SaveUserReportAPIResponse>({
          url: `${API_ENDPOINT}/rcassessmentapi/saveUserReport`,
          token: authToken,
          payload: {
            engagementId: selectedClient?.engagementId,
            language: 'EN',
            reportId: currentReportID,
          },
          requestId: generateRequestId(),
        })

        if (response) {
          const tiers = response.reportDestinationPath.split('/')
          if (tiers.length > 0) {
            tiers.forEach((tier, index) => {
              if (currentReportTierMap[index]) {
                currentReportTierMap[index](tier)
              }
            })
            setGeneratedReportID(tiers[tiers.length - 1])
          }
          setReportsContainerPath(response.reportDestinationPath)
          setIsSaveButtonDisabled(true)
        }
      }
    } catch (e) {
      updateReportProcessError('saveReport', true)
    } finally {
      setIsSavingAdHocReport(false)
    }
  }

  const getReportData = async ({
    reportId,
    engagementId,
    fileLocation,
  }: {
    reportId: string
    engagementId: string
    fileLocation: API.AssessmentAPI.GetReportDataAPIRequest['fileLocation']
  }) => {
    try {
      setIsFetchingReportData(true)
      const token = await getToken()
      const payload: API.AssessmentAPI.GetReportDataAPIRequest = {
        language: 'EN',
        engagementId,
        reportId,
        fileLocation,
      }
      const reportData = await fetchData<API.AssessmentAPI.GetReportDataAPIResponse>({
        url: `${API_ENDPOINT}/rcassessmentapi/getReportData`,
        token,
        payload,
        requestId: generateRequestId(),
      })
      const { data } = reportData
      setAdHocReportData((currentAdHocReportData) => {
        return {
          ...currentAdHocReportData,
          [reportId]: data,
        }
      })
      setReportJsonData((currentReportJsonData) => {
        return { ...currentReportJsonData, [reportId]: JSON.stringify(data) }
      })
    } catch (e) {
      if (e instanceof AssessmentSchemaError) {
        throw new AssessmentSchemaError(e.message)
      }
      throw new AssessmentError('assessment-request-error-getReportData', e as Error)
    } finally {
      setIsFetchingReportData(false)
    }
  }

  return (
    <>
      <AssessmentContext.Provider
        value={{
          adhocElapsedTime,
          adHocGenerationStatistics,
          adHocReportData,
          adHocSteps,
          adHocStepsState,
          configProcessError,
          currentReportID,
          errorRequestID,
          exceededExpectedConfigurationTime,
          exportReportData,
          exportReportError,
          generatedReportID,
          generatedReportTier1,
          generatedReportTier2,
          generatedReportTier3,
          getCompletedSteps,
          getFirstFailedStepValue,
          getReportData,
          getReportProcessErrors,
          getReportStatus,
          hierarchy,
          isDownloadButtonDisabled,
          isDownloadingAdHocReport,
          isExportReportData,
          isFetchingReportData,
          isFileUploading,
          isGettingAdHocPreview,
          isInitialLoad,
          isProcessCompleted,
          isProcessing,
          isSaveButtonDisabled,
          isSavingAdHocReport,
          isWarningAcknowledged,
          maxFileNameLength,
          maxFileSize,
          openQuestionPreviewId,
          reportJsonData,
          reportsContainerPath,
          resetAdHocProcessInfo,
          resetAdHocStepsStateStatus,
          resetAllClientSpecificInfo,
          saveUserReport,
          selectedClient,
          setAdhocElapsedTime,
          setAdHocGenerationStatistics,
          setAdHocStepsState,
          setConfigProcessError,
          setExportReportError,
          setHierarchy,
          setIsDownloadButtonDisabled,
          setIsDownloadingAdHocReport,
          setIsExportReportData,
          setIsGettingAdHocPreview,
          setIsInitialLoad,
          setIsProcessCompleted,
          setIsProcessing,
          setIsWarningAcknowledged,
          setOpenQuestionPreviewId,
          setReportJsonData,
          setReportsContainerPath,
          setSelectedClient,
          setUploadError,
          updateAdHocSteps,
          updateAdHocStepStatus,
          updateInProgressStepToFailed,
          updateReportProcessError,
          uploadConfigRequest,
          uploadError,
          uploadFileMaxCount,
        }}
      >
        {children}
      </AssessmentContext.Provider>
    </>
  )
}

export const useAssessmentContext = (): AssessmentContextType => useContext(AssessmentContext)
