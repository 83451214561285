import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Text } from '@chakra-ui/layout'
import cx from 'classnames'

import { useBotSpecificContext } from 'providers/BotSpecificProvider'
import { useI18Context } from 'providers/i18Provider'
import { useKBotContext } from 'providers/KBotsProvider'
import { useMessagesContext } from 'providers/MessageProvider'
import { useThemeContext } from 'providers/ThemeProvider'
import { useUploadContext } from 'providers/UploadProvider'

import { FileConfigs } from 'types/types'

import { Banner } from './Banner'
import { ModalBox } from './Modal'
import { Uploader } from './Uploader'

type UploadWithPreviewProps = {
  allowMultipleFiles?: boolean
  botName: string
  fileTypesByMaxSize?: Record<string, string[]>
  isFileUploading: boolean
  isOpen: boolean
  // Pass in kBotFormId for when we are creating/editing a k-bot: this is just to indicate that we are on either the create or edit form.
  kBotFormId?: 'createKBotTemp' | 'editKBotTemp'
  maxFileNameLength: number
  maxFileSize: string
  maxNumFiles: number
  onClose: () => void
  onError: (error: string) => void
  onSubmit: (files: File[], fileConfigs: FileConfigs) => void
  mapUploadTypesToText: Array<string>
  allowedUploadTypes: Array<string>
  uploadError: string
  uploadTokenMaxSize: number
}

export const UploadModal = ({
  allowMultipleFiles = false,
  botName,
  fileTypesByMaxSize,
  isFileUploading,
  isOpen,
  kBotFormId,
  maxFileNameLength,
  maxFileSize,
  maxNumFiles,
  onClose,
  onError,
  onSubmit,
  mapUploadTypesToText,
  allowedUploadTypes,
  uploadError,
  uploadTokenMaxSize,
}: UploadWithPreviewProps) => {
  const { language } = useI18Context()
  const { t } = useTranslation('uploadModal')
  const { docContents, tempDocContents } = useUploadContext()

  const { getConversation } = useMessagesContext()
  const { kBotDetailsForChatUtilization } = useKBotContext()
  const { currentConversationID } = useBotSpecificContext()
  const { isLightMode } = useThemeContext()

  const [isWarningAcknowledged, setIsWarningAcknowledged] = useState<boolean>(false)

  const docContentsToUse = useMemo(() => {
    return kBotFormId
      ? // If we received a K-Bot Form ID we are in the create/edit page of K-Bots; use that as the key to read current doc contents
        docContents?.[botName]?.[kBotFormId]
      : currentConversationID
        ? // Otherwise, reference the current conversation's doc contents if it exists
          docContents?.[botName]?.[currentConversationID]
        : // Reference the placeholder conversation's docContents if the currentConversationID is null (we are in the placeholder conversation)
          tempDocContents[botName]
  }, [botName, currentConversationID, docContents, kBotFormId, tempDocContents])

  const extensionTypes = useMemo(() => {
    return [...new Set([...allowedUploadTypes, ...mapUploadTypesToText])]
  }, [allowedUploadTypes, mapUploadTypesToText])

  const numberFormat = useMemo(() => {
    return new Intl.NumberFormat(language)
  }, [language])

  const onModalClose = useCallback(() => {
    // After the user closes the modal, reset the error message
    onError('')
    onClose()
  }, [onClose, onError])

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onError('')
      onSubmit(acceptedFiles, { allowedUploadTypes, mapUploadTypesToText })
    },
    [onError, onSubmit, allowedUploadTypes, mapUploadTypesToText]
  )

  const maxCharacterCount = useMemo(() => {
    let kBotUploadedCharacters = 0
    const currentConversation = getConversation(botName, currentConversationID)
    // Calculate the total number of characters used by the K-Bots File Content and User Instructions properties, and use this towards subtracting from the max upload character count
    if (currentConversation || kBotDetailsForChatUtilization[botName]) {
      const fileContentLength =
        currentConversation?.fileContent?.length ?? kBotDetailsForChatUtilization[botName]?.fileContent?.length ?? 0
      const userInstructionsLength =
        currentConversation?.userInstructions?.length ??
        kBotDetailsForChatUtilization[botName]?.userInstructions?.length ??
        0

      kBotUploadedCharacters += fileContentLength + userInstructionsLength
    }

    // (Max character count) - (K-Bot File Content count + K-Bot User Instructions count) - (Previously upload documents count)
    const calculatedMax =
      uploadTokenMaxSize * 4 - // the base character limit
      kBotUploadedCharacters - // character count of uploaded files for a kbot's knowledge base
      (allowMultipleFiles ? docContentsToUse?.characterCount || 0 : 0) // If we allow multiple files, take into account the already uploaded content.

    // Make sure we never return a negative number
    return calculatedMax < 0 ? 0 : calculatedMax
  }, [
    allowMultipleFiles,
    botName,
    currentConversationID,
    docContentsToUse?.characterCount,
    getConversation,
    kBotDetailsForChatUtilization,
    uploadTokenMaxSize,
  ])

  const uploadErrorMessage = useMemo(() => {
    return t(`modal.${uploadError}`, {
      maxFileNameLength,
      // If the uploadFileMaxSize is less than 1,000,000 then we are dealing with KB (e.g., 900,000 -> 900 KB). Otherwise we are dealing with MB (e.g., 20,000,000 -> 20 MB)
      maxFileSize,
      maxNumberTokens: numberFormat.format(maxCharacterCount),
    })
  }, [maxCharacterCount, maxFileNameLength, maxFileSize, numberFormat, t, uploadError])

  const uploadWarningBanner = useMemo(() => {
    const result = t('modal.warningBanner', { returnObjects: true })

    if (Array.isArray(result)) {
      return result
    }

    return []
  }, [t])

  return (
    <ModalBox
      disableCloseButton={isFileUploading}
      isOpen={isOpen}
      onClose={onModalClose}
      modalHeader={t('modal.uploadCTA')}
      modalFooter={{
        modalFooter: (
          <Box>
            {uploadError && <Text className="mb-2 text-xs text-red-400">{uploadErrorMessage}</Text>}
            <Text className="text-xs">{t('modal.disclaimer')}</Text>
          </Box>
        ),
      }}
      modalBody={
        <>
          <Uploader
            botName={botName}
            banner={
              <Banner
                className="mb-4"
                onClick={() => setIsWarningAcknowledged(true)}
                title={t('modal.warningBannerHeader')}
                description={
                  <Box className="last:mb-0">
                    {uploadWarningBanner.map((warning: string, warningIndex: number) => {
                      return (
                        <Text className="mb-2" key={`${warning}_${warningIndex}`}>
                          {warning}
                        </Text>
                      )
                    })}
                  </Box>
                }
                isVisible={!isWarningAcknowledged}
              />
            }
            customMetricsMessaging={
              uploadTokenMaxSize !== 0 && (
                <>
                  {t('modal.maxCharacterCount')}:{' '}
                  <Text as="span" className="font-bold">
                    {numberFormat.format(maxCharacterCount)} {t('modal.characters')}
                  </Text>
                </>
              )
            }
            customClasses={{
              button: cx('py-4 whitespace-normal min-h-fit', {
                'bg-kpmgGray4': isLightMode,
                'bg-kpmgGray2': !isLightMode,
              }),
            }}
            fileTypesByMaxSize={fileTypesByMaxSize}
            extensionTypes={extensionTypes}
            isFileUploading={isFileUploading}
            maxFileSize={maxFileSize}
            maxNumFiles={maxNumFiles}
            onDrop={onDrop}
            uploadError={uploadError}
          />
        </>
      }
    />
  )
}
