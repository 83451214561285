import { ErrorRes, TRequest, TResponse } from './api.types.js'

export type GetTranslateUrlUploadReqItem = {
  contentType: string
  fileSize: number
  fileName: string
}

export type GetUrlUploadSuccessResItem = {
  url: string
  documentId: string
  fileName: string
}

export interface GetTranslateUrlUploadReq extends TRequest {
  folderName?: string
  files: GetTranslateUrlUploadReqItem[]
}

export type GetTranslateUrlUploadSuccessRes = {
  items: GetUrlUploadSuccessResItem[]
  requestId: string
}

/**
 * possible errors:
 *  1. ContentTypeTypeError: meaning we dont support the file type users have uploaded.
 *  2. FileSizeOverSizeError: meaning user's file is too large
 *  3. FileNameOverLengthError: meaning user's filename doesnt match our standard
 *  4. FileNameInvalidCharError: meaning user's filename containing invalid characters.
 *  5. TooManyFilesError: meaning users submit too many files for translations
 *  6. FolderNameInvalidError: meaning folder name provided by user does not follow our pattern
 *  7. FolderNameOverLengthError: meaning the folder name length is longer than 20 characters (configurable)
 */
export type GetTranslateUrlUploadRes = GetTranslateUrlUploadSuccessRes | ErrorRes

export enum TargetLanguage {
  en = 'en',
  fr = 'fr',
}

export interface TranslateAPIRequest extends TRequest {
  folderId: string
  targetLanguage: TargetLanguage
}

/**
 * expected error:
 * 1. UnsupportedTargetLanguageError: meaning user sends the target language which is not supported by our system now.
 */
export type TranslateAPISuccessResponse = { requestId: string }
export type TranslateAPIResponse = TranslateAPISuccessResponse | ErrorRes

export interface GetFolderViewAPIRequest extends TRequest {
  folderId?: string
}

export type FileStatus = {
  id: string
  file: string
  status: TranslationJobStatus
  error?: string
}

export type FolderData = {
  files: FileStatus[]
  folderId: string
  createdAt: number
  requestId: string
}

export interface GetFolderViewAPIResponse extends TResponse {
  data: FolderData[]
}

export interface DeleteAPIRequest extends TRequest {
  files: string[]
}

export interface DeleteAPIResponse extends TResponse {
  status: 'ok'
}

export interface ExportTranslationDataAPIRequest extends TRequest {
  files: string[]
}

export enum TranslationJobStatus {
  Canceled = 'Canceled',
  Cancelling = 'Cancelling',
  Failed = 'Failed',
  NotStarted = 'NotStarted',
  Running = 'Running',
  Succeeded = 'Succeeded',
  ValidationFailed = 'ValidationFailed',
}
