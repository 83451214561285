import { useEffect, useMemo } from 'react'
import { IconType } from 'react-icons'
import { SkeletonCircle } from '@chakra-ui/skeleton'

import { useImageContext } from 'providers/ImageProvider'
import { useThemeContext } from 'providers/ThemeProvider'

import { ImageWithCache } from './ImageWithCache'

type BotIconWithFallbackProps = {
  alt: string
  botName: string
  botType: string
  className: string
  Icon: IconType | undefined
}

export const BotIconWithFallback = ({ botName, botType, alt, Icon, className }: BotIconWithFallbackProps) => {
  const { isLightMode } = useThemeContext()
  const { getImageBase64, fetchImageBase64 } = useImageContext()

  // Custom image in each bot must be named exactly as "botIconBlack" or "botIconWhite"
  const customImageSrc = useMemo(() => {
    if (isLightMode) {
      return `${botName.toLocaleLowerCase()}/assets/botIconBlack.png`
    } else {
      return `${botName.toLocaleLowerCase()}/assets/botIconWhite.png`
    }
  }, [isLightMode, botName])

  const imageState = getImageBase64(customImageSrc)

  useEffect(() => {
    // If the Icon is defined, we don't need to fetch the custom image
    // This means we are rendering a bot of type "general" where the Icon was undefined, so indicate that there is no image we need to fetch for it
    if (Icon || botType.toLocaleLowerCase() === 'general') {
      return
    }

    if (!imageState) {
      fetchImageBase64(customImageSrc)
    }
  }, [Icon, botType, customImageSrc, fetchImageBase64, getImageBase64, imageState])

  // If we were presented a BiIcon to use, render this
  if (Icon) {
    return <Icon className={className} />
  }

  if (botType.toLocaleLowerCase() === 'general') {
    return (
      <ImageWithCache
        alt={alt}
        imagePath={isLightMode ? 'images/robotBlack.png' : 'images/robotWhite.png'}
        className={className}
      />
    )
  }

  // If we haven't set the image to display yet, show the fallback Skeleton loading component
  if (!imageState || imageState.status === 'LOADING') {
    return <SkeletonCircle className={className} />
  }

  // If we are rendering a bot of type "general" and we don't have a custom BiIcon to display for the bot
  if (imageState.status === 'NOT_FOUND') {
    return (
      <ImageWithCache
        alt={alt}
        imagePath={isLightMode ? 'images/robotBlack.png' : 'images/robotWhite.png'}
        className={className}
      />
    )
  }

  // If we are not rendering a bot of type "general" and we weren't presented a BiIcon to display for the bot, display the fetched custom bot icon image
  return <ImageWithCache alt="Robot" imagePath={customImageSrc} className={className} />
}
