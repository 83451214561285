import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react'

import { FEUIConfig } from 'types/types'

type TermsContextType = {
  getTermsForBot: (botKey: string) => boolean
  setTermsForBot: (botKey: string, value: boolean) => void
  termsRecord: Record<string, boolean>
}

export const TermsContext = createContext<TermsContextType>({} as TermsContextType)

export const TermsProvider = ({ children, config }: { children: ReactNode; config: FEUIConfig[] | undefined }) => {
  const [termsRecord, setTermsRecord] = useState<Record<string, boolean>>({})

  useEffect(() => {
    if (config) {
      const initialTermsRecord = config.reduce(
        (acc, conf) => {
          // Check if isAcknowledgement is defined and true
          if (conf.isAcknowledgement === true) {
            acc[conf.botName] = false
          }
          return acc
        },
        {} as Record<string, boolean>
      )

      setTermsRecord(initialTermsRecord)
    }
  }, [config])

  const setTermsForBot = useCallback((botName: string, value: boolean) => {
    setTermsRecord((prev) => ({ ...prev, [botName]: value }))
  }, [])

  const getTermsForBot = useCallback(
    (botKey: string) => {
      return termsRecord[botKey]
    },
    [termsRecord]
  )

  return (
    <TermsContext.Provider value={{ getTermsForBot, setTermsForBot, termsRecord }}>{children}</TermsContext.Provider>
  )
}

export const useTermsContext = (): TermsContextType => useContext(TermsContext)
