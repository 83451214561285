import { StyleSheet } from '@react-pdf/renderer'
import kleoColors from 'styles/colors'

export const styles = StyleSheet.create({
  page: { padding: 54 },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    borderBottom: '1px solid black',
  },
  normalText: {
    fontFamily: 'Helvetica',
  },
  boldText: {
    fontFamily: 'Helvetica-Bold',
  },
  italicText: {
    fontFamily: 'Helvetica-Oblique',
  },
  title: {
    paddingVertical: '4px',
  },
  flexBoxColumn: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  inlineContainer: {
    fontSize: '10px',
    color: kleoColors.kpmgPurple,
    paddingTop: '4px',
  },
  codeBlockHeader: {
    display: 'flex',
    border: 1,
    borderColor: kleoColors.kpmgGray45,
    backgroundColor: kleoColors.kpmgGray45,
    fontFamily: 'Courier',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    paddingHorizontal: 14,
    paddingVertical: 7,
    marginTop: 4,
  },
  codeBlock: {
    display: 'flex',
    border: 1,
    borderColor: kleoColors.kpmgGray45,
    fontFamily: 'Courier',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    paddingHorizontal: 14,
    paddingBottom: 14,
    marginBottom: 4,
  },
  inlineCode: {
    fontFamily: 'Courier',
  },
})
