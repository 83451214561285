import { ReactNode } from 'react'

import { useThemeContext } from 'providers/ThemeProvider'

type GradientHeaderTextWrapperProps = {
  children: ReactNode
}

export const GradientHeaderTextWrapper = (props: GradientHeaderTextWrapperProps) => {
  const { children } = props

  const { isLightMode } = useThemeContext()

  return (
    <div
      className={`max-w-max font-opensanscondensed text-transparent bg-gradient-to-r bg-clip-text ${
        isLightMode ? 'from-kpmgCobaltBlue to-kpmgPurple' : 'from-kpmgLightBlue to-kpmgLightPurple'
      }`}
    >
      {children}
    </div>
  )
}
