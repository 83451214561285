import React, { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Heading, Text } from '@chakra-ui/layout'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal'

import { useThemeContext } from 'providers/ThemeProvider'

import { TextButtonBaseProps, WithStylingProps } from 'types/types'

// TODO - combine the TextButton components/use only one type
import { TextButton as Button } from './buttons/TextButton'
import { TooltipButton } from './buttons/TooltipButton'
import { TextButton } from './Button'

type ModalBoxProps = {
  closeOnEscape?: boolean
  closeOnOverlayClick?: boolean
  customHeaderContent?: React.ReactNode
  disableCloseButton?: boolean
  hasAutoWidth?: boolean
  hasFullWidth?: boolean
  hasOverflowedBody?: boolean
  hideCloseButton?: boolean
  isOpen: boolean
  modalBody?: React.ReactNode
  modalBodyText?: ModalBodyTextProps
  modalFooter?: ModalFooterProps
  modalHeader: string
  modalHeaderClass?: string
  onClose: () => void
  showHeaderDivider?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | 'full'
  subHeaders?: React.ReactNode
}

type ModalBodyTextProps =
  | { text?: string; className?: string; heading?: never; description?: never; details?: never }
  | { text?: never; className?: string; heading?: string; description?: string; details?: string }

type ModalFooterProps =
  | {
      modalFooter: React.ReactNode
      confirmFooterButton?: never
      cancelFooterButton?: never
    }
  | {
      modalFooter?: never
      confirmFooterButton?: ModalFooterButtonProps
      cancelFooterButton?: ModalFooterButtonProps
    }

type ModalFooterButtonProps = {
  tooltip?: {
    label: string
    placement?: 'top' | 'bottom' | 'left' | 'right'
  }
} & TextButtonBaseProps &
  WithStylingProps

const ModalBodyText = ({ text, heading, description, details, className }: ModalBodyTextProps) => {
  return (
    <Box className={className}>
      {text ? (
        <Text>{text}</Text>
      ) : (
        <>
          {heading && (
            <Text as="h2" className="mb-2 font-bold">
              {heading}
            </Text>
          )}
          {description && <Text as="p">{description}</Text>}
          {details && (
            <Text as="p" className="my-4">
              {details}
            </Text>
          )}
        </>
      )}
    </Box>
  )
}

const ModalFooterButton = ({ tooltip, ...rest }: ModalFooterButtonProps) => {
  return tooltip ? (
    <TooltipButton label={tooltip.label} placement={tooltip.placement} button={<TextButton {...rest} />} />
  ) : (
    <TextButton {...rest} />
  )
}

export const ModalBox = (props: ModalBoxProps) => {
  const {
    closeOnEscape = true,
    closeOnOverlayClick = false,
    customHeaderContent,
    disableCloseButton = false,
    hasAutoWidth,
    hasFullWidth,
    hasOverflowedBody = false,
    hideCloseButton = false,
    isOpen,
    modalBody,
    modalBodyText,
    modalFooter,
    modalHeader,
    modalHeaderClass = 'text-lg',
    onClose,
    showHeaderDivider = false,
    size = 'md',
    subHeaders,
  } = props

  const { isDesktop, isTablet, isLightMode } = useThemeContext()

  const handleDisabledClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    // if it's not disabled, close it, else do nothing
    if (!disableCloseButton) {
      onClose()
    }
  }

  const modalContentClass = hasAutoWidth
    ? {
        width: hasFullWidth ? 'full' : 'auto',
        maxWidth: isDesktop ? '66.6667%' : isTablet ? '75%' : '91.6667%', // simulating width-layout
      }
    : {}

  const { confirmFooterButton, cancelFooterButton } = modalFooter || {}

  return (
    <Modal
      closeOnEsc={closeOnEscape}
      closeOnOverlayClick={closeOnOverlayClick}
      isOpen={isOpen}
      onClose={onClose}
      size={size}
    >
      <ModalOverlay />
      <ModalContent className={`mx-4 ${isLightMode ? 'bg-white' : 'bg-kpmgGray1'}`} sx={modalContentClass}>
        <ModalHeader
          test-id="modal-header"
          className={`${!hideCloseButton && 'pr-12'} text-base md:text-lg ${showHeaderDivider && 'border-b border-b-kpmgGray45'}`}
        >
          <Box className="flex flex-row flex-wrap items-center justify-between gap-2">
            <Heading className={modalHeaderClass} tabIndex={0}>
              {modalHeader}
            </Heading>
            <Box className={`${!hideCloseButton && 'pr-2'}`}>{customHeaderContent}</Box>
          </Box>
          {subHeaders}
        </ModalHeader>
        {!hideCloseButton && (
          <ModalCloseButton
            onClick={handleDisabledClose}
            rounded={'full'}
            _disabled={disableCloseButton ? { cursor: 'not-allowed', opacity: 0.4 } : {}}
            isDisabled={disableCloseButton}
          />
        )}
        <ModalBody className={`text-sm md:text-base ${hasOverflowedBody && 'overflow-y-auto'}`}>
          {modalBodyText && <ModalBodyText {...modalBodyText} />}
          {modalBody && <Box>{modalBody}</Box>}
        </ModalBody>
        <ModalFooter className="pt-2">
          {modalFooter?.modalFooter ? (
            <Box>{modalFooter.modalFooter}</Box>
          ) : (
            <Box className={'flex flex-wrap justify-end gap-3'}>
              {cancelFooterButton && <ModalFooterButton {...cancelFooterButton} />}
              {confirmFooterButton && (
                <ModalFooterButton
                  buttonBackground={isLightMode ? 'bg-kpmgCobaltBlue' : 'bg-white'}
                  fontColour={isLightMode ? 'text-white' : 'text-black'}
                  {...confirmFooterButton}
                />
              )}
            </Box>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

type ModalConfirmCancelFooterProps = {
  closeOnPrimaryClick?: boolean
  delay?: number
  isDisabled?: boolean
  onClose: () => void
  primaryButtonLabel: ReactNode
  primaryButtonOnClick: () => void
}

export const ModalConfirmCancelFooter = ({
  closeOnPrimaryClick = true,
  delay,
  isDisabled = false,
  onClose,
  primaryButtonLabel,
  primaryButtonOnClick,
}: ModalConfirmCancelFooterProps) => {
  const { t } = useTranslation('controls')
  const { isLightMode } = useThemeContext()

  const confirmOnClick = () => {
    primaryButtonOnClick()
    !!closeOnPrimaryClick && onClose()
  }

  return (
    <Box className="flex flex-wrap justify-end gap-3">
      <TextButton
        aria-label="close"
        id="close-button"
        disabled={isDisabled}
        onClick={onClose}
        text={t('controls.cancel')}
      />
      {delay ? (
        <CountdownButton
          isDisabled={isDisabled}
          delay={delay}
          primaryButtonOnClick={primaryButtonOnClick}
          primaryButtonLabel={primaryButtonLabel}
        />
      ) : (
        <Button
          aria-label="next"
          className={`rounded-full text-sm md:text-base ${
            isLightMode
              ? 'clear-messages-modal-button text-white bg-kpmgCobaltBlue hover:bg-kpmgCobaltBlueHover'
              : 'text-black bg-white hover:bg-whiteHover'
          }`}
          id="next-button"
          isDisabled={isDisabled}
          onClick={confirmOnClick}
        >
          {primaryButtonLabel}
        </Button>
      )}
    </Box>
  )
}

const CountdownButton = ({
  delay,
  primaryButtonOnClick,
  primaryButtonLabel,
  isDisabled,
}: Pick<ModalConfirmCancelFooterProps, 'primaryButtonOnClick' | 'primaryButtonLabel' | 'isDisabled'> & {
  delay: number
}) => {
  const { isLightMode } = useThemeContext()

  const [seconds, setSeconds] = useState(delay)
  const [isDisabledDelay, setIsDisabledDelay] = useState(true)

  useEffect(() => {
    if (seconds > 0) {
      const timerId = setTimeout(() => setSeconds(seconds - 1), 1000)
      return () => clearTimeout(timerId)
    } else {
      setIsDisabledDelay(false)
    }
  }, [seconds])

  return (
    <Button
      className="text-sm rounded-full md:text-base clear-messages-modal-button"
      aria-label="next"
      id="next-button"
      onClick={primaryButtonOnClick}
      isDisabled={isDisabled || isDisabledDelay}
      variant={isLightMode ? 'kpmgCobaltBlue' : 'kpmgWhite'}
    >
      {isDisabledDelay ? seconds : primaryButtonLabel}
    </Button>
  )
}
