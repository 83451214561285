import { useEffect } from 'react'
import { Image, ImageProps } from '@chakra-ui/image'

import { useImageContext } from 'providers/ImageProvider'

type ImageWithCacheProps = ImageProps & {
  alt: string
  className?: string
  imagePath: string
}

const ImageWithCache = ({ imagePath, alt, className = '', ...rest }: ImageWithCacheProps) => {
  const { getImageBase64, fetchImageBase64 } = useImageContext()
  const imageState = getImageBase64(imagePath)

  useEffect(() => {
    // Fetch image if not in cache or previously failed
    if (!imageState || imageState.status === 'NOT_FOUND') {
      fetchImageBase64(imagePath)
    }
  }, [imagePath, imageState, fetchImageBase64])

  if (!imageState || imageState.status === 'NOT_FOUND' || imageState.status === 'LOADING') return null

  return <Image alt={alt} src={imageState.base64} className={className} {...rest} />
}

export { ImageWithCache }
