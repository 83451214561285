import { KeyboardEvent } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BiBookOpen,
  BiCustomize,
  BiGlobe,
  BiHome,
  BiInfoCircle,
  BiLogOut,
  BiMenu,
  BiMoon,
  BiSidebar,
  BiSun,
} from 'react-icons/bi'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthenticatedTemplate } from '@azure/msal-react'
import { useDisclosure } from '@chakra-ui/hooks'
import { Image } from '@chakra-ui/image'
import { Box } from '@chakra-ui/layout'
import { Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuList } from '@chakra-ui/menu'
import cx from 'classnames'

import { useAuthContext } from 'providers/AuthProvider'
import { useI18Context } from 'providers/i18Provider'
import { useSidebarContext } from 'providers/SidebarProvider'
import { useThemeContext } from 'providers/ThemeProvider'

import { FEUIConfig } from 'types/types'

import { IconButton } from './Button'
import { ImageWithCache } from './ImageWithCache'
import { UserProfile } from './UserProfile'

export const Navbar = (props: { config: FEUIConfig[] | undefined; isChatBot: boolean }) => {
  const { isOpen: isUserProfileOpen, onOpen: onUserProfileOpen, onClose: onUserProfileClose } = useDisclosure()
  const { config, isChatBot } = props
  const navigate = useNavigate()
  const location = useLocation()
  const { msalInstance, areTermsAccepted } = useAuthContext()
  const { language, changeLanguage } = useI18Context()
  const { isTablet, toggleColorMode, isLightMode } = useThemeContext()
  const { handleSidebarToggle } = useSidebarContext()
  const { t } = useTranslation('navigation/navbar')

  const languageNames: { [key: string]: string } = {
    'en-US': 'English',
    'fr-CA': 'Français',
  }

  // Filter the data array to include only objects with isHidden: false
  const currentBot = config && config.filter((bot) => location.pathname.includes(bot.route))

  const assetsPrefix = currentBot && currentBot[0]?.botName && `${currentBot[0].botName.toLocaleLowerCase()}/assets`

  const resourcesURL =
    language === 'en-US'
      ? 'https://kpmgcan.sharepoint.com/sites/CA-OI-BUS-NMC-Kleo/SitePages/Home.aspx'
      : 'https://kpmgcan.sharepoint.com/sites/CA-OI-BUS-NMC-Kleo/SitePages/fr/Home.aspx'

  const libraryURL =
    language === 'en-US'
      ? 'https://kpmgcan.sharepoint.com/sites/CA-OI-BUS-NMC-Kleo/SitePages/K-Bot-Library.aspx'
      : 'https://kpmgcan.sharepoint.com/sites/CA-OI-BUS-NMC-Kleo/SitePages/fr/K-Bot-Library.aspx'

  // Value used to determine if we need to show the button in the Navbar for toggling the Sidebar on a mobile view. Is true if either:
  // 1. "isChatBot" - We are on a bot that is meant to display the Sidebar
  // 2. "currentBot && currentBot[0]?.type === 'assessment' ? location.pathname === '/assessment/search' : true" - If we are on the Assessment Bot, only show the sidebar when on the Search tab
  // 3. "currentBot && currentBot[0]?.type === 'translation' ? false : true" -  We aren't on the Translation Bot
  const showSidebarToggle =
    isChatBot &&
    (currentBot && currentBot[0]?.type === 'assessment' ? location.pathname === '/assessment/search' : true) &&
    (currentBot && currentBot[0]?.type === 'translation' ? false : true)

  return (
    <Box
      className={`flex items-center justify-between space-x-3 px-4 py-3 text-sm md:text-base ${isLightMode ? 'bg-backgroundLight' : 'bg-backgroundDark'}`}
    >
      {!isTablet ? (
        <>
          {showSidebarToggle && <IconButton size="xl" Icon={BiSidebar} onClick={handleSidebarToggle} />}
          <Box className="flex items-center">
            <Image
              alt="k-logo"
              className="h-[32px] hover:cursor-pointer"
              onClick={() => navigate(areTermsAccepted ? '/home' : '/')}
              onKeyDown={(event: KeyboardEvent<HTMLImageElement>) => {
                if (event.key === 'Enter') {
                  navigate(areTermsAccepted ? '/home' : '/')
                }
              }}
              src="../images/k-logo.png"
              tabIndex={0}
            />
            {currentBot && currentBot[0]?.navbarLogoLight && currentBot[0]?.navbarLogoDark && assetsPrefix && (
              // Max height of the image is set to 45px to match the KPMG logo
              <Image
                className="mr-4 max-h-[45px]"
                src={
                  isLightMode
                    ? `${assetsPrefix}/${currentBot[0].navbarLogoLight}.svg`
                    : `${assetsPrefix}/${currentBot[0].navbarLogoDark}.svg`
                }
                alt="navbar-secondary-logo"
                tabIndex={0}
              />
            )}
          </Box>
          <Box className="z-50">
            <Menu>
              <MenuButton
                className={cx(
                  'p-1.5 rounded-full bg-opacity-10 transition hover:bg-opacity-20 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2',
                  isLightMode ? 'bg-black' : 'bg-white'
                )}
                aria-label="navbar-options"
              >
                <span className="flex-shrink-0">
                  <BiMenu className="text-xl" />
                </span>
              </MenuButton>
              <MenuList className="max-w-[90vw] pt-0">
                <MenuGroup className="mx-3" aria-label="page-menu-selections" title={t('groups.pages')}>
                  <AuthenticatedTemplate>
                    <MenuItem
                      tabIndex={0}
                      onClick={() => navigate(areTermsAccepted ? '/home' : '/')}
                      onKeyDown={(event: KeyboardEvent<HTMLButtonElement>) => {
                        if (event.key === 'Enter') {
                          navigate(areTermsAccepted ? '/home' : '/')
                        }
                      }}
                      icon={<BiHome className="text-lg" />}
                    >
                      {t('links.home')}
                    </MenuItem>
                  </AuthenticatedTemplate>
                  {areTermsAccepted && (
                    <AuthenticatedTemplate>
                      <MenuItem
                        tabIndex={0}
                        onClick={() => navigate('/k-bots/viewKBots')}
                        onKeyDown={(event: KeyboardEvent<HTMLButtonElement>) => {
                          if (event.key === 'Enter') {
                            navigate('/k-bots/viewKBots')
                          }
                        }}
                        icon={<BiCustomize className="text-lg" />}
                      >
                        K-Bots
                      </MenuItem>
                    </AuthenticatedTemplate>
                  )}
                  <MenuItem
                    tabIndex={0}
                    onKeyDown={(event: KeyboardEvent<HTMLButtonElement>) => {
                      if (event.key === 'Enter') {
                        window.open(libraryURL, '_blank')
                      }
                    }}
                    onClick={() => window.open(libraryURL, '_blank')}
                    icon={<BiBookOpen className="text-lg" />}
                  >
                    {t('links.library')}
                  </MenuItem>
                  <MenuItem
                    tabIndex={0}
                    onKeyDown={(event: KeyboardEvent<HTMLButtonElement>) => {
                      if (event.key === 'Enter') {
                        window.open(resourcesURL, '_blank')
                      }
                    }}
                    onClick={() => window.open(resourcesURL, '_blank')}
                    icon={<BiInfoCircle className="text-lg" />}
                  >
                    {t('links.help')}
                  </MenuItem>

                  <MenuItem
                    tabIndex={0}
                    onKeyDown={(event: KeyboardEvent<HTMLButtonElement>) => {
                      if (event.key === 'Enter') {
                        navigate('/about')
                      }
                    }}
                    onClick={() => navigate('/about')}
                    icon={<BiInfoCircle className="text-lg" />}
                  >
                    {t('links.about')}
                  </MenuItem>
                </MenuGroup>
                <MenuDivider />
                <MenuGroup className="mx-3" aria-label="utility-menu-selections" title={t('groups.utilities')}>
                  <AuthenticatedTemplate>
                    <MenuItem
                      tabIndex={0}
                      onClick={() => msalInstance.logout()}
                      onKeyDown={(event: KeyboardEvent<HTMLButtonElement>) => {
                        if (event.key === 'Enter') {
                          msalInstance.logout()
                        }
                      }}
                      icon={<BiLogOut className="text-lg" />}
                    >
                      {t('links.logout')}
                    </MenuItem>
                  </AuthenticatedTemplate>
                  <MenuItem
                    tabIndex={0}
                    onClick={() => changeLanguage(language === 'en-US' ? 'fr-CA' : 'en-US')}
                    onKeyDown={(event: KeyboardEvent<HTMLButtonElement>) => {
                      if (event.key === 'Enter') {
                        changeLanguage(language === 'en-US' ? 'fr-CA' : 'en-US')
                      }
                    }}
                    icon={<BiGlobe className="text-lg" />}
                  >
                    {languageNames[language === 'en-US' ? 'fr-CA' : 'en-US']}
                  </MenuItem>
                  <MenuItem
                    tabIndex={0}
                    icon={isLightMode ? <BiMoon className="text-lg" /> : <BiSun className="text-lg" />}
                    onClick={toggleColorMode}
                    onKeyDown={(event: KeyboardEvent<HTMLButtonElement>) => {
                      if (event.key === 'Enter') {
                        toggleColorMode()
                      }
                    }}
                  >
                    {t('links.theme')}
                  </MenuItem>
                  {areTermsAccepted && (
                    <UserProfile
                      isOpen={isUserProfileOpen}
                      onClose={onUserProfileClose}
                      onOpen={onUserProfileOpen}
                      type="menu"
                    />
                  )}
                </MenuGroup>
              </MenuList>
            </Menu>
          </Box>
        </>
      ) : (
        <>
          <Box className="flex items-center">
            <ImageWithCache
              alt="k-logo"
              className="h-10 mr-4 hover:cursor-pointer"
              onClick={() => navigate(areTermsAccepted ? '/home' : '/')}
              onKeyDown={(event: KeyboardEvent<HTMLImageElement>) => {
                if (event.key === 'Enter') {
                  navigate(areTermsAccepted ? '/home' : '/')
                }
              }}
              imagePath="../images/k-logo.png"
              tabIndex={0}
            />
            {currentBot && currentBot[0]?.navbarLogoLight && currentBot[0]?.navbarLogoDark && assetsPrefix && (
              // Max height of the image is set to 45px to match the KPMG logo
              <ImageWithCache
                className="mr-4 max-h-[45px]"
                imagePath={
                  isLightMode
                    ? `${assetsPrefix}/${currentBot[0].navbarLogoLight}.svg`
                    : `${assetsPrefix}/${currentBot[0].navbarLogoDark}.svg`
                }
                alt="navbar-secondary-logo"
                tabIndex={0}
              />
            )}
          </Box>
          <Box className="flex items-center">
            <AuthenticatedTemplate>
              <p
                tabIndex={0}
                onClick={() => navigate(areTermsAccepted ? '/home' : '/')}
                onKeyDown={(event: KeyboardEvent<HTMLParagraphElement>) => {
                  if (event.key === 'Enter') {
                    navigate(areTermsAccepted ? '/home' : '/')
                  }
                }}
                className="px-3 my-1 text-sm shadow-none lg:text-base hover:cursor-pointer hover:underline whitespace-nowrap"
              >
                {t('links.home')}
              </p>
            </AuthenticatedTemplate>
            {areTermsAccepted && (
              <AuthenticatedTemplate>
                <p
                  tabIndex={0}
                  onClick={() => navigate('/k-bots')}
                  onKeyDown={(event: KeyboardEvent<HTMLParagraphElement>) => {
                    if (event.key === 'Enter') {
                      navigate('/k-bots')
                    }
                  }}
                  className="px-3 my-1 text-sm shadow-none lg:text-base hover:cursor-pointer hover:underline whitespace-nowrap"
                >
                  K-Bots
                </p>
              </AuthenticatedTemplate>
            )}
            <p
              tabIndex={0}
              onClick={() => window.open(libraryURL, '_blank')}
              onKeyDown={(event: KeyboardEvent<HTMLParagraphElement>) => {
                if (event.key === 'Enter') {
                  window.open(libraryURL, '_blank')
                }
              }}
              className="px-3 my-1 text-sm shadow-none lg:text-base hover:cursor-pointer hover:underline whitespace-nowrap"
            >
              {t('links.library')}
            </p>
            <p
              tabIndex={0}
              onClick={() => window.open(resourcesURL, '_blank')}
              onKeyDown={(event: KeyboardEvent<HTMLParagraphElement>) => {
                if (event.key === 'Enter') {
                  window.open(resourcesURL, '_blank')
                }
              }}
              className="px-3 my-1 text-sm shadow-none lg:text-base hover:cursor-pointer hover:underline whitespace-nowrap"
            >
              {t('links.help')}
            </p>
            <p
              tabIndex={0}
              onClick={() => navigate('/about')}
              onKeyDown={(event: KeyboardEvent<HTMLParagraphElement>) => {
                if (event.key === 'Enter') {
                  navigate('/about')
                }
              }}
              // Added whitespace-nowrap below because "About" translates to "A propos de" and made the item start wrapping to the next line due to the spaces
              className="px-3 my-1 text-sm shadow-none lg:text-base hover:cursor-pointer hover:underline whitespace-nowrap"
            >
              {t('links.about')}
            </p>
            <Box className="pr-1 ml-2 border-l border-kpmgGray4">&nbsp;</Box>
            <AuthenticatedTemplate>
              <p
                tabIndex={0}
                onClick={() => msalInstance.logout()}
                onKeyDown={(event: KeyboardEvent<HTMLParagraphElement>) => {
                  if (event.key === 'Enter') {
                    msalInstance.logout()
                  }
                }}
                className="px-3 my-1 text-sm shadow-none lg:text-base hover:cursor-pointer hover:underline whitespace-nowrap"
              >
                {t('links.logout')}
              </p>
            </AuthenticatedTemplate>

            <p
              tabIndex={0}
              className="px-3 my-1 text-sm shadow-none lg:text-base hover:cursor-pointer hover:underline whitespace-nowrap"
              onClick={() => changeLanguage(language === 'en-US' ? 'fr-CA' : 'en-US')}
              onKeyDown={(event: KeyboardEvent<HTMLParagraphElement>) => {
                if (event.key === 'Enter') {
                  changeLanguage(language === 'en-US' ? 'fr-CA' : 'en-US')
                }
              }}
            >
              {languageNames[language === 'en-US' ? 'fr-CA' : 'en-US']}
            </p>
            <Box className="ml-4">
              <IconButton
                aria-label="application-color-theme"
                Icon={isLightMode ? BiMoon : BiSun}
                onClick={toggleColorMode}
              />
            </Box>
            {areTermsAccepted && (
              <Box className="ml-2">
                <UserProfile isOpen={isUserProfileOpen} onClose={onUserProfileClose} onOpen={onUserProfileOpen} />
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  )
}
