import { KeyboardEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Text } from '@chakra-ui/layout'
import { API } from '@kleo/types'

import { useThemeContext } from 'providers/ThemeProvider'

type TemperatureSelectionProps = {
  handleTemperatureSelect: (temperature: API.OpenAITemperature) => void
  selectedTemperatureOption: API.OpenAITemperature
  temperatureOptions?: API.OpenAITemperature[]
  isDisabled?: boolean
}

export const TemperatureSelection = ({
  handleTemperatureSelect,
  selectedTemperatureOption,
  temperatureOptions = ['0.0', '0.5', '1.0'],
  isDisabled,
}: TemperatureSelectionProps) => {
  const { t } = useTranslation('settings')
  const { isLightMode } = useThemeContext()

  return (
    <>
      <Box
        className={`flex flex-wrap gap-1.5 p-1.5 rounded-md w-full bg-gradient-to-r bg-[length:200%_200%] animate-[gradient-move_20s_ease_infinite]
        ${isLightMode ? 'from-kpmgPacificBlue via-kpmgLightBlue to-kpmgLightPurple' : 'from-kpmgBlue via-kpmgCobaltBlue to-kpmgPurple'}
      `}
      >
        {temperatureOptions &&
          temperatureOptions.map((temperature) => {
            return (
              <Box
                key={temperature}
                tabIndex={0}
                className={`flex-1 ${isDisabled ? 'hover:cursor-not-allowed' : temperature !== selectedTemperatureOption && 'hover:cursor-pointer'} ${
                  temperature === selectedTemperatureOption
                    ? isLightMode
                      ? 'bg-white bg-opacity-80 text-black'
                      : 'bg-kpmgDarkBlue bg-opacity-85 text-white'
                    : `bg-black text-white ${isLightMode ? 'bg-opacity-10' : 'bg-opacity-20'}`
                } px-3 py-[7px] rounded transition-colors duration-200`}
                onClick={() =>
                  temperature !== selectedTemperatureOption && !isDisabled && handleTemperatureSelect(temperature)
                }
                onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
                  if (event.key === 'Enter' && temperature !== selectedTemperatureOption && !isDisabled) {
                    handleTemperatureSelect(temperature)
                  }
                }}
              >
                <Text className="text-center">{t(`settings.temperatures.${temperature}`)}</Text>
              </Box>
            )
          })}
      </Box>
      <Text className={`mt-2 text-xs text-gray-${isLightMode ? 500 : 400}`}>
        {selectedTemperatureOption && t(`settings.details.${selectedTemperatureOption}`)}
      </Text>
    </>
  )
}
