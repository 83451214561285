import { SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Text } from '@chakra-ui/layout'
import { KBotBaseConfig } from '@kleo/types'

import { KBotCard } from './kbots/KBotCard'
import { TextButton } from './Button'

type LoadMoreGridProps = {
  gridItems: KBotBaseConfig[]
  numColumns: number
  numRows: number
  setCurrentViewingBot: (value: SetStateAction<KBotBaseConfig | null>) => void
}

export const LoadMoreGrid = ({ gridItems, setCurrentViewingBot, numColumns = 3, numRows = 1 }: LoadMoreGridProps) => {
  const { t } = useTranslation('generic')

  const [numberOfSections, setNumberOfSections] = useState(1)

  const handleLoadMoreClick = () => {
    // If we want to show another "section" of grid elements
    if (gridItems.length > numColumns * numRows * numberOfSections) {
      setNumberOfSections(numberOfSections + 1)
    }
  }

  return (
    <Box className="flex flex-col flex-grow text-center">
      <Box className={`gap-2 md:gap-3 grid p-2 md:p-3 w-full grid-cols-${numColumns} auto-rows-min grid-flow-row`}>
        {gridItems.map((gridItem: KBotBaseConfig, gridItemIndex: number) => {
          if (gridItemIndex < numColumns * numRows * numberOfSections) {
            return (
              <Box
                className="flex flex-grow col-span-1 basis-full"
                key={`${gridItem.templateId}-${gridItem.name}-${gridItemIndex}`}
              >
                <KBotCard
                  onClick={() => setCurrentViewingBot(gridItem)}
                  className={'col-span-1 h-full'}
                  kBot={gridItem}
                />
              </Box>
            )
          }
          return null
        })}
      </Box>
      <Box className="mb-2">
        <Text className="text-xs text-center md:text-sm">
          {t('generic.showing', {
            current:
              gridItems.length > numColumns * numRows * numberOfSections
                ? numColumns * numRows * numberOfSections
                : gridItems.length,
            total: gridItems.length,
          })}
        </Text>
      </Box>
      {numColumns * numRows * numberOfSections < gridItems.length && (
        <Box className="mb-2 text-center">
          <TextButton size="sm" onClick={handleLoadMoreClick} text={t('generic.loadMore')} />
        </Box>
      )}
    </Box>
  )
}
