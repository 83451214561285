import { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDisclosure } from '@chakra-ui/hooks'
import { Translate } from '@kleo/types'
import { saveAs } from 'file-saver'
import { TFunction } from 'i18next'

import { AzureFileUploadError, TranslationError } from 'utils/appError'
import { formatMaxFileSize } from 'utils/formatter'
import { generateRequestId } from 'utils/generateRequestId'
import { getFileName } from 'utils/http/file'
import { fetchData, fetchDataRaw, putFileIntoStorage } from 'utils/http/methods'
import { normalizeTranslationData } from 'utils/normalizeTableData'

import type { FEUIConfig, SortingOrder, TableColumn, TableRow, TierEntity } from 'types/types'

import { useAuthContext } from './AuthProvider'
import { useConfigContext } from './ConfigurationProvider'
import { useI18Context } from './i18Provider'

export type UploadFile = {
  type: string
  name: string
  bytes: number
  dateTime: number
}

export type TranslationUploadState = 'terms' | 'uploader'

export type UploadFilesByLanguage = Record<Translate.TargetLanguage, UploadFile[]>
export type TranslationReceipt = UploadFilesByLanguage & { id: string; folder: string; language: string }

export type selectedTranslationFolderFilesStatus = {
  completed: string
  failed: string
  inProgress: string
  total: string
}

type TranslationContextType = {
  checkedItems: string[]
  columnConfig: {
    level1: {
      hasCheckbox: boolean
      columns: TableColumn[]
    }
    level2: {
      hasCheckbox: boolean
      columns: TableColumn[]
    }
  }
  normalizedTableRows: TableRow[]
  currentFolderBeingExplored: Translate.FolderData | null
  deleteTranslations: (files: string[]) => Promise<void>
  deleteTranslationsError: boolean
  downloadTranslations: (files: string[]) => Promise<void>
  fetchFolderError: boolean
  fetchFolders: (userRefresh?: boolean, preUpload?: boolean) => Promise<number | undefined>
  folderData: Translate.FolderData[] | null
  handleCheckboxClick: (id: string) => void
  handleDeleteClick: () => Promise<void>
  handleDownloadClick: () => Promise<void>
  handleRefreshClick: () => Promise<void>
  handleSelectAllCheckboxClick: () => void
  isCheckingFileLimit: boolean
  isDeleteModalOpen: boolean
  isDeletingTranslations: boolean
  isDownloadingTranslations: boolean
  isFetchingFolders: boolean
  isFileLevel: boolean
  isFileUploading: boolean
  isFolderLevel: boolean
  isOpenReceipt: boolean
  isRefreshing: boolean
  isTranslationCompleted: boolean
  isWarningAcknowledged1: boolean
  isWarningAcknowledged2: boolean
  maxFileSize: string
  onCloseReceipt: () => void
  onDeleteClose: () => void
  onDeleteModalOpen: () => void
  onOpenReceipt: () => void
  refreshError: boolean
  refreshProgress: number
  clearTranslationReceipts: () => void
  selectedTranslationFolder: Translate.FolderData | null
  selectedTranslationFolderFilesStatuses: selectedTranslationFolderFilesStatus | null
  setCheckedItems: Dispatch<SetStateAction<string[]>>
  setDeleteTranslationsError: Dispatch<SetStateAction<boolean>>
  setFetchFolderError: Dispatch<SetStateAction<boolean>>
  setIsDeletingTranslations: Dispatch<SetStateAction<boolean>>
  setIsDownloadingTranslations: Dispatch<SetStateAction<boolean>>
  setIsFetchingFolders: Dispatch<SetStateAction<boolean>>
  setIsFileUploading: Dispatch<SetStateAction<boolean>>
  setIsRefreshing: Dispatch<SetStateAction<boolean>>
  setIsTranslationCompleted: Dispatch<SetStateAction<boolean>>
  setIsWarningAcknowledged1: Dispatch<SetStateAction<boolean>>
  setIsWarningAcknowledged2: Dispatch<SetStateAction<boolean>>
  setRefreshError: Dispatch<SetStateAction<boolean>>
  setRefreshProgress: Dispatch<SetStateAction<number>>
  setShowFetchFolderErrorMessage: Dispatch<SetStateAction<boolean>>
  setShowFolderSkeleton: Dispatch<SetStateAction<boolean>>
  setSortingOrder: Dispatch<SetStateAction<SortingOrder[]>>
  setTier1: Dispatch<SetStateAction<string | null>>
  setTranslationError: Dispatch<SetStateAction<string[] | null>>
  setTranslationUploadState: Dispatch<SetStateAction<TranslationUploadState>>
  setTranslationReceipts: Dispatch<SetStateAction<TranslationReceipt[]>>
  showFetchFolderErrorMessage: boolean
  showFolderSkeleton: boolean
  sortingOrder: SortingOrder[]
  tier1: string | null
  totalNonFailedFiles: number
  translationError: string[] | null
  translationProgress: null | number
  translationUploadState: TranslationUploadState
  addTranslationReceipt: (newFiles: UploadFile[], lang: Translate.TargetLanguage, id: string, folder: string) => void
  removeTranslationReceipt: (id: string) => void
  translationReceipts: TranslationReceipt[]
  uploadFileMaxCount: number
  uploadFilesRequest: (folderName: string, targetLanguage: Translate.TargetLanguage, files: File[]) => void
}

export const TranslationContext = createContext<TranslationContextType>({} as TranslationContextType)

type TranslationProviderProps = {
  children?: React.ReactNode
  config: FEUIConfig[] | undefined
}

export const TranslationProvider = ({ children, config }: TranslationProviderProps) => {
  const { getToken } = useAuthContext()
  const { API_ENDPOINT } = useConfigContext()
  const { language } = useI18Context()
  const { t } = useTranslation('translation')
  const { isOpen: isOpenReceipt, onOpen: onOpenReceipt, onClose: onCloseReceipt } = useDisclosure()

  const [translationUploadState, setTranslationUploadState] = useState<TranslationUploadState>('terms')

  const [isDeletingTranslations, setIsDeletingTranslations] = useState<boolean>(false)

  const [translationProgress] = useState<null | number>(null)
  const [isWarningAcknowledged1, setIsWarningAcknowledged1] = useState<boolean>(false)
  const [isWarningAcknowledged2, setIsWarningAcknowledged2] = useState<boolean>(false)
  const [isFileUploading, setIsFileUploading] = useState(false)

  const [translationReceipts, setTranslationReceipts] = useState<TranslationReceipt[]>([])

  const [tier1, setTier1] = useState<string | null>(null)
  const [checkedItems, setCheckedItems] = useState<Array<string>>([]) // list of IDs that have been checked
  const [folderData, setFolderData] = useState<Translate.FolderData[] | null>(null)

  const [translationError, setTranslationError] = useState<string[] | null>(null)
  const [isTranslationCompleted, setIsTranslationCompleted] = useState(false)
  const [sortingOrder, setSortingOrder] = useState<SortingOrder[]>([])

  const [refreshProgress, setRefreshProgress] = useState(100)

  const { isOpen: isDeleteModalOpen, onOpen: onDeleteModalOpen, onClose: onDeleteClose } = useDisclosure()

  // reset to folder level on unmount
  useEffect(() => {
    return () => {
      setTier1(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const normalizedTableRows: TableRow[] = useMemo(() => {
    if (folderData) {
      return normalizeTranslationData(folderData, language, t)
    }
    return []
  }, [folderData, language, t])

  const isFolderLevel = useMemo(() => {
    return !tier1
  }, [tier1])

  const isFileLevel = useMemo(() => {
    return !!tier1
  }, [tier1])

  const clearTranslationReceipts = useCallback((): void => {
    setTranslationReceipts([])
  }, [])

  const { uploadFileMaxCount, uploadFileMaxSize } = useMemo(() => {
    const translationBotConfig = config?.find((botConfig) => botConfig.type === 'translation')

    if (!translationBotConfig) {
      return {
        uploadFileMaxCount: 0,
        uploadFileMaxSize: 0,
      }
    }

    return {
      uploadFileMaxCount: translationBotConfig.uploadFileMaxCount ?? 0,
      uploadFileMaxSize: translationBotConfig.uploadFileMaxSize ?? 0,
    }
  }, [config])

  const numberFormat = useMemo(() => {
    return new Intl.NumberFormat(language)
  }, [language])

  const maxFileSize = useMemo(() => {
    return formatMaxFileSize(uploadFileMaxSize, numberFormat, t as TFunction)
  }, [uploadFileMaxSize, numberFormat, t])

  // loading states
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false)
  const [isDownloadingTranslations, setIsDownloadingTranslations] = useState(false)
  const [showFolderSkeleton, setShowFolderSkeleton] = useState(false)
  const [isFetchingFolders, setIsFetchingFolders] = useState(false)
  const [isCheckingFileLimit, setIsCheckingFileLimit] = useState(false)

  // error states
  const [deleteTranslationsError, setDeleteTranslationsError] = useState(false)
  const [refreshError, setRefreshError] = useState(false)
  const [fetchFolderError, setFetchFolderError] = useState(false)
  const [showFetchFolderErrorMessage, setShowFetchFolderErrorMessage] = useState(false)

  useEffect(() => {
    setShowFetchFolderErrorMessage(fetchFolderError)
  }, [fetchFolderError])

  const totalNonFailedFiles = useMemo(() => {
    if (folderData === null) return 0
    return folderData.reduce((total, folder) => {
      const nonFailedFiles = folder.files.filter((file) => file.status !== Translate.TranslationJobStatus.Failed).length

      return total + nonFailedFiles
    }, 0)
  }, [folderData])

  const uploadFilesRequest = async (folderName: string, targetLanguage: Translate.TargetLanguage, files: File[]) => {
    try {
      await initializeUploadAndTranslate(folderName, targetLanguage, files)
    } catch (err: unknown) {
      if (
        err instanceof TranslationError &&
        err.errorStreamMessage &&
        Array.isArray(err.errorStreamMessage) &&
        err.errorStreamMessage.length > 0 &&
        err.errorStreamMessage[0].code
      ) {
        const errorCodes = err.errorStreamMessage.map((item) => item.code)
        setTranslationError(errorCodes)
      } else {
        setTranslationError(['generic'])
      }
    } finally {
      setIsFileUploading(false)
    }
  }

  const initializeUploadAndTranslate = async (
    folderName: string,
    targetLanguage: Translate.TargetLanguage,
    files: File[]
  ) => {
    const token = await getToken()
    const filesPayload = files.map((item) => {
      return {
        contentType: item.type,
        fileSize: item.size,
        fileName: item.name,
      }
    })

    const urlUploadResponse = await fetchData<Translate.GetTranslateUrlUploadRes>({
      url: `${API_ENDPOINT}/translateapi/urlUpload`,
      token,
      payload: {
        language: 'EN',
        files: filesPayload,
        folderName,
      },
      requestId: generateRequestId(),
    })

    if (urlUploadResponse && 'requestId' in urlUploadResponse && 'items' in urlUploadResponse) {
      try {
        const statusArray = []
        for (let i = 0; i < urlUploadResponse.items.length; i++) {
          const { status, statusText } = await putFileIntoStorageContainer({
            url: urlUploadResponse.items[i].url,
            file: files[i],
          })
          statusArray.push({ status, statusText })
        }

        if (statusArray.every((element) => element.status === 201 && element.statusText === 'Created')) {
          try {
            setIsTranslationCompleted(false)
            const translateResponse = await fetchData<Translate.TranslateAPIResponse>({
              url: `${API_ENDPOINT}/translateapi/translate`,
              token,
              payload: {
                language: 'EN',
                folderId: urlUploadResponse.requestId,
                targetLanguage,
              },
              requestId: generateRequestId(),
            })

            if (translateResponse && 'requestId' in translateResponse) {
              handledUploadedFiles(targetLanguage, files, translateResponse.requestId, urlUploadResponse.requestId)
              fetchFolders(true)
            } else if (translateResponse && 'errorList' in translateResponse) {
              throw new TranslationError('translateApi-translate-request', translateResponse.errorList)
            }
          } finally {
            setIsTranslationCompleted(true)
          }
        }
      } catch (e) {
        if (e instanceof AzureFileUploadError) {
          throw new TranslationError('translationApi-urlUpload-request')
        }
        throw e
      }
    } else if (urlUploadResponse && 'errorList' in urlUploadResponse) {
      throw new TranslationError('translationApi-urlUpload-request', urlUploadResponse.errorList)
    }
  }

  const putFileIntoStorageContainer = async ({ url, file }: { url: string; file: File }) => {
    try {
      const { status, statusText } = await putFileIntoStorage({
        url,
        file,
        headers: {
          'Content-Type': file.type,
          'x-ms-blob-type': 'BlockBlob',
        },
        feRequestId: generateRequestId(),
      })

      return { status, statusText }
    } catch (e) {
      throw new Error('upload-request-error-documentUpload', e as Error)
    }
  }

  const addTranslationReceipt = useCallback(
    (newFiles: UploadFile[], lang: Translate.TargetLanguage, id: string, folder: string): void => {
      const baseUploadedFilesObj = {
        [Translate.TargetLanguage.en]: [],
        [Translate.TargetLanguage.fr]: [],
        id,
        folder,
        language: lang,
      }
      setTranslationReceipts((prevItems) => [{ ...baseUploadedFilesObj, [lang]: newFiles }, ...prevItems])
    },
    []
  )

  const removeTranslationReceipt = useCallback((id: string): void => {
    setTranslationReceipts((prevItems) => {
      return [...prevItems].filter((receipt) => receipt.id !== id)
    })
  }, [])

  const handledUploadedFiles = useCallback(
    (targetLanguage: Translate.TargetLanguage, files: File[], id: string, folder: string) => {
      const filesToProcess: UploadFile[] = files.map((item) => {
        // handle edge case where filenames without extension or with multiple dots
        const parts = item.name.split('.')
        const fileType = parts.length > 1 && parts[parts.length - 1] ? parts.pop() : ''
        const currEpochTime = Math.floor(Date.now() / 1000)

        return {
          name: item.name,
          bytes: item.size || 0,
          status: 'uploading',
          type: fileType as string,
          dateTime: currEpochTime,
        }
      })
      addTranslationReceipt(filesToProcess, targetLanguage, id, folder)
    },
    [addTranslationReceipt]
  )

  const fetchFolders = useCallback(
    async (isRefresh = false, preUpload = false) => {
      try {
        if (preUpload) {
          setIsCheckingFileLimit(true)
        }
        if (isRefresh) {
          setIsRefreshing(true)
        } else {
          setIsFetchingFolders(true)
        }
        setRefreshError(false)
        setFetchFolderError(false)

        if (!folderData) {
          setShowFolderSkeleton(true)
        }
        const token = await getToken()
        const { data } = await fetchData<Translate.GetFolderViewAPIResponse>({
          url: `${API_ENDPOINT}/translateapi/getFolderView`,
          token,
          payload: {
            language: 'EN',
          },
          requestId: generateRequestId(),
        })

        // const { data } = await timeOutAction(fetcher, 2000, () => {
        //   throw new Error('fetch folder took longer than 1 second')
        // })

        setFolderData(data)
        return data.reduce((total, folder) => total + folder.files.length, 0)
      } catch (e) {
        setFetchFolderError(true)
        if (isRefresh) {
          setRefreshError(true)
        }
      } finally {
        setIsCheckingFileLimit(false)
        setIsRefreshing(false)
        setIsFetchingFolders(false)
        setShowFolderSkeleton(false)
      }
    },
    [API_ENDPOINT, folderData, getToken, setFetchFolderError]
  )

  const deleteTranslations = useCallback(
    async (files: string[]) => {
      try {
        setDeleteTranslationsError(false)
        const token = await getToken()
        const { status } = await fetchData<Translate.DeleteAPIResponse>({
          url: `${API_ENDPOINT}/translateapi/delete`,
          token,
          payload: {
            language: 'EN',
            files,
          },
          requestId: generateRequestId(),
        })

        if (status === 'ok') {
          await fetchFolders(true)
        }
      } catch (e) {
        setDeleteTranslationsError(true)
      }
    },
    [API_ENDPOINT, fetchFolders, getToken]
  )

  const downloadTranslations = useCallback(
    async (files: string[]) => {
      try {
        setIsDownloadingTranslations(true)
        const token = await getToken()
        const response = await fetchDataRaw<Response>({
          url: `${API_ENDPOINT}/translateapi/exportTranslationData`,
          token,
          payload: {
            language: 'EN',
            files,
          },
          requestId: generateRequestId(),
        })

        if (response) {
          try {
            const blob = await response.blob()
            saveAs(blob, getFileName(response.headers))
          } catch (e) {
            console.error(e)
          }
        }
      } catch (e) {
        console.error(e)
      } finally {
        setIsDownloadingTranslations(false)
      }
    },
    [API_ENDPOINT, getToken]
  )

  // This tracks the value of what's currently shown in the table
  const currentRows: TierEntity[] = useMemo(() => {
    if (folderData) {
      const timeSortedFolders = folderData.sort((folderA, folderB) => folderB.createdAt - folderA.createdAt)
      if (!tier1) {
        const entities = timeSortedFolders.map((folder, index) => {
          return {
            date: folder.createdAt.toString(),
            files: folder.files,
            id: folder.folderId,
            name: folder.folderId,
          }
        })
        return entities
      } else if (tier1) {
        const fileSubData = timeSortedFolders.find((folder) => folder.folderId === tier1)
        if (fileSubData) {
          const entities = fileSubData.files.map((file) => {
            return {
              date: fileSubData.createdAt.toString(),
              id: file.id,
              name: file.file,
              status: file.status,
            }
          })
          return entities
        }
      }
    }

    return []
  }, [folderData, tier1])

  const handleSelectAllCheckboxClick = useCallback(() => {
    if (isFileLevel) {
      // if all checkboxes are selected already, only count the succeeded files
      const nonFailedEntities = currentRows.filter((ent) => {
        if ('status' in ent) {
          return ent.status === Translate.TranslationJobStatus.Succeeded
        }

        return ent
      })

      if (nonFailedEntities.length === checkedItems.length) {
        setCheckedItems([])
      } else {
        const ids = currentRows
          .map((current) => {
            // if status is in the row, it means its files level
            if ('status' in current) {
              return current.status === Translate.TranslationJobStatus.Succeeded ? current.id : null
            }
            return current.id
          })
          .filter((id): id is string => id !== null)

        setCheckedItems(ids)
      }
    }
  }, [checkedItems.length, isFileLevel, currentRows])

  const handleCheckboxClick = useCallback(
    (id: string) => {
      setCheckedItems((currentCheckedItems) => {
        // at folder level, only allow one checked checkbox at any time
        if (isFolderLevel) {
          if (currentCheckedItems.includes(id)) {
            return []
          }
          return [id]
        }
        if (currentCheckedItems.includes(id)) {
          return currentCheckedItems.filter((currentCheckedItem) => currentCheckedItem !== id)
        }

        const selectedRow = currentRows.find((row) => row.id === id)
        if (selectedRow && 'status' in selectedRow && selectedRow['status']) {
          if (selectedRow.status !== Translate.TranslationJobStatus.Succeeded) {
            return currentCheckedItems
          }
        }

        return [...currentCheckedItems, id]
      })
    },
    [currentRows, isFolderLevel]
  )

  const columnConfig: {
    level1: {
      hasCheckbox: boolean
      columns: TableColumn[]
    }
    level2: {
      hasCheckbox: boolean
      columns: TableColumn[]
    }
  } = useMemo(() => {
    const level1: TableColumn[] = [
      {
        key: 'name',
        label: t('translation.view.name'),
        sortable: true,
      },
      {
        key: 'createdAt',
        label: t('translation.view.createdAt'),
        sortable: true,
      },
    ]
    const level2: TableColumn[] = [
      {
        key: 'name',
        label: t('translation.view.name'),
        sortable: true,
      },
      {
        key: 'status',
        label: t('translation.view.translationStatus'),
        sortable: true,
      },
    ]

    return { level1: { hasCheckbox: true, columns: level1 }, level2: { hasCheckbox: true, columns: level2 } }
  }, [t])

  const selectedTranslationFolder: Translate.FolderData | null = useMemo(() => {
    const checkedItemsSet = new Set(checkedItems)
    if (folderData) {
      if (isFolderLevel) {
        const checkedItem: string | undefined = checkedItemsSet.values().next().value
        if (checkedItem) {
          const selectedRow = currentRows.find((row) => row.id === checkedItem)
          if (selectedRow) {
            const folder = folderData.find((folder) => folder.folderId === selectedRow.id)
            if (folder) {
              return {
                ...folder,
                files: folder.files,
              }
            }
          }
        }
        return null
      } else if (isFileLevel) {
        const folder = folderData.find((folder) => folder.folderId === tier1)

        if (folder) {
          const filteredFiles = currentRows.reduce<Translate.FileStatus[]>((acc, row) => {
            if (checkedItems.length) {
              const fileId = row.id
              if (checkedItemsSet.has(fileId)) {
                const fileToAdd = folder.files.find((file) => file.id === fileId)
                fileToAdd && acc.push(fileToAdd)
              }
              return acc
            } else {
              return folder.files
            }
          }, [])

          return {
            ...folder,
            files: filteredFiles,
          }
        }
        return null
      }
    }
    return null
  }, [checkedItems, currentRows, folderData, isFileLevel, isFolderLevel, tier1])

  const currentFolderBeingExplored = useMemo(() => {
    if (!folderData || !tier1) return null

    const folder = folderData.find((folder) => folder.folderId === tier1)

    if (!folder) return null

    return folder
  }, [folderData, tier1])

  const handleDownloadClick = useCallback(async () => {
    if (selectedTranslationFolder) {
      const folderPaths = selectedTranslationFolder.files.reduce<string[]>((acc, file) => {
        if (file.status === Translate.TranslationJobStatus.Succeeded) {
          return [...acc, `${selectedTranslationFolder.folderId}/${file.file}`]
        }

        return acc
      }, [])

      await downloadTranslations(folderPaths)
    }
  }, [downloadTranslations, selectedTranslationFolder])

  const handleRefreshClick = useCallback(async () => {
    await fetchFolders(true)
  }, [fetchFolders])

  const handleDeleteClick = useCallback(async () => {
    setIsDeletingTranslations(true)
    if (selectedTranslationFolder) {
      let filesToDelete = []
      const allFilesFailed = selectedTranslationFolder.files.every(
        (file) => file.status === Translate.TranslationJobStatus.Failed
      )
      if (isFolderLevel || allFilesFailed) {
        filesToDelete = [selectedTranslationFolder.folderId]
      } else {
        filesToDelete = selectedTranslationFolder.files.reduce<string[]>((acc, file) => {
          return [...acc, `${selectedTranslationFolder.folderId}/${file.file}`]
        }, [])
      }
      await deleteTranslations(filesToDelete)
      setCheckedItems([])
      onDeleteClose()
    }
    setIsDeletingTranslations(false)
  }, [deleteTranslations, isFolderLevel, onDeleteClose, selectedTranslationFolder])

  // get stats from folder status's summary
  const selectedTranslationFolderFilesStatuses = useMemo(() => {
    if (!folderData || !folderData.length) {
      return null
    }
    const currentFolder = folderData.find((folder) => folder.folderId === tier1)

    if (currentFolder) {
      const { completed, failed, inProgress } = currentFolder.files.reduce(
        (acc, file) => {
          if (file.status === Translate.TranslationJobStatus.Succeeded) {
            acc.completed++
          } else if (
            [
              Translate.TranslationJobStatus.Failed,
              Translate.TranslationJobStatus.Canceled,
              Translate.TranslationJobStatus.ValidationFailed,
            ].includes(file.status)
          ) {
            acc.failed++
          } else if (
            [
              Translate.TranslationJobStatus.Cancelling,
              Translate.TranslationJobStatus.NotStarted,
              Translate.TranslationJobStatus.Running,
            ].includes(file.status)
          ) {
            acc.inProgress++
          }
          return acc
        },
        { completed: 0, failed: 0, inProgress: 0 }
      )

      return {
        completed: completed.toString(),
        failed: failed.toString(),
        inProgress: inProgress.toString(),
        total: currentFolder.files.length.toString(),
      }
    }

    return null
  }, [folderData, tier1])

  return (
    <>
      <TranslationContext.Provider
        value={{
          addTranslationReceipt,
          checkedItems,
          columnConfig,
          normalizedTableRows,
          currentFolderBeingExplored,
          deleteTranslations,
          deleteTranslationsError,
          downloadTranslations,
          fetchFolderError,
          fetchFolders,
          folderData,
          handleCheckboxClick,
          handleDeleteClick,
          handleDownloadClick,
          handleRefreshClick,
          handleSelectAllCheckboxClick,
          isCheckingFileLimit,
          isDeleteModalOpen,
          isDeletingTranslations,
          isDownloadingTranslations,
          isFetchingFolders,
          isFileLevel,
          isFileUploading,
          isFolderLevel,
          isOpenReceipt,
          isRefreshing,
          isTranslationCompleted,
          isWarningAcknowledged1,
          isWarningAcknowledged2,
          maxFileSize,
          onCloseReceipt,
          onDeleteClose,
          onDeleteModalOpen,
          onOpenReceipt,
          refreshError,
          refreshProgress,
          removeTranslationReceipt,
          clearTranslationReceipts,
          selectedTranslationFolder,
          selectedTranslationFolderFilesStatuses,
          setCheckedItems,
          setDeleteTranslationsError,
          setFetchFolderError,
          setIsDeletingTranslations,
          setIsDownloadingTranslations,
          setIsFetchingFolders,
          setIsFileUploading,
          setIsRefreshing,
          setIsTranslationCompleted,
          setIsWarningAcknowledged1,
          setIsWarningAcknowledged2,
          setRefreshError,
          setRefreshProgress,
          setShowFetchFolderErrorMessage,
          setShowFolderSkeleton,
          setSortingOrder,
          setTier1,
          setTranslationError,
          setTranslationUploadState,
          setTranslationReceipts,
          showFetchFolderErrorMessage,
          showFolderSkeleton,
          sortingOrder,
          tier1,
          totalNonFailedFiles,
          translationError,
          translationProgress,
          translationUploadState,
          translationReceipts,
          uploadFileMaxCount,
          uploadFilesRequest,
        }}
      >
        {children}
      </TranslationContext.Provider>
    </>
  )
}

export const useTranslationContext = (): TranslationContextType => useContext(TranslationContext)
