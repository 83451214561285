import { KeyboardEvent, useCallback } from 'react'
import { Navigate, Route, Routes as RouterRoutes, useLocation } from 'react-router-dom'
import { Box, Text } from '@chakra-ui/layout'
import kleoColors from 'styles/colors'

import { useThemeContext } from 'providers/ThemeProvider'

import { TabsType } from 'types/types'

export const Tab = ({
  handleTabClick,
  parentRouteSection,
  tabSection,
  className,
  selectedTabColor = 'bg-blue-400',
}: {
  handleTabClick: (route: string) => void
  parentRouteSection: string
  tabSection: TabsType
  className?: string
  selectedTabColor?: string
}) => {
  const location = useLocation()
  const { isLightMode } = useThemeContext()
  const isSelected = location.pathname === `/${parentRouteSection}${tabSection.route}`

  const handleTabSelection = useCallback(() => {
    // do nothing if the tab is already selected
    if (!isSelected) {
      handleTabClick(tabSection.route)
    }
  }, [handleTabClick, isSelected, tabSection.route])

  return (
    <Box
      onClick={handleTabSelection}
      onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
          handleTabSelection()
        }
      }}
      tabIndex={0}
      className={`relative flex items-center justify-center text-sm lg:text-base cursor-pointer mr-2 min-w-24 md:min-w-36 lg:min-w-48 px-3 py-3 h-full rounded-lg ${
        isLightMode
          ? 'bg-white bg-opacity-80 text-black hover:bg-white/[1.0]'
          : `bg-kpmgGray4 bg-opacity-25 hover:bg-[${kleoColors.kpmgLightBlue3}]/[.5]`
      } font-black ${className}`}
    >
      {tabSection.icon && <Box className="flex items-center mr-1 text-2xl">{tabSection.icon}</Box>}
      <Text>{tabSection.label}</Text>
      {isSelected && (
        <Box className="absolute bottom-0 left-0 right-0 px-3 mb-1 md:px-5">
          <Box className={`w-full h-0.5 rounded-b-lg ${selectedTabColor}`} />
        </Box>
      )}
    </Box>
  )
}

export const TabPanels = ({ tabSections, defaultTab }: { defaultTab: string; tabSections: TabsType[] }) => {
  return (
    <Box className="flex-grow h-full mt-2 overflow-hidden">
      <RouterRoutes>
        {defaultTab && <Route path="/" element={<Navigate to={defaultTab} />} />}
        {tabSections.map((tabSection) => {
          return (
            <Route
              key={tabSection.route}
              path={tabSection.route}
              element={<Box className="flex flex-col h-full">{tabSection.component}</Box>}
            />
          )
        })}
      </RouterRoutes>
    </Box>
  )
}
