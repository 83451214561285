export enum ClientType {
  PAYGO = 'PAYGO',
  ASBOT = 'ASBOT',
  PAYGO_US = 'PAYGO_US',
  AUDIO = 'AUDIO',
  'O3-MINI' = 'O3-MINI',
}
export enum Models {
  'O3-MINI' = 'O3-MINI',
  WHISPER = 'WHISPER',
  'GPT-4O-MINI' = 'GPT-4O-MINI',
  'GPT-4O-MINI-PAYGO' = 'GPT-4O-MINI-PAYGO',
  'GPT-4O' = 'GPT-4O',
}
