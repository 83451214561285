import { useTranslation } from 'react-i18next'
import { Text } from '@chakra-ui/layout'
import cx from 'classnames'

import { useThemeContext } from 'providers/ThemeProvider'

type RequiredAsteriskTextProps = {
  asteriskPosition?: 'left' | 'right'
  showText?: boolean
} & ({ text: string; italic: boolean } | { text?: undefined; italic?: never }) // If `text` is defined, `italic` becomes required

export const RequiredAsteriskText: React.FC<RequiredAsteriskTextProps> = ({
  asteriskPosition = 'left',
  italic,
  showText = true,
  text,
}) => {
  const { t } = useTranslation('generic')
  const { isLightMode } = useThemeContext()

  const textContent = text ?? t('generic.required')

  return (
    <Text as="span" className={cx(isLightMode ? 'text-gray-600' : 'text-slate-400')}>
      {asteriskPosition === 'left' && (
        <>
          <Text as="span" className="text-red-600">
            *
          </Text>{' '}
        </>
      )}
      {showText && (
        <Text as="span" className={cx({ italic })}>
          {textContent}
        </Text>
      )}
      {asteriskPosition === 'right' && (
        <>
          {' '}
          <Text as="span" className="text-red-600">
            *
          </Text>
        </>
      )}
    </Text>
  )
}
