import { createContext, ReactNode, useCallback, useContext, useRef, useState } from 'react'

type ImageContextProps = {
  getImageBase64: (imagePath: string) => ImageState | undefined
  fetchImageBase64: (imagePath: string) => Promise<void>
}

export type ImageState =
  | { status: 'LOADING'; base64: undefined } // For loading state, base64 is undefined
  | { status: 'LOADED'; base64: string } // For loaded state, base64 is a string
  | { status: 'NOT_FOUND'; base64: null } // For not found state, base64 is null

const ImageContext = createContext<ImageContextProps>({} as ImageContextProps)

const normalizeImagePath = (imagePath: string): string => {
  if (imagePath.startsWith('/')) {
    return new URL(imagePath, window.location.origin).href
  }

  try {
    // Resolve relative paths and normalize URL
    return new URL(imagePath, window.location.href).href
  } catch {
    // Fallback for invalid paths
    return imagePath
  }
}

export const ImageProvider = ({ children }: { children: ReactNode }) => {
  // only reserved for small size images, large size not recommended
  const [imageCache, setImageCache] = useState<Record<string, ImageState>>({})

  // Map for handling concurrent requests
  const fetchingRequests = useRef<Map<string, boolean>>(new Map())

  const updateImageCache = useCallback((imagePath: string, imageState: ImageState): void => {
    const normalizedPath = normalizeImagePath(imagePath)
    setImageCache((prevCache) => ({
      ...prevCache,
      [normalizedPath]: imageState,
    }))
  }, [])

  const fetchImageBase64 = useCallback(
    async (imagePath: string) => {
      const normalizedPath = normalizeImagePath(imagePath)

      // Skip fetching since it is fetching in progress
      if (fetchingRequests.current.has(normalizedPath)) {
        return
      }

      // Mark the image as being fetched
      fetchingRequests.current.set(normalizedPath, true)

      // Initialize loading state
      updateImageCache(normalizedPath, { status: 'LOADING', base64: undefined })

      try {
        const response = await fetch(normalizedPath)

        if (!response.ok) {
          throw new Error('failed to fetch image')
        }

        const blob = await response.blob()
        const reader = new FileReader()

        reader.onloadend = () => {
          updateImageCache(normalizedPath, { status: 'LOADED', base64: reader.result as string })
        }

        reader.readAsDataURL(blob)
      } catch (error) {
        updateImageCache(normalizedPath, { status: 'NOT_FOUND', base64: null })
      } finally {
        fetchingRequests.current.delete(normalizedPath)
      }
    },
    [updateImageCache]
  )

  const getImageBase64 = useCallback(
    (imagePath: string) => {
      const normalizedPath = normalizeImagePath(imagePath)
      return imageCache[normalizedPath]
    },
    [imageCache]
  )

  return (
    <>
      <ImageContext.Provider
        value={{
          getImageBase64,
          fetchImageBase64,
        }}
      >
        {children}
      </ImageContext.Provider>
    </>
  )
}

export const useImageContext = (): ImageContextProps => useContext(ImageContext)
