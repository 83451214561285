import { Suspense, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { InteractionStatus } from '@azure/msal-browser'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'

import { PageDotDotDot } from 'components/DotDotDot'

import { useAuthContext } from 'providers/AuthProvider'
import { useI18Context } from 'providers/i18Provider'

import { ChildrenProps } from '../types/types'

export const SuspenseFullPage = ({ children }: ChildrenProps) => {
  return <Suspense fallback={<PageDotDotDot />}>{children}</Suspense>
}

export const SuspenseAuthFullPage = ({ children }: ChildrenProps) => {
  return (
    <>
      <AuthenticatedTemplate>
        <Suspense fallback={<FullSizePageDotDotDot />}>{children}</Suspense>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <AuthenticationDotDotDot />
      </UnauthenticatedTemplate>
    </>
  )
}
export const SuspenseRedirectFullPage = ({ children }: ChildrenProps) => {
  const { areTermsAccepted } = useAuthContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (!areTermsAccepted) {
      navigate('/')
      window.scrollTo(0, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <AuthenticatedTemplate>
        <Suspense fallback={<FullSizePageDotDotDot />}>{children}</Suspense>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <AuthenticationDotDotDot />
      </UnauthenticatedTemplate>
    </>
  )
}

export const AuthenticationDotDotDot = ({ redirect = true }) => {
  const { isAuthenticated, msalInstance, inProgress } = useAuthContext()
  const { language } = useI18Context()

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None && redirect) {
      msalInstance.loginRedirect({
        redirectStartPage: '/#/login',
        scopes: [],
        extraQueryParameters: {
          ui_locales: language,
        },
      })
    }
  }, [msalInstance, isAuthenticated, language, inProgress, redirect])

  return <FullSizePageDotDotDot />
}

export const FullSizePageDotDotDot = ({ delay }: { delay?: boolean }) => {
  return (
    <div className="flex h-full">
      <PageDotDotDot delay={delay} />
    </div>
  )
}
