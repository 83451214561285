import { ReactElement, ReactNode, useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { BiCloudUpload } from 'react-icons/bi'
import { Box, Text } from '@chakra-ui/layout'
import { Tooltip, TooltipProps } from '@chakra-ui/tooltip'

import { TextButton } from 'components/Button'

import { useI18Context } from 'providers/i18Provider'

import { GeneralSpinner } from 'router/Spinner'

import { AnimatedBox } from './layouts/AnimatedBox'
import { BannerProps } from './Banner'

type UploaderProps = {
  banner?: ReactElement<BannerProps>
  botName: string
  customClasses?: {
    container?: string
    uploadZone?: string
    button?: string
  }
  customMetricsMessaging?: ReactNode
  extensionTypes: string[]
  isDisabled?: boolean
  isFileUploading: boolean
  fileNameLength?: number
  fileTypesByMaxSize?: Record<string, string[]>
  maxFileSize: string
  maxNumFiles: number
  maxNumberTranslationFiles?: number
  onDrop: (acceptedFiles: File[]) => void
  showInlineError?: boolean
  tooltip?: Omit<TooltipProps, 'children'>
  uploadError: string
  uploadModalClickMessage?: {
    key: string
    ns: string
  }
}
export const Uploader = ({
  banner,
  botName,
  customClasses,
  customMetricsMessaging,
  fileTypesByMaxSize,
  extensionTypes,
  fileNameLength,
  isDisabled = false,
  isFileUploading,
  maxFileSize,
  maxNumFiles,
  maxNumberTranslationFiles,
  onDrop,
  showInlineError = false,
  tooltip,
  uploadError,
  uploadModalClickMessage,
}: UploaderProps) => {
  const { doesBotTranslationExist, getTForNS } = useI18Context()
  const { t } = useTranslation(['uploadModal', 'translation'])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, disabled: isFileUploading || isDisabled })
  const botT = getTForNS(`bot/${botName.toLocaleLowerCase()}`)
  const translationKey = maxNumFiles > 1 ? 'modal.dragMessageMultiple' : 'modal.dragMessage'
  const uploadDragMessage = doesBotTranslationExist(translationKey, botName.toLocaleLowerCase())
    ? botT(translationKey)
    : t(translationKey)

  const uploaderContent = useMemo(
    () => (
      <AnimatedBox>
        <Box className="flex flex-col items-center justify-center mb-4 text-center">
          <BiCloudUpload className="w-6 h-6 mb-2 md:h-8 md:w-8" />
          <Text className="text-sm md:text-base">{uploadDragMessage}</Text>
          <Text className="p-1 text-xs md:text-sm">{t('modal.or')}</Text>
          <TextButton
            disabled={isDisabled}
            text={
              uploadModalClickMessage === undefined
                ? t('modal.clickMessage', { ns: 'uploadModal' })
                : t(uploadModalClickMessage.key, { ns: uploadModalClickMessage.ns })
            }
          />
        </Box>
        <Box className="flex flex-col items-center justify-center text-center">
          <Text className="mb-2 text-xs">
            {t('modal.maxFileSize')}:{' '}
            <Text as="span" className="font-bold">
              {/* If the uploadFileMaxSize is less than 1,000,000 then we are dealing with KB (e.g., 900,000 -> 900 KB). Otherwise we are dealing with MB (e.g., 20,000,000 -> 20 MB) */}
              {maxFileSize}
            </Text>
          </Text>
          {fileTypesByMaxSize &&
            Object.values(fileTypesByMaxSize).some((fileTypes) => fileTypes.length > 0) &&
            Object.entries(fileTypesByMaxSize).map(([fileSize, fileTypes]) => (
              <Text className="mb-2 text-xs">
                {t('modal.maxFileSize')}
                {' ('}
                <Text as="span" className="text-xs uppercase">
                  {fileTypes.join(', ')}
                </Text>
                {'): '}
                <Text as="span" className="font-bold">
                  {fileSize}
                </Text>
              </Text>
            ))}
          <Text className="mb-2 text-xs">
            {t('translation.upload.modal.maxNumFiles', { ns: 'translation' })}:{' '}
            <Text as="span" className="text-xs font-bold">
              {maxNumFiles}
            </Text>
          </Text>
          {customMetricsMessaging && <Text className="mb-2 text-xs">{customMetricsMessaging}</Text>}
          {!!extensionTypes.length && (
            <Text className="text-xs">
              {t('modal.supportedFileTypes')}:{' '}
              <Text as="span" className="text-xs font-bold uppercase">
                {extensionTypes.length && extensionTypes.join(', ')}
              </Text>
            </Text>
          )}
          {uploadError && showInlineError && (
            <Text className="my-2 text-xs text-red-400">
              {t(`modal.error.${uploadError}`, {
                maxFileNameLength: fileNameLength,
                maxFileSize: maxFileSize,
                maxNumberTranslationFiles,
              })}
            </Text>
          )}
        </Box>
      </AnimatedBox>
    ),
    [
      customMetricsMessaging,
      extensionTypes,
      fileNameLength,
      fileTypesByMaxSize,
      isDisabled,
      maxFileSize,
      maxNumFiles,
      maxNumberTranslationFiles,
      showInlineError,
      t,
      uploadDragMessage,
      uploadError,
      uploadModalClickMessage,
    ]
  )

  return (
    <>
      {banner && banner}
      <Box
        className={`w-full rounded-lg ${customClasses && customClasses.container && customClasses.container}`}
        {...getRootProps()}
      >
        <input {...getInputProps()} aria-label="file-upload" disabled={isDisabled} />
        <Box
          className={`p-4 text-sm border-2 border-dashed shadow-sm rounded-md ${
            isDragActive ? 'border-solid' : 'border-dashed'
          } ${uploadError && 'border-red-400'} ${customClasses && customClasses.uploadZone && customClasses.uploadZone}`}
        >
          {isFileUploading ? (
            <Box className="flex flex-col items-center justify-center">
              <Text className="text-sm md:text-base">{t('modal.uploading')}...</Text>
              <GeneralSpinner fullHeight={false} />
              <Text className="text-xs text-center md:text-sm">{t('modal.uploadingMessaging')}</Text>
            </Box>
          ) : tooltip ? (
            <Tooltip {...tooltip}>{uploaderContent}</Tooltip>
          ) : (
            uploaderContent
          )}
        </Box>
      </Box>
    </>
  )
}
