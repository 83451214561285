import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiFileBlank, BiFullscreen, BiTrash } from 'react-icons/bi'
import { useDisclosure } from '@chakra-ui/hooks'
import { Box, Text } from '@chakra-ui/layout'
import { TFunction } from 'i18next'

import { Banner } from 'components/Banner'
import { IconButton, TextButton } from 'components/Button'
import { TooltipButton } from 'components/buttons/TooltipButton'
import { ClearFilesBody, ClearFilesFooter } from 'components/ClearFilesForm'
import { HTMLStringParser } from 'components/HTMLStringParser'
import { ModalBox } from 'components/Modal'
import { UploadModal } from 'components/UploadModal'

import { formatMaxFileSize } from 'utils/formatter'

import { useBotSpecificContext } from 'providers/BotSpecificProvider'
import { useConversationSettingsContext } from 'providers/ConversationSettingsProvider'
import { useI18Context } from 'providers/i18Provider'
import { useThemeContext } from 'providers/ThemeProvider'
import { useUploadContext } from 'providers/UploadProvider'

import { FileConfigs } from 'types/types'

import { InfoTooltip } from './InfoTooltip'

type UploadWithPreviewProps = {
  allowMultipleFiles?: boolean
  allowPopOut?: boolean
  botName: string
  currentConversationID: string | null
  isDisabled?: boolean
  isExpanded?: boolean
  isFetchingChart: boolean
  isStreaming: boolean
  kBotFormId?: 'createKBotTemp' | 'editKBotTemp'
  onClear?: () => void // an optional callback function to perform after clearing the content
  onSubmit: (files: File[], fileConfigs: FileConfigs) => void
  mapUploadTypesToText: Array<string>
  allowedUploadTypes: Array<string>
  uploadTokenMaxSize: number
}

export const UploadWithPreview = ({
  allowMultipleFiles = false,
  allowPopOut = false,
  botName,
  currentConversationID,
  isDisabled,
  isExpanded,
  isFetchingChart,
  isStreaming,
  kBotFormId,
  onClear,
  onSubmit,
  mapUploadTypesToText,
  allowedUploadTypes,
  uploadTokenMaxSize,
}: UploadWithPreviewProps) => {
  const {
    deleteDocumentContent,
    deleteTempDocuments,
    docContents,
    getUploadFileParameters,
    isDeletingFileContext,
    isFileUploading,
    setUploadError,
    tempDocContents,
    uploadError,
    uploadModalOperations: { onOpen, isOpen, onClose },
  } = useUploadContext()
  const { getConversationSettings } = useConversationSettingsContext()

  const scrollRef = useRef<HTMLDivElement | null>(null) // use this to scroll to the top of the file contents when the content changes

  const { language } = useI18Context()
  const { t } = useTranslation(['uploadModal', 'generic'])
  const { isTablet, isLightMode } = useThemeContext()
  const { isOpen: isClearFileOpen, onOpen: onClearFileOpen, onClose: onClearFileClose } = useDisclosure()
  const { isOpen: isPopOutOpen, onOpen: onPopOutOpen, onClose: onPopOutClose } = useDisclosure()
  const { isKBot } = useBotSpecificContext()

  // We need to use "KBOTS" as the value for holding uploaded file content, but the "general" bot's config values need to be fetched as parameters for uploading content
  const uploadConfigBotName = botName === 'KBOTS' ? 'general' : botName

  const { fileTypesByMaxSize, maxFileNameLength, ocrMaxFileLimit, uploadFileMaxCount, uploadFileMaxSize } = useMemo(
    () => getUploadFileParameters(uploadConfigBotName),
    [uploadConfigBotName, getUploadFileParameters]
  )
  const numberFormat = useMemo(() => {
    return new Intl.NumberFormat(language)
  }, [language])

  const maxFileSize = useMemo(() => {
    return formatMaxFileSize(uploadFileMaxSize, numberFormat, t as TFunction<'translation', undefined>)
  }, [uploadFileMaxSize, numberFormat, t])

  const maxSizeOCR = useMemo(() => {
    return {
      mb: formatMaxFileSize(ocrMaxFileLimit, numberFormat, t as TFunction<'translation', undefined>),
      value: ocrMaxFileLimit,
    }
  }, [ocrMaxFileLimit, numberFormat, t])

  const conversationSettings = useMemo(() => {
    return getConversationSettings(currentConversationID, botName)
  }, [botName, currentConversationID, getConversationSettings])

  const [isFileSizeWarningAcknowledged, setIsFileSizeWarningAcknowledged] = useState<boolean>(false)

  const documentContentsToRender = useMemo(() => {
    if (currentConversationID) {
      if (botName in docContents && currentConversationID in docContents[botName]) {
        return docContents[botName][currentConversationID]
      }
    } else {
      if (botName in tempDocContents) {
        return tempDocContents[botName]
      }
    }
  }, [currentConversationID, botName, docContents, tempDocContents])

  const shouldShowFileSizeContentWarning = useMemo(() => {
    const fileTypesThatUseOCR = ['docx', 'gif', 'jpeg', 'jpg', 'pdf', 'pdf', 'png', 'pptx']
    return !!(
      !isFileSizeWarningAcknowledged &&
      documentContentsToRender &&
      documentContentsToRender.size > maxSizeOCR.value &&
      !documentContentsToRender.usedOCR &&
      fileTypesThatUseOCR.includes(documentContentsToRender.type)
    )
  }, [documentContentsToRender, isFileSizeWarningAcknowledged, maxSizeOCR.value])

  useEffect(() => {
    if (documentContentsToRender && documentContentsToRender?.text && scrollRef.current) {
      scrollRef.current.scrollTop = 0
    }
  }, [documentContentsToRender, documentContentsToRender?.text])

  const clearFileContents = () => {
    onClearFileClose()
    if (currentConversationID) {
      if (botName in docContents && currentConversationID in docContents[botName]) {
        deleteDocumentContent([botName], [currentConversationID], conversationSettings?.saveConversation)
        onClear && onClear()
        return
      }
    } else {
      if (botName in tempDocContents) {
        deleteTempDocuments([botName])
        onClear && onClear()
        return
      }
    }
  }

  return (
    <>
      <UploadModal
        allowMultipleFiles={allowMultipleFiles}
        botName={botName}
        fileTypesByMaxSize={fileTypesByMaxSize}
        isFileUploading={isFileUploading}
        isOpen={isOpen}
        kBotFormId={kBotFormId}
        maxFileNameLength={maxFileNameLength}
        maxFileSize={maxFileSize}
        maxNumFiles={uploadFileMaxCount}
        onClose={onClose}
        onError={(error: string) => setUploadError(error)}
        onSubmit={onSubmit}
        mapUploadTypesToText={mapUploadTypesToText}
        allowedUploadTypes={allowedUploadTypes}
        uploadError={uploadError}
        uploadTokenMaxSize={uploadTokenMaxSize}
      />
      {allowMultipleFiles && (
        <ModalBox
          isOpen={isClearFileOpen}
          modalBody={<ClearFilesBody />}
          modalFooter={{
            modalFooter: (
              <ClearFilesFooter
                isButtonDisabled={isDeletingFileContext}
                onClose={onClearFileClose}
                primaryButtonOnClick={clearFileContents}
              />
            ),
          }}
          modalHeader={t('generic.clearFiles', { ns: 'generic' })}
          onClose={onClearFileClose}
        />
      )}
      {allowPopOut && (
        <ModalBox
          size="6xl"
          isOpen={isPopOutOpen}
          modalBody={
            <Box className="flex flex-col w-full h-full max-h-[75vh] min-h-0 mt-4">
              <Box className="h-full overflow-auto rounded-xl">
                <Box
                  className={`h-full min-h-[480px] lg:min-h-[300px] p-2 ${isLightMode ? 'bg-slate-200' : 'bg-gray-600 bg-opacity-[0.3]'}`}
                  whiteSpace="pre-wrap"
                >
                  {documentContentsToRender && documentContentsToRender?.text ? (
                    <Text as="span" className={`text-xs ${isLightMode ? 'text-kpmgGray1' : 'text-white'}`}>
                      <HTMLStringParser
                        htmlString={'<html><body>' + documentContentsToRender.text + '</body></html>'}
                      />
                    </Text>
                  ) : (
                    <Text as="span" className={`text-xs ${isLightMode ? 'text-kpmgGray1' : 'text-white'}`}>
                      {t('modal.noContent')}
                    </Text>
                  )}
                </Box>
              </Box>
            </Box>
          }
          modalHeader={t('modal.uploadedFileContents')}
          onClose={onPopOutClose}
        />
      )}
      <Box
        className={`w-full box-content flex ${
          isExpanded && isTablet ? 'flex-col justify-between items-start' : 'flex-col justify-center'
        }`}
      >
        {/* when kBotFormId is not undefined, we are in the kBot createEdit page */}
        <Box
          className={`flex flex-row items-center gap-2 ${kBotFormId !== undefined ? 'w-full md:w-[50%]' : 'w-full'}`}
        >
          <TextButton
            aria-label="upload-file"
            disabled={isStreaming || isFetchingChart || isFileUploading || isDisabled}
            loading={isFileUploading}
            text={
              isFileUploading
                ? t('modal.uploadingCTA')
                : allowMultipleFiles && documentContentsToRender
                  ? t('modal.uploadAnother')
                  : t('modal.uploadCTA')
            }
            onClick={() => onOpen()}
          />
          {allowMultipleFiles && (
            <TooltipButton
              button={
                <IconButton
                  aria-label="clearFiles"
                  Icon={BiTrash}
                  disabled={isStreaming || isFetchingChart || !documentContentsToRender}
                  onClick={() => onClearFileOpen()}
                  test-id="clear-files-button"
                />
              }
              label={t('generic.clearFiles', { ns: 'generic' })}
            />
          )}
        </Box>
        <Box className={`flex-1 ${isExpanded && isTablet ? 'mt-4' : 'mt-4'}`}>
          {documentContentsToRender ? (
            <Box className="flex items-center">
              <BiFileBlank className="w-6 h-6" />
              <Text as="span" className="mx-1 text-xs">
                {`${numberFormat.format(documentContentsToRender.characterCount ?? 0)} ${t('modal.characters')}`}
              </Text>
              <InfoTooltip
                label={t('generic.fileContentCharacterCount', { ns: 'generic' })}
                placement="top"
                tabIndex={0}
                iconClassName="text-xs md:text-sm"
              />
            </Box>
          ) : (
            <Box>
              <Text className="text-sm">{t('modal.noFileUploaded')}</Text>
            </Box>
          )}
        </Box>
      </Box>

      <Box className="flex flex-col w-full h-full min-h-0 mt-4">
        <Box className="flex items-center justify-between">
          <Text className="py-2 text-sm font-bold md:text-base">{t('modal.uploadedFileContents')}:</Text>
          {allowPopOut && documentContentsToRender && documentContentsToRender?.text && (
            <TooltipButton
              button={
                <IconButton
                  aria-label="clearFiles"
                  Icon={BiFullscreen}
                  disabled={!(documentContentsToRender && documentContentsToRender?.text)}
                  onClick={() => onPopOutOpen()}
                  size={isTablet ? 'sm' : 'xs'}
                />
              }
              label={t('modal.seeExpanded')}
            />
          )}
        </Box>
        <Box className="h-full overflow-hidden rounded-xl">
          <Box
            className={`h-full relative p-2 overflow-auto ${isLightMode ? 'bg-slate-200' : 'bg-gray-600 bg-opacity-[0.3]'} ${!isKBot ? 'min-h-[300px]' : 'max-h-[33vh]'}`}
            whiteSpace="pre-wrap"
            ref={scrollRef}
          >
            <Banner
              className="mb-4"
              onClick={() => setIsFileSizeWarningAcknowledged(true)}
              title={t('modal.fileSizeWarningHeader')}
              description={
                <Box className="last:mb-0">
                  <Text className="mb-2">{t('modal.fileSizeWarningBody', { maxSizeOCR: maxSizeOCR.mb })}</Text>
                </Box>
              }
              isVisible={shouldShowFileSizeContentWarning}
            />
            {documentContentsToRender && documentContentsToRender?.text ? (
              <Text as="span" className={`text-xs ${isLightMode ? 'text-kpmgGray1' : 'text-white'}`}>
                <HTMLStringParser htmlString={'<html><body>' + documentContentsToRender.text + '</body></html>'} />
              </Text>
            ) : (
              <Text as="span" className={`text-xs ${isLightMode ? 'text-kpmgGray1' : 'text-white'}`}>
                {t('modal.noContent')}
              </Text>
            )}
          </Box>
        </Box>
      </Box>
      <Box flex="0 0 auto" />
    </>
  )
}
