import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { useFiltersContext } from 'providers/FiltersAndFormsProvider'
import { useMessagesContext } from 'providers/MessageProvider'
import { useThemeContext } from 'providers/ThemeProvider'
import { useUploadContext } from 'providers/UploadProvider'

import { ModalBox } from './Modal'

type ConfirmDeleteConversationModalProps = {
  conversationsToDelete: null | string[]
  isOpen: boolean
  onClose: () => void
  showConversationCount?: boolean
  setConversationIDToDelete?: Dispatch<SetStateAction<string | null>>
  botNames: string[]
  onDelete?: () => void
}

export const ConfirmDeleteConversationModal = ({
  botNames,
  conversationsToDelete,
  isOpen,
  onClose,
  onDelete,
  showConversationCount = false,
  setConversationIDToDelete,
}: ConfirmDeleteConversationModalProps) => {
  const { t } = useTranslation('deleteInstanceModal')
  const { isLightMode } = useThemeContext()

  const { deleteConversations, listOfConversationsBeingDeleted } = useMessagesContext()
  const { deleteDocumentContent } = useUploadContext()
  const { deleteSelectedFilterValues, deleteSelectedFormValues } = useFiltersContext()
  return (
    <ModalBox
      isOpen={isOpen}
      modalBodyText={{
        heading: t(
          `modal.areYouSureMessages${conversationsToDelete && conversationsToDelete.length > 1 ? 'Multi' : ''}`
        ),
        description: t(
          `modal.clearExplanation${conversationsToDelete && conversationsToDelete.length > 1 ? 'Multi' : ''}`
        ),
        details:
          conversationsToDelete && showConversationCount
            ? t('modal.conversationsWillBeDeleted', {
                conversationsCount: conversationsToDelete.length,
              })
            : undefined,
      }}
      modalFooter={{
        cancelFooterButton: {
          text: t('controls.cancel', { ns: 'controls' }),
          disabled: !!listOfConversationsBeingDeleted.length,
          'aria-label': 'close',
          id: 'close-button',
          onClick: onClose,
        },
        confirmFooterButton: {
          text: t(`modal.deleteInstance${conversationsToDelete && conversationsToDelete.length > 1 ? 'Multi' : ''}`),
          disabled: !!listOfConversationsBeingDeleted.length,
          'aria-label': 'delete-conversation',
          id: 'delete-conversation-button',
          onClick: () => {
            if (conversationsToDelete && conversationsToDelete.length) {
              // Need to delete all values in providers that used to belong to the conversation ID that has now been deleted
              deleteConversations(botNames, conversationsToDelete)
              deleteDocumentContent(botNames, conversationsToDelete)
              deleteSelectedFormValues(conversationsToDelete)
              deleteSelectedFilterValues(conversationsToDelete)
              if (setConversationIDToDelete) {
                setConversationIDToDelete(null)
              }
              onDelete && onDelete()
            }
            onClose()
          },
          buttonBackground: isLightMode ? 'bg-kpmgCobaltBlue' : 'bg-white',
          fontColour: isLightMode ? 'text-white' : 'text-black',
        },
      }}
      modalHeader={t(
        `modal.deleteInstanceHeader${conversationsToDelete && conversationsToDelete?.length > 1 ? 'Multi' : ''}`
      )}
      onClose={onClose}
    />
  )
}
