import { FetchRequest } from 'types/types'

import { AzureFileUploadError, NetworkTimeoutError } from '../appError'
import { sleep } from '../sleep'

import { httpJSON, httpRaw, httpStream, ResponseWithRequestId } from './http'

export type SendRequestConfig = {
  url: string
  token: string | null
  requestId: string
  headers?: Record<string, string>
  setRetrying?: (value: boolean) => void
  payload?: FetchRequest
  timeout?: number
  retries?: number
}

export type StreamRequestConfig = {
  url: string
  token: string | null
  payload: FetchRequest
  requestId: string
  setRetrying?: (value: boolean) => void
  retries?: number
}

export const fetchData = async <R>({
  url,
  token,
  requestId,
  headers,
  payload,
  setRetrying,
  timeout = 60000,
  retries = 1,
}: SendRequestConfig): Promise<R> => {
  const config = {
    method: 'post',
    ...(payload && { body: JSON.stringify(Object.assign(payload)) }),
    headers: {
      'Content-Type': 'application/json',
      ...(token && { Authorization: `Bearer ${token}` }),
      ...headers,
    },
  }

  const timeoutPromise = sleep(timeout).then(() => 'sleepMethod')
  const fetchPromise = httpJSON<R>(url, config, retries, requestId, setRetrying)

  const finishedPromise = await Promise.race([fetchPromise, timeoutPromise])

  if (finishedPromise === 'sleepMethod') {
    throw new NetworkTimeoutError('Failed to read from API', undefined, timeout)
  }

  return finishedPromise as R
}

export const fetchDataRaw = async <R>({
  url,
  token,
  requestId,
  headers,
  payload,
  setRetrying,
  timeout = 60000,
  retries = 1,
}: SendRequestConfig): Promise<Response | R> => {
  const config = {
    method: 'post',
    ...(payload && { body: JSON.stringify(Object.assign(payload)) }),
    headers: {
      'Content-Type': 'application/json',
      ...(token && { Authorization: `Bearer ${token}` }),
      ...headers,
    },
  }

  const timeoutPromise = sleep(timeout).then(() => 'sleepMethod')
  const fetchPromise = httpRaw(url, config, retries, requestId, setRetrying)

  const finishedPromise = await Promise.race([fetchPromise, timeoutPromise])
  if (finishedPromise === 'sleepMethod') {
    throw new NetworkTimeoutError('Failed to read from API', undefined, timeout)
  }

  if (isResponseWithRequestId(finishedPromise)) {
    return finishedPromise.response
  }

  return finishedPromise as R
}

export const fetchDataStream = ({
  setRetrying,
  payload,
  url,
  token,
  retries = 1,
  requestId,
}: StreamRequestConfig): { response: Promise<Response>; signalController: AbortController } => {
  const signalController = new AbortController()
  const init: RequestInit = {
    method: 'post',
    body: JSON.stringify(Object.assign(payload)),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    signal: signalController.signal,
  }

  return {
    response: httpStream(url, init, retries, requestId, setRetrying),
    signalController,
  }
}

export const timeOutAction = async <T>(call: Promise<T>, duration: number, timeoutCallback: () => void): Promise<T> => {
  const promises = [call, sleep<T>(duration)]
  const finishedPromise = await Promise.race(promises)
  if (finishedPromise === 'sleepMethod') {
    timeoutCallback()
  }
  return finishedPromise
}

export const putFileIntoStorage = async ({
  url,
  file,
  headers,
  feRequestId,
  timeout = 60000,
}: {
  url: string
  file: File
  headers: { 'Content-Type': string; 'x-ms-blob-type': 'BlockBlob' }
  feRequestId: string
  timeout?: number
}): Promise<Response> => {
  const init = {
    method: 'PUT',
    headers,
    body: file,
  }
  const retries = 1

  const promises = [httpRaw(url, init, retries, feRequestId, undefined, false), sleep<string>(timeout)]

  const finishedPromise = await Promise.race(promises)
  if (typeof finishedPromise === 'string') {
    if (finishedPromise === 'sleepMethod') {
      throw new NetworkTimeoutError('Failed to upload to storage', undefined, timeout)
    }
    throw new NetworkTimeoutError('Failed to upload to storage', undefined, timeout)
  }
  const { response, requestId } = finishedPromise
  const { status, statusText } = response

  // If status is 201 and statusText is 'Created', return the response
  if (status === 201 && statusText === 'Created') {
    return response
  }

  // Convert response headers to an array of strings for error reporting
  const headersArray = Array.from(response.headers.entries()).map(([key, value]) => `${key}: ${value}`)

  throw new AzureFileUploadError(
    `Did NOT get back status === 201 && statusText === 'Created' when calling putFileIntoStorageContainer. status: ${status}, statusText: ${statusText}`,
    -1,
    headersArray,
    status.toString(),
    requestId,
    statusText
  )
}

const isResponseWithRequestId = (result: unknown): result is ResponseWithRequestId<Response> => {
  return typeof result === 'object' && result !== null && 'response' in result && 'requestId' in result
}
