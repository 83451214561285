import { Fragment } from 'react'
import { OpenAITemperature } from '@kleo/types/dist/api.types'
import { Document, Link, Page, Text, View } from '@react-pdf/renderer'
import kleoColors from 'styles/colors'

import { I18ContextType } from '../../providers/i18Provider'
import { ChatMessage, DisclaimerProperty } from '../../types/types'

import { styles } from './pdfStyles'

type ConversationPDFDocumentProps = {
  botName: string
  messages: ChatMessage[]
  date: string
  disclaimer: DisclaimerProperty[]
  temperature: OpenAITemperature | undefined
} & Pick<I18ContextType, 't'>

export const ConversationPDFDocument = ({
  botName,
  messages,
  date,
  disclaimer,
  t,
  temperature,
}: ConversationPDFDocumentProps) => {
  return (
    <Document>
      <Page wrap size="LETTER" style={styles.page}>
        <View style={styles.container}>
          <Text style={[styles.title, styles.boldText, { fontSize: '14px' }]}>KPMG Kleo - {botName}</Text>
          <Text style={[styles.title, styles.boldText, { fontSize: '12px' }]}>{date}</Text>
        </View>
        {temperature && (
          <Text style={[styles.normalText, { fontSize: '12px', marginTop: '8px', marginBottom: '8px' }]}>
            {t('settings.temperature', { ns: 'settings' })}:{' '}
            {t(`settings.temperatures.${temperature}`, { ns: 'settings' })}
          </Text>
        )}
        {/* Only when provided disclaimer content should we display a disclaimer and it's content */}
        {Array.isArray(disclaimer) && disclaimer.length > 0 && (
          <View style={styles.inlineContainer}>
            <Text>
              {disclaimer.map((property, disclaimerIndex: number) => {
                return (
                  <Fragment
                    key={`${property.type}_${property.value}_${disclaimerIndex}`}
                    // Add in a space at the end of each part if we are not at the last property
                  >
                    {property.type === 'title' ? (
                      <Text style={styles.boldText}>{property.value}:</Text>
                    ) : property.type === 'text' ? (
                      <Text>{property.value}</Text>
                    ) : property.type === 'link' ? (
                      <Link src={property.value.url}>{property.value.text}</Link>
                    ) : null}
                    {disclaimerIndex !== disclaimer.length && ' '}
                  </Fragment>
                )
              })}
            </Text>
          </View>
        )}
        <View style={[styles.flexBoxColumn, { alignItems: 'flex-start' }]}>
          {messages
            .filter((message) => message.role !== 'chart')
            .map((message, index: number) => {
              const messageContent = message.delimiter
                ? message.content.split(message.delimiter)[0].replace(/\n+$/, '')
                : message.content
              const codeBlockFirstIndex = messageContent.indexOf('```')
              return (
                <Fragment key={`${message.role}_${index}`}>
                  <div style={{ marginBottom: '16px' }}>
                    <Text
                      style={[
                        styles.boldText,
                        {
                          textAlign: 'left',
                          fontSize: '12px',
                          color: message.role === 'assistant' ? kleoColors.black : kleoColors.kpmgBlue,
                          textDecoration: 'underline',
                          textTransform: 'capitalize',
                          marginBottom: '4px',
                        },
                      ]}
                    >
                      {t(`generic.${message.role}`)}:
                    </Text>
                    {/* parse for code blocks and format the font accorrdingly */}
                    {messageContent.split('```').map((content: string, contentIndex: number) => {
                      if (
                        // not in a code block, check for inline code (eg. `code`)
                        (codeBlockFirstIndex === 0 && contentIndex % 2 === 1) ||
                        (codeBlockFirstIndex > 0 && contentIndex % 2 === 0) ||
                        content === '' ||
                        codeBlockFirstIndex < 0
                      ) {
                        const inlineCodeFirstIndex = messageContent.indexOf('`')
                        return (
                          // need to wrap it in a Text tag to preserve paragraphs
                          <Text>
                            {/* parse the non-code-block chunks for inline code */}
                            {content.split('`').map((text, textIndex) => {
                              if (
                                // not inline code
                                (inlineCodeFirstIndex === 0 && textIndex % 2 === 1) ||
                                (inlineCodeFirstIndex > 0 && textIndex % 2 === 0) ||
                                text === '' ||
                                inlineCodeFirstIndex < 0
                              ) {
                                const boldTextFirstIndex = text.indexOf('**')
                                return (
                                  // need to wrap it in a Text tag to preserve paragraphs
                                  <Text>
                                    {/* parse non-code (neither inline nor block) for bolded text */}
                                    {text.split('**').map((plainText, plainTextIndex) => {
                                      return (
                                        <Text
                                          style={[
                                            (boldTextFirstIndex === 0 && plainTextIndex % 2 === 1) ||
                                            (boldTextFirstIndex > 0 && plainTextIndex % 2 === 0) ||
                                            plainText === '' ||
                                            boldTextFirstIndex < 0
                                              ? styles.normalText
                                              : styles.boldText,
                                            {
                                              textAlign: 'left',
                                              fontSize: '12px',
                                            },
                                          ]}
                                        >
                                          {plainText}
                                        </Text>
                                      )
                                    })}
                                  </Text>
                                )
                              } else {
                                return (
                                  <Text
                                    style={[
                                      styles.normalText,
                                      styles.inlineCode,
                                      {
                                        textAlign: 'left',
                                        fontSize: '12px',
                                      },
                                    ]}
                                  >
                                    {text}
                                  </Text>
                                )
                              }
                            })}
                          </Text>
                        )
                      } else {
                        // we are in a code block
                        // eg.
                        // ```
                        // code
                        // ```
                        // separate the header (ie. the language of the chunk, which is the first line of the string)
                        const codeChunks = content.split('\n')
                        const codeHeader = codeChunks[0]
                        const code = codeChunks.slice(1)
                        return (
                          <>
                            <Text
                              style={[
                                styles.normalText,
                                styles.codeBlockHeader,
                                {
                                  textAlign: 'left',
                                  fontSize: '12px',
                                },
                              ]}
                            >
                              {codeHeader}
                            </Text>
                            <Text
                              style={[
                                styles.normalText,
                                styles.codeBlock,
                                {
                                  textAlign: 'left',
                                  fontSize: '12px',
                                },
                              ]}
                            >
                              {code.map((codeChunk) => `\n${codeChunk}`)}
                            </Text>
                          </>
                        )
                      }
                    })}
                  </div>
                </Fragment>
              )
            })}
        </View>
      </Page>
    </Document>
  )
}
