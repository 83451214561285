import { useTranslation } from 'react-i18next'
import { FormLabel } from '@chakra-ui/form-control'
import { Box, Heading, Text } from '@chakra-ui/layout'
import { Textarea } from '@chakra-ui/textarea'
import { VisuallyHidden } from '@chakra-ui/visually-hidden'

import { InfoTooltip } from 'components/InfoTooltip'
import { RequiredAsteriskText } from 'components/RequiredAsteriskText'

import { useThemeContext } from 'providers/ThemeProvider'

import { KBotFormState, KBotFormValues, SetKBotFormValue, ValidationErrors } from 'types/types'

export const KBotInstructions = ({
  formErrors,
  instructionsValue,
  maxLength,
  setKBotFormValue,
  shouldInputsBeDisabled,
  state,
}: {
  formErrors: ValidationErrors<KBotFormValues>
  instructionsValue: string
  maxLength: number
  setKBotFormValue: SetKBotFormValue
  shouldInputsBeDisabled: boolean
  state: KBotFormState
}) => {
  const { t } = useTranslation('kBots')
  const { isLightMode } = useThemeContext()

  return (
    <>
      <Box className="flex items-center justify-start mb-4">
        <Heading as="h2" id="kbot-instructions-heading" size="sm" className="mr-2">
          {t('kBots.createEdit.instructions')} <RequiredAsteriskText showText={false} />
        </Heading>
        <InfoTooltip
          label={
            <>
              <Text>{t('kBots.createEdit.instructionsExplanation')}</Text>
              <Text>{t('kBots.createEdit.instructionsExplanation2')}</Text>
            </>
          }
          placement="right"
          aria-describedby="kbot-instructions-heading"
          iconClassName="self-end w-4 h-4 text-gray-600"
        />
      </Box>
      {formErrors.instructions && (
        <Text as="span" className={`block mb-2 text-sm ${isLightMode ? 'text-red-600' : 'text-red-400'}`}>
          {t(formErrors.instructions)}
        </Text>
      )}
      <VisuallyHidden>
        <FormLabel htmlFor="instructions-textarea">{t('kBots.createEdit.instructions')}</FormLabel>
      </VisuallyHidden>
      <Textarea
        id="instructions-textarea"
        className="mb-3 border rounded-md border-kpmgGray3 focus-visible:border-kpmgGray3 focus-visible:shadow-none"
        value={instructionsValue}
        onChange={(e) => setKBotFormValue('instructions', e.target.value, state)}
        placeholder={t('kBots.createEdit.typeSomething')}
        size="sm"
        maxLength={maxLength}
        isDisabled={shouldInputsBeDisabled}
      />
    </>
  )
}
