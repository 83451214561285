import {
  DualLanguageOptionalValues,
  PromptLibraryParentPrompts,
  PromptLibraryPrompts,
  StarterPrompt,
} from '@kleo/types'

import { KBotFormValues, PromptLibraryProperty } from 'types/types'

export const fillMissingLanguageField = ({ en, fr }: DualLanguageOptionalValues) => {
  if (!en?.trim() && !fr?.trim()) {
    throw new Error('Both English and French values cannot be null, undefined, or empty.')
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const filledEn = en?.trim() || fr!
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const filledFr = fr?.trim() || en!

  return { en: filledEn, fr: filledFr }
}

export const findIconInPrompts = (starterPrompts: StarterPrompt[]): string | undefined => {
  for (const prompt of starterPrompts) {
    if (prompt.icon) {
      return prompt.icon
    }

    if ('subPrompts' in prompt) {
      const foundIcon = findIconInPrompts(prompt.subPrompts)
      if (foundIcon) {
        return foundIcon
      }
    }
  }
  return undefined
}

export const collectAllPromptsFromSubPrompts = (starterPrompts: StarterPrompt[]) => {
  const firstPrompts = starterPrompts.find(
    (prompt) =>
      ('prompts' in prompt && prompt.prompts.length > 0) ||
      ('subPrompts' in prompt && prompt.subPrompts.some((sub) => sub.prompts.length > 0))
  )

  if (firstPrompts && 'prompts' in firstPrompts && firstPrompts.prompts.length > 0) {
    return firstPrompts.prompts
  } else if (firstPrompts && 'subPrompts' in firstPrompts) {
    return firstPrompts.subPrompts.find((sub) => sub.prompts.length > 0)?.prompts || []
  }

  return []
}

export const trimFormValues = (formValues: KBotFormValues) => {
  return {
    ...formValues,
    instructions: formValues.instructions.trim(),
    starterPrompts: {
      ...formValues.starterPrompts,
      prompts: formValues.starterPrompts.prompts.filter((prompts) => !(prompts.en === null && prompts.fr === null)),
    },
    description: formValues.description.filter(
      (desc) => !(desc.en == null && desc.fr == null) && !(desc.en === '' && desc.fr === '')
    ),
  }
}

export const nullForEmptyStrings = (values: DualLanguageOptionalValues[]) => {
  return values.map((item) => ({
    en: item.en === '' ? null : item.en,
    fr: item.fr === '' ? null : item.fr,
  }))
}

// check if all 'en' or 'fr' prompts or sub-prompts values are null
export const isLanguagePromptsNull = (
  language: 'en' | 'fr',
  library: StarterPrompt[] | PromptLibraryProperty[]
): boolean => {
  return (
    Array.isArray(library) &&
    library.length > 0 &&
    library.every((item) => {
      if ('prompts' in item) {
        return (item as PromptLibraryPrompts).prompts.every((prompt) => prompt[language] === null)
      } else if ('subPrompts' in item) {
        return (item as PromptLibraryParentPrompts).subPrompts.every((subPrompt) =>
          subPrompt.prompts.every((prompt) => prompt[language] === null)
        )
      }
      return true
    })
  )
}
