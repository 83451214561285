import { ReactElement, ReactNode, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { FEUIConfig } from 'types/types'

import { Footer } from '../components/Footer'
import { Navbar } from '../components/Navbar'

import { ErrorBoundaryWithLogger } from './ErrorBoundary'

export function Layout({ children, config }: { children: ReactNode; config?: FEUIConfig[] }): ReactElement {
  const location = useLocation()

  const isBotPage = useMemo(() => {
    // Get all parts of the route, making sure we don't have any empty string (i.e. because if the route is /abc/terms/, then the last item in the array would be empty string because of it ending in a slash)
    const routeSegments = location.pathname.split('/').filter((segment) => segment.length > 0)
    const lastSegment = routeSegments[routeSegments.length - 1]

    // Check if the current route matches any bot config route, and that we aren't showing the "terms" for a bot
    return (
      (config?.some((botConfig) => location.pathname.startsWith(botConfig.route) && botConfig.type !== 'translation') &&
        lastSegment !== 'terms') ??
      false
    )
  }, [location.pathname, config])

  const isTargetRoute = useMemo(() => {
    // Check if the current route are any of the routes specified
    return location.pathname.includes('k-bots')
  }, [location.pathname])

  return (
    <div className="flex flex-col min-h-screen">
      {isBotPage || isTargetRoute ? (
        <>
          <div className="flex flex-col h-screen">
            <header>
              <Navbar config={config} isChatBot={isBotPage} />
            </header>
            <main className="flex-grow min-h-0 overflow-y-auto">
              <ErrorBoundaryWithLogger>{children}</ErrorBoundaryWithLogger>
            </main>
          </div>
          <footer>
            <Footer />
          </footer>
        </>
      ) : (
        <>
          <header>
            <Navbar config={config} isChatBot={isBotPage} />
          </header>
          {/* Makes <main> fill available space and forces its direct children to grow as well */}
          <main className="flex flex-col flex-1 child:flex-grow">
            <ErrorBoundaryWithLogger>{children}</ErrorBoundaryWithLogger>
          </main>
          <footer>
            <Footer />
          </footer>
        </>
      )}
    </div>
  )
}
