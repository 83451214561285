import { type ThemeConfig } from '@chakra-ui/theme'
import { mode, type StyleFunctionProps } from '@chakra-ui/theme-tools'
import { extendTheme } from '@chakra-ui/theme-utils'
import kleoColors from 'styles/colors'

const config: ThemeConfig = {
  // Set the initial color mode to 'dark'
  initialColorMode: 'dark',
  // Disable the use of the system color mode
  useSystemColorMode: false,
}

const theme = extendTheme({
  config,
  components: {
    Button: {
      variants: {
        kpmgWhite: {
          bg: 'white',
          color: kleoColors.darkGray800, // gray-800
          _hover: {
            bg: kleoColors.whiteHover, // whiteHover
            _disabled: {
              bg: 'white', // Ensure it stays the same as the initial bg
            },
          },
        },
        kpmgWhiteBlueText: {
          bg: 'white',
          color: kleoColors.kpmgBlue, // kpmgBlue
          _hover: {
            bg: kleoColors.whiteHover, // whiteHover
            _disabled: {
              bg: 'white', // Ensure it stays the same as the initial bg
            },
          },
        },
        kpmgBlue: {
          bg: kleoColors.kpmgBlue, // kpmgBlue
          color: 'white',
          _hover: {
            bg: 'kleoColors.kpmgBlueHover', // kpmgBlueHover
            _disabled: {
              bg: kleoColors.kpmgBlue, // Ensure it stays the same as the initial bg
            },
          },
        },
        kpmgDarkBlue: {
          bg: kleoColors.kpmgDarkBlue, // kpmgDarkBlue
          color: 'white',
          _hover: {
            bg: kleoColors.kpmgDarkBlueHover, // kpmgDarkBlueHover
            _disabled: {
              bg: kleoColors.kpmgDarkBlue, // Ensure it stays the same as the initial bg
            },
          },
        },
        kpmgCobaltBlue: {
          bg: kleoColors.kpmgCobaltBlue, // kpmgCobaltBlue
          color: 'white',
          _hover: {
            bg: kleoColors.kpmgCobaltBlueHover, // kpmgCobaltBlueHover
            _disabled: {
              bg: kleoColors.kpmgCobaltBlue, // Ensure it stays the same as the initial bg
            },
          },
        },
        kpmgLightPurple: {
          bg: kleoColors.kpmgLightPurple, // kpmgLightPurple
          color: 'white',
          _hover: {
            bg: kleoColors.kpmgLightPurpleHover, // kpmgLightPurpleHover
            _disabled: {
              bg: kleoColors.kpmgLightPurple, // Ensure it stays the same as the initial bg
            },
          },
        },
        kpmgPurple: {
          bg: kleoColors.kpmgPurple, // kpmgPurple
          color: 'white',
          _hover: {
            bg: kleoColors.kpmgPurpleHover, // kpmgPurpleHover
            _disabled: {
              bg: kleoColors.kpmgPurple, // Ensure it stays the same as the initial bg
            },
          },
        },
        whiteAlpha: {
          bg: kleoColors.whiteAlpha, // whiteAlpha
          color: 'white',
          _hover: {
            bg: kleoColors.whiteAlphaHover, // whiteAlphaHover
            _disabled: {
              bg: kleoColors.whiteAlpha, // Ensure it stays the same as the initial bg
            },
          },
        },
        blackAlpha: {
          bg: kleoColors.blackAlpha, // blackAlpha
          color: kleoColors.darkGray800,
          _hover: {
            bg: kleoColors.blackAlphaHover, // blackAlphaHover
            _disabled: {
              bg: kleoColors.blackAlpha, // Ensure it stays the same as the initial bg
            },
          },
        },
      },
    },
    Menu: {
      parts: ['list', 'item'],
      baseStyle: (props: StyleFunctionProps) => ({
        list: {
          // For MenuList: background changes based on color mode.
          bg: mode(kleoColors.white, kleoColors.kpmgGray1)(props),
        },
        item: {
          // For MenuItem: background and hover state
          bg: mode(kleoColors.white, kleoColors.kpmgGray1)(props),
          _hover: {
            bg: mode(kleoColors.whiteHover, kleoColors.kpmgGray1Hover)(props),
          },
          _focus: {
            bg: mode(kleoColors.whiteHover, kleoColors.kpmgGray1Hover)(props),
          },
        },
      }),
    },
  },
  fonts: {
    heading: 'OpenSansCondensed, ui-sans-serif, system-ui',
    body: 'OpenSans, ui-sans-serif, system-ui',
  },
  styles: {
    global: (props: StyleFunctionProps) => ({
      body: {
        bg: mode(kleoColors.backgroundLight, kleoColors.backgroundDark)(props),
      },
      html: {
        bg: mode(kleoColors.backgroundLight, kleoColors.backgroundDark)(props),
      },
    }),
  },
})

export default theme
