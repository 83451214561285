import { useTranslation } from 'react-i18next'
import { Box, Text } from '@chakra-ui/layout'
import { aboutKPMGLinks, helpAndSupportLinks, usefulLinks } from 'constants/footerLinks'

import { useI18Context } from 'providers/i18Provider'
import { useThemeContext } from 'providers/ThemeProvider'

import { ImageWithCache } from './ImageWithCache'
import { FooterLink } from './Link'

export const Footer = () => {
  const { isLightMode } = useThemeContext()
  const { language, languageAbbreviation } = useI18Context()
  const { t } = useTranslation('navigation/footer')

  const currentYear = new Date().getFullYear()

  const helpAndSupportLinksTranslated = helpAndSupportLinks[language]
  const usefulLinksTranslated = usefulLinks[language]
  const aboutKPMGLinksTranslated = aboutKPMGLinks[language]

  return (
    <Box className={`py-10 ${isLightMode ? 'bg-backgroundLight' : 'bg-backgroundDark'}`}>
      <Box className="width-layout">
        <Box className="flex flex-col items-start mx-4 md:mx-0 md:mb-8 md:grid md:grid-cols-4 md:gap-4">
          <ImageWithCache
            className="h-8 mb-4 md:mb-0"
            imagePath={isLightMode ? '../images/kpmg.svg' : '../images/kpmgWhite.svg'}
            alt="KPMG"
          />
          <Box className="mb-4 md:mb-0">
            <Box tabIndex={0} className="mb-2">
              {t('headers.helpAndSupport')}
            </Box>
            <Box className="flex flex-col">
              {Object.keys(helpAndSupportLinksTranslated).map((property: string, index: number) => (
                <FooterLink
                  key={`${property}_${index}`}
                  name={t(`links.${property}`)}
                  url={helpAndSupportLinksTranslated[property]}
                />
              ))}
            </Box>
          </Box>
          <Box className="mb-4 md:mb-0">
            <Box tabIndex={0} className="mb-2">
              {t('headers.usefulLinks')}
            </Box>
            <Box className="flex flex-col">
              {Object.keys(usefulLinksTranslated).map((property: string, index: number) => (
                <FooterLink
                  key={`${property}_${index}`}
                  name={t(`links.${property}`)}
                  url={usefulLinksTranslated[property]}
                />
              ))}
            </Box>
          </Box>
          <Box className="mb-4 md:mb-0">
            <Box tabIndex={0} className="mb-2">
              {t('headers.aboutKPMG')}
            </Box>
            <Box className="flex flex-col">
              {Object.keys(aboutKPMGLinksTranslated).map((property: string, index: number) => (
                <FooterLink
                  key={`${property}_${index}`}
                  name={t(`links.${property}`)}
                  url={aboutKPMGLinksTranslated[property]}
                />
              ))}
            </Box>
          </Box>
        </Box>
        <hr className="mb-4" />
        {languageAbbreviation === 'en' ? (
          <Box className="mx-4 text-xs md:mx-0">{t('legalVerbiage', { year: currentYear })}</Box>
        ) : (
          <Box className="mx-4 text-xs md:mx-0">
            <Text as="span">{t('legalVerbiage1', { year: currentYear })}</Text>
            <Text as="span" className="text-2xs">
              {t('legalVerbiage2')}
            </Text>
            <Text as="span">{t('legalVerbiage3')}</Text>
          </Box>
        )}
      </Box>
    </Box>
  )
}
