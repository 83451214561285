import { forwardRef } from 'react'
import { IconType } from 'react-icons'
import { IconButton as ChakraIconButton, IconButtonProps as ChakraIconButtonProps } from '@chakra-ui/button'
import cx from 'classnames'

import { useThemeContext } from 'providers/ThemeProvider'

import { CustomButtonProps } from 'types/types'

type IconButtonProps = {
  iconName: IconType
  iconClassName: string
} & Omit<ChakraIconButtonProps, 'icon'> &
  CustomButtonProps

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
  const {
    className: classNameToAdd,
    iconClassName,
    padding = 'py-2',
    iconName: Icon,
    rounded = 'lg',
    variant,
    whiteSpace = 'normal',
    ...rest
  } = props
  const { isLightMode } = useThemeContext()

  const isLightButton =
    variant === 'kpmgWhite' ||
    variant === 'blackAlpha' ||
    (isLightMode && (variant === 'outline' || variant === 'ghost'))

  return (
    <ChakraIconButton
      {...rest}
      className={cx('shadow-none', classNameToAdd, padding, {
        'focus-visible:ring-light': isLightButton,
        'focus-visible:ring-dark': !isLightButton,
      })}
      icon={<Icon className={iconClassName} />}
      ref={ref}
      rounded={rounded}
      tabIndex={0}
      variant={variant}
      whiteSpace={whiteSpace}
    />
  )
})
