import { createContext, RefObject, useCallback, useContext, useEffect, useRef, useState } from 'react'
import set from 'lodash/set'
import * as Yup from 'yup'

import { isFirstDotFilters } from 'utils/filters'

import {
  BotCombinedValues,
  ChatMessage,
  ChildrenProps,
  ConversationSettings,
  FilterValue,
  SearchFilterItem,
} from 'types/types'

import { useBotSpecificContext } from './BotSpecificProvider'
import { useConversationSettingsContext } from './ConversationSettingsProvider'
import { useFiltersContext } from './FiltersAndFormsProvider'

export type BotFormErrors = {
  userQuery: string | null
  filters: {
    [filterName: string]: string | null
  }
  folderNameInput?: string | null
  translationTargetLanguage?: string | null
}

type ValidateFormValueType =
  | Extract<SetValueArgs, { value: string }>['value']
  | Extract<SetValueArgs, { value: null | ChatMessage }>['value']
  | Extract<SetValueArgs, { value: { [key: string]: FilterValue } }>['value']
  | Extract<SetValueArgs, { value: FilterValue | boolean | Readonly<SearchFilterItem[]> }>['value']

type ValidateFormFieldType =
  | Extract<SetValueArgs, { field: Extract<keyof BotCombinedValues, 'userQuery'> }>['field']
  | Extract<SetValueArgs, { field: Extract<keyof BotCombinedValues, 'filters'> }>['field']
  | Extract<SetValueArgs, { field: Extract<keyof BotCombinedValues, 'folderNameInput'> }>['field']
  | Extract<SetValueArgs, { field: Extract<keyof BotCombinedValues, 'translationTargetLanguage'> }>['field']
  | Extract<SetValueArgs, { field: Exclude<string, keyof BotCombinedValues> }>['field']

type SetValueArgs =
  | {
      field: Extract<keyof BotCombinedValues, 'userQuery'>
      value: string
      shouldValidate?: boolean
    }
  | {
      field: Extract<keyof BotCombinedValues, 'filters'>
      value: {
        [key: string]: FilterValue
      }
      shouldValidate?: boolean
    }
  | {
      field: Extract<keyof BotCombinedValues, 'folderNameInput'>
      value: string
      shouldValidate?: boolean
    }
  | {
      field: Extract<keyof BotCombinedValues, 'translationTargetLanguage'>
      value: SearchFilterItem[]
      shouldValidate?: boolean
    }
  | {
      field: Exclude<string, keyof BotCombinedValues>
      value: FilterValue | boolean | Readonly<SearchFilterItem[]>
      shouldValidate?: boolean
    }

type BotSpecificFormContextType = {
  clearError: (field: keyof BotFormErrors) => void
  formErrors: BotFormErrors
  getCombinedValue: <K extends keyof BotCombinedValues>(field: K) => BotCombinedValues[K]
  getCombinedValues: () => BotCombinedValues
  getFilterValue: <K extends keyof FilterValue>(filterName: string, field: K) => FilterValue[K]
  handleSubmit: (userInput?: string) => void
  handleSubmitWithFiles: (files: File[]) => void
  reset: () => void
  setValue: ({ field, value, shouldValidate }: SetValueArgs) => void
  userQueryInputRef: RefObject<HTMLTextAreaElement>
}

export const BotSpecificFormContext = createContext<BotSpecificFormContextType>({} as BotSpecificFormContextType)

type BotSpecificFormProviderProps = {
  handleChatSubmit?: (
    values: BotCombinedValues,
    conversationSettings: ConversationSettings | undefined
  ) => Promise<void>
  handleFileSubmit?: (values: BotCombinedValues, files: File[]) => Promise<void>
  schema: Yup.AnyObjectSchema
} & ChildrenProps

const defaultFormErrors = {
  userQuery: null,
  filters: {},
  folderNameInput: null,
  translationTargetLanguage: null,
}

export const BotSpecificFormProvider = ({
  children,
  handleChatSubmit,
  handleFileSubmit,
  schema,
}: BotSpecificFormProviderProps) => {
  const {
    deleteSelectedFilterValues,
    deleteSelectedFormValues,
    deleteTempSelectedFilterValues,
    deleteTempSelectedFormValues,
    getSelectedFilterValues,
    getSelectedFormAndFilterValues,
    getSelectedFormValues,
    setSelectedFilterValues,
    setTempSelectedFilterValues,
    updateSelectedFilterValues,
    updateSelectedFormValues,
  } = useFiltersContext()
  const { getConversationSettings } = useConversationSettingsContext()
  const { botName, currentConversationID, kBotTemplateId } = useBotSpecificContext()

  const [formErrors, setFormErrors] = useState<BotFormErrors>(defaultFormErrors)

  const userQueryInputRef = useRef<HTMLTextAreaElement>(null)

  const clearError = useCallback((field: keyof BotFormErrors) => {
    setFormErrors((currentFormErrors) => ({
      ...currentFormErrors,
      [field]: defaultFormErrors[field],
    }))
  }, [])

  useEffect(() => {
    if (currentConversationID || botName || kBotTemplateId) {
      clearError('userQuery')
      clearError('filters')
    }
  }, [clearError, currentConversationID, botName, kBotTemplateId])

  const validateForm = useCallback(
    /*
      when shouldValidate = true, we need to pass in the current field and value for validation, otherwise the state changed (tick)
      will be 1 tick behind
      when shouldValidate = false, we can use the state `conversationsBasedBotValues` as validation
    /*/
    async (field?: ValidateFormFieldType, value?: ValidateFormValueType) => {
      const conversationsBasedBotValues = getSelectedFormAndFilterValues(botName, currentConversationID)

      // Use lodash's set to update a nested property by dot-path, avoiding creation of a new top-level key.
      // E.g., "filters.llm" as the "field" will update the original object properly using lodash set, otherwise a top-level key of "filters.llm" is created
      const validation =
        field && value !== undefined ? set(conversationsBasedBotValues, field, value) : conversationsBasedBotValues

      try {
        await schema.validate(validation, { abortEarly: false })
        setFormErrors(defaultFormErrors)
        // clear errors if validation passes
        return true
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const validationErrors = err
          const errors: BotFormErrors = JSON.parse(JSON.stringify(defaultFormErrors))
          validationErrors.inner.forEach((error) => {
            if (error.path) {
              const path = error.path
              if (path === 'userQuery' || path === 'folderNameInput' || path === 'translationTargetLanguage') {
                errors[path] = error.message
              } else {
                // the error of too many filters values selected
                if (error.type === 'totalFilterValidation') {
                  errors['filters']['total'] = error.message

                  // all other filter specific errors
                } else {
                  const fieldKeys = path.split('.')
                  if (isFirstDotFilters(fieldKeys)) {
                    errors[fieldKeys[0]][fieldKeys[1]] = error.message
                  }
                }
              }
            }
          })
          setFormErrors(errors)
        }
        return false
      }
    },
    [botName, currentConversationID, getSelectedFormAndFilterValues, schema]
  )

  const setValue = useCallback(
    ({ field, value, shouldValidate = true }: SetValueArgs) => {
      if (shouldValidate) {
        validateForm(field, value)
      }

      switch (field) {
        case 'userQuery':
        case 'folderNameInput': {
          const currentFormValues = getSelectedFormValues(botName, currentConversationID)
          if (currentFormValues && typeof value === 'string') {
            updateSelectedFormValues(botName, currentConversationID, { [field]: value })
          }
          break
        }
        case 'translationTargetLanguage': {
          if (Array.isArray(value) && value.length)
            updateSelectedFormValues(botName, currentConversationID, { [field]: value?.[0]?.value ?? '' })
          break
        }
        case 'filters': {
          const currentFilterValues = getSelectedFilterValues(botName, currentConversationID)

          if (currentFilterValues) {
            const updatedValues = { ...currentFilterValues, [field]: value as { [key: string]: FilterValue } }
            updateSelectedFilterValues(botName, currentConversationID, updatedValues)
          }
          break
        }
        default: {
          const keys = field.split('.')
          const firstKey = keys[0]

          // Need a little bit more granularity in how we're setting filters here, so we can't just call updateSelectedFilterValues and let that handle the update - hence the check for currentConversationID
          if (currentConversationID) {
            // if the last string of the dot notation is items (part of FilterValue)
            if (keys.at(-1) === 'items') {
              setSelectedFilterValues((currentFilterValues) => ({
                ...currentFilterValues,
                [botName]: {
                  ...currentFilterValues[botName],
                  [currentConversationID]: {
                    filters: {
                      ...currentFilterValues[botName][currentConversationID].filters,
                      // assuming that items is always preceded by a field key in filters
                      [keys[keys.length - 2]]: {
                        ...currentFilterValues[botName][currentConversationID].filters[keys[keys.length - 2]],
                        items: value as SearchFilterItem[],
                      },
                    },
                  },
                },
              }))
              // if the last string of the dot notation is isChecked (part of FilterValue)
            } else if (keys.at(-1) === 'isChecked') {
              setSelectedFilterValues((currentFilterValues) => ({
                ...currentFilterValues,
                [botName]: {
                  ...currentFilterValues[botName],
                  [currentConversationID]: {
                    filters: {
                      ...currentFilterValues[botName][currentConversationID].filters,
                      // assuming that items is always preceded by a field key in filters
                      [keys[keys.length - 2]]: {
                        ...currentFilterValues[botName][currentConversationID].filters[keys[keys.length - 2]],
                        isChecked: value as boolean,
                      },
                    },
                  },
                },
              }))

              // if first part of dot notation is filters, but NOT just filters
            } else if (firstKey === 'filters') {
              setSelectedFilterValues((currentFilterValues) => ({
                ...currentFilterValues,
                [botName]: {
                  ...currentFilterValues[botName],
                  [currentConversationID]: {
                    [firstKey]: {
                      ...currentFilterValues[botName][currentConversationID].filters,
                      [keys[1]]: value as FilterValue,
                    },
                  },
                },
              }))
            }
          } else {
            // if the last string of the dot notation is items (part of FilterValue)
            if (keys.at(-1) === 'items') {
              setTempSelectedFilterValues((currentFilterValues) => ({
                ...currentFilterValues,
                [botName]: {
                  ...currentFilterValues[botName],
                  filters: {
                    ...currentFilterValues[botName].filters,
                    // assuming that items is always preceded by a field key in filters
                    [keys[keys.length - 2]]: {
                      ...currentFilterValues[botName].filters[keys[keys.length - 2]],
                      items: value as SearchFilterItem[],
                    },
                  },
                },
              }))
              // if the last string of the dot notation is isChecked (part of FilterValue)
            } else if (keys.at(-1) === 'isChecked') {
              setTempSelectedFilterValues((currentFilterValues) => ({
                ...currentFilterValues,
                [botName]: {
                  ...currentFilterValues[botName],
                  filters: {
                    ...currentFilterValues[botName].filters,
                    // assuming that items is always preceded by a field key in filters
                    [keys[keys.length - 2]]: {
                      ...currentFilterValues[botName].filters[keys[keys.length - 2]],
                      isChecked: value as boolean,
                    },
                  },
                },
              }))

              // if first part of dot notation is filters, but NOT just filters
            } else if (firstKey === 'filters') {
              setTempSelectedFilterValues((currentFilterValues) => ({
                ...currentFilterValues,
                [botName]: {
                  ...currentFilterValues[botName],
                  [firstKey]: {
                    ...currentFilterValues[botName].filters,
                    [keys[1]]: value as FilterValue,
                  },
                },
              }))
            }
          }
          // maybe something else here
        }
      }
    },
    [
      botName,
      currentConversationID,
      getSelectedFilterValues,
      getSelectedFormValues,
      setSelectedFilterValues,
      setTempSelectedFilterValues,
      updateSelectedFilterValues,
      updateSelectedFormValues,
      validateForm,
    ]
  )

  const getCombinedValues = useCallback(() => {
    return getSelectedFormAndFilterValues(botName, currentConversationID)
  }, [botName, currentConversationID, getSelectedFormAndFilterValues])

  const getCombinedValue = useCallback(
    <K extends keyof BotCombinedValues>(field: K): BotCombinedValues[K] => {
      return getSelectedFormAndFilterValues(botName, currentConversationID)[field]
    },
    [botName, currentConversationID, getSelectedFormAndFilterValues]
  )

  const getFilterValue = useCallback(
    <K extends keyof FilterValue>(filterName: string, field: K): FilterValue[K] => {
      return getSelectedFormAndFilterValues(botName, currentConversationID)?.filters?.[filterName]?.[field]
    },
    [botName, currentConversationID, getSelectedFormAndFilterValues]
  )

  const handleSubmit = useCallback(
    async (userInput?: string) => {
      setFormErrors(defaultFormErrors)

      // because userQuery might not be updated to the latest value, so take the userInput from chatInput instead
      if ((await validateForm('userQuery', userInput)) && handleChatSubmit) {
        const values = getSelectedFormAndFilterValues(botName, currentConversationID)
        handleChatSubmit(
          { ...values, ...(userInput ? { userQuery: userInput } : {}) },
          {
            saveConversation: getConversationSettings(currentConversationID, botName)?.saveConversation ?? true,
          }
        )
      }
    },
    [
      botName,
      currentConversationID,
      getConversationSettings,
      getSelectedFormAndFilterValues,
      handleChatSubmit,
      validateForm,
    ]
  )

  const reset = useCallback(() => {
    if (currentConversationID) {
      deleteSelectedFilterValues([currentConversationID])
      deleteSelectedFormValues([currentConversationID])
    } else {
      deleteTempSelectedFilterValues([botName])
      deleteTempSelectedFormValues([botName])
    }
  }, [
    botName,
    currentConversationID,
    deleteSelectedFilterValues,
    deleteSelectedFormValues,
    deleteTempSelectedFilterValues,
    deleteTempSelectedFormValues,
  ])

  const handleSubmitWithFiles = useCallback(
    async (files: File[]) => {
      setFormErrors(defaultFormErrors)
      if (await validateForm()) {
        handleFileSubmit && handleFileSubmit(getSelectedFormAndFilterValues(botName, currentConversationID), files)
      }
    },
    [botName, currentConversationID, getSelectedFormAndFilterValues, handleFileSubmit, validateForm]
  )

  return (
    <BotSpecificFormContext.Provider
      value={{
        clearError,
        formErrors,
        getCombinedValue,
        getCombinedValues,
        getFilterValue,
        handleSubmit,
        handleSubmitWithFiles,
        reset,
        setValue,
        userQueryInputRef,
      }}
    >
      {children}
    </BotSpecificFormContext.Provider>
  )
}

export const useBotSpecificFormContext = (): BotSpecificFormContextType => useContext(BotSpecificFormContext)
