import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { ChatMessage, ConversationSettings, FEUIConfig, StreamingStep } from 'types/types'

import { useBotContext } from './BotProvider'
import { useConversationSettingsContext } from './ConversationSettingsProvider'
import { useFiltersContext } from './FiltersAndFormsProvider'
import { useKBotContext } from './KBotsProvider'
import { useMessagesContext } from './MessageProvider'
import { useUploadContext } from './UploadProvider'

type BotSpecificContextType = {
  calculatedMaxInput: number
  currentConversationID: string | null
  currentConversationSettings: ConversationSettings | undefined
  errorText: string | null
  hasMounted: boolean
  includeDocument: boolean
  includeLLM: boolean
  isFetchingChart: boolean
  isFetchingKBotData: boolean
  isKBot: boolean
  isAppLoading: boolean
  isLoading: boolean
  isStreaming: boolean
  kBotTemplateId: string | undefined
  messages: ChatMessage[]
  setHasMounted: Dispatch<SetStateAction<boolean>>
  setShowKBotSelection: Dispatch<SetStateAction<boolean>>
  showKBotSelection: boolean
  streamingMessage: ChatMessage | null
  streamingStep: StreamingStep | undefined
  streamingSummary: string | null
  unableToSelectKBot: boolean
} & FEUIConfig

type BotProviderProps = {
  children?: React.ReactNode
  config: FEUIConfig
}

export const BotSpecificContext = createContext<BotSpecificContextType>({} as BotSpecificContextType)

export const BotSpecificProvider = ({ children, config }: BotProviderProps) => {
  const { botName, maxInput, type } = config

  const {
    failedToPreSelectKBot,
    fetchKBotsError,
    isFetchingKBots,
    kBotDetailsForChatUtilization,
    kBotDataValidationError,
  } = useKBotContext()
  const { getSelectedFilterValues } = useFiltersContext()
  const {
    getCurrentConversationIDForBot,
    getMessages,
    isCreatingConversation,
    isCreatingConversationError,
    isFetchingConversationData,
    isFetchingConversationDataError,
    isValidatingConversationDataError,
    streamingMessage,
    streamingSummary,
  } = useMessagesContext()
  const { docContents, tempDocContents } = useUploadContext()
  const { fetchingChartStatuses, streamingStatuses } = useBotContext()
  const { getConversationSettings } = useConversationSettingsContext()
  const { t } = useTranslation(['generic', 'kBots'])

  // This value is used to determine whether we need to show the UI of selecting a new K-Bot
  const [showKBotSelection, setShowKBotSelection] = useState<boolean>(false)

  // This value is used to determine if the user has landed on the page. Currently used to control when we need to show fancy animations
  const [hasMounted, setHasMounted] = useState<boolean>(false)

  // This determines whether we need to run K-Bot specific logic for the bot
  const isKBot = type === 'general'

  const currentConversationID = useMemo(() => {
    return getCurrentConversationIDForBot(botName)
  }, [botName, getCurrentConversationIDForBot])

  const kBotTemplateId = useMemo(() => {
    const kBotDetails = kBotDetailsForChatUtilization[botName]
    return kBotDetails ? kBotDetails.templateId : undefined
  }, [botName, kBotDetailsForChatUtilization])

  const docContentsToUse = useMemo(() => {
    return currentConversationID ? docContents?.[botName]?.[currentConversationID] : tempDocContents[botName]
  }, [botName, currentConversationID, docContents, tempDocContents])

  const selectedBotFilterValues = useMemo(() => {
    return getSelectedFilterValues(botName, currentConversationID)
  }, [botName, currentConversationID, getSelectedFilterValues])

  // This determines for us whether the user wants to look against the content they've uploaded to answer their requests
  const includeDocument = useMemo(() => {
    if (selectedBotFilterValues) {
      for (const [key, filter] of Object.entries(selectedBotFilterValues.filters)) {
        if (key === 'document' && filter.isChecked) {
          return true
        }
      }
    }
    return false
  }, [selectedBotFilterValues])

  // This determines for us whether the user wants to look against the AI Model/LLM to answer their requests
  const includeLLM = useMemo(() => {
    if (selectedBotFilterValues) {
      for (const [key, filter] of Object.entries(selectedBotFilterValues.filters)) {
        if (key === 'llm' && filter.isChecked) {
          return true
        }
      }
    }
    return false
  }, [selectedBotFilterValues])

  // Based on other content alongside the request (e.g., uploaded file content, K-Bot instructions, etc.), determine the maximum input the user can type in for their request
  const calculatedMaxInput = useMemo(() => {
    let limit = maxInput
    if (includeDocument && docContentsToUse) {
      limit -= docContentsToUse.characterCount
    }

    if (isKBot && kBotDetailsForChatUtilization[botName] && kBotDetailsForChatUtilization[botName].fileContent) {
      limit -= kBotDetailsForChatUtilization[botName].fileContent.length
    }

    if (isKBot && kBotDetailsForChatUtilization[botName] && kBotDetailsForChatUtilization[botName].userInstructions) {
      limit -= kBotDetailsForChatUtilization[botName].userInstructions.length
    }
    // Make sure we never return a negative number
    return limit < 0 ? 0 : limit
  }, [botName, docContentsToUse, includeDocument, isKBot, kBotDetailsForChatUtilization, maxInput])

  const isStreaming = useMemo(() => {
    // the value will be null for a conversation that is yet to be mapped to a proper uuid
    return !!streamingStatuses?.[botName]?.status
  }, [botName, streamingStatuses])

  const streamingStep = useMemo(() => {
    // the value will be null for a conversation that is yet to be mapped to a proper uuid
    return streamingStatuses?.[botName]?.step
  }, [botName, streamingStatuses])

  const currentConversationSettings = useMemo(() => {
    return getConversationSettings(currentConversationID, botName)
  }, [botName, currentConversationID, getConversationSettings])

  const isFetchingChart = useMemo(() => {
    return !!fetchingChartStatuses?.[botName]
  }, [botName, fetchingChartStatuses])

  const messages = useMemo(() => {
    return getMessages(botName)
  }, [botName, getMessages])

  /**
   * "isLoading" is passed down to indicate whether something is in a loading state and is used to determine whether or not to disable certain inputs
   *
   * isCreatingConversation[botName]: Are we currently creating a new conversation instance for this bot?
   * currentConversationID && isFetchingConversationData[currentConversationID]: Are we currently fetching the data belonging to a conversationID from the BE?
   * isKBot && isFetchingKBots: Are we currently fetching the available K-Bots that exist for a bot that needs to use K-Bot data?
   * isKBot && kBotDetailsForChatUtilization === null && !failedToPreSelectKBot: Are we currently waiting for a K-Bot to be pre-selected for a bot that needs to use K-Bot data?
   */
  const isLoading = useMemo(() => {
    return (
      isCreatingConversation[botName] ||
      (currentConversationID && isFetchingConversationData[currentConversationID]) ||
      (isKBot && isFetchingKBots) ||
      (isKBot && kBotDetailsForChatUtilization === null && !failedToPreSelectKBot)
    )
  }, [
    isCreatingConversation,
    botName,
    currentConversationID,
    isFetchingConversationData,
    isKBot,
    isFetchingKBots,
    kBotDetailsForChatUtilization,
    failedToPreSelectKBot,
  ])

  /**
   * "isAppLoading" is used to indicate whether the application is in a loading or processing state,
   * preventing user interactions such as button clicks and other UI interactions.
   *
   * isStreaming: Is the app currently processing and streaming a response?
   * isFetchingChart: Are we currently fetching chart data from the backend?
   * isLoading: Is any other part of the app currently in a loading state (e.g., fetching conversations, K-Bot data, etc.)?
   */
  const isAppLoading = useMemo(() => {
    return isStreaming || isFetchingChart || isLoading
  }, [isStreaming, isFetchingChart, isLoading])

  /**
   * "isFetchingKBotData" is passed down to indicate whether we are in a loading state related to K-Bots
   *
   * isKBot && isFetchingKBots: Are we currently fetching the available K-Bots that exist for a bot that needs to use K-Bot data?
   * isKBot && kBotDetailsForChatUtilization === null && !failedToPreSelectKBot: Are we currently waiting for a K-Bot to be pre-selected for a bot that needs to use K-Bot data?
   */
  const isFetchingKBotData = useMemo(() => {
    return (isKBot && isFetchingKBots) || (isKBot && kBotDetailsForChatUtilization === null && !failedToPreSelectKBot)
  }, [isKBot, isFetchingKBots, kBotDetailsForChatUtilization, failedToPreSelectKBot])

  /**
   * This determines the "error" message to display while in a specific conversation in a bot
   *
   * currentConversationID && isValidatingConversationDataError[currentConversationID]: We didn't pass the yup schema in order to create a conversation
   * isCreatingConversationError[botName] || (currentConversationID && isFetchingConversationDataError[currentConversationID]): An issue occurred while attempting to create/load a conversation
   * isKBot && fetchKBotsError === 'fetchKBotsError': We failed to fetch K-Bots for the bot we are on
   * kBotDataValidationError: We failed to validate selecting a K-Bot to use for a chat interaction
   */
  const errorText = useMemo(() => {
    let text = null
    if (currentConversationID && isValidatingConversationDataError[currentConversationID]) {
      text = t('generic.yupValidationError', {
        dataType: isValidatingConversationDataError[currentConversationID],
      })
    }

    if (
      isCreatingConversationError[botName] ||
      (currentConversationID && isFetchingConversationDataError[currentConversationID])
    ) {
      if (!isCreatingConversationError[botName]) {
        text = t('generic.failedFetchingConversationData')
      }

      if (isCreatingConversationError[botName] === 'TOO-MANY-CONVERSATIONS') {
        text = t('generic.tooManyConversations')
      }

      text = t('generic.failedCreatingConversation')
    }
    if (isKBot && fetchKBotsError === 'fetchKBotsError') {
      text = t('kBots.failedFetchingKBots', { ns: 'kBots' })
    }
    if (kBotDataValidationError) {
      text = t('kBots.failedGettingKBots', { ns: 'kBots' })
    }
    return text
  }, [
    currentConversationID,
    isValidatingConversationDataError,
    isCreatingConversationError,
    botName,
    isFetchingConversationDataError,
    isKBot,
    fetchKBotsError,
    kBotDataValidationError,
    t,
  ])

  // This value is used to determine if we need to show fallback UI on a Chat Bot using K-Bots, if:
  // 1. We are on a Chat Bot using K-Bots
  // 2. No value is set for kBotDetailsForChatUtilization[botName], therefore they can't use the General bot
  // 3. We have failed to attempt to pre-select a K-Bot for the user (usually this is the General K-Bot on the initial mount)
  const unableToSelectKBot = useMemo(() => {
    return isKBot && kBotDetailsForChatUtilization[botName] === null && failedToPreSelectKBot
  }, [botName, failedToPreSelectKBot, isKBot, kBotDetailsForChatUtilization])

  return (
    <BotSpecificContext.Provider
      value={{
        ...config,
        calculatedMaxInput,
        currentConversationID,
        currentConversationSettings,
        errorText,
        hasMounted,
        includeDocument,
        includeLLM,
        isFetchingChart,
        isFetchingKBotData,
        isKBot,
        isLoading,
        isAppLoading,
        isStreaming,
        kBotTemplateId,
        messages,
        setHasMounted,
        setShowKBotSelection,
        showKBotSelection,
        streamingMessage: streamingMessage[botName],
        streamingStep,
        streamingSummary: streamingSummary[botName],
        unableToSelectKBot,
      }}
    >
      {children}
    </BotSpecificContext.Provider>
  )
}
export const useBotSpecificContext = (): BotSpecificContextType => useContext(BotSpecificContext)
