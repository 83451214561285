import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Heading } from '@chakra-ui/layout'
import { API } from '@kleo/types'

import { RequiredAsteriskText } from 'components/RequiredAsteriskText'
import { TemperatureSelection } from 'components/settings/TemperatureSelection'

import { GetKBotFormValue, KBotFormState, SetKBotFormValue } from 'types/types'

type KBotTemperatureProps = {
  getKBotFormValue: GetKBotFormValue
  setKBotFormValue: SetKBotFormValue
  shouldInputsBeDisabled: boolean
  state: KBotFormState
}

export const KBotTemperature = ({
  getKBotFormValue,
  setKBotFormValue,
  shouldInputsBeDisabled,
  state,
}: KBotTemperatureProps) => {
  const { t } = useTranslation('settings')

  const handleTemperatureSelect = useCallback(
    (temp: API.OpenAITemperature) => {
      setKBotFormValue('temperature', temp, state)
    },
    [setKBotFormValue, state]
  )

  const temperatureValue = useMemo(() => getKBotFormValue('temperature', state), [getKBotFormValue, state])

  return (
    <>
      <Heading as="h2" size="sm" className="mb-4">
        {t('settings.temperature')} <RequiredAsteriskText showText={false} />
      </Heading>
      <TemperatureSelection
        handleTemperatureSelect={handleTemperatureSelect}
        isDisabled={shouldInputsBeDisabled}
        selectedTemperatureOption={temperatureValue}
      />
    </>
  )
}
