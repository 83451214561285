import { KeyboardEvent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconType } from 'react-icons'
import * as BiIcons from 'react-icons/bi'
import { BiInfoCircle, BiMessageAdd, BiPlus, BiSolidRocket } from 'react-icons/bi'
import Select, { CSSObjectWithLabel } from 'react-select'
import { Accordion, AccordionItem, AccordionPanel } from '@chakra-ui/accordion'
import { useDisclosure } from '@chakra-ui/hooks'
import { Icon } from '@chakra-ui/icon'
import { Box, Flex, Text, VStack } from '@chakra-ui/layout'
import { StarterPrompt } from '@kleo/types'
import cx from 'classnames'
import { TFunction } from 'i18next'
import kleoColors from 'styles/colors'

import { isLanguagePromptsNull } from 'utils/KBotsUtils'

import { useBotSpecificFormContext } from 'providers/FormProvider'
import { useThemeContext } from 'providers/ThemeProvider'

import { PromptLibraryProperty, SearchFilterItem } from 'types/types'

import { useI18Context } from '../providers/i18Provider'

import { KleoAccordionButton as AccordionButton } from './buttons/AccordionButton'
import { TextButton } from './buttons/TextButton'
import { IconAndTextButton } from './Button'
import { ImageWithCache } from './ImageWithCache'
import { Link } from './Link'
import { ModalBox } from './Modal'

type PromptLibraryProps = {
  botName: string
  isReadOnly?: boolean
  variant?: 'horizontal' | 'gridLayout'
} & (
  | {
      promptLibraryArray?: never
      isKBot: false
      kBotIcon?: never
    }
  | {
      promptLibraryArray: StarterPrompt[]
      isKBot: true
      kBotIcon?: string
    }
)

export const PromptLibrary = (props: PromptLibraryProps) => {
  const { botName, isKBot, isReadOnly = false, promptLibraryArray, kBotIcon, variant = 'horizontal' } = props
  const { getTForNS, languageAbbreviation, doesBotTranslationExist } = useI18Context()
  const { t } = useTranslation('promptLibrary')
  const { isTablet, isLightMode } = useThemeContext()
  const [selectedCategory, setSelectedCategory] = useState(0)
  const [selectedSubCategory, setSelectedSubCategory] = useState(0)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const botT = getTForNS(`bot/${botName.toLocaleLowerCase()}`)
  const iconLibrary = BiIcons as Record<string, IconType>

  const promptLibrary = useMemo(() => {
    if (isKBot) return promptLibraryArray || []
    return doesBotTranslationExist('promptLibrary', botName.toLocaleLowerCase())
      ? (botT('promptLibrary', { returnObjects: true }) as PromptLibraryProperty[])
      : []
  }, [isKBot, promptLibraryArray, doesBotTranslationExist, botName, botT])

  // Generate dropdown options based on categories and subcategories
  const dropdownOptions = useMemo(() => {
    const options: SearchFilterItem[] = []
    promptLibrary.forEach((promptItem, index) => {
      const category =
        typeof promptItem.category === 'string' ? promptItem.category : promptItem.category[languageAbbreviation]
      const label = isKBot ? category : botT(category)

      if ('prompts' in promptItem) {
        options.push({ value: `${index}`, label })
      } else if ('subPrompts' in promptItem) {
        promptItem.subPrompts.forEach((subPrompt, subIndex) => {
          const subCategory =
            typeof subPrompt.category === 'string' ? subPrompt.category : subPrompt.category[languageAbbreviation]
          options.push({ value: `${index}-${subIndex}`, label: `${label} - ${botT(subCategory)}` })
        })
      }
    })
    return options
  }, [promptLibrary, isKBot, languageAbbreviation, botT])

  // Generate options for subcategories when a category is selected
  const subCategoryOptions = useMemo(() => {
    const currentCategory = promptLibrary[selectedCategory]
    if (!currentCategory || !('subPrompts' in currentCategory)) return []
    return currentCategory.subPrompts.map((subPrompt, index) => ({
      value: index.toString(),
      label:
        typeof subPrompt.category === 'string'
          ? botT(subPrompt.category)
          : botT(subPrompt.category[languageAbbreviation]),
    }))
  }, [selectedCategory, promptLibrary, botT, languageAbbreviation])

  // Display prompts based on selected category/subcategory
  const getPromptsToDisplay = useMemo(() => {
    const category = promptLibrary[selectedCategory]

    if (!promptLibrary[selectedCategory]) return []

    if ('prompts' in category) return category.prompts

    if ('subPrompts' in category && category.subPrompts.length > 0) {
      return category.subPrompts[selectedSubCategory]?.prompts || []
    }
    return []
  }, [promptLibrary, selectedCategory, selectedSubCategory])

  // Selection handlers
  const handleCategoryChange = (index: number) => {
    setSelectedCategory(index)
    setSelectedSubCategory(0)
  }
  const handleSubCategoryChange = (index: number) => {
    setSelectedSubCategory(index)
  }
  const handleDropdownChange = (value: string) => {
    if (!value) return

    const parts = value.split('-')

    setSelectedCategory(parseInt(parts[0]))
    setSelectedSubCategory(parts.length > 1 ? parseInt(parts[1]) : 0)
  }

  // Define styles
  const accordionClass = 'bg-gray-800 backdrop-blur bg-opacity-[.3] rounded-md first:border-t-0 last:border-b-0 w-full'
  const accordionPanelClass = 'grid grid-cols-1 gap-2 pb-4 xs:grid-cols-2 xl:grid-cols-3'

  const selectStyles = {
    placeholder: (provided: CSSObjectWithLabel) => ({ ...provided, color: kleoColors.gray600 }),
    option: (provided: CSSObjectWithLabel, state: { isSelected: boolean }) => ({
      ...provided,
      cursor: 'pointer',
      fontSize: '14px',
      backgroundColor: state.isSelected ? kleoColors.darkLink : provided.backgroundColor,
    }),
    container: (provided: CSSObjectWithLabel) => ({ ...provided, width: '100%' }),
    noOptionsMessage: (provided: CSSObjectWithLabel) => ({ ...provided, color: 'black', fontSize: '14px' }),
    control: (provided: CSSObjectWithLabel) => ({ ...provided, cursor: 'text', width: '100%' }),
    menuPortal: (provided: CSSObjectWithLabel) => ({ ...provided, zIndex: 9999 }),
  }

  const styles = useMemo(
    () => ({
      disclaimer: cx(
        'mb-4 px-2 md:px-3 py-3 rounded-md inline-block border-l-4 border-opacity-75 border-kpmgPacificBlue',
        {
          'bg-gray-200 backdrop-blur bg-opacity-[.6]': isLightMode,
          'border-kpmgPacificBlue bg-gray-800 backdrop-blur bg-opacity-[.3]': !isLightMode,
        }
      ),
    }),
    [isLightMode]
  )

  if (isLanguagePromptsNull(languageAbbreviation, promptLibrary)) return null
  if (!Array.isArray(promptLibrary) || !promptLibrary.length) return null

  switch (variant) {
    case 'horizontal': {
      // hide accordion if no prompts / sub-prompts for language
      return (
        <>
          {!isReadOnly && <Box className="h-[1px] w-full mx-auto my-4 md:my-8 bg-white bg-opacity-50" />}
          <Box className="flex flex-col items-center">
            {!isKBot && !isReadOnly && (
              <Box className="mb-4 bg-gray-800 backdrop-blur bg-opacity-[.3] px-2 md:px-3 py-1 rounded-md inline-block">
                <Text tabIndex={0} className="text-xs font-bold md:text-sm">
                  {t('promptLibrary.linkVerbiage')}{' '}
                  <Link
                    classToAdd="text-darkLink hover:text-darkLinkHover"
                    external
                    name={t('promptLibrary.library')}
                    url="https://kpmgcan.sharepoint.com/sites/CA-OI-BUS-NMC-Kleo/SitePages/Kleo-prompt-library.aspx"
                  />
                </Text>
              </Box>
            )}
            <Accordion allowToggle className={accordionClass}>
              {promptLibrary.map((promptItem, promptLibraryIndex: number) => {
                // Always prioritize using promptItem.icon as the icon for the PromptLibrary. Otherwise if we are on a K-Bot and promptItem.icon isn't defined, default to using the kBotIcon
                const PromptBiIcon =
                  promptItem.icon && promptItem.icon !== ''
                    ? iconLibrary[promptItem.icon]
                    : isKBot && kBotIcon && kBotIcon !== ''
                      ? iconLibrary[kBotIcon]
                      : undefined
                const category =
                  typeof promptItem.category === 'string'
                    ? promptItem.category
                    : promptItem.category[languageAbbreviation]
                const label = isKBot ? category : botT(category)
                return (
                  <AccordionItem key={`${promptLibraryIndex}_${category}`}>
                    <AccordionIconOrImageButton BiIcon={PromptBiIcon} label={label} isReadOnly={isReadOnly} />
                    <AccordionPanel className={`${'prompts' in promptItem && accordionPanelClass}`}>
                      {'prompts' in promptItem ? (
                        promptItem.prompts.map((promptValue, promptIndex: number) => {
                          const prompt =
                            typeof promptValue === 'string' ? promptValue : promptValue[languageAbbreviation]
                          return prompt ? (
                            <AccordionPrompts
                              botT={botT}
                              index={promptIndex}
                              isReadOnly={isReadOnly}
                              key={`${promptLibraryIndex}_${prompt}_${promptIndex}`}
                              prompt={prompt}
                            />
                          ) : null
                        })
                      ) : (
                        <Accordion allowToggle className={accordionClass}>
                          {promptItem.subPrompts.map((subPromptItem, subPromptIndex: number) => {
                            const SubPromptBiIcon =
                              subPromptItem.icon && subPromptItem.icon !== ''
                                ? iconLibrary[subPromptItem.icon]
                                : undefined
                            const subPromptCategory =
                              typeof subPromptItem.category === 'string'
                                ? subPromptItem.category
                                : subPromptItem.category[languageAbbreviation]
                            const subPromptLabel = isKBot ? subPromptCategory : botT(subPromptCategory)

                            return (
                              <AccordionItem key={`${subPromptIndex}_${subPromptCategory}`}>
                                <AccordionIconOrImageButton
                                  BiIcon={SubPromptBiIcon}
                                  label={subPromptLabel}
                                  isReadOnly={isReadOnly}
                                />
                                <AccordionPanel className={accordionPanelClass}>
                                  {subPromptItem.prompts.map((subPromptValue, promptIndex: number) => {
                                    const subPrompt =
                                      typeof subPromptValue === 'string'
                                        ? subPromptValue
                                        : subPromptValue[languageAbbreviation]
                                    return subPrompt ? (
                                      <AccordionPrompts
                                        botT={botT}
                                        index={promptIndex}
                                        isReadOnly={isReadOnly}
                                        key={`${promptLibraryIndex}_${subPrompt}_${promptIndex}`}
                                        prompt={subPrompt}
                                      />
                                    ) : null
                                  })}
                                </AccordionPanel>
                              </AccordionItem>
                            )
                          })}
                        </Accordion>
                      )}
                    </AccordionPanel>
                  </AccordionItem>
                )
              })}
            </Accordion>
          </Box>
        </>
      )
    }
    case 'gridLayout': {
      const currentCategory = promptLibrary[selectedCategory]
      const hasSubPrompts = currentCategory && 'subPrompts' in currentCategory

      return (
        <>
          <IconAndTextButton
            Icon={BiSolidRocket}
            text={t('promptLibrary.viewPrompts')}
            size={isTablet ? 'base' : 'sm'}
            onClick={onOpen}
          />
          <ModalBox
            isOpen={isOpen}
            modalHeader={t('promptLibrary.header')}
            size="4xl"
            onClose={() => onClose()}
            modalBody={
              <Box>
                <>
                  {!isKBot && !isReadOnly && (
                    <Box className={styles.disclaimer}>
                      <Box className="flex flex-row items-center">
                        <Box className="w-4 h-4 mr-2">
                          <BiInfoCircle className="text-base text-kpmgPacificBlue" />
                        </Box>
                        <Text tabIndex={0} className="text-xs md:text-sm">
                          {t('promptLibrary.linkVerbiage')}{' '}
                          <Link
                            classToAdd="text-darkLink hover:text-darkLinkHover"
                            external
                            name={t('promptLibrary.library')}
                            url="https://kpmgcan.sharepoint.com/sites/CA-OI-BUS-NMC-Kleo/SitePages/Kleo-prompt-library.aspx"
                          />
                        </Text>
                      </Box>
                    </Box>
                  )}

                  <Box className="w-full h-[600px] overflow-hidden">
                    <Flex className="flex-col h-full md:flex-row">
                      {isTablet ? (
                        <GridSidebar
                          promptLibrary={promptLibrary}
                          selectedCategory={selectedCategory}
                          handleCategoryChange={handleCategoryChange}
                          isKBot={isKBot}
                          kBotIcon={kBotIcon}
                          iconLibrary={iconLibrary}
                          botT={botT}
                          languageAbbreviation={languageAbbreviation}
                        />
                      ) : (
                        <Box className="z-50 items-center w-full space-x-4">
                          <Select
                            placeholder={t('promptLibrary.select')}
                            value={dropdownOptions.find((opt) => {
                              const parts = opt.value.split('-')
                              return (
                                parseInt(parts[0]) === selectedCategory &&
                                (parts.length > 1 ? parseInt(parts[1]) === selectedSubCategory : true)
                              )
                            })}
                            onChange={(selected) => selected && handleDropdownChange(selected.value)}
                            options={dropdownOptions}
                            formatOptionLabel={formatOptionLabel}
                            styles={selectStyles}
                          />
                        </Box>
                      )}

                      <Box className="flex-1 px-2 py-4 overflow-y-auto md:p-4">
                        {hasSubPrompts && isTablet && (
                          <Box className="mb-4">
                            <Select
                              name="subPromptCategory"
                              options={subCategoryOptions}
                              formatOptionLabel={formatOptionLabel}
                              value={
                                selectedSubCategory < subCategoryOptions.length
                                  ? {
                                      value: selectedSubCategory.toString(),
                                      label: subCategoryOptions[selectedSubCategory]?.label || '',
                                    }
                                  : null
                              }
                              onChange={(selected) => {
                                if (selected) {
                                  handleSubCategoryChange(Number(selected.value))
                                }
                              }}
                              styles={selectStyles}
                            />
                          </Box>
                        )}

                        <GridPrompts
                          prompts={getPromptsToDisplay}
                          botT={botT}
                          languageAbbreviation={languageAbbreviation}
                          isReadOnly={isReadOnly}
                          onClose={onClose}
                        />
                      </Box>
                    </Flex>
                  </Box>
                </>
              </Box>
            }
          />
        </>
      )
    }
    default:
      return null
  }
}

const AccordionPrompts = (props: {
  botT: TFunction<'translation', undefined>
  index: number
  isReadOnly: boolean
  prompt: string
}) => {
  const { botT, index, isReadOnly, prompt } = props
  const { setValue } = useBotSpecificFormContext()
  const valueToSet = botT(prompt)
  return (
    <Box
      key={`${index}_${prompt}`}
      tabIndex={0}
      className={`relative px-2 py-1 text-black border rounded-br-none rounded-xl bg-kpmgGray5 ${!isReadOnly && 'hover:bg-kpmgGray4 hover:border-kpmgGray4 hover:cursor-pointer '}`}
      onClick={() => !isReadOnly && setValue({ field: 'userQuery', value: valueToSet, shouldValidate: false })}
      onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && !isReadOnly) {
          event.preventDefault()
          setValue({ field: 'userQuery', value: valueToSet, shouldValidate: false })
        }
      }}
    >
      <Text className="pr-4 text-xs">{botT(prompt)}</Text>
      {!isReadOnly && <BiPlus className="absolute right-0.5 bottom-0.5" />}
    </Box>
  )
}
const AccordionIconOrImageButton = (props: { BiIcon: IconType | undefined; label: string; isReadOnly: boolean }) => {
  const { BiIcon, label, isReadOnly } = props

  const { isLightMode } = useThemeContext()

  const imagePath = useMemo(() => {
    return isReadOnly
      ? isLightMode
        ? '../images/robotBlack.png'
        : '../images/robotWhite.png'
      : '../images/robotWhite.png'
  }, [isReadOnly, isLightMode])

  return BiIcon ? (
    <AccordionButton Icon={BiIcon} label={label} iconClass="mr-2 text-lg md:text-xl" />
  ) : (
    <AccordionButton
      Image={<ImageWithCache alt="Robot" className="w-4 h-4 mr-2 md:w-5 md:h-5" imagePath={imagePath} />}
      label={label}
    />
  )
}

const GridSidebar = (props: {
  promptLibrary: { icon?: string; category: string | Record<string, string> }[]
  selectedCategory: number
  handleCategoryChange: (index: number) => void
  isKBot: boolean
  languageAbbreviation: string
  botT: (category: string) => string
  iconLibrary: Record<string, React.ElementType>
  kBotIcon?: string
}) => {
  const {
    promptLibrary,
    selectedCategory,
    handleCategoryChange,
    isKBot,
    languageAbbreviation,
    botT,
    iconLibrary,
    kBotIcon,
  } = props

  const { isLightMode } = useThemeContext()

  const styles = useMemo(
    () => ({
      sidebar: cx('w-56 h-full p-4 md:mr-2 space-y-2 rounded-md', {
        'bg-kpmgGray12 bg-opacity-20 backdrop-blur': !isLightMode,
        'bg-gray-200 bg-opacity-30 backdrop-blur': isLightMode,
      }),
      categoryButton: (isSelected: boolean) =>
        cx(
          'w-full justify-start space-x-2',
          isLightMode
            ? isSelected
              ? 'bg-gray-200'
              : 'hover:bg-gray-200'
            : isSelected
              ? 'bg-gray-700'
              : 'hover:bg-gray-700'
        ),
      categoryIcon: (isSelected: boolean) =>
        cx(
          'text-lg',
          isLightMode
            ? isSelected
              ? 'text-kpmgCobaltBlue'
              : 'text-kpmgPacificBlue'
            : isSelected
              ? 'text-white'
              : 'text-kpmgPacificBlue'
        ),
    }),
    [isLightMode]
  )

  const imagePath = useMemo(() => {
    return isLightMode ? '../images/robotBlack.png' : '../images/robotWhite.png'
  }, [isLightMode])

  return (
    <VStack className={styles.sidebar}>
      {promptLibrary.map((promptItem, promptLibraryIndex) => {
        const PromptBiIcon =
          promptItem.icon && promptItem.icon !== ''
            ? iconLibrary[promptItem.icon]
            : isKBot && kBotIcon && kBotIcon !== ''
              ? iconLibrary[kBotIcon]
              : undefined
        const category =
          typeof promptItem.category === 'string' ? promptItem.category : promptItem.category[languageAbbreviation]
        const label = isKBot ? category : botT(category)

        return (
          <TextButton
            key={`category-${promptLibraryIndex}`}
            variant="ghost"
            size="sm"
            className={styles.categoryButton(selectedCategory === promptLibraryIndex)}
            onClick={() => handleCategoryChange(promptLibraryIndex)}
          >
            {PromptBiIcon ? (
              <Icon as={PromptBiIcon} className={styles.categoryIcon(selectedCategory === promptLibraryIndex)} />
            ) : (
              <ImageWithCache imagePath={imagePath} alt="Robot" className="w-4 h-4 mr-2" />
            )}
            <Text className="text-sm">{label}</Text>
          </TextButton>
        )
      })}
    </VStack>
  )
}

const GridPrompts = (props: {
  prompts: (string | Record<string, string | null>)[]
  languageAbbreviation: string
  botT: (text: string) => string
  isReadOnly: boolean
  onClose?: () => void
}) => {
  const { prompts, languageAbbreviation, botT, isReadOnly, onClose } = props

  const { setValue, userQueryInputRef } = useBotSpecificFormContext()
  const { isLightMode } = useThemeContext()

  const styles = useMemo(
    () => ({
      promptCard: cx(
        'cursor-pointer flex items-center relative py-2 px-4 rounded-xl border font-normal text-left transition-[background] min-h-12',
        {
          'bg-kpmgGray12 bg-opacity-30 text-white hover:bg-gray-700 hover:shadow-xl': !isLightMode,
          'bg-white hover:bg-gray-200 hover:bg-opacity-20 hover:shadow-lg': isLightMode,
        }
      ),
    }),
    [isLightMode]
  )

  return (
    <Box className="grid grid-cols-1 gap-3">
      {prompts.map((promptValue, index) => {
        const prompt = typeof promptValue === 'string' ? promptValue : promptValue[languageAbbreviation]
        const valueToSet = prompt ? botT(prompt) : ''

        return prompt ? (
          <Box
            key={`prompt-${index}`}
            className={styles.promptCard}
            onClick={() => {
              if (!isReadOnly && valueToSet) {
                setValue({ field: 'userQuery', value: valueToSet, shouldValidate: false })
                onClose?.()
                setTimeout(() => {
                  userQueryInputRef?.current?.focus()
                }, 200)
              }
            }}
          >
            <Text className="pr-3 text-xs md:text-sm">{prompt}</Text>
            <span className="flex-shrink-0">
              <BiMessageAdd className="text-lg md:text-xl" />
            </span>
          </Box>
        ) : null
      })}
    </Box>
  )
}

const formatOptionLabel = (data: SearchFilterItem) => (
  <Box className="flex items-center justify-between">
    <span className="text-black">{data.label}</span>
  </Box>
)
