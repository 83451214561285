import { useEffect, useState } from 'react'

import { useThemeContext } from 'providers/ThemeProvider'

export const ChatDotDotDot = () => {
  const { isTablet } = useThemeContext()

  const dotClass = `${isTablet ? 'w-2 h-2 mx-1' : 'w-1.5 h-1.5 mx-[3px]'} bg-gray-600 rounded-full animate-loader`

  return (
    // The animation moves the dots up from their original position, so when using the component, that that into account when applying (external) margins/padding
    <div className="flex mt-2">
      <div className={dotClass} />
      <div className={`${dotClass} animation-delay-200`} />
      <div className={`${dotClass} animation-delay-400`} />
    </div>
  )
}

export const PageDotDotDot = ({ delay = true }) => {
  const { isLightMode, isTablet } = useThemeContext()

  const [showDots, setShowDots] = useState(!delay)

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | undefined
    if (delay) {
      timer = setTimeout(() => {
        setShowDots(true)
      }, 500)
    }
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [delay])

  const dotClass = `rounded-full animate-loader ${isTablet ? 'w-6 h-6' : 'w-5 h-5'} ${isLightMode ? 'bg-kpmgCobaltBlue' : 'bg-kpmgLightPurple'}`

  return (
    <div className="flex items-center justify-center flex-grow">
      {/* The animation moves the dots up from their original position, so when using the component, that that into account when applying (external) margins/padding */}
      <div className={`flex items-center justify-center h-full my-8 ${isTablet ? 'space-x-4' : 'space-x-3'}`}>
        {showDots && (
          <>
            <div className={dotClass} />
            <div className={`${dotClass} animation-delay-200`} />
            <div className={`${dotClass} animation-delay-400`} />
          </>
        )}
      </div>
    </div>
  )
}
