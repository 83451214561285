import { useCallback, useMemo } from 'react'
import { BiDotsHorizontal } from 'react-icons/bi'
import { Navigate, Route, Routes as RouterRoutes, useLocation, useNavigate } from 'react-router-dom'
import { Box } from '@chakra-ui/layout'
import { Menu, MenuItem, MenuList } from '@chakra-ui/menu'
import { Portal } from '@chakra-ui/portal'

import { IconButton } from 'components/buttons/IconButton'
import { MenuButton } from 'components/buttons/MenuButton'

import { useThemeContext } from 'providers/ThemeProvider'

import { TabsType } from 'types/types'

import { Tab } from '../tabs/Tabs'

type KBotsTabsProps = {
  tabSections: TabsType[]
}

export const KBotsTabPanels = ({ tabSections }: KBotsTabsProps) => {
  const location = useLocation()

  return (
    <Box
      className={`flex-grow h-full ${location.pathname === '/k-bots/createKBot' || location.pathname === '/k-bots/editKBot' ? 'overflow-hidden' : 'overflow-x-hidden overflow-y-auto'}`}
    >
      <RouterRoutes>
        <Route path="/" element={<Navigate to="viewKBots" />} />
        {tabSections.map((tabSection) => {
          return (
            <Route
              key={tabSection.route}
              path={tabSection.route}
              element={
                <Box className="flex flex-col items-center justify-start h-full p-2 pl-0">{tabSection.component}</Box>
              }
            />
          )
        })}
      </RouterRoutes>
    </Box>
  )
}

export const KBotsTabs = ({ tabSections }: { tabSections: TabsType[] }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { isMobile, isLightMode } = useThemeContext()

  const handleTabClick = useCallback(
    (route: string) => {
      navigate(`/k-bots${route}`)
    },
    [navigate]
  )

  const tabs = useMemo(() => {
    if (isMobile) {
      const activeTab =
        tabSections.find((section) => {
          return `/k-bots${section.route}` === location.pathname
        }) ?? tabSections[0]

      return {
        visibleTabs: [activeTab],
        hiddenTabs: tabSections.filter((section) => section.route !== activeTab.route),
      }
    } else {
      return {
        visibleTabs: tabSections,
        hiddenTabs: null,
      }
    }
  }, [isMobile, tabSections, location])

  // TODO - Make this a generic component because AssessmentTabs is very very similar
  return (
    <Box
      className={`flex  text-white ${isMobile ? 'flex-col justify-start items-start' : 'flex-row items-center justify-between'}`}
    >
      <Box
        className={`flex -mb-[1px] h-full ${isMobile ? 'justify-start items-center mb-3' : 'items-end justify-start'}`}
      >
        <>
          {tabs.visibleTabs.map((tabSection, tabSectionIndex: number) => (
            <Tab
              handleTabClick={handleTabClick}
              key={`tab-${tabSection.label}-${tabSectionIndex}`}
              parentRouteSection="k-bots"
              tabSection={tabSection}
            />
          ))}
          {tabs.hiddenTabs && (
            <Menu>
              <MenuButton aria-label="other-navigation-options" className="h-full">
                <IconButton
                  size="lg"
                  aria-label="kbots-tabs-menu"
                  iconName={BiDotsHorizontal}
                  iconClassName="text-lg md:text-xl"
                  className={isLightMode ? 'bg-white bg-opacity-80 text-black' : 'bg-kpmgGray4 bg-opacity-25'}
                />
              </MenuButton>
              <Portal>
                <MenuList>
                  {tabs.hiddenTabs.map((tabSection, tabSectionIndex: number) => (
                    <MenuItem
                      onClick={() => handleTabClick(tabSection.route)}
                      key={`tab-hidden-${tabSection.label}-${tabSectionIndex}`}
                    >
                      {tabSection.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </Portal>
            </Menu>
          )}
        </>
      </Box>
    </Box>
  )
}
