import { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { replaceIdInRecordBotSpecific } from 'utils/replaceIdInRecord'

import {
  BotCombinedValues,
  BotFilterOptions,
  BotFilterValues,
  BotFormValues,
  FEUIConfig,
  FilterValue,
} from 'types/types'

// default values for a conversation (form) that is yet to be mapped to a proper uuid
const defaultFormValues = {
  userQuery: '',
  folderNameInput: undefined,
  translationTargetLanguage: undefined,
}

type FiltersContextType = {
  defaultFilterValues: Record<string, BotFilterValues>
  deleteSelectedFilterValues: (conversationIDsToDelete: string[]) => void
  deleteSelectedFormValues: (conversationIDsToDelete: string[]) => void
  deleteTempSelectedFilterValues: (conversationIDsToDelete: string[]) => void
  deleteTempSelectedFormValues: (conversationIDsToDelete: string[]) => void
  getAvailableFilterOptions: (botName: string) => BotFilterOptions | undefined
  getSelectedFormAndFilterValues: (botName: string, conversationID: string | null) => BotCombinedValues
  getIsFetchingDocumentData: (botName: string) => boolean
  getSelectedFilterValues: (botName: string, conversationID: string | null) => BotFilterValues
  getSelectedFormValues: (botName: string, conversationID: string | null) => BotFormValues
  replaceConversationIdInFiltersAndForms: (payload: {
    botName: string
    oldConversationId: string | null
    newConversationId: string
    initializeIfNoBotRecordExists?: boolean
  }) => void
  setSelectedFilterValues: Dispatch<SetStateAction<Record<string, Record<string, BotFilterValues>>>>
  setTempSelectedFilterValues: Dispatch<SetStateAction<Record<string, BotFilterValues>>>
  setTempSelectedFormValues: Dispatch<SetStateAction<Record<string, BotFormValues>>>
  updateAvailableFilterOptions: (botName: string, filterOptions: BotFilterOptions) => void

  updateIsFetchingDocumentData: (botName: string, isFetching: boolean) => void
  updateSelectedFilterAndFormValues: (
    botName: string,
    conversationID: string | null,
    newFilterValues?: BotFilterValues,
    newFormValues?: BotFormValues
  ) => void
  updateSelectedFilterValues: (
    botName: string,
    conversationID: string | null,
    filterValues?: Partial<BotFilterValues>,
    mode?: 'replace' | 'update'
  ) => void
  updateSelectedFormValues: (botName: string, conversationID: string | null, formValues: Partial<BotFormValues>) => void
}

export const FiltersContext = createContext<FiltersContextType>({} as FiltersContextType)

type FiltersAndFormsProviderProps = {
  children?: React.ReactNode
  config: FEUIConfig[] | undefined
}

export const FiltersAndFormsProvider = ({ children, config }: FiltersAndFormsProviderProps) => {
  // Options presented to the user based on selections. Used for remembering state when jumping between pages
  const [availableFilterOptions, setAvailableFilterOptions] = useState<Record<string, BotFilterOptions>>({})
  // Selections made by the user. Used for remembering state when jumping between pages.
  const [selectedFilterValues, setSelectedFilterValues] = useState<Record<string, Record<string, BotFilterValues>>>({})
  const [tempSelectedFilterValues, setTempSelectedFilterValues] = useState<Record<string, BotFilterValues>>({})
  // Selections made by the user. Used for remembering state when jumping between pages
  const [selectedFormValues, setSelectedFormValues] = useState<Record<string, Record<string, BotFormValues>>>({})
  const [tempSelectedFormValues, setTempSelectedFormValues] = useState<Record<string, BotFormValues>>({})
  // These are the values we can use to reset filter values. They are the "empty" or "default" values for each bot's filters
  const [defaultFilterValues, setDefaultFilterValues] = useState<Record<string, BotFilterValues>>({})
  // These are the boolean values that represent whether a bot is currently fetching document data
  const [isFetchingDocumentData, setIsFetchingDocumentData] = useState<Record<string, boolean>>({})

  const defaultValues: {
    defaultFormValues: Record<string, Record<string, BotFormValues>>
    defaultSelectedFilterValues: Record<string, Record<string, BotFilterValues>>
    defaultFilterValues: Record<string, BotFilterValues>
  } = useMemo(() => {
    if (!config) {
      return { defaultFormValues: {}, defaultSelectedFilterValues: {}, defaultFilterValues: {} }
    }

    return config.reduce(
      (acc, botConfig) => ({
        // Initialize the record of form values - this is per-conversation
        defaultFormValues: {
          ...acc.defaultFormValues,
          [botConfig.botName]: {},
        },
        // Default values for filters - this is per-bot
        defaultFilterValues: {
          ...acc.defaultFilterValues,
          [botConfig.botName]: {
            filters:
              botConfig.filters?.reduce<Record<string, FilterValue>>(
                (accu, filter) => {
                  accu[filter.key] = {
                    items: [],
                    isChecked: !!filter.defaultChecked,
                  }
                  return accu
                },
                {
                  // If Model is being set in the bot config, then we also initialize here
                  ...(botConfig.models
                    ? {
                        model: {
                          items: [],
                          isChecked: true,
                        },
                      }
                    : {}),
                }
              ) ?? {},
          },
        },
        // Initialize the record of form values - this is per-conversation
        defaultSelectedFilterValues: {
          ...acc.defaultSelectedFilterValues,
          [botConfig.botName]: {},
        },
      }),
      { defaultFormValues: {}, defaultSelectedFilterValues: {}, defaultFilterValues: {} }
    )
  }, [config])

  const getSelectedFilterValues = useCallback(
    (botName: string, conversationID: string | null): BotFilterValues => {
      if (conversationID) {
        return selectedFilterValues?.[botName]?.[conversationID] ?? defaultValues.defaultFilterValues[botName]
      } else {
        return tempSelectedFilterValues?.[botName]
      }
    },
    [defaultValues.defaultFilterValues, selectedFilterValues, tempSelectedFilterValues]
  )
  const getSelectedFormValues = useCallback(
    (botName: string, conversationID: string | null) => {
      if (conversationID) {
        return selectedFormValues?.[botName]?.[conversationID] ?? defaultFormValues
      } else {
        return tempSelectedFormValues[botName]
      }
    },
    [selectedFormValues, tempSelectedFormValues]
  )
  const getSelectedFormAndFilterValues = useCallback(
    (botName: string, conversationID: string | null): BotFormValues & BotFilterValues => {
      const filterValues = getSelectedFilterValues(botName, conversationID)
      const formValues = getSelectedFormValues(botName, conversationID)
      return { ...filterValues, ...formValues }
    },
    [getSelectedFilterValues, getSelectedFormValues]
  )

  const getAvailableFilterOptions = useCallback(
    (botName: string): BotFilterOptions | undefined => {
      return availableFilterOptions?.[botName]
    },
    [availableFilterOptions]
  )
  const getIsFetchingDocumentData = useCallback(
    (botName: string): boolean => {
      return isFetchingDocumentData[botName] ?? false
    },
    [isFetchingDocumentData]
  )

  /**
   * Updates a conversation's selected filters, if it exists. If it doesn't exist, update the placeholder (temp) conversation for the bot
   * @param {string} botName - The bot to update.
   * @param {string | null} conversationID - The ID of the conversation to update. Pass null if you want to update the placeholder conversation's values.
   * @param {Partial<BotFilterValues>} [filterValues] - (Optional) The values you want to update to. If left out, will update to default values.
   * @param {'replace' | 'update'} [mode='replace'] - (Optional) Mode to determine whether to replace or update the existing filter values.
   */
  const updateSelectedFilterValues = useCallback(
    (
      botName: string,
      conversationID: string | null,
      filterValues?: Partial<BotFilterValues>,
      mode?: 'replace' | 'update'
    ) => {
      const currentMode = mode ?? 'replace'

      if (conversationID) {
        setSelectedFilterValues((currentSelectedFilterValues) => {
          const fallbackValues = filterValues ?? defaultValues.defaultSelectedFilterValues[botName]

          return {
            ...currentSelectedFilterValues,
            [botName]: {
              ...currentSelectedFilterValues[botName],
              [conversationID]: {
                ...(currentMode === 'update'
                  ? {
                      ...currentSelectedFilterValues[botName]?.[conversationID],
                      ...fallbackValues,
                      filters: {
                        ...currentSelectedFilterValues[botName]?.[conversationID]?.filters,
                        ...fallbackValues.filters,
                      },
                    }
                  : {
                      ...fallbackValues,
                      filters: fallbackValues.filters || {}, // Ensure filters is never undefined
                    }), // Replace entire value in 'replace' mode
              },
            },
          }
        })
      } else {
        setTempSelectedFilterValues((currentSelectedFilterValues) => {
          const fallbackValues = filterValues ?? defaultValues.defaultSelectedFilterValues[botName]
          return {
            ...currentSelectedFilterValues,
            [botName]: {
              ...currentSelectedFilterValues[botName],
              ...fallbackValues,
            },
          }
        })
      }
    },
    [defaultValues.defaultSelectedFilterValues]
  )

  /**
   * Updates a conversation's selected form values, if it exists. If it doesn't exist, update the placeholder (temp) conversation for the bot
   * @param {string} botName - The bot to update.
   * @param {string | null} conversationID - The ID of the conversation to update. Pass null if you want to update the placeholder conversation's values.
   * @param {Partial<BotFormValues>} [formValues] - (Optional) The values you want to update to. If left out, will update to default values.
   */
  const updateSelectedFormValues = useCallback(
    (botName: string, conversationID: string | null, formValues: Partial<BotFormValues>) => {
      if (conversationID) {
        setSelectedFormValues((currentSelectedFormValues) => {
          return {
            ...currentSelectedFormValues,
            [botName]: {
              ...currentSelectedFormValues[botName],
              [conversationID]: { ...currentSelectedFormValues[botName][conversationID], ...formValues },
            },
          }
        })
      } else {
        setTempSelectedFormValues((currentTempSelectedFormValues) => {
          return {
            ...currentTempSelectedFormValues,
            [botName]: {
              ...currentTempSelectedFormValues[botName],
              ...formValues,
            },
          }
        })
      }
    },
    []
  )

  const updateAvailableFilterOptions = useCallback((botName: string, filterOptions: BotFilterOptions) => {
    setAvailableFilterOptions((currentAvailableFilterOptions) => {
      return {
        ...currentAvailableFilterOptions,
        [botName]: {
          filters: { ...(currentAvailableFilterOptions[botName]?.filters || {}), ...filterOptions.filters },
        },
      }
    })
  }, [])

  const updateIsFetchingDocumentData = useCallback((botName: string, isFetching: boolean) => {
    setIsFetchingDocumentData((currentIsFetchingDocumentData) => {
      return { ...currentIsFetchingDocumentData, [botName]: isFetching }
    })
  }, [])

  // Based on new filter and form values, update those for the specific conversation ID in the function payload
  const updateSelectedFilterAndFormValues = useCallback(
    (
      botName: string,
      conversationID: string | null,
      newFilterValues?: BotFilterValues,
      newFormValues?: BotFormValues
    ): void => {
      updateSelectedFilterValues(botName, conversationID, newFilterValues || defaultFilterValues[botName])
      updateSelectedFormValues(botName, conversationID, newFormValues || { userQuery: '' })
    },
    [defaultFilterValues, updateSelectedFilterValues, updateSelectedFormValues]
  )

  // Based on conversationIDs in the function payload, delete those Record's selected filter values
  const deleteSelectedFilterValues = useCallback((conversationIDsToDelete: string[]) => {
    setSelectedFilterValues((currentSelectedFilterValues) => {
      const filteredConversations: Record<string, Record<string, BotFilterValues>> = {}

      for (const key in currentSelectedFilterValues) {
        const conversationRecord = currentSelectedFilterValues[key]
        const filteredRecord: Record<string, BotFilterValues> = {}

        for (const numKey in conversationRecord) {
          if (!conversationIDsToDelete.includes(numKey)) {
            filteredRecord[numKey] = conversationRecord[numKey]
          }
        }

        if (Object.keys(filteredRecord).length > 0) {
          filteredConversations[key] = filteredRecord
        }
      }

      return filteredConversations
    })
  }, [])

  // Based on conversation IDs in the function payload, delete those Record's selected form values
  const deleteSelectedFormValues = useCallback((conversationIDsToDelete: string[]) => {
    setSelectedFormValues((currentSelectedFormValues) => {
      const filteredConversations: Record<string, Record<string, BotFormValues>> = {}
      for (const bot in currentSelectedFormValues) {
        const conversationRecord = currentSelectedFormValues[bot]
        const filteredRecord: Record<string, BotFormValues> = {}

        for (const conversationNumber in conversationRecord) {
          if (!conversationIDsToDelete.includes(conversationNumber)) {
            filteredRecord[conversationNumber] = conversationRecord[conversationNumber]
          }
        }
        filteredConversations[bot] = filteredRecord
      }

      return filteredConversations
    })
  }, [])

  // Based on conversationIDs in the function payload, delete those Record's selected filter values
  const deleteTempSelectedFilterValues = useCallback((botNamesToDelete: string[]) => {
    setTempSelectedFilterValues((currentTempSelectedFilterValues) => {
      const filteredConversations: Record<string, BotFilterValues> = {}

      for (const botNameInCurrent in currentTempSelectedFilterValues) {
        if (!botNamesToDelete.includes(botNameInCurrent)) {
          filteredConversations[botNameInCurrent] = currentTempSelectedFilterValues[botNameInCurrent]
        }
      }

      return filteredConversations
    })
  }, [])

  // Based on conversation IDs in the function payload, delete those Record's selected form values
  const deleteTempSelectedFormValues = useCallback((botNamesToDelete: string[]) => {
    setTempSelectedFormValues((currentTempSelectedFormValues) => {
      const filteredConversations: Record<string, BotFormValues> = {}

      for (const botNameInCurrent in currentTempSelectedFormValues) {
        if (!botNamesToDelete.includes(botNameInCurrent)) {
          filteredConversations[botNameInCurrent] = currentTempSelectedFormValues[botNameInCurrent]
        } else {
          filteredConversations[botNameInCurrent] = defaultFormValues
        }
      }
      return filteredConversations
    })
  }, [])

  const replaceConversationIdInFiltersAndForms = (payload: {
    botName: string
    oldConversationId: string | null
    newConversationId: string
    initializeIfNoBotRecordExists?: boolean
  }) => {
    const { botName, initializeIfNoBotRecordExists, oldConversationId, newConversationId } = payload

    replaceIdInRecordBotSpecific({
      botName,
      initializeIfNoBotRecordExists,
      oldConversationId,
      newConversationId,
      setState: setSelectedFormValues,
    })
    replaceIdInRecordBotSpecific({
      botName,
      initializeIfNoBotRecordExists,
      oldConversationId,
      newConversationId,
      setState: setSelectedFilterValues,
    })
  }

  // set default values for all bots right off the bat when FE config gets loaded
  useEffect(() => {
    if (defaultValues && config) {
      setSelectedFormValues(defaultValues.defaultFormValues)
      setSelectedFilterValues(defaultValues.defaultSelectedFilterValues)
      setDefaultFilterValues(defaultValues.defaultFilterValues)
      for (const bot of config) {
        setTempSelectedFormValues((current) => {
          return {
            ...current,
            [bot.botName]: defaultFormValues,
          }
        })
        setTempSelectedFilterValues((current) => {
          return {
            ...current,
            [bot.botName]: {
              filters:
                bot.filters?.reduce<Record<string, FilterValue>>(
                  (accu, filter) => {
                    accu[filter.key] = {
                      items: [],
                      isChecked: !!filter.defaultChecked,
                    }
                    return accu
                  },
                  {
                    ...(bot.models
                      ? {
                          model: {
                            items: [],
                            isChecked: true,
                          },
                        }
                      : {}),
                  }
                ) ?? {},
            },
          }
        })
      }

      setDefaultFilterValues(defaultValues.defaultFilterValues)
    }
  }, [config, defaultValues])

  return (
    <>
      <FiltersContext.Provider
        value={{
          defaultFilterValues,
          deleteSelectedFilterValues,
          deleteSelectedFormValues,
          deleteTempSelectedFilterValues,
          deleteTempSelectedFormValues,
          getAvailableFilterOptions,
          getIsFetchingDocumentData,
          getSelectedFilterValues,
          getSelectedFormAndFilterValues,
          getSelectedFormValues,
          replaceConversationIdInFiltersAndForms,
          setSelectedFilterValues,
          setTempSelectedFilterValues,
          setTempSelectedFormValues,
          updateAvailableFilterOptions,
          updateIsFetchingDocumentData,
          updateSelectedFilterAndFormValues,
          updateSelectedFilterValues,
          updateSelectedFormValues,
        }}
      >
        {children}
      </FiltersContext.Provider>
    </>
  )
}

export const useFiltersContext = (): FiltersContextType => useContext(FiltersContext)
