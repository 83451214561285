import { ReactElement } from 'react'
import { Tooltip, TooltipProps } from '@chakra-ui/tooltip'

import {
  IconAndTextButton as KleoIconAndTextButton,
  IconButton as KleoIconButton,
  TextButton as KleoTextButton,
} from 'components/Button'

import { useThemeContext } from 'providers/ThemeProvider'

import { IconButton } from './IconButton'
import { MenuButton } from './MenuButton'
import { TextButton } from './TextButton'
import { TextIconButton } from './TextIconButton'

type TooltipButtonProps = {
  button:
    | ReactElement<typeof IconButton>
    | ReactElement<typeof MenuButton>
    | ReactElement<typeof TextButton>
    | ReactElement<typeof TextIconButton>
    | ReactElement<typeof KleoIconButton>
    | ReactElement<typeof KleoIconAndTextButton>
    | ReactElement<typeof KleoTextButton>
} & Omit<TooltipProps, 'children'>

export const TooltipButton = (props: TooltipButtonProps) => {
  const { isTablet, isLightMode } = useThemeContext()
  const { button, placement = 'top', isDisabled = !isTablet, ...rest } = props

  return (
    <Tooltip
      {...rest}
      className={`text-xs ${isLightMode ? 'text-white' : 'text-black'}`}
      closeOnScroll
      isDisabled={isDisabled}
      openDelay={200}
      placement={placement}
      tabIndex={0}
    >
      {button}
    </Tooltip>
  )
}
