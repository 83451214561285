import { Fragment, useEffect, useState } from 'react'
import { lazily } from 'react-lazily'
import { Route, Routes as RouterRoutes } from 'react-router-dom'
import { Configuration, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { API } from '@kleo/types'
import { Conversations } from 'pages/Conversations'
import { KBots } from 'pages/KBots'
import { LoginRedirect } from 'pages/LoginRedirect'

import { useScrollToTop } from 'hooks/useScrollToTop'

import { generateRequestId } from 'utils/generateRequestId'
import { fetchData } from 'utils/http/methods'
import { importIfValid } from 'utils/importIfValid'

import { AssessmentProvider } from 'providers/AssessmentProvider'
import { AuthProvider, useAuthContext } from 'providers/AuthProvider'
import { BotProvider } from 'providers/BotProvider'
import { BotSpecificProvider } from 'providers/BotSpecificProvider'
import { ChartProvider } from 'providers/ChartProvider'
import { useConfigContext } from 'providers/ConfigurationProvider'
import { ConversationSettingsProvider } from 'providers/ConversationSettingsProvider'
import { FiltersAndFormsProvider } from 'providers/FiltersAndFormsProvider'
import { useI18Context } from 'providers/i18Provider'
import { KBotsProvider } from 'providers/KBotsProvider'
import { MessagesProvider } from 'providers/MessageProvider'
import { PDFProvider } from 'providers/PDFProvider'
import { ScrollProvider } from 'providers/ScrollProvider'
import { SidebarProvider } from 'providers/SidebarProvider'
import { TermsProvider } from 'providers/TermsProvider'
import { TranslationProvider } from 'providers/TranslationProvider'
import { UIConfigProvider } from 'providers/UIConfigProvider'
import { UploadProvider } from 'providers/UploadProvider'
import { UserProfileProvider } from 'providers/UserProfileProvider'

import { BotFunctions, FEUIConfig } from 'types/types'

import { AuthErrorBoundary } from './APIErrorBoundary'
import { BotRouteWrapper, TermsAndConditions, TermsGuard } from './BotTerms'
import { ErrorBoundaryWithLogger } from './ErrorBoundary'
import { FullSizePageDotDotDot, SuspenseAuthFullPage, SuspenseFullPage, SuspenseRedirectFullPage } from './Fallback'
import { Layout } from './Layout'
import { LoadingInBotsFallback } from './Spinner'

const { About } = lazily(() => import('../pages/About'))
const { General } = lazily(() => import('../pages/General'))
const { Home } = lazily(() => import('../pages/Home'))
const { Knowledge } = lazily(() => import('../pages/Knowledge'))
const { Landing } = lazily(() => import('../pages/Landing'))
const { NotFound } = lazily(() => import('../pages/NotFound'))
const { Assessment } = lazily(() => import('../pages/Assessment'))
const { Translation } = lazily(() => import('../pages/Translation'))

export const App = () => {
  useScrollToTop()

  const config = useConfigContext()
  const [msalInstance, setMsalInstance] = useState<IPublicClientApplication | null>(null)
  // Need to do this check so that we don't run into: "ClientConfigurationError: empty_url_error: url was empty or null."
  // The ClientConfigurationError occurs when the fetch of the config.json doesn't happen fast enough and we provide the PublicClientApplication an empty configuration

  useEffect(() => {
    if (config.isContextReady) {
      const configuration: Configuration = {
        auth: {
          clientId: config.AUTH.CLIENT_ID,
          authority: config.AUTH.AUTHORITY,
          redirectUri: config.AUTH.REDIRECT_URI,
          knownAuthorities: config.AUTH.KNOWN_AUTHORITIES,
        },
        cache: {
          cacheLocation: 'sessionStorage',
        },
      }
      const fetchPcaConfig = async () => {
        try {
          const pcaInstance = await PublicClientApplication.createPublicClientApplication(configuration)
          setMsalInstance(pcaInstance)
        } catch (err) {
          console.error(err)
        }
      }
      fetchPcaConfig()
    }
  }, [
    config.AUTH.AUTHORITY,
    config.AUTH.CLIENT_ID,
    config.AUTH.KNOWN_AUTHORITIES,
    config.AUTH.REDIRECT_URI,
    config.isContextReady,
  ])

  if (!msalInstance) {
    return (
      <Layout>
        <FullSizePageDotDotDot delay={false} />
      </Layout>
    )
  }

  return (
    <AuthErrorBoundary>
      <MsalProvider instance={msalInstance}>
        <AuthProvider>
          <ErrorBoundaryWithLogger>
            <Routes />
          </ErrorBoundaryWithLogger>
        </AuthProvider>
      </MsalProvider>
    </AuthErrorBoundary>
  )
}

const Routes = () => {
  const { API_ENDPOINT, isContextReady } = useConfigContext()
  const { getToken, isAuthenticated } = useAuthContext()
  const [retryBotConfig, setRetryBotConfig] = useState<boolean>(false)
  const [botConfig, setBotConfig] = useState<FEUIConfig[] | undefined>(undefined)
  const [kBotConfig, setKBotConfig] = useState<API.KBotsConfig | undefined>(undefined)
  const [botConfigAPIError, setBotConfigAPIError] = useState<boolean>(false)
  const [fetchingBotConfig, setFetchingBotConfig] = useState<boolean>(false)
  const { loadBotTranslations } = useI18Context()

  useEffect(() => {
    async function fetchFEConfigs() {
      try {
        const authToken = await getToken()

        const { botConfigs, kBotsConfig } = await fetchData<{
          botConfigs: API.FEConfig[]
          kBotsConfig: API.KBotsConfig
        }>({
          url: `${API_ENDPOINT}/chatapi/ui`,
          token: authToken,
          setRetrying: setRetryBotConfig,
          requestId: generateRequestId(),
        })

        const UIBotConfigs: FEUIConfig[] = []

        for await (const bot of botConfigs) {
          await loadBotTranslations(bot.botName)
          try {
            const functions: BotFunctions = await importIfValid<BotFunctions>(
              /* webpackIgnore: true */ `/${bot.botName.toLocaleLowerCase()}/assets/functions.js`
            )
            UIBotConfigs.push({ ...bot, functions })
          } catch (e) {
            const functions: BotFunctions = await import('../utils/defaults/functions')
            UIBotConfigs.push({ ...bot, functions })
          }
        }

        if (kBotsConfig) {
          setKBotConfig(kBotsConfig)
        }

        setBotConfig(UIBotConfigs)
        setBotConfigAPIError(false)
      } catch (error) {
        // We set botConfig to an empty array here to identify that we failed to fetch and produced 0 bots
        setBotConfig([])
        setBotConfigAPIError(true)
      } finally {
        setFetchingBotConfig(false)
      }
    }
    // If we have the context ready, user is authenticated, we aren't currently fetching, and we haven't set any bots: call the function to fetch bot configs
    if (isContextReady && API_ENDPOINT && isAuthenticated && !botConfig && !fetchingBotConfig) {
      setFetchingBotConfig(true)
      fetchFEConfigs()
    }
  }, [API_ENDPOINT, getToken, isAuthenticated, isContextReady, botConfig, fetchingBotConfig, loadBotTranslations])

  return (
    <UIConfigProvider>
      <UserProfileProvider>
        <ConversationSettingsProvider config={botConfig}>
          <FiltersAndFormsProvider config={botConfig}>
            <AssessmentProvider config={botConfig}>
              <UploadProvider config={botConfig}>
                <KBotsProvider kBotConfig={kBotConfig} botConfigs={botConfig}>
                  <MessagesProvider config={botConfig}>
                    <ScrollProvider>
                      <BotProvider config={botConfig}>
                        <PDFProvider>
                          <ChartProvider>
                            <TranslationProvider config={botConfig}>
                              <TermsProvider config={botConfig}>
                                <SidebarProvider config={botConfig}>
                                  <Layout config={botConfig}>
                                    <RouterRoutes>
                                      <Route
                                        path="/"
                                        element={
                                          <SuspenseAuthFullPage>
                                            <Landing />
                                          </SuspenseAuthFullPage>
                                        }
                                      />
                                      <Route
                                        path="/login"
                                        element={
                                          <SuspenseFullPage>
                                            <LoginRedirect />
                                          </SuspenseFullPage>
                                        }
                                      />
                                      <Route
                                        path="/about"
                                        element={
                                          <SuspenseAuthFullPage>
                                            <About />
                                          </SuspenseAuthFullPage>
                                        }
                                      />
                                      <Route
                                        path="/home"
                                        element={
                                          <SuspenseRedirectFullPage>
                                            <Home
                                              config={botConfig}
                                              apiError={botConfigAPIError}
                                              retryAPI={retryBotConfig}
                                            />
                                          </SuspenseRedirectFullPage>
                                        }
                                      />
                                      <Route
                                        path="/conversations"
                                        element={
                                          <SuspenseRedirectFullPage>
                                            <Conversations config={botConfig} />
                                          </SuspenseRedirectFullPage>
                                        }
                                      />
                                      {botConfig &&
                                        botConfig.map((config) => (
                                          <Fragment key={config.route}>
                                            {/* Add the terms route if acknowledgment is required */}
                                            {config.isAcknowledgement && (
                                              <Route
                                                key={`${config.route}-terms`}
                                                path={`${config.route}/terms`}
                                                element={
                                                  <SuspenseRedirectFullPage>
                                                    <TermsAndConditions config={config} />
                                                  </SuspenseRedirectFullPage>
                                                }
                                              />
                                            )}
                                            <Route
                                              path={`${config.route}/*`}
                                              element={
                                                config.functions ? (
                                                  <SuspenseRedirectFullPage>
                                                    <BotSpecificProvider config={config}>
                                                      <BotRouteWrapper config={config}>
                                                        <TermsGuard config={config}>
                                                          {(() => {
                                                            switch (config.type) {
                                                              case 'general':
                                                                return <General />
                                                              case 'knowledge':
                                                                return <Knowledge />
                                                              case 'assessment':
                                                                return <Assessment />
                                                              case 'translation':
                                                                return <Translation />
                                                              default:
                                                                return null // Handle any other cases if necessary
                                                            }
                                                          })()}
                                                        </TermsGuard>
                                                      </BotRouteWrapper>
                                                    </BotSpecificProvider>
                                                  </SuspenseRedirectFullPage>
                                                ) : null
                                              }
                                            />
                                          </Fragment>
                                        ))}
                                      {kBotConfig && (
                                        <Route
                                          path="/k-bots/*"
                                          element={
                                            <SuspenseRedirectFullPage>
                                              <KBots />
                                            </SuspenseRedirectFullPage>
                                          }
                                        />
                                      )}
                                      {botConfig ? (
                                        // If we have fetched bot config, show a 404 error for all routes that haven't been defined
                                        <Route
                                          path="*"
                                          element={
                                            <SuspenseAuthFullPage>
                                              <NotFound />
                                            </SuspenseAuthFullPage>
                                          }
                                        />
                                      ) : (
                                        // If we have not fetched bot config, show a Spinner while we are in the middle of the fetch
                                        <Route
                                          path="*"
                                          element={
                                            <SuspenseAuthFullPage>
                                              <LoadingInBotsFallback />
                                            </SuspenseAuthFullPage>
                                          }
                                        />
                                      )}
                                    </RouterRoutes>
                                  </Layout>
                                </SidebarProvider>
                              </TermsProvider>
                            </TranslationProvider>
                          </ChartProvider>
                        </PDFProvider>
                      </BotProvider>
                    </ScrollProvider>
                  </MessagesProvider>
                </KBotsProvider>
              </UploadProvider>
            </AssessmentProvider>
          </FiltersAndFormsProvider>
        </ConversationSettingsProvider>
      </UserProfileProvider>
    </UIConfigProvider>
  )
}
