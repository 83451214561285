import { ChangeEvent, Dispatch, SetStateAction, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconType } from 'react-icons'
import * as BiIcons from 'react-icons/bi'
import { useLocation, useNavigate } from 'react-router-dom'
import { Checkbox } from '@chakra-ui/checkbox'
import { useDisclosure } from '@chakra-ui/hooks'
import { Box, Text } from '@chakra-ui/layout'
import { Spinner } from '@chakra-ui/spinner'
import { Tooltip } from '@chakra-ui/tooltip'

import { BotIconWithFallback } from 'components/BotIconWithFallback'
import { TextButton } from 'components/Button'
import { TooltipButton } from 'components/buttons/TooltipButton'
import { ConfirmDeleteConversationModal } from 'components/ConfirmDeleteConversation'
import { GradientHeaderTextWrapper } from 'components/GradientHeaderText'

import { useBotContext } from 'providers/BotProvider'
import { useI18Context } from 'providers/i18Provider'
import { useKBotContext } from 'providers/KBotsProvider'
import { ConversationType, useMessagesContext } from 'providers/MessageProvider'
import { useThemeContext } from 'providers/ThemeProvider'

import { FEUIConfig } from 'types/types'

type ChatBotType = {
  botName: string
  header: string
  Icon: IconType | undefined
  type: string
}

// Used to resolve a WAVE error by adding an invisible label to the checkbox
const checkboxPlaceholder = 'checkbox'

export const Conversations = ({ config }: { config: FEUIConfig[] | undefined }) => {
  const { isLightMode } = useThemeContext()
  const navigate = useNavigate()
  const location = useLocation()
  const { doesTranslationExist } = useI18Context()
  const { t } = useTranslation(['generic', 'settings', 'controls'])
  const {
    botList,
    conversations,
    getNumberOfConversations,
    fetchingBotConversationsError,
    getBotConversationsWithId,
    isFetchingBotConversations,
    deletingConversationError,
  } = useMessagesContext()
  const { isOpen, onClose, onOpen } = useDisclosure()

  const [selectedConversations, setSelectedConversations] = useState<string[]>([])

  const chatBots = useMemo(() => {
    const loadBots = (botConfig: FEUIConfig[]) => {
      const newBots: ChatBotType[] = []

      for (const conf of botConfig) {
        let Icon: IconType | undefined = undefined

        if (conf.icon) {
          const BiIcon = (BiIcons as Record<string, IconType>)[conf.icon]
          // Check if the component exists in the 'react-icons' library
          if (BiIcon) {
            Icon = BiIcon
          }
        }

        if (typeof conf.botName === 'string' && !conf.isHidden) {
          newBots.push({
            Icon: Icon,
            botName: conf.botName,
            header: 'home.header',
            type: conf.type,
          })
        }
      }

      const sortOrder = ['general', 'translation', 'assessment', 'knowledge']

      const sortedBots = newBots.sort((a, b) => {
        return sortOrder.indexOf(a.type) - sortOrder.indexOf(b.type)
      })

      return sortedBots
    }

    if (config) {
      return loadBots(config)
    } else return []
  }, [config])

  const numberOfConversations = useMemo(() => {
    return getNumberOfConversations()
  }, [getNumberOfConversations])

  // Since we fetch the count of conversations from the BE including the ones for bots that aren't visible, we need to take those out of consideration for showing the "no conversations" message.
  // botList is the list of available bots
  const noVisibleConversations = useMemo(() => {
    let numConversations = 0
    botList.forEach((botName) => {
      if (conversations[botName]) {
        numConversations += Object.keys(conversations[botName]).length
      }
    })
    return numConversations === 0
  }, [botList, conversations])

  const deletingConversationErrorMessage = useMemo(() => {
    return t(`settings.error.${deletingConversationError}`, { ns: 'settings' })
  }, [t, deletingConversationError])

  return (
    <Box
      backgroundImage={`${
        isLightMode
          ? 'linear-gradient(rgba(222, 222, 222, 0.8), rgba(222, 222, 222, 0.8)), url(../images/geometry.webp)'
          : 'linear-gradient(rgba(41, 41, 41, 0.8), rgba(41, 41, 41, 0.8)), url(../images/geometry.webp)'
      }`}
      className="flex flex-col h-full overflow-x-hidden bg-center bg-cover"
    >
      <ConfirmDeleteConversationModal
        conversationsToDelete={selectedConversations}
        isOpen={isOpen}
        onClose={onClose}
        onDelete={() => setSelectedConversations([])}
        showConversationCount={true}
        botNames={chatBots
          .map((bot) => bot.botName)
          .filter((botName) => !!(conversations[botName] && Object.keys(conversations[botName]).length))}
      />
      <Box className="flex flex-col items-center justify-start gap-2 my-2 width-layout">
        <Box className="flex flex-col justify-start w-full mt-2 2xl:mt-0">
          <GradientHeaderTextWrapper>
            <h1 className="mb-2 text-3xl md:text-4xl">{t('generic.manageConversations')}</h1>
          </GradientHeaderTextWrapper>
          <Text className="text-sm 2xl:text-left md:text-base" test-id="conversation-count">
            {t('generic.manageConversationsDetails')}
          </Text>
        </Box>
      </Box>
      <Box className={`width-layout border-b opacity-50 ${isLightMode ? 'border-kpmgGray2' : 'border-kpmgGray4'}`} />
      <Box className="relative flex flex-col flex-grow overflow-hidden">
        <Box className="flex flex-col flex-grow overflow-hidden width-layout">
          <Box className="h-full overflow-y-auto">
            <Box className="flex flex-col items-center justify-center gap-4 py-4">
              {isFetchingBotConversations && <Spinner className="mt-4" size="lg" />}
              {!isFetchingBotConversations &&
              !fetchingBotConversationsError &&
              (numberOfConversations === 0 || noVisibleConversations) ? (
                <Text>{t('generic.conversationsEmpty')}</Text>
              ) : fetchingBotConversationsError ? (
                <Box
                  className={`shadow-xl min-w-min flex items-center flex-col rounded m-4 md:m-8 ${
                    isLightMode ? 'bg-white bg-opacity-80' : 'bg-kpmgDarkBlue bg-opacity-60'
                  }`}
                >
                  <Box className="flex flex-col p-4 text-center md:p-6">
                    <Text className="mb-2 text-red-600 md:text-lg">{t('generic.errorFetchingConversations')}</Text>
                    <Text className={`md:text-lg ${isLightMode ? 'text-black' : 'text-white'}`}>
                      {t('generic.failedFetchingConversations')}
                    </Text>
                  </Box>
                </Box>
              ) : (
                !isFetchingBotConversations && (
                  <>
                    {chatBots.map((bot) => {
                      const botConversations = getBotConversationsWithId(bot.botName)
                      if (botConversations.length) {
                        return (
                          <ChatBotConversationsItem
                            botName={bot.botName}
                            header={bot.header}
                            Icon={bot.Icon}
                            type={bot.type}
                            key={`${bot.botName}-conversations`}
                            conversations={botConversations}
                            setSelectedConversations={setSelectedConversations}
                            selectedConversations={selectedConversations}
                          />
                        )
                      } else return null
                    })}
                  </>
                )
              )}
            </Box>
          </Box>
          <Box className={`border-b opacity-50 ${isLightMode ? 'border-kpmgGray2' : 'border-kpmgGray4'}`} />
        </Box>
        {doesTranslationExist(`settings.error.${deletingConversationError}`, 'settings') && (
          <Box className="flex flex-row justify-end mt-4 width-layout">
            <Text as="span" className={`block text-sm ${isLightMode ? 'text-red-600' : 'text-red-400'}`}>
              {deletingConversationErrorMessage}
            </Text>
          </Box>
        )}
        <Box className="flex flex-row justify-end gap-2 my-4 mb-4 width-layout min-h-min">
          <TextButton
            disabled={!selectedConversations.length || isFetchingBotConversations || fetchingBotConversationsError}
            onClick={onOpen}
            text={t('controls.deleteSelected', { ns: 'controls' })}
          />
          <TextButton
            disabled={
              !numberOfConversations ||
              isFetchingBotConversations ||
              fetchingBotConversationsError ||
              noVisibleConversations
            }
            onClick={() => {
              const newSelections: string[] = []
              chatBots.forEach((bot) => {
                const botConversations = getBotConversationsWithId(bot.botName)
                if (botConversations) {
                  Object.values(botConversations).forEach((conversation) => {
                    newSelections.push(conversation.conversationID)
                  })
                }
              })
              setSelectedConversations(newSelections)
              onOpen()
            }}
            text={t('controls.deleteAll', { ns: 'controls' })}
          />
          <Box className="pr-0 ml-1 border-l border-kpmgGray4">&nbsp;</Box>
          <TooltipButton
            button={
              <TextButton
                onClick={() => {
                  if (location.state?.allowReturn) {
                    navigate(-1)
                  } else {
                    navigate('/home')
                  }
                }}
                text={t('controls.cancel', { ns: 'controls' })}
              />
            }
            label={t('generic.cancelManage')}
          />
        </Box>
      </Box>
    </Box>
  )
}

type ChatBotConversationsItemProps = {
  Icon: IconType | undefined
  header: string
  botName: string
  type: string
  conversations: Array<
    ConversationType & {
      conversationID: string
    }
  >
  selectedConversations: string[]
  setSelectedConversations: Dispatch<SetStateAction<string[]>>
  key: string
}

export const ChatBotConversationsItem = (props: ChatBotConversationsItemProps) => {
  const { botName, conversations, header, Icon, key, selectedConversations, setSelectedConversations, type } = props
  const { isLightMode } = useThemeContext()
  const { listOfConversationsBeingDeleted } = useMessagesContext()

  const { kBotData } = useKBotContext()

  const { getStreamingStatus } = useBotContext()

  const { languageAbbreviation, getTForNS } = useI18Context()
  const { t } = useTranslation('kBots')

  const botT = getTForNS(`bot/${botName.toLocaleLowerCase()}`)

  const disableBotConversationDelete = useMemo(() => {
    return !!getStreamingStatus(botName)?.status || !!listOfConversationsBeingDeleted.length
  }, [botName, getStreamingStatus, listOfConversationsBeingDeleted.length])

  const getStripeColor = () => {
    if (type === 'general') {
      return 'bg-green-500'
    } else if (type === 'knowledge') {
      return 'bg-kpmgPacificBlue'
    }
    return 'bg-kpmgPurple'
  }

  const someSelected = useMemo(() => {
    return conversations.map((conv) => conv.conversationID).some((id) => selectedConversations.includes(id))
  }, [conversations, selectedConversations])

  const allSelected = useMemo(() => {
    return conversations.map((conv) => conv.conversationID).every((id) => selectedConversations.includes(id))
  }, [conversations, selectedConversations])

  const handleSelectAllBotConversationsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedConversations((current) => {
      if (e.target.checked) {
        const newSelections = [...current]
        conversations.forEach((conversation) => {
          if (!current.includes(conversation.conversationID)) {
            newSelections.push(conversation.conversationID)
          }
        })
        return newSelections
      } else {
        const newSelections = [...current]
        const botConversationIds = conversations.map((conversation) => conversation.conversationID)

        return newSelections.filter((idInCurrent) => !botConversationIds.includes(idInCurrent))
      }
    })
  }

  const handleConversationClick = (conversationID: string) => {
    setSelectedConversations((current) => {
      if (!selectedConversations.includes(conversationID)) {
        const newSelections = [...current]
        newSelections.push(conversationID)
        return newSelections
      } else {
        const newSelections = [...current]
        return newSelections.filter((idInCurrent) => conversationID !== idInCurrent)
      }
    })
  }

  const handleKbotClick = (
    kBotConversations: Array<ConversationType & { conversationID: string }>,
    isAllSelected: boolean
  ) => {
    setSelectedConversations((current) => {
      if (!isAllSelected) {
        const newSelections = [...current]
        kBotConversations.forEach((conversation) => {
          if (!current.includes(conversation.conversationID)) {
            newSelections.push(conversation.conversationID)
          }
        })
        return newSelections
      } else {
        const newSelections = [...current]
        const botConversationIds = kBotConversations.map((conversation) => conversation.conversationID)
        return newSelections.filter((idInCurrent) => {
          return !botConversationIds.includes(idInCurrent)
        })
      }
    })
  }

  return (
    <Box
      className="relative flex flex-row w-full h-full overflow-x-hidden overflow-y-hidden transition rounded-lg shadow-lg"
      tabIndex={0}
      key={key}
    >
      <Box className={`absolute left-0 top-0 w-2 h-full rounded-l-lg ${getStripeColor()}`}>&nbsp;</Box>
      <Box
        className={`${isLightMode ? 'bg-white hover:bg-whiteHover' : 'bg-kpmgGray1 hover:bg-kpmgGray1Hover'} flex flex-col justify-between flex-1 p-3 pl-4 border border-l-0 rounded-r-lg md:p-4 md:pl-6 border-box`}
      >
        <Box>
          <Box className="flex flex-row items-start justify-between mb-2 space-x-3 space-y-0">
            <Box className="flex flex-row items-center gap-2">
              <Box className="flex-shrink-0">
                <BotIconWithFallback
                  alt={`${botName}-icon`}
                  Icon={Icon}
                  botName={botName}
                  botType={type}
                  className="w-6 h-6 md:w-8 md:h-8"
                />
              </Box>
              <Text id="select-all-bot-conversations" as="h2" className="text-base font-black text-left md:text-lg">
                {botT(header)}
              </Text>
            </Box>
            <Checkbox
              aria-label="select-all-bot-conversations"
              isDisabled={disableBotConversationDelete}
              onChange={(e) => {
                if (!disableBotConversationDelete) {
                  handleSelectAllBotConversationsChange(e)
                }
              }}
              isIndeterminate={someSelected && !allSelected}
              isChecked={allSelected}
            >
              <Box id="select-all-bot-conversations" className="sr-only">
                {checkboxPlaceholder}
              </Box>
            </Checkbox>
          </Box>
        </Box>
        <Box>
          {type === 'general' ? (
            Object.entries(
              conversations.reduce<{ [key: string]: Array<ConversationType & { conversationID: string }> }>(
                (acc, conversation) => {
                  const { conversationID, template, templateId } = conversation
                  const groupKey =
                    (template?.[languageAbbreviation] ||
                      // If we don't have a template, try to match the ID to an available K-Bot
                      kBotData?.botConfigs.find((config) => config.templateId === templateId)?.template?.[
                        languageAbbreviation
                      ]) ??
                    'noTemplate'
                  if (!acc[groupKey]) {
                    acc[groupKey] = []
                  }
                  if (conversationID) {
                    acc[groupKey].push(conversation)
                  }
                  return acc
                },
                {}
              )
            ).map(([template, conversationList], kbotIndex) => {
              const someKbotConvSelected = conversationList
                .map((conv) => conv.conversationID)
                .some((id) => selectedConversations.includes(id))
              const allKbotConvSelected = conversationList
                .map((conv) => conv.conversationID)
                .every((id) => selectedConversations.includes(id))

              return (
                <Box key={template} className={kbotIndex === 0 ? 'my-4' : 'mb-4'}>
                  {/* Section heading for templateId */}
                  <Box className="flex flex-row items-center justify-start mb-2 ">
                    <Text
                      as="span"
                      className={`pr-2 text-xs font-bold cursor-pointer ${disableBotConversationDelete && 'cursor-not-allowed'}`}
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        if (!disableBotConversationDelete) {
                          handleKbotClick(conversationList, allKbotConvSelected)
                        }
                      }}
                    >
                      {template === 'noTemplate' ? t('kBots.other') : template || t('kBots.failedToFindKBotName')}
                    </Text>
                    <Checkbox
                      aria-label="select-all-kbot-conversations"
                      isDisabled={disableBotConversationDelete}
                      onChange={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        if (!disableBotConversationDelete) {
                          handleKbotClick(conversationList, allKbotConvSelected)
                        }
                      }}
                      isIndeterminate={someKbotConvSelected && !allKbotConvSelected}
                      isChecked={allKbotConvSelected}
                    >
                      <Box id="select-all-kbot-conversations" className="sr-only">
                        {checkboxPlaceholder}
                      </Box>
                    </Checkbox>
                  </Box>
                  <Box className="flex flex-row flex-wrap gap-4">
                    {/* Render conversations for this templateId */}
                    {conversationList.map((item, conversationListIndex) => {
                      const { conversationID, displayName } = item
                      if (displayName) {
                        return (
                          <ConversationListItem
                            disabled={disableBotConversationDelete}
                            displayName={displayName}
                            conversationID={conversationID}
                            conversationListIndex={conversationListIndex}
                            handleConversationClick={handleConversationClick}
                            selectedConversations={selectedConversations}
                          />
                        )
                      }
                      return null
                    })}
                  </Box>
                </Box>
              )
            })
          ) : (
            <Box className="flex flex-row flex-wrap gap-4 mt-4">
              {conversations.map((item, conversationListIndex) => {
                const { conversationID, displayName } = item
                if (displayName) {
                  return (
                    <ConversationListItem
                      disabled={disableBotConversationDelete}
                      displayName={displayName}
                      conversationID={conversationID}
                      conversationListIndex={conversationListIndex}
                      handleConversationClick={handleConversationClick}
                      selectedConversations={selectedConversations}
                    />
                  )
                }
                return null
              })}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

type ConversationListItemType = {
  disabled: boolean
  displayName: string
  conversationID: string
  conversationListIndex: number
  handleConversationClick: (conversationID: string) => void
  selectedConversations: string[]
}

const ConversationListItem = ({
  disabled,
  displayName,
  conversationID,
  conversationListIndex,
  handleConversationClick,
  selectedConversations,
}: ConversationListItemType) => {
  const { isLightMode } = useThemeContext()
  const { listOfConversationsBeingDeleted } = useMessagesContext()
  const { t } = useTranslation('generic')
  return (
    <Tooltip
      label={disabled ? t('generic.currentlyStreaming') : displayName}
      placement="top-start"
      className="text-xs text-black bg-kpmgGray5 line-clamp-3"
    >
      <Box
        className={`flex justify-between mb-2 text-xs font-normal text-left border rounded-md md:text-sm px-2 py-2.5 cursor-pointer ${isLightMode ? 'border-kpmgGray4 bg-whiteHover hover:bg-white' : 'bg-kpmgGray1Hover hover:bg-kpmgGray1'}
        ${disabled && 'cursor-not-allowed'}`}
        key={`${conversationID}_${conversationListIndex}`}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          if (!disabled) {
            handleConversationClick(conversationID)
          }
        }}
      >
        <Text className="pr-2 truncate max-w-48 whitespace-nowrap">{displayName}</Text>
        {listOfConversationsBeingDeleted.includes(conversationID) ? (
          <Spinner size="sm" />
        ) : (
          <Checkbox
            aria-label="select-conversation"
            isDisabled={disabled}
            onChange={(e) => {
              e.stopPropagation()
              e.preventDefault()
              if (!disabled) {
                handleConversationClick(conversationID)
              }
            }}
            isChecked={selectedConversations.includes(conversationID)}
          >
            <Box id="select-conversation" className="sr-only">
              {checkboxPlaceholder}
            </Box>
          </Checkbox>
        )}
      </Box>
    </Tooltip>
  )
}
