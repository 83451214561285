import { ChangeEvent, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FormLabel } from '@chakra-ui/form-control'
import { Input, InputGroup, InputLeftAddon } from '@chakra-ui/input'
import { Box, Heading, Stack, StackDivider, Text } from '@chakra-ui/layout'
import { VisuallyHidden } from '@chakra-ui/visually-hidden'

import { InfoTooltip } from 'components/InfoTooltip'

import { useKBotContext } from 'providers/KBotsProvider'
import { useThemeContext } from 'providers/ThemeProvider'

import { KBotFormState, KBotFormValues, SetKBotFormValue, ValidationErrors } from 'types/types'

export const KBotDescriptions = ({
  formErrors,
  maxLength,
  setKBotFormValue,
  shouldInputsBeDisabled,
  state,
}: {
  formErrors: ValidationErrors<KBotFormValues>
  maxLength: number
  setKBotFormValue: SetKBotFormValue
  shouldInputsBeDisabled: boolean
  state: KBotFormState
}) => {
  const { t } = useTranslation('kBots')
  const { isLightMode } = useThemeContext()
  const { getKBotFormValue } = useKBotContext()

  const handleInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>, lang: 'en' | 'fr', index: number) => {
      setKBotFormValue(
        'description',
        (prevDesc) => {
          // If there's no array or an empty one, initialize with a minimal shape
          if (!prevDesc || prevDesc.length === 0) {
            return [{ en: '', fr: '', [lang]: e.target.value }]
          }
          // Otherwise, map as usual
          return prevDesc.map((desc, i) => (i === index ? { ...desc, [lang]: e.target.value } : desc))
        },
        state
      )
    },
    [setKBotFormValue, state]
  )

  const safeDescriptions = useMemo(() => {
    const value = getKBotFormValue('description', state)
    // When initially loading a selected K-Bots details, have a fallback of an empty state to render on screen
    return value.length ? value : [{ en: '', fr: '' }]
  }, [getKBotFormValue, state])

  return (
    <>
      <Box className="flex items-center justify-start mb-4">
        <Heading as="h2" size="sm" className="mr-2" id="kbot-description-heading">
          {t('kBots.createEdit.describeKBot')}
        </Heading>
        <InfoTooltip
          label={
            <>
              <Text>{t('kBots.createEdit.descriptionExplanation')}</Text>
              <Text>{t('kBots.createEdit.descriptionExplanation2')}</Text>
            </>
          }
          placement="right"
          aria-describedby="kbot-description-heading"
          iconClassName="self-end w-4 h-4 text-gray-600"
        />
      </Box>
      {formErrors.description && (
        <Text as="span" className={`block mb-2 text-sm ${isLightMode ? 'text-red-600' : 'text-red-400'}`}>
          {t(formErrors.description)}
        </Text>
      )}
      {safeDescriptions.map((description, index) => (
        <Box key={`description-input-${index}`} className="border rounded-md border-kpmgGray3 h-[51px] mb-3">
          <Stack spacing={0} divider={<StackDivider />}>
            <InputGroup size="xs">
              <VisuallyHidden>
                <FormLabel htmlFor={`kbot-description-en-${index}`}>
                  {`${t('kBots.createEdit.languageEN')} ${t('kBots.createEdit.describeKBot')}`}
                </FormLabel>
              </VisuallyHidden>
              <InputLeftAddon className="flex items-center justify-center uppercase rounded-bl-none rounded-tl-md min-w-11">
                en
              </InputLeftAddon>
              <Input
                id={`kbot-description-en-${index}`}
                name="kBotDescription"
                value={description.en ?? ''}
                onChange={(e) => handleInput(e, 'en', index)}
                maxLength={maxLength}
                variant="unstyled"
                className="pl-3"
                placeholder={`${t('kBots.createEdit.typeSomething')} (${t('kBots.createEdit.languageEN')})`}
                isDisabled={shouldInputsBeDisabled}
              />
            </InputGroup>
            <InputGroup size="xs">
              <VisuallyHidden>
                <FormLabel htmlFor={`kbot-description-fr-${index}`}>
                  {`${t('kBots.createEdit.languageFR')} ${t('kBots.createEdit.describeKBot')}`}
                </FormLabel>
              </VisuallyHidden>
              <InputLeftAddon className="flex items-center justify-center uppercase rounded-tl-none rounded-bl-md min-w-11">
                fr
              </InputLeftAddon>
              <Input
                id={`kbot-description-fr-${index}`}
                name="kBotDescription"
                value={description.fr ?? ''}
                onChange={(e) => handleInput(e, 'fr', index)}
                maxLength={maxLength}
                variant="unstyled"
                className="pl-3"
                placeholder={`${t('kBots.createEdit.typeSomething')} (${t('kBots.createEdit.languageFR')})`}
                isDisabled={shouldInputsBeDisabled}
              />
            </InputGroup>
          </Stack>
        </Box>
      ))}
    </>
  )
}
