import { useTranslation } from 'react-i18next'
import { Box, Text } from '@chakra-ui/layout'

import { useThemeContext } from 'providers/ThemeProvider'

import { TextButton } from './Button'

export const ClearFilesBody = () => {
  const { t } = useTranslation('clearFilesModal')
  return (
    <Box>
      <Text as="h2" className="mb-2 font-bold">
        {t('modal.areYouSureClearFiles')}
      </Text>
      <Text as="p">{t('modal.clearFilesExplanation')}</Text>
    </Box>
  )
}

type ClearFilesProps = {
  isButtonDisabled: boolean
  onClose: () => void
  primaryButtonOnClick: () => void
}

export const ClearFilesFooter = (props: ClearFilesProps) => {
  const { isButtonDisabled, onClose, primaryButtonOnClick } = props
  const { t } = useTranslation(['clearFilesModal', 'controls'])
  const { isLightMode } = useThemeContext()

  const clearMessagesOnClick = () => {
    primaryButtonOnClick()
    onClose()
  }

  return (
    <Box className="flex flex-wrap justify-end gap-3">
      <TextButton
        aria-label="close"
        size="sm"
        id="close-button"
        onClick={onClose}
        text={t('controls.cancel', { ns: 'controls' })}
      />
      <TextButton
        aria-label="next"
        size="sm"
        id="next-button"
        disabled={isButtonDisabled}
        onClick={clearMessagesOnClick}
        buttonBackground={isLightMode ? 'bg-kpmgCobaltBlue' : 'bg-white'}
        fontColour={isLightMode ? 'text-white' : 'text-black'}
        text={t('modal.clearAllFiles')}
      />
    </Box>
  )
}
