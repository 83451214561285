import { useEffect } from 'react'
import { InteractionStatus } from '@azure/msal-browser'
import { Box } from '@chakra-ui/layout'
import { Spinner } from '@chakra-ui/spinner'

import { useAuthContext } from 'providers/AuthProvider'

import { FullSizePageDotDotDot } from './Fallback'

export const GeneralSpinner = ({ fullHeight = true }: { fullHeight?: boolean }) => {
  return (
    <Box className={`flex items-center justify-center text-2xl ${fullHeight ? 'flex-grow my-20' : 'my-4'}`}>
      <Spinner size="lg" />
    </Box>
  )
}

export const LoadingInBotsFallback = () => {
  const { isAuthenticated, msalInstance, inProgress } = useAuthContext()

  useEffect(() => {
    // If the user hasn't been authenticated and no interaction with msal-react is currently in progress, log them out
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      msalInstance.logout()
    }
  }, [isAuthenticated, msalInstance, inProgress])

  return <FullSizePageDotDotDot />
}
