import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  API,
  ClearChatMessagesResponse,
  ConversationConfigUpdateResponse,
  ConversationCreateResponse,
  ConversationDeleteResponse,
  ConversationListResponse,
  DualLanguageOptionalValues,
  KBotConfig,
  LLM,
  SourceType,
  StarterPrompt,
  TemplateType,
} from '@kleo/types'
import { v4 as uuidv4 } from 'uuid'

import { useFetcher } from 'hooks/useFetcher'

import { generateRequestId } from 'utils/generateRequestId'
import { fetchData } from 'utils/http/methods'

import type { ChatMessage, ConversationSettings, FEUIConfig, FilterValue, SearchFilterItem } from 'types/types'

import { useAssessmentContext } from './AssessmentProvider'
import { useAuthContext } from './AuthProvider'
import { useConfigContext } from './ConfigurationProvider'
import { useConversationSettingsContext } from './ConversationSettingsProvider'
import { useFiltersContext } from './FiltersAndFormsProvider'
import { useI18Context } from './i18Provider'
import { useKBotContext } from './KBotsProvider'

export type ConversationType = {
  created: number
  displayName: string | null
  haveMessagesBeenFetched: boolean
  isSaved: boolean
  messages: ChatMessage[]
  summary: Array<string> | null
  templateId?: string
} & Partial<ConversationKBotConfig>

export type ConversationKBotConfig = {
  description: DualLanguageOptionalValues[]
  fileContent: string
  kbotTemperature: API.OpenAITemperature
  source: SourceType
  starterPrompts: StarterPrompt[]
  template: TemplateType
  userInstructions: string
  model: string
}

export type ConversationsRecordType = Record<string, Record<string, ConversationType>>

type MessagesContextType = {
  addConversation: (
    botName: string,
    saveConversation: boolean,
    localKBotSelection?: KBotConfig | null,
    preserveFilters?: string[]
  ) => void
  clearMessagesForBot: (botName: string) => void
  addMessage: (
    botData: { botName: string; botType: string },
    newMessage: ChatMessage,
    saveConversation: boolean,
    localKBotSelection?: KBotConfig | null,
    temperature?: API.OpenAITemperature,
    isAssessment?: boolean,
    model?: string
  ) => Promise<{
    conversationIDUsed: string | null | undefined
    kBotTemplateId: string | undefined
    conversationExists: boolean
  }>
  areConversationsLoaded: boolean
  botList: string[]
  conversations: ConversationsRecordType
  deleteAllMessagesInConversation: (botName: string) => Promise<void>
  deleteConversations: (
    botName: string[],
    conversationIDsToDelete: string[],
    keepLocalInstance?: boolean
  ) => Promise<{ success: boolean }>
  fetchingBotConversationsError: boolean
  findEmptyConversationID: (botConversations: Record<string, ConversationType>) => string | undefined
  getAcknowledgementForBot: (botName: string) => AcknowledgementData | undefined
  getBotConversationsWithId: (botName: string) => Array<ConversationType & { conversationID: string }>
  getCharLimitForBot: (botName: string) => number
  getConversation: (botName: string, conversationID: string | null) => ConversationType | undefined
  getCurrentConversationIDForBot: (botName: string) => string | null
  getMessages: (botName: string) => ChatMessage[]
  getNumberOfConversations: (botName?: string) => number
  getSummary: (botName: string) => string | null
  getTokenLimitForBot: (botName: string) => number
  isClearingMessages: boolean
  isCreatingConversation: Record<string, boolean>
  isCreatingConversationError: Record<string, string | null>
  listOfConversationsBeingDeleted: string[]
  deletingConversationError: string | null
  isFetchingBotConversations: boolean
  isFetchingConversationData: Record<string, boolean>
  isFetchingConversationDataError: Record<string, boolean>
  isValidatingConversationDataError: Record<string, string | null>
  maxConversations: null | 'apiError' | 'missingMaxConversations' | number
  previousConversationID: Record<string, string | null>
  resetHaveMessagesBeenFetchedForBot: (botName: string) => void
  renameConversation: (
    botName: string,
    conversationID: string,
    newConversationName: string,
    saveConversations: boolean
  ) => Promise<void>
  setActiveConversationIDForBot: (botName: string, conversationID: string | null) => void
  setClearingMessages: Dispatch<SetStateAction<boolean>>
  setConversations: Dispatch<SetStateAction<ConversationsRecordType>>
  setConversationSummary: (botName: string, summary: string | null, chatSummaryMemory: number) => void
  setDeletingConversationError: Dispatch<SetStateAction<string | null>>
  setFetchedMessagesForConversation: (
    botName: string,
    conversationID: string,
    messages: ChatMessage[],
    summary: string[],
    conversationKBotConfig?: ConversationKBotConfig
  ) => void
  setIsFetchingConversationData: Dispatch<SetStateAction<Record<string, boolean>>>
  setIsFetchingConversationDataError: Dispatch<SetStateAction<Record<string, boolean>>>
  setIsValidatingConversationDataError: Dispatch<SetStateAction<Record<string, string | null>>>
  setStreamingMessage: Dispatch<SetStateAction<Record<string, ChatMessage | null>>>
  setStreamingSummary: Dispatch<SetStateAction<Record<string, string | null>>>
  streamingMessage: Record<string, ChatMessage | null>
  streamingSummary: Record<string, string | null>
  tooManyConversations: boolean
  updateAcknowledgementForBot: (botName: string, ack: AcknowledgementData) => void
  updateCharLimitForBot: (botName: string, charLimit: number) => void
  updateConversationIsSaved: (botName: string, conversationID: string, newSettings: ConversationSettings) => void
  updateModelInConversation: (botName: string, conversationID: string, model: SearchFilterItem) => void
}

export const MessagesContext = createContext<MessagesContextType>({} as MessagesContextType)

type MessagesProviderProps = {
  children?: React.ReactNode
  config: FEUIConfig[] | undefined
}

type AcknowledgementData = {
  isAck: boolean
}

export const MessagesProvider = ({ children, config }: MessagesProviderProps) => {
  const { languageAbbreviation } = useI18Context()
  const { API_ENDPOINT } = useConfigContext()
  const { getToken, isAuthenticated } = useAuthContext()
  const {
    defaultFilterValues,
    getSelectedFilterValues,
    updateSelectedFilterAndFormValues,
    updateSelectedFilterValues,
    updateSelectedFormValues,
  } = useFiltersContext()
  const { selectedClient } = useAssessmentContext()
  const { getConversationSettings, updateConversationSettings } = useConversationSettingsContext()
  const { getKBotDataWithValidation, setKBotDataValidationError } = useKBotContext()

  const [maxConversations, setMaxConversations] = useState<null | 'apiError' | 'missingMaxConversations' | number>(null)

  // Here we have to records for conversations; this simplifies tracking number of conversations, as well as which conversations can be managed/deleted

  // The record of "actual" conversations per bot that have been created
  const [conversations, setConversations] = useState<ConversationsRecordType>({})
  // The record of "temporary" conversations for each bot - each bot can have at most one conversation in this record.
  // This is used as the reference value for conversations that have "null" as their id.
  const [tempConversations, setTempConversations] = useState<Record<string, ConversationType>>({})

  const [fetchingBotConversationsError, setFetchingBotConversationsError] = useState<boolean>(false)
  const [hasFetchedConversations, setHasFetchedConversations] = useState<boolean>(false)
  const [streamingMessage, setStreamingMessage] = useState<Record<string, ChatMessage | null>>({})
  const [streamingSummary, setStreamingSummary] = useState<Record<string, string | null>>({})

  const [activeConversationID, setActiveConversationID] = useState<Record<string, string | null>>({})
  const [previousConversationID, setPreviousConversationID] = useState<Record<string, string | null>>({})
  const [isCreatingConversation, setIsCreatingConversation] = useState<Record<string, boolean>>({})
  const [listOfConversationsBeingDeleted, setListOfConversationsBeingDeleted] = useState<string[]>([])
  const [isCreatingConversationError, setIsCreatingConversationError] = useState<Record<string, string | null>>({})
  const [deletingConversationError, setDeletingConversationError] = useState<string | null>(null)
  const [isFetchingConversationData, setIsFetchingConversationData] = useState<Record<string, boolean>>({})
  const [isFetchingConversationDataError, setIsFetchingConversationDataError] = useState<Record<string, boolean>>({})
  const [isValidatingConversationDataError, setIsValidatingConversationDataError] = useState<
    Record<string, string | null>
  >({})
  const [isClearingMessages, setClearingMessages] = useState(false)

  const [charLimit, setCharLimit] = useState<Record<string, number>>({})
  const [tokenLimit, setTokenLimit] = useState<Record<string, number>>({})

  const [acknowledgement, setAcknowledgement] = useState<Record<string, AcknowledgementData | undefined>>({})

  const isDeleteConvLockedRef = useRef(false)

  useEffect(() => {
    config?.forEach((botConfig) => {
      // If bot has acknowledgement configured (not undefined)
      if (botConfig.isAcknowledgement !== undefined) {
        setAcknowledgement((prev) => ({
          ...prev,
          [botConfig.botName]: { isAck: false },
        }))
      }

      if (botConfig.maxInput) {
        setCharLimit((prev) => ({
          ...prev,
          [botConfig.botName]: botConfig.maxInput,
        }))
        setTokenLimit((prev) => ({
          ...prev,
          [botConfig.botName]: botConfig.maxInput / 4,
        }))
      }
    })
  }, [config])

  const { isLoading: isFetchingBotConversations } = useFetcher<ConversationListResponse>({
    allowRefetch: false,
    url: '/chatapi/ListConvApi',
    shouldFetch:
      isAuthenticated &&
      !hasFetchedConversations &&
      Object.keys(conversations).length === 0 &&
      conversations.constructor === Object,
    noCache: true,
    payload: {
      language: 'EN',
    },
    onError: (e) => {
      setFetchingBotConversationsError(true)
      setMaxConversations('apiError')
      if ((e as Error).name !== 'FetchTokenError') {
        setHasFetchedConversations(true)
      }
    },
    onSuccess: (response) => {
      if ('errorList' in response) {
        setFetchingBotConversationsError(true)
        setMaxConversations('missingMaxConversations')
        console.error('Error occurred:', response.errorList[0].code)
      } else {
        setMaxConversations(response.maxConversations)
        // when setting conversation from BE, needs to set ALL bots so the counts of total conversation is correct
        const result = Object.values(response.conversations).reduce<ConversationsRecordType>((acc, curr) => {
          // We know saveConversation will always be true since we are fetching saved conversations
          updateConversationSettings(curr.botName, curr.conversationId, { saveConversation: true })
          return {
            ...acc,
            [curr.botName]: {
              ...acc[curr.botName],
              [curr.conversationId]: {
                displayName: curr.conversationName,
                messages: getConversation(curr.botName, curr.conversationId)?.messages || [],
                summary: getConversation(curr.botName, curr.conversationId)?.summary || [],
                created: curr.created,
                // We know isSaved will always be true since we are fetching saved conversations
                isSaved: true,
                haveMessagesBeenFetched:
                  getConversation(curr.botName, curr.conversationId)?.haveMessagesBeenFetched ?? false,
                templateId: curr.templateId,
                template: curr.template,
              },
            },
          }
        }, {})
        setFetchingBotConversationsError(false)
        setConversations(result)
      }
      setHasFetchedConversations(true)
    },
  })

  const getCharLimitForBot = useCallback(
    (botName: string): number => {
      return charLimit[botName]
    },
    [charLimit]
  )

  const getTokenLimitForBot = useCallback(
    (botName: string): number => {
      return tokenLimit[botName]
    },
    [tokenLimit]
  )

  const getAcknowledgementForBot = useCallback(
    (botName: string): AcknowledgementData | undefined => {
      return acknowledgement[botName]
    },
    [acknowledgement]
  )

  const updateCharLimitForBot = (botName: string, charLimit: number): void => {
    setCharLimit((prev) => {
      return { ...prev, [botName]: charLimit }
    })
    setTokenLimit((prev) => {
      return { ...prev, [botName]: charLimit / 4 }
    })
  }

  const updateAcknowledgementForBot = (botName: string, updatedData: AcknowledgementData): void => {
    setAcknowledgement((prev) => ({
      ...prev,
      [botName]: {
        ...prev[botName],
        ...updatedData,
      },
    }))
  }

  /**
   * Returns the ID of an empty conversation instance
   * @param {Record<number, ConversationType>} botConversations - The conversations associated with the bot.
   * @returns {number | undefined} The ID of the empty conversation instance, if found; otherwise, undefined.
   */
  const findEmptyConversationID = useCallback(
    (botConversations: Record<string, ConversationType>): string | undefined => {
      const emptyConversationEntry = Object.entries(botConversations).find(
        ([, conversation]) => conversation.messages.length === 0 && conversation.displayName === null
      )

      return emptyConversationEntry ? emptyConversationEntry[0] : undefined
    },
    []
  )

  /**
   * Returns the ID of the conversation opened on a specific bot
   * @param {string} botName - The name of the bot to retrieve the current conversation ID for.
   * @returns {number | null} The ID of the conversation opened on the specified bot, or null if not found. null indicates the current conversation is the placeholder (temp) conversation.
   */
  const getCurrentConversationIDForBot = useCallback(
    (botName: string): string | null => {
      if (botName in activeConversationID) {
        return activeConversationID[botName]
      } else {
        return null
      }
    },
    [activeConversationID]
  )

  /**
   * Updates the ID of the conversation that is currently opened on a bot
   * @param {string} botName - The name of the bot to update the current conversation ID for.
   * @param {number} conversationID - The ID of the conversation to set as the current conversation.
   * @returns {void}
   */
  const setActiveConversationIDForBot = useCallback(
    (botName: string, conversationID: string | null): void => {
      if (typeof conversationID === 'string') {
        setPreviousConversationID({ ...activeConversationID })
        setActiveConversationID((currentActiveConversationID) => {
          return { ...currentActiveConversationID, [botName]: conversationID }
        })
      } else {
        setPreviousConversationID({ ...activeConversationID })
        setActiveConversationID((currentActiveConversationID) => {
          const currentConversationCopy = { ...currentActiveConversationID }
          delete currentConversationCopy[botName]
          return currentConversationCopy
        })
      }
    },
    [activeConversationID]
  )

  const updateNewConversation = useCallback(
    (
      botName: string,
      created: number,
      newConversationID: string,
      newMessage: ChatMessage,
      saveConversation: boolean,
      kBotInformation?: KBotConfig | null,
      temperature?: API.OpenAITemperature
    ): void => {
      setConversations((currentConversations) => {
        return {
          ...currentConversations,
          [botName]: {
            ...currentConversations[botName],
            [newConversationID]: {
              displayName: newMessage.content,
              // If we are responding to a message (document link click at bottom of message), don't include the message if it belongs to the user
              messages: [newMessage],
              summary: null,
              created,
              isSaved: saveConversation,
              haveMessagesBeenFetched: true,
              kbotTemperature: temperature,
              ...(kBotInformation
                ? {
                    description: kBotInformation.description,
                    fileContent: kBotInformation.fileContent,
                    source: kBotInformation.source,
                    starterPrompts: kBotInformation.starterPrompts,
                    template: kBotInformation.template,
                    templateId: kBotInformation.templateId,
                    userInstructions: kBotInformation.userInstructions,
                  }
                : {}),
            },
          },
        }
      })

      updateSelectedFilterAndFormValues(
        botName,
        newConversationID,
        { filters: getSelectedFilterValues(botName, null)?.filters },
        { userQuery: '' }
      )

      updateConversationSettings(botName, newConversationID, { saveConversation })

      setActiveConversationIDForBot(botName, newConversationID)
    },
    [
      getSelectedFilterValues,
      setActiveConversationIDForBot,
      updateConversationSettings,
      updateSelectedFilterAndFormValues,
    ]
  )

  type SaveAndUpdateConversationToBEProps = {
    botName: string
    newMessage: ChatMessage
    saveConversation: boolean
    kBotInformation?: KBotConfig | null
    temperature?: API.OpenAITemperature
    model?: string
  } & (
    | {
        currentConversation: ConversationType
        currentConversationID: string
      }
    | {
        currentConversation?: never
        currentConversationID?: never
      }
  )

  const saveAndUpdateConversationToBE = useCallback(
    async (props: SaveAndUpdateConversationToBEProps): Promise<string | undefined> => {
      const {
        botName,
        currentConversation,
        currentConversationID,
        kBotInformation,
        model,
        newMessage,
        saveConversation,
        temperature,
      } = props
      try {
        setIsCreatingConversation((currentIsCreatingConversation) => ({
          ...currentIsCreatingConversation,
          [botName]: true,
        }))
        const messageContent = currentConversation?.messages?.[0]?.content ?? newMessage.content
        const displayName = currentConversation?.displayName
        const authToken = await getToken()
        const createConvApiResponse = await fetchData<ConversationCreateResponse>({
          url: `${API_ENDPOINT}/chatapi/CreateConvApi`,
          token: authToken,
          setRetrying: () => false,
          payload: {
            botName,
            convName:
              (displayName && displayName.length > 100 ? displayName.slice(0, 100) : displayName) ??
              (messageContent.length > 100 ? messageContent.slice(0, 100) : messageContent),

            engagementId: botName === 'ASSESSMENT' && selectedClient ? selectedClient.engagementId : undefined,
            language: 'EN',
            templateId: kBotInformation?.templateId,
          },
          requestId: generateRequestId(),
        })

        if ('errorList' in createConvApiResponse) {
          setIsCreatingConversationError((currentIsCreatingConversationError) => ({
            ...currentIsCreatingConversationError,
            [botName]: createConvApiResponse.errorList?.[0]?.code,
          }))
          console.error('Error occurred:', createConvApiResponse.errorList[0].code)
          // bubble up the error
          throw new Error(createConvApiResponse.errorList[0].code)
        } else {
          // Set our config values here to move them from temp and assign to the correct conversation ID
          try {
            const authToken = await getToken()
            const updateConvApiResponse = await fetchData<ConversationConfigUpdateResponse>({
              url: `${API_ENDPOINT}/chatapi/UpdateConvApi`,
              token: authToken,
              setRetrying: () => false,
              payload: {
                botName,
                convID: createConvApiResponse.conversationId,
                config: {
                  fileContext: newMessage.docContext,
                  kbotTemperature: temperature,
                  model: model as LLM.Models,
                },
                language: 'EN',
              },
              requestId: generateRequestId(),
            })

            if ('errorList' in updateConvApiResponse) {
              console.error('Error occurred:', updateConvApiResponse.errorList[0].code)
            } else {
              updateSelectedFilterValues(
                botName,
                createConvApiResponse.conversationId,
                {
                  filters: {
                    model: {
                      items: [{ value: model as string, label: model as string }],
                      isChecked: false,
                    },
                  },
                },
                'update'
              )
            }
          } catch (error) {
            console.error('Error occurred when trying to update conversation settings', error)
          }
          if (currentConversation && currentConversationID) {
            setConversations((prevConversations) => {
              // Get the botName from the conversations (e.g., GENERAL)
              const sectionConversations = prevConversations[botName]

              // If the botName or conversation doesn't exist, return the previous state
              if (!sectionConversations || !sectionConversations[currentConversationID]) {
                return prevConversations
              }

              // Get the conversation we want to replace
              const conversationToReplace = sectionConversations[currentConversationID]

              // Create a new object with the old key replaced by the new key
              const updatedSection: Record<string, ConversationType> = {
                ...sectionConversations,
                [createConvApiResponse.conversationId]: {
                  ...conversationToReplace,
                  messages: [
                    ...conversationToReplace.messages,
                    // If we are responding to a message (document link click at bottom of message), don't include the message if it belongs to the user
                    ...[newMessage],
                  ],
                  isSaved: true,
                  ...(temperature ? { kbotTemperature: temperature } : {}),
                  ...(kBotInformation
                    ? {
                        description: kBotInformation.description,
                        fileContent: kBotInformation.fileContent,
                        source: kBotInformation.source,
                        starterPrompts: kBotInformation.starterPrompts,
                        template: kBotInformation.template,
                        templateId: kBotInformation.templateId,
                        userInstructions: kBotInformation.userInstructions,
                      }
                    : {}),
                }, // Add new key with the conversation
              }

              // Delete the old key
              delete updatedSection[currentConversationID]

              // Return updated conversations state with the updated botName
              return {
                ...prevConversations,
                [botName]: updatedSection,
              }
            })
            setActiveConversationIDForBot(botName, createConvApiResponse.conversationId)
          } else {
            updateNewConversation(
              botName,
              createConvApiResponse.created,
              createConvApiResponse.conversationId,
              newMessage,
              saveConversation,
              kBotInformation,
              temperature
            )
          }

          setIsCreatingConversationError((currentIsCreatingConversationError) => ({
            ...currentIsCreatingConversationError,
            [botName]: null,
          }))
          return createConvApiResponse.conversationId
        }
      } catch (error) {
        setIsCreatingConversationError((currentIsCreatingConversationError) => ({
          ...currentIsCreatingConversationError,
          [botName]: 'error',
        }))
        console.error('Fetch failed:', error)
        throw new Error('Failed to create conversation')
      } finally {
        setIsCreatingConversation((currentIsCreatingConversation) => ({
          ...currentIsCreatingConversation,
          [botName]: false,
        }))
      }
    },
    [
      API_ENDPOINT,
      getToken,
      selectedClient,
      setActiveConversationIDForBot,
      updateNewConversation,
      updateSelectedFilterValues,
    ]
  )

  /**
   * Adds a new empty instance of a conversation
   * @param {string} botName - The name of the bot to which the conversation will be added.
   * @returns {void}
   */
  const addConversation = useCallback(
    (
      botName: string,
      saveConversation: boolean,
      kBotInformation?: KBotConfig | null,
      preserveFilters?: string[]
    ): void => {
      // Reset the selected filter values for the temp conversation
      updateSelectedFilterValues(botName, null)

      setTempConversations((currentTempConversations) => {
        return {
          ...currentTempConversations,
          [botName]: {
            displayName: null,
            messages: [],
            summary: null,
            created: Date.now(),
            isSaved: saveConversation,
            haveMessagesBeenFetched: true,
            ...(kBotInformation
              ? {
                  description: kBotInformation.description,
                  fileContent: kBotInformation.fileContent,
                  kbotTemperature: kBotInformation.kbotTemperature,
                  source: kBotInformation.source,
                  starterPrompts: kBotInformation.starterPrompts,
                  template: kBotInformation.template,
                  templateId: kBotInformation.templateId,
                  userInstructions: kBotInformation.userInstructions,
                }
              : {}),
          },
        }
      })

      const filtersToPreserve: { [key: string]: FilterValue } = {}
      if (Array.isArray(preserveFilters) && preserveFilters.length) {
        const selectedFilters = getSelectedFilterValues(botName, null)['filters']

        preserveFilters.forEach((filter) => {
          if (filter in selectedFilters) {
            filtersToPreserve[filter] = selectedFilters[filter]
          }
        })
      }

      // Running the below update functions will create default instances of filter, form, and conversation settings values for the new conversation ID
      updateSelectedFilterAndFormValues(
        botName,
        null,
        Object.keys(filtersToPreserve).length
          ? { filters: { ...defaultFilterValues[botName].filters, ...filtersToPreserve } }
          : undefined
      )
      setActiveConversationIDForBot(botName, null)
      updateConversationSettings(botName, null, { saveConversation })
    },
    [
      defaultFilterValues,
      getSelectedFilterValues,
      setActiveConversationIDForBot,
      updateConversationSettings,
      updateSelectedFilterAndFormValues,
      updateSelectedFilterValues,
    ]
  )

  const updateModelInConversation = useCallback(
    async (botName: string, conversationIDUsed: string, model: SearchFilterItem) => {
      try {
        const authToken = await getToken()
        const updateConvApiResponse = await fetchData<ConversationConfigUpdateResponse>({
          url: `${API_ENDPOINT}/chatapi/UpdateConvApi`,
          token: authToken,
          setRetrying: () => false,
          payload: {
            botName,
            convID: conversationIDUsed,
            config: {
              model: model.value as LLM.Models,
            },
            language: 'EN',
          },
          requestId: generateRequestId(),
        })

        if ('errorList' in updateConvApiResponse) {
          console.error('Error occurred:', updateConvApiResponse.errorList[0].code)
        } else {
          updateSelectedFilterValues(
            botName,
            conversationIDUsed,
            {
              filters: {
                model: {
                  items: [model],
                  isChecked: false,
                },
              },
            },
            'update'
          )
        }
      } catch (error) {
        console.error('Error occurred when trying to update conversation settings', error)
      }
    },
    [API_ENDPOINT, getToken, updateSelectedFilterValues]
  )

  const addMessage = useCallback(
    async (
      botData: { botName: string; botType: string },
      newMessage: ChatMessage,
      saveConversation: boolean,
      kBotInformation?: KBotConfig | null,
      temperature?: API.OpenAITemperature,
      isAssessment?: boolean,
      model?: string
    ) => {
      const { botName, botType } = botData

      let conversationIDUsed = getCurrentConversationIDForBot(botName)
      let conversationExists = true
      if (botName in conversations && conversationIDUsed && conversationIDUsed in conversations[botName]) {
        // If we are now needing to save the conversation that was originally set to unsaved
        if (saveConversation === true && conversations[botName][conversationIDUsed].isSaved === false) {
          try {
            if (botType === 'general') {
              const validationResult = await getKBotDataWithValidation(botName)

              if (!validationResult.success) {
                setKBotDataValidationError(true)

                // exist early if validation fails
                return {
                  conversationIDUsed: null,
                  kBotTemplateId: kBotInformation?.templateId ?? undefined,
                  conversationExists: false,
                }
              }
              setKBotDataValidationError(false)
            }

            const conversationIDCreated = await saveAndUpdateConversationToBE({
              botName,
              newMessage,
              saveConversation,
              kBotInformation,
              currentConversation: conversations[botName][conversationIDUsed],
              currentConversationID: conversationIDUsed,
              temperature,
              model,
            })
            if (conversationIDCreated) {
              conversationIDUsed = conversationIDCreated
            }
          } catch {
            conversationExists = false
          }
        } else {
          setConversations((currentConversations) => {
            if (conversationIDUsed) {
              return {
                ...currentConversations,
                [botName]: {
                  ...currentConversations[botName],
                  [conversationIDUsed]: {
                    ...currentConversations[botName][conversationIDUsed],
                    displayName: currentConversations[botName][conversationIDUsed].displayName ?? newMessage.content,
                    messages: [
                      ...currentConversations[botName][conversationIDUsed].messages,
                      // If we are responding to a message (document link click at bottom of message), don't include the message if it belongs to the user
                      ...[newMessage],
                    ],
                    isSaved: saveConversation,
                    ...(temperature ? { kbotTemperature: temperature } : {}),
                    ...(kBotInformation
                      ? {
                          description: kBotInformation.description,
                          fileContent: kBotInformation.fileContent,
                          source: kBotInformation.source,
                          starterPrompts: kBotInformation.starterPrompts,
                          template: kBotInformation.template,
                          templateId: kBotInformation.templateId,
                          userInstructions: kBotInformation.userInstructions,
                        }
                      : {}),
                  },
                },
              }
            } else return { ...currentConversations }
          })
        }
      } else {
        if (saveConversation) {
          try {
            if (botType === 'general') {
              const validationResult = await getKBotDataWithValidation(botName)

              if (!validationResult.success) {
                setKBotDataValidationError(true)

                // exist early if validation fails
                return {
                  conversationIDUsed: null,
                  kBotTemplateId: kBotInformation?.templateId ?? undefined,
                  conversationExists: false,
                }
              }
              setKBotDataValidationError(false)
            }

            const conversationIDCreated = await saveAndUpdateConversationToBE({
              botName,
              newMessage,
              saveConversation,
              kBotInformation,
              temperature,
              model,
            })
            if (conversationIDCreated) {
              conversationIDUsed = conversationIDCreated
            }
          } catch {
            conversationExists = false
          }
        } else {
          const newConversationID = `${selectedClient && isAssessment ? `${selectedClient.engagementId}-` : ''}${uuidv4()}`
          conversationIDUsed = newConversationID
          updateNewConversation(
            botName,
            Date.now(),
            newConversationID,
            newMessage,
            saveConversation,
            kBotInformation,
            temperature
          )
        }
        // Reset the value for the temporary conversation of this bot
        updateSelectedFormValues(botName, null, { userQuery: '' })
      }
      return {
        conversationIDUsed,
        kBotTemplateId: kBotInformation?.templateId,
        conversationExists,
      }
    },
    [
      conversations,
      getCurrentConversationIDForBot,
      getKBotDataWithValidation,
      saveAndUpdateConversationToBE,
      selectedClient,
      setKBotDataValidationError,
      updateNewConversation,
      updateSelectedFormValues,
    ]
  )

  /**
   * Delete all messages from the current conversation in the database
   * @param {string} botName - The name of the bot associated with the conversation.
   * @returns {void}
   */
  const deleteAllMessagesInConversation = useCallback(
    async (botName: string): Promise<void> => {
      const conversationID = getCurrentConversationIDForBot(botName)
      if (botName in conversations && conversationID && conversationID in conversations[botName]) {
        try {
          setClearingMessages(true)
          const authToken = await getToken()
          const response = await fetchData<ClearChatMessagesResponse>({
            url: `${API_ENDPOINT}/chatapi/ClearChatMessages`,
            token: authToken,
            setRetrying: () => false,
            payload: {
              botName,
              conversationId: conversationID,
              language: 'EN',
            },
            requestId: generateRequestId(),
          })
          if ('errorList' in response) {
            console.error('Error occurred:', response.errorList[0].code)
          }
        } catch {
          console.error('SOMETHING WENT WRONG in deleting conversations')
        } finally {
          setClearingMessages(false)
        }
        setConversations((currentConversations) => ({
          ...currentConversations,
          [botName]: {
            ...currentConversations[botName],
            [conversationID]: { ...currentConversations[botName][conversationID], messages: [] },
          },
        }))
      }
    },
    [API_ENDPOINT, conversations, getCurrentConversationIDForBot, getToken]
  )

  /**
   * Resets all messages to blank for a bot in the provider
   * @param {string} botName - The name of the bot.
   * @returns {void}
   */
  const clearMessagesForBot = useCallback((botName: string): void => {
    setConversations((prevConversations) => {
      const updatedConversations = { ...prevConversations }

      if (updatedConversations[botName]) {
        Object.keys(updatedConversations[botName]).forEach((conversationId) => {
          updatedConversations[botName][conversationId] = {
            ...updatedConversations[botName][conversationId],
            messages: [],
          }
        })
      }

      return updatedConversations
    })
  }, [])

  /**
   * Resets all haveMessagesBeenFetched state to false for a bot in the provider
   * @param {string} botName - The name of the bot.
   * @returns {void}
   */
  const resetHaveMessagesBeenFetchedForBot = useCallback((botName: string): void => {
    setConversations((prevConversations) => {
      const updatedConversations = { ...prevConversations }

      if (updatedConversations[botName]) {
        Object.keys(updatedConversations[botName]).forEach((conversationId) => {
          updatedConversations[botName][conversationId] = {
            ...updatedConversations[botName][conversationId],
            haveMessagesBeenFetched: false,
          }
        })
      }

      return updatedConversations
    })
  }, [])

  /**
   * Deletes an entire conversation instance and the settings associated with it
   * @param {[string]} botName - The name(s) of the bot(s) associated with the conversation(s).
   * @param {[string]} conversationIDsToDelete - The ID(s) of the conversation(s) to be deleted.
   * @returns {Promise<{ success: boolean }>}
   */
  const deleteConversations = useCallback(
    async (
      botNames: string[],
      conversationIDsToDelete: string[],
      keepLocalInstance?: boolean
    ): Promise<{ success: boolean }> => {
      // locks to block double click
      if (!!listOfConversationsBeingDeleted.length || isDeleteConvLockedRef.current) {
        return { success: false }
      }

      isDeleteConvLockedRef.current = true
      setListOfConversationsBeingDeleted([...conversationIDsToDelete])
      setDeletingConversationError(null)

      // Filter out any conversations that are marked as do not save, because those don't exist
      const convListForPayload = conversationIDsToDelete.filter((id) => {
        return getConversationSettings(id)?.saveConversation
      })

      try {
        // Only send the request to delete conversations if we have any saved ones to delete
        // We still need to do everything else afterwards like deleting the local instances
        if (convListForPayload.length) {
          const authToken = await getToken()
          const response = await fetchData<ConversationDeleteResponse>({
            url: `${API_ENDPOINT}/chatapi/DeleteConvApi`,
            token: authToken,
            setRetrying: () => false,
            payload: {
              convList: convListForPayload,
              language: 'EN',
            },
            requestId: generateRequestId(),
          })
          if ('errorList' in response) {
            console.error('Error occurred:', response.errorList[0].code)
            return { success: false }
          }
        }

        if (!keepLocalInstance) {
          setConversations((currentConversations) => {
            const filteredConversations: ConversationsRecordType = {}
            for (const key in currentConversations) {
              const conversationRecord = currentConversations[key]
              const filteredRecord: Record<string, ConversationType> = {}

              for (const numKey in conversationRecord) {
                if (!conversationIDsToDelete.includes(numKey)) {
                  filteredRecord[numKey] = conversationRecord[numKey]
                }
              }

              if (Object.keys(filteredRecord).length > 0) {
                filteredConversations[key] = filteredRecord
              }
            }
            return filteredConversations
          })

          const filteredDocContents: Record<string, string> = {}
          for (const key in activeConversationID) {
            const recordNumber = activeConversationID[key]
            if (recordNumber && !conversationIDsToDelete.includes(recordNumber)) {
              filteredDocContents[key] = recordNumber
            }
          }

          for (const botName of botNames) {
            // Check if we're deleting an active conversation
            if (!filteredDocContents[botName]) {
              // If we are deleting an active conversation, the system will go back to using the temporary conversation. So we need to re-set these to make sure we weren't holding old values
              updateSelectedFilterAndFormValues(botName, null)
            }
          }

          // If we are deleting a conversation that used to be the current conversation ID opened, reset this value
          setActiveConversationID(filteredDocContents)
        }
        return { success: true }
      } catch (err) {
        setDeletingConversationError('deleteConversationGeneric')
        return { success: false }
      } finally {
        // Reset locks
        isDeleteConvLockedRef.current = false
        setListOfConversationsBeingDeleted([])
      }
    },
    [
      API_ENDPOINT,
      activeConversationID,
      getConversationSettings,
      getToken,
      listOfConversationsBeingDeleted,
      updateSelectedFilterAndFormValues,
    ]
  )

  /**
   * Returns all conversations for a specific bot, including the key as a property, in reverse order
   * @param {string} botName - The name of the bot to retrieve conversations for.
   * @returns {Array<ConversationType & { conversationID: string }>} An array containing all conversations for the specified bot, with each conversation object extended to include the conversationID property.
   */
  const getBotConversationsWithId = useCallback(
    (botName: string, reverse = true): Array<ConversationType & { conversationID: string }> => {
      if (botName in conversations) {
        const conversationsArray = Object.entries(conversations[botName]).map(([key, value]) => ({
          ...value, // Spread the properties of the original ConversationType object
          conversationID: key, // Add the key as conversationID and ensure it's a number
        }))
        if (reverse) {
          return [...conversationsArray].reverse()
        } else {
          return [...conversationsArray]
        }
      }
      return []
    },
    [conversations]
  )

  /**
   * Returns the data related to a conversation
   * @param {string} botName - The name of the bot associated with the conversation.
   * @param {string | null} conversationID - The ID of the conversation to retrieve data for. If null, use the getCurrentConversationIDForBot method to get the conversation for current ID
   * @returns {ConversationType | undefined} The data related to the specified conversation, or undefined if the conversation does not exist.
   */
  const getConversation = useCallback(
    (botName: string, conversationID: string | null): ConversationType | undefined => {
      if (botName in conversations) {
        if (conversationID) {
          if (conversationID in conversations[botName]) {
            return conversations[botName][conversationID]
          }
        } else {
          const currentConversationID = getCurrentConversationIDForBot(botName)
          if (currentConversationID) {
            return conversations[botName][currentConversationID]
          } else {
            return tempConversations[botName]
          }
        }
      }
    },
    [conversations, getCurrentConversationIDForBot, tempConversations]
  )

  /**
   * Returns the message data for the current conversation opened on a bot
   * @param {string} botName - The name of the bot associated with the conversation.
   * @returns {ChatMessage[]} An array containing the message data for the specified conversation.
   */
  const getMessages = useCallback(
    (botName: string): ChatMessage[] => {
      const conversationID = getCurrentConversationIDForBot(botName)
      if (botName in conversations && conversationID && conversationID in conversations[botName]) {
        return conversations[botName][conversationID].messages
      }
      return []
    },
    [conversations, getCurrentConversationIDForBot]
  )

  /**
   * Returns the number of conversations across all bots
   * @returns {number} The total number of conversations across all bots.
   */
  const getNumberOfConversations = useCallback(
    (botName?: string) => {
      if (botName) {
        if (botName in conversations) {
          return Object.keys(conversations[botName]).length
        }
        return 0
      }
      return Object.values(conversations).reduce((acc, userConversations) => {
        return acc + Object.keys(userConversations).length
      }, 0)
    },
    [conversations]
  )

  /**
   * Returns the summary value of a bot
   * @param {string} botName - The name of the bot associated with the conversation.
   * @returns {string | null} The summary value of the specified bot, or null if not found.
   */
  const getSummary = useCallback(
    (botName: string): string | null => {
      const conversationID = getCurrentConversationIDForBot(botName)
      if (botName in conversations && conversationID && conversationID in conversations[botName]) {
        return conversations[botName][conversationID]?.summary?.join('\n') || null
      }
      return null
    },
    [conversations, getCurrentConversationIDForBot]
  )

  /**
   * Updates the displayName property of a specific conversation
   * @param {string} botName - The name of the bot associated with the conversation.
   * @param {number} conversationID - The ID of the conversation to update.
   * @param {string} newConversationName - The new display name for the conversation.
   * @returns {void}
   */
  const renameConversation = useCallback(
    async (
      botName: string,
      conversationID: string,
      newConversationName: string,
      saveConversations: boolean
    ): Promise<void> => {
      if (botName in conversations && conversationID in conversations[botName] && newConversationName.trim().length) {
        setConversations((currentConversations) => {
          return {
            ...currentConversations,
            [botName]: {
              ...currentConversations[botName],
              [conversationID]: { ...currentConversations[botName][conversationID], displayName: newConversationName },
            },
          }
        })
        if (saveConversations) {
          try {
            const authToken = await getToken()
            const response = await fetchData<ConversationConfigUpdateResponse>({
              url: `${API_ENDPOINT}/chatapi/UpdateConvApi`,
              token: authToken,
              setRetrying: () => false,
              payload: {
                botName,
                convID: conversationID,
                config: {
                  convName: newConversationName.length <= 100 ? newConversationName : newConversationName.slice(0, 100),
                },
                language: 'EN',
              },
              requestId: generateRequestId(),
            })

            if ('errorList' in response) {
              console.error('Error occurred:', response.errorList[0].code)
            }
          } catch {
            console.error('Error occurred when trying to save conversation settings')
          }
        }
      }
    },
    [API_ENDPOINT, conversations, getToken]
  )

  /**
   * Updates the summary property of a specific conversation
   * @param {string} botName - The name of the bot associated with the conversation.
   * @param {string | null} summary - The new summary value for the conversation.
   * @returns {void}
   */
  const setConversationSummary = useCallback(
    (botName: string, summary: string | null, chatSummaryMemory: number): void => {
      const conversationID = getCurrentConversationIDForBot(botName)
      if (botName in conversations && conversationID && conversationID in conversations[botName]) {
        setConversations((currentConversations) => {
          const currentSummary = currentConversations[botName][conversationID]?.summary
          if (summary === null) {
            // Update summary to null and return updated state
            return {
              ...currentConversations,
              [botName]: {
                ...currentConversations[botName],
                [conversationID]: { ...currentConversations[botName][conversationID], summary: null },
              },
            }
          }

          let summaryToSet: Array<string>
          if (Array.isArray(currentSummary)) {
            summaryToSet =
              chatSummaryMemory && currentSummary.length >= chatSummaryMemory
                ? // The current chatSummary contains more than the max number of items, so remove the necessary amount from the beginning and add the new responseSummary
                  [...currentSummary.slice(currentSummary.length - chatSummaryMemory + 1), summary]
                : // There is no limitation on the number of summaries we can keep ahold of, so add the new responseSummary
                  [...currentSummary, summary]
          } else {
            // There aren't any summaries yet set for this particular bot, so set the summary with just the new responseSummary
            summaryToSet = [summary]
          }
          return {
            ...currentConversations,
            [botName]: {
              ...currentConversations[botName],
              [conversationID]: { ...currentConversations[botName][conversationID], summary: summaryToSet },
            },
          }
        })
      }
    },
    [conversations, getCurrentConversationIDForBot]
  )

  const setFetchedMessagesForConversation = useCallback(
    (
      botName: string,
      conversationID: string,
      messages: ChatMessage[],
      summary: string[],
      conversationKBotConfig?: ConversationKBotConfig
    ): void => {
      setConversations((currentConversations) => {
        return {
          ...currentConversations,
          [botName]: {
            ...currentConversations[botName],
            [conversationID]: {
              ...currentConversations[botName][conversationID],
              haveMessagesBeenFetched: true,
              messages,
              summary,
              ...(conversationKBotConfig
                ? {
                    description: conversationKBotConfig.description,
                    fileContent: conversationKBotConfig.fileContent,
                    kbotTemperature: conversationKBotConfig.kbotTemperature,
                    source: conversationKBotConfig.source,
                    starterPrompts: conversationKBotConfig.starterPrompts,
                    template: conversationKBotConfig?.template[languageAbbreviation]?.length
                      ? conversationKBotConfig.template
                      : currentConversations[botName][conversationID].template,
                    userInstructions: conversationKBotConfig.userInstructions,
                  }
                : {}),
            },
          },
        }
      })
    },
    [languageAbbreviation]
  )

  const updateConversationIsSaved = useCallback(
    (botName: string, conversationID: string, newSettings: ConversationSettings) => {
      setConversations((currentConversations) => {
        return {
          ...currentConversations,
          [botName]: {
            ...currentConversations[botName],
            [conversationID]: {
              ...currentConversations[botName][conversationID],
              isSaved: newSettings.saveConversation,
            },
          },
        }
      })
    },
    []
  )

  const botList = useMemo(() => {
    if (config) {
      return config.map((botConfig) => botConfig.botName)
    }
    return []
  }, [config])

  const tooManyConversations = useMemo(() => {
    if (typeof maxConversations !== 'number') {
      return true
    }

    return getNumberOfConversations() >= maxConversations
  }, [getNumberOfConversations, maxConversations])

  /**
   * The logic is below
   * 1. Initial app load maxConversations is null (it doesn't show the error message or gray mask on bots)
   * 2. If ListConvApi is a success and maxConversations from response is returned, maxConversations state is a number
   * 3. If ListConvApi returns errorList, maxConversations is 'missingMaxConversations' (error message and gray mask is on bots)
   * 4. If ListConvApi errors out either on catch, maxConversations is 'apiError'
   *
   */
  const areConversationsLoaded = useMemo(() => {
    if (typeof maxConversations === 'number' || maxConversations === null) {
      return true
    }

    return false
  }, [maxConversations])

  return (
    <>
      <MessagesContext.Provider
        value={{
          addConversation,
          addMessage,
          areConversationsLoaded,
          botList,
          clearMessagesForBot,
          conversations,
          deleteAllMessagesInConversation,
          deleteConversations,
          deletingConversationError,
          fetchingBotConversationsError,
          findEmptyConversationID,
          getAcknowledgementForBot,
          getBotConversationsWithId,
          getCharLimitForBot,
          getConversation,
          getCurrentConversationIDForBot,
          getMessages,
          getNumberOfConversations,
          getSummary,
          getTokenLimitForBot,
          isClearingMessages,
          isCreatingConversation,
          isCreatingConversationError,
          listOfConversationsBeingDeleted,
          isFetchingBotConversations,
          isFetchingConversationData,
          isFetchingConversationDataError,
          isValidatingConversationDataError,
          maxConversations,
          previousConversationID,
          renameConversation,
          resetHaveMessagesBeenFetchedForBot,
          setActiveConversationIDForBot,
          setClearingMessages,
          setConversations,
          setConversationSummary,
          setDeletingConversationError,
          setFetchedMessagesForConversation,
          setIsFetchingConversationData,
          setIsFetchingConversationDataError,
          setIsValidatingConversationDataError,
          setStreamingMessage,
          setStreamingSummary,
          streamingMessage,
          streamingSummary,
          tooManyConversations,
          updateAcknowledgementForBot,
          updateCharLimitForBot,
          updateConversationIsSaved,
          updateModelInConversation,
        }}
      >
        {children}
      </MessagesContext.Provider>
    </>
  )
}

export const useMessagesContext = (): MessagesContextType => useContext(MessagesContext)
