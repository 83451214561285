import * as Yup from 'yup'

type SchemaMap = Record<string, Yup.AnyObjectSchema>

/**
 * Validates an array of messages against a set of schemas.
 * This function checks the `type` of each message, looks up the corresponding validation schema in the provided `schemaMap`,
 * and validates the message. If the type is not found or if the message is invalid, it throws an error.
 *
 * @param {string[]} messages - An array of raw JSON string messages to be validated.
 * @param {SchemaMap} schemaMap - A map where the key is the message type (e.g., 'message', 'context') and the value is the Yup validation schema for that type.
 * @throws {Error} Throws an error if:
 *   - The `type` of the message is not found in the `schemaMap`.
 *   - The message fails to parse as valid JSON.
 *   - The message fails to pass the Yup schema validation.
 */
export const validateMessagesWithSchemaMap = async (messages: string[], schemaMap: SchemaMap) => {
  for (const rawMessage of messages) {
    // Skip empty messages
    if (!rawMessage.trim()) {
      continue
    }

    let parsedMessage

    try {
      parsedMessage = JSON.parse(rawMessage.trim())

      const schema = schemaMap[parsedMessage.type]
      if (!schema) {
        // Throw an error if the type is not found in the map
        throw new Yup.ValidationError(`Unknown message type: ${parsedMessage.type}`)
      }
      await schema.validate(parsedMessage)
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        // Rethrow validation error
        err.type = parsedMessage.type ?? 'unknown'

        throw new Yup.ValidationError(`Invalid message format, does not pass schema requirements: ${err}`)
      } else if (err instanceof SyntaxError) {
        throw new Error(`Invalid JSON format in message: ${err.message}`)
      } else {
        // Handle unknown errors
        throw new Error(`Invalid message format: ${err}`)
      }
    }
  }
}
