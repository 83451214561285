import { createContext, useCallback, useContext, useEffect, useState } from 'react'

import { replaceIdInRecordBotSpecific } from 'utils/replaceIdInRecord'

import type { ConversationSettings, FEUIConfig } from 'types/types'

export function isKeyOfObject<T extends object>(key: string | number | symbol, obj: T): key is keyof T {
  return key in obj
}

type ConversationSettingsContextType = {
  deleteConversationSettings: (conversationIDsToDelete: string[]) => void
  getConversationSettings: (conversationID: string | null, botName?: string) => ConversationSettings | undefined
  replaceConversationIdInConversationSettings: (payload: {
    botName: string
    oldConversationId: string | null
    newConversationId: string
    initializeIfNoBotRecordExists?: boolean
  }) => void
  updateConversationSettings: (
    botName: string,
    conversationID: string | null,
    newSettings?: ConversationSettings
  ) => void
}

export const ConversationSettingsContext = createContext<ConversationSettingsContextType>(
  {} as ConversationSettingsContextType
)

type ConversationSettingsProviderProps = {
  children?: React.ReactNode
  config: FEUIConfig[] | undefined
}

type ConversationsSettingsType = Record<string, Record<string, ConversationSettings>>

export const ConversationSettingsProvider = ({ children, config }: ConversationSettingsProviderProps) => {
  const [conversationSettings, setConversationSettings] = useState<ConversationsSettingsType>({})
  const [tempConversationSettings, setTempConversationSettings] = useState<Record<string, ConversationSettings>>({})
  const [defaultBotSettings, setDefaultBotSettings] = useState<Record<string, ConversationSettings>>({})

  // Set default empty conversation settings
  useEffect(() => {
    config?.forEach((botConfig) => {
      // If we ever get more settings for a conversation, we need to modify this logic
      if (botConfig.saveConversations) {
        setDefaultBotSettings((currentDefaultSettings) => ({
          ...currentDefaultSettings,
          [botConfig.botName]: {
            saveConversation: true,
          },
        }))
        setTempConversationSettings((currentConversationSettings) => ({
          ...currentConversationSettings,
          [botConfig.botName]: {
            saveConversation: true,
          },
        }))
      }
    })
  }, [config])

  /**
   * Returns the settings of the provided bot-conversation
   * @param {string | null} conversationID - The id of the conversation
   * @param {string} [botName] - The name of the bot (optional)
   * @returns {ConversationSettings | undefined}
   */
  const getConversationSettings = useCallback(
    (conversationID: string | null, botName?: string): ConversationSettings | undefined => {
      if (botName) {
        if (conversationID) {
          if (
            isKeyOfObject(botName, conversationSettings) &&
            isKeyOfObject(conversationID, conversationSettings[botName])
          ) {
            return conversationSettings[botName][conversationID]
          }
        } else {
          if (isKeyOfObject(botName, tempConversationSettings)) {
            return tempConversationSettings[botName]
          }
        }
      } else if (!botName && conversationID) {
        if (config) {
          for (const bot of config) {
            if (
              isKeyOfObject(bot.botName, conversationSettings) &&
              isKeyOfObject(conversationID, conversationSettings[bot.botName])
            ) {
              return conversationSettings[bot.botName][conversationID]
            }
          }
        }
      } else {
        return undefined
      }
      return undefined
    },
    [config, conversationSettings, tempConversationSettings]
  )

  /**
   * Updates the settings of the provided bot-conversation, with the provided new values
   * If no new values are provided, updates it with the default values for the provided bot
   * @param {string} botName - The name of the bot
   * @param {string} conversationID - The id of the conversation
   * @param {ConversationSettings | undefined} newSettings - The new settings that the current conversation will be saved to
   * @returns {void}
   */
  const updateConversationSettings = useCallback(
    (botName: string, conversationID: string | null, newSettings?: ConversationSettings): void => {
      if (conversationID) {
        setConversationSettings((currentSettings) => {
          return {
            ...currentSettings,
            [botName]: { ...currentSettings[botName], [conversationID]: newSettings || defaultBotSettings[botName] },
          }
        })
      } else {
        setTempConversationSettings((currentTempSettings) => {
          return {
            ...currentTempSettings,
            [botName]: { ...currentTempSettings[botName], ...(newSettings || defaultBotSettings[botName]) },
          }
        })
      }
    },
    [defaultBotSettings]
  )

  /**
   * Deletes the settings of the provided bot-conversation
   * @param {string[]} conversationIDsToDelete - The id's of the conversations to delete
   * @returns {void}
   */
  const deleteConversationSettings = useCallback((conversationIDsToDelete: string[]): void => {
    setConversationSettings((currentSettings) => {
      const filteredConversations: ConversationsSettingsType = {}

      for (const key in currentSettings) {
        const conversationRecord = currentSettings[key]
        const filteredRecord: Record<string, ConversationSettings> = {}

        for (const numKey in conversationRecord) {
          if (!conversationIDsToDelete.includes(numKey)) {
            filteredRecord[numKey] = conversationRecord[numKey]
          }
        }

        if (Object.keys(filteredRecord).length > 0) {
          filteredConversations[key] = filteredRecord
        }
      }

      return filteredConversations
    })
  }, [])

  const replaceConversationIdInConversationSettings = (payload: {
    botName: string
    oldConversationId: string | null
    newConversationId: string
    initializeIfNoBotRecordExists?: boolean
  }) => {
    const { botName, initializeIfNoBotRecordExists, oldConversationId, newConversationId } = payload

    replaceIdInRecordBotSpecific({
      botName,
      initializeIfNoBotRecordExists,
      oldConversationId,
      newConversationId,
      setState: setConversationSettings,
    })
  }

  return (
    <>
      <ConversationSettingsContext.Provider
        value={{
          deleteConversationSettings,
          getConversationSettings,
          replaceConversationIdInConversationSettings,
          updateConversationSettings,
        }}
      >
        {children}
      </ConversationSettingsContext.Provider>
    </>
  )
}

export const useConversationSettingsContext = (): ConversationSettingsContextType =>
  useContext(ConversationSettingsContext)
