import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ChildrenProps, UIClassNameConfig, UICLassNameConfigValue } from '../types/types'

import { useConfigContext } from './ConfigurationProvider'
import { useI18Context } from './i18Provider'
import { useThemeContext } from './ThemeProvider'

type UIConfigContextType = {
  getUILabel: (key: string, namespace: string) => string | undefined
  getUIClassName: (key: string, defaultClassName?: string) => string | undefined
}

export const UIConfigContext = createContext<UIConfigContextType>({} as UIConfigContextType)

export function UIConfigProvider({ children }: ChildrenProps): JSX.Element {
  const { doesTranslationExist, getTForNS, loadUISpecificTranslations } = useI18Context()
  const { i18n } = useTranslation()
  const { isLightMode } = useThemeContext()
  const { ENV_TYPE } = useConfigContext()
  const [loadedUIConfigs, setLoadedUIConfigs] = useState(false)
  const [uiOverrideStyles, setUIOverrideStyles] = useState({})
  const uiT = getTForNS(`appconfiguration/${ENV_TYPE}`)

  useEffect(() => {
    const fetchData = async () => {
      const uiStylesOverride = await fetch(/* webpackIgnore: true */ `/appconfiguration/${ENV_TYPE}/styles/styles.json`)
      await loadUISpecificTranslations(ENV_TYPE)
      setUIOverrideStyles(await uiStylesOverride.json())
      // Setting this flag ensures that we only run this useEffect ONCE on application load
      setLoadedUIConfigs(true)
    }
    if (!loadedUIConfigs) {
      fetchData()
    }
  }, [ENV_TYPE, loadUISpecificTranslations, loadedUIConfigs])

  /**
   * Returns the whole object of a given ui configuration, if it exists
   * @param {string} dotNotationKey - the nesting of a json object in dot notation (eg a[b][c] -> a.b.c)
   * @returns {string | undefined}
   */
  const getUIClassNameConfigValue = useCallback(
    (dotNotationKey: string): string | undefined => {
      const keys = dotNotationKey.split('.')
      const uiClassNameConfigValue: UICLassNameConfigValue = keys.reduce(
        (currentVal: UIClassNameConfig | UICLassNameConfigValue, key) => {
          if (!currentVal || 'light' in currentVal) {
            return currentVal
          }
          return currentVal[key]
        },
        uiOverrideStyles ?? {}
      ) as UICLassNameConfigValue

      // if the type of the value is string at the end, then that means we found the desired value
      return uiClassNameConfigValue?.[isLightMode ? 'light' : 'dark']
    },
    [isLightMode, uiOverrideStyles]
  )

  /**
   * Returns the label of a given ui configuration
   * @param {string} key - the nesting of a json object in dot notation (eg a[b][c] -> a.b.c)
   * @param {string} namespace - the namespace of the key
   * @returns {string} - the label as a string
   */
  const getUILabel = useCallback(
    (key: string, namespace: string): string | undefined => {
      // Check if the namespace is already loaded
      if (!i18n.hasLoadedNamespace(namespace)) {
        // If it's not loaded, load it
        i18n.loadNamespaces(namespace).then(() => {
          // Now the namespace is loaded, you can safely get the translation
          const t = i18n.getFixedT(i18n.language, namespace)
          return doesTranslationExist(key, `appconfiguration/${ENV_TYPE}`) ? uiT(key) : t(key)
        })
      } else {
        // If the namespace is already loaded, use the translation immediately
        const t = i18n.getFixedT(i18n.language, namespace)
        return doesTranslationExist(key, `appconfiguration/${ENV_TYPE}`) ? uiT(key) : t(key)
      }
    },
    [ENV_TYPE, doesTranslationExist, i18n, uiT]
  )

  /**
   * Returns the className of a given ui configuration
   * @param {string} key - the nesting of a json object in dot notation (eg a[b][c] -> a.b.c)
   * @param {string} defaultClassName - a className to fall back on if the key does not exist in the configuration
   * @returns {JSX.Element | string} - the label as a string, or if loading a skeleton
   */
  const getUIClassName = useCallback(
    (key: string, defaultClassName?: string): string | undefined => {
      const className = getUIClassNameConfigValue(key)

      return className ?? defaultClassName
    },
    [getUIClassNameConfigValue]
  )

  return (
    <>
      <UIConfigContext.Provider value={{ getUILabel, getUIClassName }}>{children}</UIConfigContext.Provider>
    </>
  )
}

export const useUIConfigContext = () => useContext(UIConfigContext)
