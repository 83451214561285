import XRegExp from 'xregexp'

// function to sanitize unicode strings
const sanitizeChatInput = (inputString: string): string => {
  // preserve newlines
  const stringifyInput = JSON.stringify(inputString)

  // p{L} - Letters, p{N} - Numbers, p{Sm} - math symbols, p{Sc} - currency symbols
  const allowedPattern = XRegExp('^[\\p{L}\\p{N}\\p{Sm}\\p{Sc}\\p{P}\\s,.!*?-]*$')

  // bidirectional text control characters, zero-width characters
  const problematicPattern = XRegExp('[\\p{Cf}\\p{Zl}\\p{Zp}\\p{Cc}\\u200E\\u200F\\u202A-\\u202E]+', 'g')

  const sanitizedInput = XRegExp.replace(stringifyInput, problematicPattern, '')

  if (XRegExp.test(sanitizedInput, allowedPattern)) {
    try {
      // input is safe
      // Run JSON.parse since we originally stringified the inputString
      const parsed = JSON.parse(sanitizedInput)
      // If parsed is a string, return it directly; otherwise coerce to string:
      return typeof parsed === 'string' ? parsed : String(parsed)
    } catch (e) {
      // If we are unable to parse, just return the string we were trying to sanitize
      // Most likely a weird occurrences of the user sending "`" or "^"
      return inputString
    }
  } else {
    const disallowedPattern = XRegExp('[^\\p{L}\\p{N}\\s,.!?-]', 'g')
    try {
      // input is not safe, run another replace
      // Run JSON.parse since we originally stringified the inputString
      const parsed = JSON.parse(XRegExp.replace(stringifyInput, disallowedPattern, ''))
      // If parsed is a string, return it directly; otherwise coerce to string:
      return typeof parsed === 'string' ? parsed : String(parsed)
    } catch (e) {
      // If we are unable to parse, just return the string we were trying to sanitize
      // Most likely a weird occurrences of the user sending "`" or "^"
      return inputString
    }
  }
}

export { sanitizeChatInput }
