import { createContext, ReactNode, useContext } from 'react'
import { useColorMode } from '@chakra-ui/color-mode'
import { useMediaQuery } from '@chakra-ui/media-query'

type ThemeType = {
  isDesktop: boolean
  isExtraLargeDesktop: boolean
  isLaptop: boolean
  isLargeDesktop: boolean
  isLargeTablet: boolean
  isLightMode: boolean
  isMobile: boolean
  isTablet: boolean
  toggleColorMode: () => void
}

export const ThemeContext = createContext<ThemeType>({} as ThemeType)

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [isMobile] = useMediaQuery('(max-width: 450px)')
  const [isTablet] = useMediaQuery('(min-width: 768px)')
  const [isLargeTablet] = useMediaQuery('(min-width: 864px)')
  const [isLaptop] = useMediaQuery('(min-width: 1024px)')
  const [isDesktop] = useMediaQuery('(min-width: 1280px)')
  const [isLargeDesktop] = useMediaQuery('(min-width: 1536px)')
  const [isExtraLargeDesktop] = useMediaQuery('(min-width: 2048px)')

  const { colorMode, toggleColorMode } = useColorMode()

  return (
    <>
      <ThemeContext.Provider
        value={{
          isDesktop,
          isExtraLargeDesktop,
          isLaptop,
          isLargeDesktop,
          isLargeTablet,
          isLightMode: colorMode === 'light',
          isMobile,
          isTablet,
          toggleColorMode,
        }}
      >
        {children}
      </ThemeContext.Provider>
    </>
  )
}

export const useThemeContext = (): ThemeType => useContext(ThemeContext)
