import React, { useEffect, useRef, useState } from 'react'
import { BiInfoCircle } from 'react-icons/bi'
import { Tooltip, TooltipProps } from '@chakra-ui/tooltip'
import { Fade } from '@chakra-ui/transition'
import cx from 'classnames'

import { useThemeContext } from 'providers/ThemeProvider'

type InfoTooltipProps = {
  label: React.ReactNode
  iconClassName?: string
} & Omit<TooltipProps, 'children'>

const InfoTooltip: React.FC<InfoTooltipProps> = ({
  iconClassName = 'self-end w-4 h-4 text-gray-600',
  className,
  placement = 'right',
  tabIndex = 0,
  closeOnClick = true,
  isDisabled = false,
  ...rest
}) => {
  const { isLightMode, isMobile, isDesktop } = useThemeContext()
  const [isOpen, setIsOpen] = useState(false)
  const tooltipRef = useRef<HTMLDivElement | null>(null)

  const handleMouseEnter = () => !isDisabled && isDesktop && setIsOpen(true)
  const handleMouseLeave = () => !isDisabled && isDesktop && setIsOpen(false)
  const handleClick = () => !isDisabled && setIsOpen((prev) => !prev)

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick)
    } else {
      document.removeEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [isOpen])

  const tooltipClasses = ['text-xs', isMobile && 'max-w-[45vw]', isLightMode ? 'text-white' : 'text-black']

  return (
    <Tooltip
      ref={tooltipRef}
      className={cx(className, tooltipClasses)}
      closeOnClick={closeOnClick}
      isDisabled={isDisabled}
      isOpen={isOpen}
      placement={placement}
      tabIndex={tabIndex}
      {...rest}
    >
      <Fade in>
        <BiInfoCircle
          className={cx(iconClassName)}
          onClick={!isDesktop ? handleClick : undefined}
          onMouseEnter={isDesktop ? handleMouseEnter : undefined}
          onMouseLeave={isDesktop ? handleMouseLeave : undefined}
        />
      </Fade>
    </Tooltip>
  )
}

export { InfoTooltip }
