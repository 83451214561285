import { NetworkError } from '../appError'

type RequestInitWithRequestId = RequestInit & {
  headers: Headers | (Record<string, string> & { 'x-msp-ui-request-id': string })
}

export type ResponseWithRequestId<T> = {
  response: T
  requestId: string
}

export const retryWrapper = async <T>(
  requestFunction: (config: RequestInit) => Promise<T>,
  path: string,
  config: RequestInit,
  retries: number,
  requestId: string,
  setRetrying?: (value: boolean) => void,
  includeRequestId = true
): Promise<ResponseWithRequestId<T>> => {
  const headers = ensureHeaders(config.headers, requestId, includeRequestId)
  const amendedConfig: RequestInitWithRequestId = { ...config, headers }

  try {
    const response = await requestFunction(amendedConfig)

    return {
      response,
      requestId,
    }
  } catch (e) {
    ;(e as NetworkError).requestId = requestId
    console.error(`Error: Failed to fetch and reach server. Retries left: ${retries}, Request ID: ${requestId}`, e)

    if (retries > 0) {
      setRetrying && setRetrying(true)
      return retryWrapper(requestFunction, path, config, retries - 1, requestId, setRetrying)
    } else {
      setRetrying && setRetrying(false)
      throw e
    }
  }
}

const http = async (path: string, config: RequestInit): Promise<Response> => {
  const response = await fetch(path, config)

  if (response.ok) {
    return response
  }

  const requestId =
    config.headers instanceof Headers ? config.headers.get('x-msp-ui-request-id') ?? 'unknown' : 'unknown'

  throw new NetworkError('request-error', -1, extractHeaders(response), response.status.toString(), requestId)
}

export const httpJSON = async <T>(
  path: string,
  config: RequestInit,
  retries: number,
  requestId: string,
  setRetrying?: (value: boolean) => void
): Promise<T> => {
  const { response } = await retryWrapper(
    async (config: RequestInit) => {
      return (await http(path, config)).json()
    },
    path,
    config,
    retries,
    requestId,
    setRetrying
  )

  return response
}

export const httpStream = async (
  path: string,
  config: RequestInit,
  retries: number,
  requestId: string,
  setRetrying?: (value: boolean) => void
): Promise<Response> => {
  const { response } = await retryWrapper(
    async (config: RequestInit) => {
      return fetch(path, config)
    },
    path,
    config,
    retries,
    requestId,
    setRetrying
  )

  return response
}

export const httpRaw = async (
  path: string,
  config: RequestInit,
  retries: number,
  requestId: string,
  setRetrying?: (value: boolean) => void,
  includeRequestId = true
): Promise<ResponseWithRequestId<Response>> => {
  return retryWrapper(
    async (config: RequestInit) => {
      return http(path, config)
    },
    path,
    config,
    retries,
    requestId,
    setRetrying,
    includeRequestId
  )
}

export const extractHeaders = (response: Response): string[] => {
  const responseHeaders: string[] = []
  if (response.headers) {
    response.headers.forEach((header, key) => {
      if (!header.toLowerCase().includes('authorization') && !key.toLowerCase().includes('authorization')) {
        responseHeaders.push(`${key}: ${header}`)
      }
    })
  }
  return responseHeaders
}

const ensureHeaders = (headers: HeadersInit | undefined, requestId: string, includeRequestId: boolean): Headers => {
  const headersInstance = headers instanceof Headers ? headers : new Headers(headers)
  if (includeRequestId) {
    headersInstance.set('x-msp-ui-request-id', requestId)
  }
  return headersInstance
}
