import { ReactNode, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

import { TextButton } from 'components/Button'
import { GradientHeaderTextWrapper } from 'components/GradientHeaderText'

import { useEventLogger } from 'hooks/useEventLogger'

import { useI18Context } from 'providers/i18Provider'
import { useTermsContext } from 'providers/TermsProvider'
import { useThemeContext } from 'providers/ThemeProvider'

import { FEUIConfig } from 'types/types'

export const BotRouteWrapper = ({ config, children }: { config: FEUIConfig; children: ReactNode }) => {
  const { botName, isAcknowledgement } = config
  const { setTermsForBot } = useTermsContext()
  const location = useLocation()

  useEffect(() => {
    // Only reset if the user did not just come from the terms page.
    if (isAcknowledgement && !location.state?.accepted) {
      setTermsForBot(botName, false)
    }
  }, [botName, setTermsForBot, isAcknowledgement, location.state?.accepted])

  return children
}

export const TermsGuard = ({ config, children }: { config: FEUIConfig; children: ReactNode }) => {
  const { botName, route, isAcknowledgement } = config
  const { getTermsForBot } = useTermsContext()
  const location = useLocation()

  // If the bot does not require acknowledgement, just return the children.
  if (!isAcknowledgement) {
    return children
  }

  if (!getTermsForBot(botName)) {
    // Redirect to the terms page, passing the current location in state.
    return <Navigate to={`${route}/terms`} state={{ from: location }} replace />
  }
  return children
}

export const TermsAndConditions = ({ config }: { config: FEUIConfig }) => {
  const { botName, route } = config

  const { setTermsForBot } = useTermsContext()
  const navigate = useNavigate()
  const { logUIGeneralUSAEvent } = useEventLogger()
  const { doesTranslationExist, getTForNS } = useI18Context()
  const { t } = useTranslation('generalModal')
  const { isLightMode } = useThemeContext()

  const getTForNSMemoized = useMemo(() => getTForNS, [getTForNS])

  const botT = getTForNSMemoized(`bot/${botName.toLocaleLowerCase()}`)

  const headerText = useMemo(() => {
    return doesTranslationExist('welcomeToHeader', botName.toLowerCase()) ? botT('welcomeToHeader') : botT('header')
  }, [botName, botT, doesTranslationExist])

  const handleAccept = () => {
    logUIGeneralUSAEvent({
      bot: botName,
      data: {
        agreeUseUSA: 'yes',
      },
    })

    // Mark the current bot as accepted.
    setTermsForBot(botName, true)

    navigate(route, { replace: true, state: { accepted: true } })
  }

  const handleCancel = () => {
    logUIGeneralUSAEvent({
      bot: botName,
      data: {
        agreeUseUSA: 'no',
      },
    })

    // Mark the current bot as accepted.
    setTermsForBot(botName, false)

    // Redirect back to the fallback page
    navigate('/general')
  }

  return (
    <div className="flex flex-col justify-center width-layout">
      <div className={`px-4 py-3 my-4 border border-kpmgGray4 rounded-xl ${isLightMode ? 'bg-white' : 'bg-kpmgGray1'}`}>
        <div className="space-y-2">
          <div className="text-3xl">
            <GradientHeaderTextWrapper>
              <h1>{t('modal.header')}</h1>
            </GradientHeaderTextWrapper>
          </div>
          <p className="font-bold">
            {t('home.youAreAboutToNavigate', { ns: 'home' })} {headerText}.
          </p>
          <p>{t('modal.ack')}</p>
        </div>
        <div className="flex justify-start mt-4 space-x-3">
          <TextButton
            onClick={handleAccept}
            text={t('modal.yes')}
            buttonBackground={isLightMode ? 'bg-kpmgCobaltBlue' : 'bg-white'}
            fontColour={isLightMode ? 'text-white' : 'text-black'}
            size="lg"
          />
          <TextButton onClick={handleCancel} text={t('modal.no')} size="lg" />
        </div>
      </div>
    </div>
  )
}
