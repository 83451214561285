import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select, { CSSObjectWithLabel, GroupBase, OptionProps, OptionsOrGroups, SingleValue } from 'react-select'
import { Checkbox } from '@chakra-ui/checkbox'
import { Box, Text } from '@chakra-ui/layout'
import { Tooltip } from '@chakra-ui/tooltip'
import cx from 'classnames'
import kleoColors from 'styles/colors'

import { useBotSpecificFormContext } from 'providers/FormProvider'
import { useI18Context } from 'providers/i18Provider'
import { useThemeContext } from 'providers/ThemeProvider'

import { GroupedOptions, SearchFilterItem, ToggleProps } from 'types/types'

import { RequiredAsteriskText } from './RequiredAsteriskText'

// In the future if we need it, GroupedOption[] is available as a type for options
type SelectProps = {
  apiError?: boolean
  botName: string
  clearable?: boolean
  disableTabbing?: boolean // Used when we don't want to allow a user to tab to this component (eg. in a hidden drawer, or it's disabled)
  fullWidth?: boolean
  handleOnChange?: (selectedOption: SingleValue<SearchFilterItem> | null) => void
  isLoading?: boolean
  isStreaming?: boolean
  label?: string
  name: string
  newStyle?: boolean
  options: SearchFilterItem[]
  placeholder?: string
  required?: boolean
  validateOnClear: boolean
  value: SearchFilterItem[]
  stylingValues?: SelectStylingProps
} & ToggleProps

type SelectStylingProps = {
  control?: (provided: CSSObjectWithLabel, isLoading?: boolean) => CSSObjectWithLabel
  indicatorsContainer?: (provided: CSSObjectWithLabel, isLoading?: boolean) => CSSObjectWithLabel
  menuPortal?: (provided: CSSObjectWithLabel, isLoading?: boolean) => CSSObjectWithLabel
  noOptionsMessage?: (provided: CSSObjectWithLabel, isLoading?: boolean) => CSSObjectWithLabel
  option?: (
    provided: CSSObjectWithLabel,
    isLoading?: boolean,
    state?: OptionProps<SearchFilterItem, boolean, GroupBase<SearchFilterItem>>
  ) => CSSObjectWithLabel
  placeholder?: (provided?: CSSObjectWithLabel, isLoading?: boolean) => CSSObjectWithLabel
  singleValue?: (provided: CSSObjectWithLabel, isLoading?: boolean) => CSSObjectWithLabel
  valueContainer?: (provided: CSSObjectWithLabel, isLoading?: boolean) => CSSObjectWithLabel
}

export const KLEOSelect = (props: SelectProps) => {
  const {
    apiError = false,
    clearable = true,
    disableTabbing = false,
    filterKey,
    fullWidth = false,
    handleToggle,
    handleOnChange,
    isCheckboxDisabled,
    isChecked,
    isLoading = false,
    isStreaming = false,
    label,
    name,
    newStyle = false,
    options,
    placeholder,
    required = false,
    validateOnClear = true,
    value: formValue,
    stylingValues,
  } = props
  const { doesTranslationExist } = useI18Context()
  const { t } = useTranslation(['filters', 'generic'])
  const { isTablet, isLightMode } = useThemeContext()
  const { setValue } = useBotSpecificFormContext()

  const [inputValue, setInputValue] = useState<string>('')
  const [isMenuHovered, setIsMenuHovered] = useState<boolean>(false)
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

  const handleInputChange = (value: string) => {
    setInputValue(value)
  }

  // Handle option selection
  const handleSelectChange = (selectedOption: SingleValue<SearchFilterItem> | null) => {
    if (handleOnChange) {
      // Call the handleOnChange prop, passing the selectedOption
      handleOnChange(selectedOption)
    }

    if (selectedOption) {
      setValue({ field: name, value: [selectedOption], shouldValidate: false })
      // After selecting an option, this prevents the Tooltip from showing up
      setIsMenuHovered(false)
    } else {
      // If there isn't a selectedOption, reset this field to undefined
      setValue({ field: name, value: [], shouldValidate: validateOnClear })
    }
  }

  // This useEffect is used to check whether the currently selected value is one of the available options
  // If it isn't one of the available options, reset it to undefined
  useEffect(() => {
    if (Array.isArray(formValue) && formValue.length > 0) {
      // Get the index of the matching value
      const indexOfValue = options.findIndex((optionsObject: SearchFilterItem) => {
        // Since it is a single dropdown, we can use index 0 for finding a matching value
        return optionsObject.value === formValue[0].value
      })
      // If indexOfValue returns a value that isn't -1 (aka false), return the options value given the indexOfValue
      if (indexOfValue && indexOfValue === -1) {
        // The selected value is no longer an option, so set the selected value to null
        setValue({ field: name, value: [], shouldValidate: validateOnClear })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  // if number of option is more than 10, depend on the inputValue, otherwise show dropdown options
  const selectOptions = useMemo(() => {
    if (options.length <= 10) {
      return options
    } else {
      if (inputValue) {
        return options
      } else {
        return []
      }
    }
  }, [inputValue, options])

  return newStyle ? (
    <Box className="w-full">
      <Box className="flex items-center mb-1" tabIndex={disableTabbing ? -1 : 0}>
        <Text>
          {label}
          {required && (
            <>
              {' '}
              <RequiredAsteriskText showText={false} asteriskPosition="right" />
            </>
          )}
        </Text>
      </Box>
      <Box
        as="span"
        className="text-sm"
        onMouseOver={() => setIsMenuHovered(true)}
        onMouseLeave={() => setIsMenuHovered(false)}
      >
        <Select
          aria-label="select-dropdown"
          closeMenuOnSelect={true}
          formatOptionLabel={newStyleFormatOptionLabel}
          inputValue={inputValue}
          isClearable={clearable}
          isDisabled={apiError || isStreaming || options?.length === 0}
          tabIndex={disableTabbing ? -1 : 0}
          isLoading={isLoading}
          isSearchable={true}
          menuPortalTarget={document.body}
          noOptionsMessage={() => (inputValue ? t('filters.noMatch') : t('filters.typeToFilter'))}
          onChange={handleSelectChange}
          onInputChange={handleInputChange}
          // onMenuOpen and onMenuClose help determine when to show the Tooltip. Don't want to show the Tooltip when the menu is open, or it will overlap the options being shown
          onMenuOpen={() => setIsMenuOpen(true)}
          onMenuClose={() => setIsMenuOpen(false)}
          options={selectOptions}
          placeholder={placeholder ?? t('generic.select', { ns: 'generic' })}
          styles={{
            // Used to update the color of the placeholder when all options selected
            placeholder: (provided) => ({
              ...provided,
            }),
            // Used to color the options in the dropdown, whether they are selected or unselected
            option: (provided, state) => ({
              ...provided,
              fontSize: '14px',
              backgroundColor: state.isFocused ? kleoColors.kpmgPacificBlue : provided.backgroundColor,
              border: state.isFocused ? `1px solid ${kleoColors.kpmgPacificBlue}` : provided.border,
              cursor: state.isSelected ? 'not-allowed' : 'pointer',
            }),
            // The text color for filters.typeToFilter message
            noOptionsMessage: (provided) => ({
              ...provided,
              color: 'black',
              fontSize: '14px',
            }),
            // This is the main dropdown component, and when hovering with the cursor we want to show the "text" variant to indicate a user can type on selection
            control: (provided, state) => ({
              ...provided,
              cursor: 'pointer',
              width: '100%',
              background: 'none',
              border: `1px solid ${kleoColors.kpmgGray2}`,
            }),
            menuPortal: (provided) => ({
              ...provided,
              zIndex: 9999,
            }),
            menuList: (provided) => ({
              ...provided,
              zIndex: 9999,
              // #9ca3af is gray-400, #1f2937 is gray-800
              background: isLightMode ? kleoColors.gray400 : kleoColors.darkGray800,
            }),

            indicatorsContainer: (provided) => ({
              ...provided,
              background: 'none',
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              color: kleoColors.kpmgPacificBlue,
            }),
            clearIndicator: (provided) => ({
              ...provided,
              color: kleoColors.kpmgPacificBlue,
            }),
          }}
          value={formValue?.length === 0 ? [] : formValue[0]}
        />
      </Box>
    </Box>
  ) : (
    <Box
      className={cx({
        'w-full': fullWidth,
      })}
    >
      {label ||
        (doesTranslationExist(`filters.${name}`, 'filters') && (
          <Box className="flex items-center mb-1" tabIndex={disableTabbing ? -1 : 0}>
            {filterKey !== undefined && handleToggle !== undefined && isChecked !== undefined ? (
              <Checkbox
                isDisabled={isCheckboxDisabled}
                onChange={(e) => {
                  handleToggle(e, filterKey)
                }}
                value={filterKey}
                isChecked={isChecked}
              >
                {label ?? t(`filters.${name}`)}
              </Checkbox>
            ) : (
              <Text>{label ?? t(`filters.${name}`)}</Text>
            )}
          </Box>
        ))}
      {/* Show a Tooltip of the full document name selected */}
      <Tooltip
        className="text-xs text-black bg-kpmgGray5"
        label={formValue?.length > 0 && formValue[0].label}
        placement="bottom-start"
        isOpen={isMenuHovered && !isMenuOpen}
        openDelay={200}
        isDisabled={!isTablet}
      >
        {/* Detect the Select component being hovered with onMouseOver and onMouseLeave. This is used to help determine when we need to show the Tooltip */}
        {/* Need the below Box as="span" in order for the Tooltip to work */}
        <Box
          as="span"
          className="text-sm"
          onMouseOver={() => setIsMenuHovered(true)}
          onMouseLeave={() => setIsMenuHovered(false)}
        >
          <Select
            aria-label="select-dropdown"
            closeMenuOnSelect={true}
            formatOptionLabel={formatOptionLabel}
            inputValue={inputValue}
            isClearable={clearable}
            isDisabled={apiError || isStreaming || options?.length === 0}
            tabIndex={disableTabbing ? -1 : 0}
            isLoading={isLoading}
            isSearchable={true}
            menuPortalTarget={document.body}
            noOptionsMessage={() => (inputValue ? t('filters.noMatch') : t('filters.typeToFilter'))}
            onChange={handleSelectChange}
            onInputChange={handleInputChange}
            // onMenuOpen and onMenuClose help determine when to show the Tooltip. Don't want to show the Tooltip when the menu is open, or it will overlap the options being shown
            onMenuOpen={() => setIsMenuOpen(true)}
            onMenuClose={() => setIsMenuOpen(false)}
            options={selectOptions}
            placeholder={placeholder ?? t('generic.select', { ns: 'generic' })}
            styles={{
              // Used to update the color of the placeholder when all options selected
              placeholder: (provided) => ({
                ...provided,
                color: isLoading ? kleoColors.gray700 : isLightMode ? kleoColors.gray600 : kleoColors.gray750,
                ...(stylingValues && stylingValues.placeholder && stylingValues.placeholder(provided)),
              }),
              // Used to color the options in the dropdown, whether they are selected or unselected
              option: (provided, state) => ({
                ...provided,
                cursor: 'pointer',
                fontSize: '14px',
                backgroundColor: state.isSelected ? '#red' : provided.backgroundColor,
                color: newStyle ? 'white' : 'black',
                ...(stylingValues && stylingValues.option && stylingValues.option(provided, undefined, state)),
              }),
              // The text color for filters.typeToFilter message
              noOptionsMessage: (provided) => ({
                ...provided,
                color: 'black',
                fontSize: '14px',
                ...(stylingValues && stylingValues.noOptionsMessage && stylingValues.noOptionsMessage(provided)),
              }),
              // This is the main dropdown component, and when hovering with the cursor we want to show the "text" variant to indicate a user can type on selection
              control: (provided) => ({
                ...provided,
                cursor: 'text',
                width: '100%',
                ...(stylingValues && stylingValues.control && stylingValues.control(provided)),
              }),
              menuPortal: (provided) => ({
                ...provided,
                zIndex: 9999,
                ...(stylingValues && stylingValues.menuPortal && stylingValues.menuPortal(provided)),
              }),
              valueContainer: (provided) => ({
                ...provided,
                ...(stylingValues && stylingValues.valueContainer && stylingValues.valueContainer(provided)),
              }),
              singleValue: (provided) => ({
                ...provided,
                ...(stylingValues && stylingValues.singleValue && stylingValues.singleValue(provided)),
              }),
              indicatorsContainer: (provided) => ({
                ...provided,
                ...(stylingValues && stylingValues.indicatorsContainer && stylingValues.indicatorsContainer(provided)),
              }),
            }}
            value={formValue?.length === 0 ? [] : formValue[0]}
          />
        </Box>
      </Tooltip>

      {apiError && (
        <Text
          tabIndex={disableTabbing ? -1 : 0}
          className={`text-sm mt-0.5 ${isLightMode ? 'text-red-600' : 'text-red-400'}`}
        >
          {t('generic.errorFetching', { ns: 'generic' })}
        </Text>
      )}
    </Box>
  )
}
type BasicSelectProps = {
  className?: string
  clearable?: boolean
  onChange: (selectedOption: SingleValue<SearchFilterItem> | null) => void
  options: OptionsOrGroups<SearchFilterItem, GroupedOptions<SearchFilterItem>> | undefined
  value: SingleValue<SearchFilterItem> | undefined
  placeholder?: string
}

export const BasicSelect = ({
  className,
  clearable = false,
  onChange,
  options,
  placeholder,
  value,
}: BasicSelectProps) => {
  const { isLightMode } = useThemeContext()
  const { t } = useTranslation('generic')
  return (
    <Select
      aria-label="select-dropdown"
      closeMenuOnSelect={true}
      formatOptionLabel={formatOptionLabel}
      isClearable={clearable}
      tabIndex={0}
      isSearchable={false}
      menuPortalTarget={document.body}
      onChange={onChange}
      options={options}
      className={className}
      placeholder={placeholder || t('generic.select', { ns: 'generic' })}
      styles={{
        // Used to update the color of the placeholder when all options selected
        placeholder: (provided) => ({
          ...provided,
          color: kleoColors.gray600,
        }),
        // Used to color the options in the dropdown, whether they are selected or unselected
        option: (provided, state) => ({
          ...provided,
          cursor: 'pointer',
          fontSize: '14px',
          backgroundColor: state.isSelected
            ? isLightMode
              ? kleoColors.kpmgLightBlue
              : kleoColors.darkLink
            : provided.backgroundColor,
          color: 'black',
        }),
        // The text color for filters.typeToFilter message
        noOptionsMessage: (provided) => ({
          ...provided,
          color: 'black',
          fontSize: '14px',
        }),
        // This is the main dropdown component, and when hovering with the cursor we want to show the "text" variant to indicate a user can type on selection
        control: (provided) => ({
          ...provided,
          cursor: 'text',
        }),
        menuPortal: (provided) => ({
          ...provided,
          zIndex: 9999,
        }),
        // the value
        singleValue: (provided) => ({
          ...provided,
          fontSize: '14px',
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          fontSize: '14px',
        }),
      }}
      value={value}
    />
  )
}

const formatOptionLabel = (data: SearchFilterItem) => (
  <Box className="flex items-center justify-between">
    <span>{data.label}</span>
  </Box>
)
const newStyleFormatOptionLabel = (data: SearchFilterItem) => (
  <Box className="flex items-center justify-between">
    <span>{data.label}</span>
  </Box>
)
