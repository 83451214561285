const kleoColors = {
  backgroundBlue: '#1a202c',
  backgroundDark: '#212121',
  backgroundLight: '#E6EFFF',
  black: '#000000',
  blackAlpha: '#00000014',
  blackAlphaHover: '#00000028',
  darkGray800: '#1f2937',
  darkLink: '#95ccff',
  darkLinkHover: '#80C1FF',
  gray400: '#9ca3af',
  gray600: '#4B5563',
  gray700: '#A8B1BD',
  gray750: '#9DA3AE',
  grayLight: '#BBC0CB',
  kpmgBlue: '#02338D',
  kpmgBlueHover: '#033FB0',
  kpmgCobaltBlue: '#1E4AE2',
  kpmgCobaltBlueHover: '#1A41CB',
  kpmgDarkBlue: '#0C233C',
  kpmgDarkBlueHover: '#0F2B49',
  kpmgDarkGray5: '#D9D9D9',
  kpmgDarkPurple: '#510DBC',
  kpmgGray1: '#333',
  kpmgGray1Hover: '#404040',
  kpmgGray12: '#424242',
  kpmgGray2: '#666',
  kpmgGray3: '#989898',
  kpmgGray4: '#B2B2B2',
  kpmgGray45: '#D1D1D1',
  kpmgGray5: '#E5E5E5',
  kpmgGray6: '#F5F5F5',
  kpmgGray7: '#D1D5DB',
  kpmgGray8: '#A1A1A1',
  kpmgGreen: '#22C55E',
  kpmgLightBlue: '#ACEAFF',
  kpmgLightBlue2: '#75D2FF',
  kpmgLightBlue3: '#62B3ED',
  kpmgLightBlueHover: '#80DFFF',
  kpmgLightPink: '#FFA3D9',
  kpmgLightPurple: '#B497FF',
  kpmgLightPurpleHover: '#A480FF',
  kpmgNavyBlue: '#0A2849',
  kpmgPacificBlue: '#00B8F5',
  kpmgPink: '#FD349C',
  kpmgPurple: '#7213EA',
  kpmgPurpleHover: '#822BEE',
  kpmgRed: '#FC8181',
  lightLink: '#0069C2',
  lightLinkHover: '#007AE6',
  white: '#FFFFFF',
  whiteAlpha: '#FFFFFF14',
  whiteAlphaHover: '#FFFFFF28',
  whiteGray: '#CCCCCC',
  whiteHover: '#F2F2F2',
}

export default kleoColors
