import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDisclosure } from '@chakra-ui/hooks'
import { SourceType } from '@kleo/types'
import { TFunction } from 'i18next'

import { ModalBox } from 'components/Modal'
import { UploadModal } from 'components/UploadModal'

import { formatMaxFileSize } from 'utils/formatter'
import { trimFormValues } from 'utils/KBotsUtils'

import { useI18Context } from 'providers/i18Provider'
import { useKBotContext } from 'providers/KBotsProvider'
import { useUploadContext } from 'providers/UploadProvider'

import type { FileConfigs, KBotFormState } from 'types/types'

import { CreateEditForm } from './CreateEditForm'

export const CreateEdit = ({ state }: { state: KBotFormState }) => {
  const botName = 'KBOTS'
  const kBotFormId = state === 'edit' ? 'editKBotTemp' : 'createKBotTemp'
  const { language } = useI18Context()
  const { t } = useTranslation('kBots')
  const {
    clearFormState,
    createValidate,
    editValidate,
    getIndividualKBotData,
    getKBotFormValue,
    getKBotFormValues,
    getTranslatedValue,
    isJSONUploading,
    generalConfig: { templateMaxFileSize, uploadTokenMaxSize, nameMaxLength, uploadFileMaxCount },
    isJSONUploadOpen,
    kBotDetailsForKBotUtilization,
    onJSONUpload,
    onJSONUploadClose,
    saveKBot,
    setKBotFormValue,
    setKBotDetailsForKBotUtilization,
    setShouldFetchKBots,
    setUploadJSONError,
    uploadJSONError,
  } = useKBotContext()
  const { deleteDocumentContent, docContents, uploadFileRequest } = useUploadContext()

  const {
    isOpen: isBotCreationFinishedOpen,
    onOpen: onOpenBotCreationFinished,
    onClose: onCloseBotCreationFinished,
  } = useDisclosure()

  const numberFormat = useMemo(() => {
    return new Intl.NumberFormat(language)
  }, [language])

  const jsonMaxFileSize = useMemo(() => {
    return formatMaxFileSize(templateMaxFileSize ?? 0, numberFormat, t as TFunction)
  }, [numberFormat, t, templateMaxFileSize])

  const [submissionErrors, setSubmissionErrors] = useState<Record<KBotFormState, string[]>>({ create: [], edit: [] })

  const updateSubmissionError = (formState: KBotFormState, error: string[]) => {
    setSubmissionErrors((prevState) => ({
      ...prevState,
      [formState]: error,
    }))
  }

  const nameValues = useMemo(() => getKBotFormValue('name', state), [getKBotFormValue, state])

  const handleSubmit = async () => {
    try {
      updateSubmissionError(state, [])
      const currentFormValues = getKBotFormValues(state)
      const cleanedFormValues = trimFormValues(currentFormValues)

      await (state === 'edit' ? editValidate(cleanedFormValues) : createValidate(cleanedFormValues))

      const saveResponse = await saveKBot({
        state,
        payload: { ...cleanedFormValues },
      })

      if (saveResponse && 'templateId' in saveResponse && 'template' in saveResponse) {
        setShouldFetchKBots(true)
        if (cleanedFormValues.fixedName && cleanedFormValues.fixedName === kBotDetailsForKBotUtilization?.name) {
          // If for this edited K-Bot we have it selected in the New Conversation modal, we need to re-fetch it's details based on latest edit changes
          const kBotData = await getIndividualKBotData(SourceType.user, cleanedFormValues.fixedName)
          if (kBotData) {
            if (kBotData.name === kBotDetailsForKBotUtilization?.name) {
              setKBotDetailsForKBotUtilization(kBotData)
            }
          }
        }
        // set the id on the current kbot state
        setKBotFormValue('templateId', saveResponse.templateId, state)
        setKBotFormValue('name', saveResponse.template, state)
        onOpenBotCreationFinished()
      }

      if ('errorList' in saveResponse) {
        const codes = saveResponse.errorList.map((error) => t(`kBots.error.${error.code}`))
        updateSubmissionError(state, codes)
      }
    } catch (e) {
      updateSubmissionError(state, [t('kBots.createEdit.unableToCreateKBot')])
      // do something when it fails
    }
  }

  // File upload
  const handleFileSubmit = useCallback(
    (files: File[], fileConfigs: FileConfigs) => {
      if (files?.length) {
        uploadFileRequest(botName, files[0], fileConfigs, false, uploadTokenMaxSize, 0, kBotFormId, true)
      }
    },
    [botName, kBotFormId, uploadFileRequest, uploadTokenMaxSize]
  )

  // sync the uploadprovider state with the formState
  useEffect(() => {
    if (botName in docContents && kBotFormId in docContents[botName]) {
      setKBotFormValue('fileContent', docContents[botName][kBotFormId].text, state)
    }
  }, [botName, kBotFormId, docContents, setKBotFormValue, state])

  const handleModalClose = useCallback(
    (resetForm = true) => {
      onCloseBotCreationFinished()
      if (resetForm) {
        clearFormState(state)
        deleteDocumentContent([botName], [kBotFormId])
      }
    },
    [botName, clearFormState, kBotFormId, deleteDocumentContent, onCloseBotCreationFinished, state]
  )

  const handleJSONUpload = (files: File[], fileConfigs: FileConfigs) => {
    onJSONUpload(files, state, uploadTokenMaxSize)
  }

  return (
    <>
      <ModalBox
        isOpen={isBotCreationFinishedOpen}
        modalHeader={t(`kBots.createEdit.${state}BotSuccess`)}
        modalBodyText={{
          text: t(`kBots.createEdit.${state}Success`, { name: getTranslatedValue(nameValues) }),
        }}
        onClose={handleModalClose}
      />
      <UploadModal
        botName={botName}
        isFileUploading={isJSONUploading}
        isOpen={isJSONUploadOpen}
        maxFileNameLength={nameMaxLength}
        maxFileSize={jsonMaxFileSize}
        maxNumFiles={uploadFileMaxCount}
        onClose={onJSONUploadClose}
        onError={(error: string) => setUploadJSONError(error)}
        onSubmit={handleJSONUpload}
        mapUploadTypesToText={[]}
        allowedUploadTypes={['json']}
        uploadError={uploadJSONError}
        uploadTokenMaxSize={0}
      />
      <CreateEditForm
        botName={botName}
        kBotFormId={kBotFormId}
        fileSubmit={handleFileSubmit}
        state={state}
        submissionErrors={submissionErrors}
        submit={handleSubmit}
      />
    </>
  )
}
