import { ButtonHTMLAttributes, forwardRef, ReactNode, useMemo } from 'react'
import { IconType } from 'react-icons'
import { BiRefresh, BiSolidSend } from 'react-icons/bi'
import { CircularProgress } from '@chakra-ui/progress'
import { Spinner } from '@chakra-ui/spinner'
import { Tooltip } from '@chakra-ui/tooltip'
import { Fade } from '@chakra-ui/transition'
import cx from 'classnames'
import kleoColors from 'styles/colors'

import { useThemeContext } from 'providers/ThemeProvider'

import { ButtonBaseProps, ButtonSize, IconButtonBaseProps, TextButtonBaseProps, WithStylingProps } from 'types/types'

import { ImageWithCache } from './ImageWithCache'

const imageSizeMap: Record<ButtonSize, string> = {
  xs: 'h-3 w-3',
  sm: 'h-3.5 w-3.5',
  base: 'h-4 w-4',
  lg: 'h-[18px] w-[18px]',
  xl: 'h-5 w-5',
  '2xl': 'h-6 w-6',
  '3xl': 'h-[30px] w-[30px]',
}

const getFocusRingClasses = (disabled: boolean | undefined, loading: boolean | undefined): string => {
  return disabled || loading ? '' : 'focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2'
}

const getCommonButtonClasses = (
  isLightMode: boolean,
  disabled: boolean | undefined,
  loading: boolean | undefined,
  buttonBackground: `bg-${string}` | undefined,
  fontColour: `text-${string}` | undefined
) => {
  return buttonBackground && fontColour
    ? cx(buttonBackground, fontColour, 'rounded-full transition', {
        'hover:bg-opacity-70': !disabled && !loading,
        'opacity-50 cursor-not-allowed': disabled || loading,
      })
    : cx('bg-opacity-10 rounded-full transition', {
        'bg-black': isLightMode,
        'bg-white': !isLightMode,
        'hover:bg-opacity-20': !disabled && !loading,
        'opacity-50 cursor-not-allowed': disabled || loading,
      })
}

type IconAndTextButtonType = TextButtonBaseProps & IconButtonBaseProps & WithStylingProps

export const IconAndTextButton = forwardRef<HTMLButtonElement, IconAndTextButtonType>(
  (props: IconAndTextButtonType, ref) => {
    const { buttonBackground, fontColour, Icon, text, size = 'base', disabled, loading, onClick, ...rest } = props
    const { isLightMode } = useThemeContext()

    const imagePath = isLightMode ? '../images/robotBlack.png' : '../images/robotWhite.png'

    // Determine if the button is considered "large" to set padding
    const isLargeButton = size === 'lg' || size === 'xl' || size === '2xl' || size === '3xl'
    const buttonPadding = isLargeButton ? 'px-4 py-1.5' : 'px-3 py-1'

    const combinedClasses = useMemo(() => {
      return cx(
        'flex items-center space-x-2',
        getCommonButtonClasses(isLightMode, disabled, loading, buttonBackground, fontColour),
        getFocusRingClasses(disabled, loading),
        buttonPadding
      )
    }, [buttonBackground, buttonPadding, fontColour, disabled, isLightMode, loading])

    const imageWithCache = useMemo(() => {
      return <ImageWithCache imagePath={imagePath} alt="Robot" className={imageSizeMap[size]} />
    }, [imagePath, size])

    return (
      <button
        ref={ref}
        onClick={disabled || loading ? undefined : onClick}
        disabled={disabled || loading}
        aria-disabled={disabled || loading}
        className={combinedClasses}
        {...rest}
      >
        {Icon ? (
          <span className="flex-shrink-0">
            <Icon className={`text-${size}`} />
          </span>
        ) : (
          imageWithCache
        )}
        <p className={`text-${size}`}>{text}</p>
      </button>
    )
  }
)

type IconAndTextButtonWithIndicatorType = TextButtonBaseProps &
  IconButtonBaseProps &
  (
    | {
        buttonBackground: `bg-${string}`
        showIndicator: boolean
      }
    | {
        buttonBackground: never
        showIndicator: never
      }
  )
const IndicatorWrapper = ({
  buttonBackground,
  children,
  showIndicator,
}: {
  buttonBackground: string
  children: ReactNode
  showIndicator: boolean
}) => {
  const { isLightMode } = useThemeContext()
  if (!showIndicator) return <div>{children}</div>
  return (
    <div
      className={`p-0.5 bg-gradient-to-r bg-[length:_300%_300%] animate-[gradient-move_4s_ease_infinite] rounded-full ${
        isLightMode
          ? 'from-kpmgLightBlue2 via-kpmgPurple to-kpmgPink'
          : 'from-kpmgLightBlue via-kpmgLightPurple to-kpmgLightPink'
      }`}
    >
      <div className={`rounded-full ${buttonBackground}`}>{children}</div>
    </div>
  )
}
export const IconAndTextButtonWithIndicator = (props: IconAndTextButtonWithIndicatorType) => {
  const { buttonBackground, Icon, onClick, showIndicator, text, size = 'base', ...rest } = props

  // Whenever we want to show the multi-coloured border to provide emphasis to the button, we will wrap the button in this

  return (
    <IndicatorWrapper buttonBackground={buttonBackground} showIndicator={showIndicator}>
      <IconAndTextButton Icon={Icon} onClick={onClick} text={text} size={size} {...rest} />
    </IndicatorWrapper>
  )
}

type IconButtonType = IconButtonBaseProps &
  WithStylingProps & { size?: ButtonSize } & (
    | {
        buttonBackground: `bg-${string}`
        showIndicator: boolean
      }
    | {
        buttonBackground?: never
        showIndicator?: never
      }
  )

export const IconButton = forwardRef<HTMLButtonElement, IconButtonType>((props: IconButtonType, ref) => {
  const { buttonBackground, Icon, onClick, size = 'lg', disabled, loading, showIndicator, fontColour, ...rest } = props
  const { isLightMode } = useThemeContext()

  const imagePath = isLightMode ? '../images/robotBlack.png' : '../images/robotWhite.png'

  const combinedClasses = useMemo(() => {
    return cx(
      'p-1.5',
      getCommonButtonClasses(isLightMode, disabled, loading, buttonBackground, fontColour),
      getFocusRingClasses(disabled, loading)
    )
  }, [buttonBackground, disabled, isLightMode, loading, fontColour])

  const imageWithCache = useMemo(() => {
    return <ImageWithCache imagePath={imagePath} alt="Robot" className={imageSizeMap[size]} />
  }, [imagePath, size])

  const buttonElement = (
    <button
      ref={ref}
      onClick={disabled || loading ? undefined : onClick}
      disabled={disabled || loading}
      aria-disabled={disabled || loading}
      className={combinedClasses}
      {...rest}
    >
      <span className="flex-shrink-0">{Icon ? <Icon className={`text-${size}`} /> : imageWithCache}</span>
    </button>
  )

  return showIndicator && buttonBackground ? (
    <IndicatorWrapper buttonBackground={buttonBackground} showIndicator={showIndicator}>
      {buttonElement}
    </IndicatorWrapper>
  ) : (
    buttonElement
  )
})

type TextButtonType = TextButtonBaseProps & WithStylingProps
export const TextButton = forwardRef<HTMLButtonElement, TextButtonType>((props, ref) => {
  const { buttonBackground, onClick, text, size = 'base', disabled, loading, fontColour, ...rest } = props
  const { isLightMode } = useThemeContext()

  // Determine if the button is considered "large" to set padding
  const isLargeButton = size === 'lg' || size === 'xl' || size === '2xl' || size === '3xl'
  const buttonPadding = isLargeButton ? 'px-4 py-1.5' : 'px-3 py-1'

  const colourClasses = useMemo(() => {
    return cx(`${fontColour} rounded-full bg-opacity-100 transition`, {
      'hover:bg-opacity-80': !disabled && !loading,
      'opacity-50 cursor-not-allowed': disabled || loading,
    })
  }, [fontColour, disabled, loading])

  const combinedClasses = useMemo(() => {
    return cx(
      buttonPadding,
      getFocusRingClasses(disabled, loading),
      fontColour && !buttonBackground
        ? colourClasses
        : getCommonButtonClasses(isLightMode, disabled, loading, buttonBackground, fontColour)
    )
  }, [buttonBackground, buttonPadding, fontColour, colourClasses, disabled, isLightMode, loading])

  return (
    <button
      disabled={disabled || loading}
      onClick={disabled || loading ? undefined : onClick}
      className={combinedClasses}
      ref={ref}
      {...rest}
    >
      <p className={`text-${size}`}>{text}</p>
    </button>
  )
})

type ChatBotSendButtonType = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean
}

export const ChatBotSendButton = (props: ChatBotSendButtonType) => {
  const { onClick, disabled, loading, ...rest } = props
  const { isLightMode } = useThemeContext()

  const combinedClasses = useMemo(() => {
    return cx('rounded-full p-2', getFocusRingClasses(disabled, loading), {
      'text-white bg-kpmgCobaltBlue hover:bg-kpmgCobaltBlueHover': isLightMode,
      'text-black bg-white hover:bg-whiteHover': !isLightMode,
      'opacity-50 cursor-not-allowed': disabled || loading,
    })
  }, [disabled, isLightMode, loading])

  return (
    <button onClick={disabled || loading ? undefined : onClick} className={combinedClasses} {...rest}>
      <span className="flex-shrink-0">
        <BiSolidSend className="text-lg" />
      </span>
    </button>
  )
}

type SidebarUtilityButtonType = ButtonHTMLAttributes<HTMLButtonElement> & {
  canFadeIn: boolean
  Icon: IconType
  isSidebarExpanded: boolean
  label: string
  loading?: boolean
} & WithStylingProps

export const SidebarUtilityButton = (props: SidebarUtilityButtonType) => {
  const {
    buttonBackground,
    canFadeIn,
    Icon,
    isSidebarExpanded,
    disabled,
    loading,
    label,
    onClick,
    fontColour,
    ...rest
  } = props

  const { isLightMode } = useThemeContext()

  const combinedClasses = useMemo(() => {
    return cx(
      'flex items-center space-x-1.5',
      getFocusRingClasses(disabled, loading),
      isSidebarExpanded && canFadeIn ? 'px-3 py-1.5' : 'p-1.5',
      getCommonButtonClasses(isLightMode, disabled, loading, buttonBackground, fontColour)
    )
  }, [buttonBackground, canFadeIn, disabled, isLightMode, isSidebarExpanded, loading, fontColour])

  return (
    <Tooltip
      className="px-2 py-1 text-xs"
      label={label}
      placement="right"
      hasArrow
      arrowSize={8}
      offset={[0, 13]}
      isDisabled={isSidebarExpanded}
    >
      <button onClick={disabled || loading ? undefined : onClick} className={combinedClasses} {...rest}>
        <span className="flex-shrink-0">
          <Icon className="text-xl" />
        </span>
        {canFadeIn && isSidebarExpanded && (
          <Fade in={true}>
            <p className="text-sm">{label}</p>
          </Fade>
        )}
      </button>
    </Tooltip>
  )
}

type ProgressButtonProps = ButtonBaseProps & {
  isInProgress: boolean
  progress: number
  progressIconSize: string
  size?: ButtonSize
  text?: string
}

export const ProgressButton = (props: ProgressButtonProps) => {
  const { disabled, isInProgress, onClick, progress, progressIconSize, size = 'base', text, ...rest } = props

  const { isLightMode } = useThemeContext()

  // Determine if the button is considered "large" to set padding
  const isLargeButton = size === 'lg' || size === 'xl' || size === '2xl' || size === '3xl'
  const buttonPadding = isLargeButton ? 'px-4 py-1.5' : text?.length ? 'px-3 py-1' : 'p-1.5'

  const combinedClasses = useMemo(() => {
    return cx(
      'relative flex items-center space-x-2',
      getCommonButtonClasses(isLightMode, disabled, undefined, undefined, undefined),
      getFocusRingClasses(disabled, undefined),
      buttonPadding
    )
  }, [buttonPadding, disabled, isLightMode])

  return (
    <button
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
      aria-disabled={disabled}
      className={combinedClasses}
      {...rest}
    >
      {isInProgress ? (
        <CircularProgress value={progress} color={kleoColors.kpmgBlue} thickness="10px" size={progressIconSize} />
      ) : (
        <BiRefresh className={cx('text-xl', { 'text-white': !isLightMode })} />
      )}
      {text && <p className={`text-${size}`}>{text}</p>}
    </button>
  )
}

type SpinnerButtonProps = ButtonBaseProps &
  WithStylingProps & {
    Icon?: IconType
    loading: boolean
    size?: ButtonSize
    text?: string
  } & (
    | {
        buttonBackground: `bg-${string}`
        showIndicator: boolean
      }
    | {
        buttonBackground?: never
        showIndicator?: never
      }
  )

export const SpinnerButton = (props: SpinnerButtonProps) => {
  const {
    buttonBackground,
    disabled,
    fontColour,
    Icon,
    loading,
    onClick,
    showIndicator,
    size = 'base',
    text,
    ...rest
  } = props

  const { isLightMode } = useThemeContext()

  // Determine if the button is considered "large" to set padding
  const isLargeButton = size === 'lg' || size === 'xl' || size === '2xl' || size === '3xl'
  const buttonPadding = isLargeButton ? 'px-4 py-1.5' : text?.length ? 'px-3 py-1' : 'p-1.5'

  const combinedClasses = useMemo(() => {
    return cx(
      'relative flex items-center space-x-2',
      getCommonButtonClasses(isLightMode, disabled, loading, buttonBackground, fontColour),
      getFocusRingClasses(disabled, undefined),
      buttonPadding
    )
  }, [buttonBackground, buttonPadding, disabled, fontColour, isLightMode, loading])

  const buttonElement = (
    <button onClick={disabled || loading ? undefined : onClick} className={combinedClasses} {...rest}>
      {loading ? <Spinner size="xs" /> : Icon ? <Icon className="mr-0 text-xl " /> : null}
      {text && <p className={`text-${size}`}>{text}</p>}
    </button>
  )

  return buttonBackground && showIndicator ? (
    <IndicatorWrapper buttonBackground={buttonBackground} showIndicator={showIndicator}>
      {buttonElement}
    </IndicatorWrapper>
  ) : (
    buttonElement
  )
}
